/**
 * Extracts a cookie from server side or client side. If this function
 * is called in server side, you must provide a request object.
 * @param {string} key - Cookie to be extracted.
 * @param {any} [req] - Request object provided by Next.js.
 * @returns {string|null|undefined}
 */
import { Http2ServerRequest } from 'http2'

import { BASE_DOMAIN } from '../config'

export const getCookie = (key: string, req: Http2ServerRequest) => {
  try {
    const cookies = req ? req.headers?.cookie : typeof window !== 'undefined' ? document?.cookie : null
    const cookie = cookies ? cookies.split(';').find((c) => c.trim().startsWith(`${key}=`)) : null

    if (!cookie) return undefined
    return cookie.split('=')[1]
  } catch (e) {
    console.log(`Unable to get ${key} from cookies`, e)
    return null
  }
}

/**
 * Generates a cookie
 * @param {string} key
 * @param {string} value
 * @param {string} [days]
 * @return {string}
 */
export const generateCookie = (key, value, days = 15) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  return (
    key + '=' + (value || '') + expires + '; path=/' + (BASE_DOMAIN !== 'localhost' ? `; domain=${BASE_DOMAIN}` : '')
  )
}

/**
 * Saves value to cookies in client side only
 * @param {string} key - Key of the cookie to be saved.
 * @param {string} value - Value of the cookie to be saved.
 * @param {number} days - Expiration time in days.
 * @return {string|null|undefined}
 */
export const setCookie = (key, value, days = 15) => {
  try {
    document.cookie = generateCookie(key, value, days)
  } catch (e) {
    console.log(`Unable to set ${key} to cookies`, e)
    return null
  }
}
