import { endOfMonth, format, startOfDay, startOfMonth } from 'date-fns'

import { ChallengeType, ImpactChallengeEvidence, JoinChallengeType } from '@/ts/types/challenges.types'
import { CountDef, SharedFile } from '@/ts/types/general.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const challengeURLEndpoint = `/gamification/impact-challenges`

export const findChallengesByPage = async ({ page }: { page: number }): Promise<CountDef<ChallengeType>> => {
  return axios
    .get(
      `${challengeURLEndpoint}?order=StartsAt&offset=${page * 5}&include=Author,ParticipantsCount,Participants,Status`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}
export const findAcceptedChallengesByPage = async ({ page }: { page: number }): Promise<CountDef<ChallengeType>> => {
  const end = startOfDay(new Date()).toISOString()
  return axios
    .get(
      `${challengeURLEndpoint}?order=StartsAt&offset=${
        page * 5
      }&include=Author,ParticipantsCount,Participants,Status&ApprovalStatus=accepted&IsPublic=true&ExpiresAt~ge=${end}&IsUniversal=true`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const findAcceptedLocationChallengesByPage = async ({
  page,
}: {
  page: number
}): Promise<CountDef<ChallengeType>> => {
  const end = startOfDay(new Date()).toISOString()
  return axios
    .get(
      `${challengeURLEndpoint}?order=StartsAt&offset=${
        page * 5
      }&include=Author,ParticipantsCount,Participants,Status&ApprovalStatus=accepted&IsPublic=true&ExpiresAt~ge=${end}&IsUniversal=false`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const findUserChallengeByPage = async (page: number, authorUUID?: string): Promise<CountDef<ChallengeType>> => {
  const authorQuery = authorUUID ? `&AuthorUUID=${authorUUID}` : ''
  return axios
    .get(
      `${challengeURLEndpoint}/user-challenges?order=StartsAt${authorQuery}&offset=${
        page * 5
      }&include=Author,ParticipantsCount,Participants,Status`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const findUserChallengeCount = async ({ authorUUID }: { authorUUID?: string }): Promise<number> => {
  return axios
    .get(`${challengeURLEndpoint}/user-challenges?AuthorUUID=${authorUUID}&offset=0&limit=0`)
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data.Count)
}

export const findChallengeByUUID = async ({
  challengeUUID,
  accessToken,
}: {
  challengeUUID: string
  accessToken?: string
}): Promise<ChallengeType> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`${challengeURLEndpoint}?UUID=${challengeUUID}&include=Author,ParticipantsCount,Participants,Status`)
    .then((res: { data: { Data: ChallengeType[] } }) => res.data.Data[0])
}

export const joinChallengeByChallengeUUIDAndAuthorUUID = async (
  challengeUUID: string,
  authorUUID: string
): Promise<JoinChallengeType> => {
  return axios
    .post(`${challengeURLEndpoint}/join`, {
      AuthorUUID: authorUUID,
      ImpactChallengeUUID: challengeUUID,
    })
    .then((res: { data: { Data: JoinChallengeType } }) => res.data.Data)
}

export const findEvidenceByChallengeUUIDAndAuthorUUID = async ({
  challengeUUID,
  authorUUID,
}: {
  challengeUUID: string
  authorUUID: string
}): Promise<ImpactChallengeEvidence> => {
  return axios
    .get(`${challengeURLEndpoint}/evidences?ImpactChallengeUUID=${challengeUUID}&AuthorUUID=${authorUUID}&Filter=all`)
    .then((res: { data: { Data: ImpactChallengeEvidence[] } }) => res.data.Data?.[0])
}

export const updateEvidenceByAuthorUUIDAndMilestoneIDAndImpactChallengeUUID = async ({
  challengeUUID,
  authorUUID,
  milestoneID,
  description,
  photo,
  video,
}: {
  challengeUUID: string
  authorUUID: string
  milestoneID: number
  description?: string
  photo: SharedFile
  video: string
}): Promise<ImpactChallengeEvidence> => {
  return axios
    .post(`${challengeURLEndpoint}/evidences/update`, {
      ImpactChallengeUUID: challengeUUID,
      AuthorUUID: authorUUID,
      MilestoneID: milestoneID,
      Description: description,
      Photo: photo,
      Video: video,
    })
    .then((res: { data: { Data: ImpactChallengeEvidence } }) => res.data.Data)
}

export const searchChallengesByQuery = async (query: string): Promise<CountDef<ChallengeType>> => {
  return axios
    .get(`${challengeURLEndpoint}/search?Query=${query}&offset=0&limit=10&include=Author,Status`)
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const findAllParticipantsByImpactChallengeUUID = async (
  page: number,
  challengeUUID?: string
): Promise<CountDef<ChallengeType>> => {
  return axios
    .get(
      `${challengeURLEndpoint}/evidences?ImpactChallengeUUID=${challengeUUID}&offset=${
        page * 5
      }limit=5&include=Author&Filter=all`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const findFolloweedParticipantsByImpactChallengeUUID = async (
  page: number,
  challengeUUID?: string
): Promise<CountDef<ChallengeType>> => {
  return axios
    .get(
      `${challengeURLEndpoint}/evidences?ImpactChallengeUUID=${challengeUUID}&offset=${
        page * 5
      }limit=5&include=Author&Filter=followee`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const findFollowersParticipantsByImpactChallengeUUID = async (
  page: number,
  challengeUUID?: string
): Promise<CountDef<ChallengeType>> => {
  return axios
    .get(
      `${challengeURLEndpoint}/evidences?ImpactChallengeUUID=${challengeUUID}&offset=${
        page * 5
      }limit=5&include=Author&Filter=follower`
    )
    .then((res: { data: { Data: CountDef<ChallengeType> } }) => res.data.Data)
}

export const createChallenge = async (values) => {
  return axios.post(`${challengeURLEndpoint}`, values).then((res) => res.data.Data)
}

export const findNearChallenges = async (location: string) => {
  const end = startOfDay(new Date()).toISOString()
  const res = await axios.get(
    `/gamification/impact-challenges/near-by?Address=${location}&include=Author,ParticipantsCount,Participants,Status&ApprovalStatus=accepted&IsPublic=true&ExpiresAt~ge=${end}`
  )
  return res.data.Data
}

export const findODSChallenges = async (ods) => {
  if (!ods) {
    return
  }
  const end = startOfDay(new Date()).toISOString()
  const res = await axios.get(
    `/gamification/impact-challenges?include=Author,ParticipantsCount,Participants,Status&ApprovalStatus=accepted&IsPublic=true&ExpiresAt~ge=${end}&ODS=${ods}`
  )
  return res.data.Data
}

export const findAllLocalizedChallenges = async () => {
  const end = startOfDay(new Date()).toISOString()
  const res = await axios.get(
    `/gamification/impact-challenges?include=Author,ParticipantsCount,Participants,Status&ApprovalStatus=accepted&IsPublic=true&ExpiresAt~ge=${end}&IsUniversal=false`
  )
  return res.data.Data
}

export const findMonthChallenges = async (date) => {
  const start = startOfMonth(date).toISOString()
  const end = endOfMonth(date).toISOString()

  const dateStart = format(new Date(start), 'yyyy-MM-dd')
  const dateEnds = format(new Date(end), 'yyyy-MM-dd')

  const res = await axios.get(
    `/gamification/impact-challenges?&include=Author,ParticipantsCount,Participants,Status&ApprovalStatus=accepted&IsPublic=true&StartsAt~ge=${dateStart}&StartsAt~le=${dateEnds}`
  )

  return res.data.Data
}
