import Link from 'next/link'
import { ReactNode } from 'react'

import { HTMLAttributeAnchorTarget, Url } from '@/ts/types/general.types'

export type CustomLinkTypes = 'internal' | 'external' | undefined

type Props = {
  /**
   * Content for profile link
   */
  children?: ReactNode | string
  /**
   * Type of profile
   */
  type?: CustomLinkTypes
  /**
   * OnClick Function related to link or a tag
   */
  onClick?: () => void
  href: Url
  rel?: string
  target?: HTMLAttributeAnchorTarget
  className?: string
  style?: object
  scroll?: boolean
}

const CustomLink = ({
  children,
  rel,
  type = 'internal',
  onClick,
  href,
  className,
  style,
  target,
  ...rest
}: Props): JSX.Element => {
  if (type === 'internal') {
    return (
      <Link href={href} passHref {...rest}>
        <button onClick={onClick} className={className} style={style} onKeyDown={onClick}>
          {children}
        </button>
      </Link>
    )
  } else {
    return (
      <a
        href={href as string}
        onClick={onClick}
        className={className}
        style={style}
        rel={rel}
        target={target}
        {...rest}
      >
        {children}
      </a>
    )
  }
}

export default CustomLink
