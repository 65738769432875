import OpacityTransition from '@/components/base/OpacityTransition'
import { Post } from '@/ts/types/post.types'

import PostUploader from './PostUploader'

type Props = {
  posts: Post[]
  onSave: (newPost: Post) => void
  customKey: string
  namespace: string
}

const NewPostsList = ({ posts, onSave, customKey, namespace }: Props): JSX.Element => {
  return (
    <OpacityTransition show={true}>
      <div>
        {posts.map((post) => (
          <PostUploader key={post.key} formValues={post} onSave={onSave} customKey={customKey} namespace={namespace} />
        ))}
      </div>
    </OpacityTransition>
  )
}

export default NewPostsList
