import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Community = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.3774 12.5203C28.5239 12.5203 30.2641 10.7802 30.2641 8.63368C30.2641 6.48717 28.5239 4.74707 26.3774 4.74707C24.2308 4.74707 22.4907 6.48717 22.4907 8.63368C22.4907 10.7802 24.2308 12.5203 26.3774 12.5203Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08491 12.5203C7.23146 12.5203 8.97161 10.7802 8.97161 8.63368C8.97161 6.48717 7.23146 4.74707 5.08491 4.74707C2.93836 4.74707 1.19824 6.48717 1.19824 8.63368C1.19824 10.7802 2.93836 12.5203 5.08491 12.5203Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7175 13.9004C29.2667 13.9004 31.3332 15.9671 31.3332 18.5163C31.3332 20.2469 31.3332 22.056 31.3332 23.1423C31.3332 23.4088 31.2273 23.6646 31.0389 23.853C30.8502 24.0414 30.5946 24.1473 30.328 24.1473C27.9356 24.1473 22.1011 24.1473 22.1011 24.1473V18.5163C22.1011 15.9671 24.1676 13.9004 26.7168 13.9004C26.717 13.9004 26.7173 13.9004 26.7175 13.9004Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28294 13.9004C7.83216 13.9004 9.89864 15.9671 9.89864 18.5163C9.89864 21.2355 9.89864 24.1473 9.89864 24.1473H1.67176C1.40519 24.1473 1.14947 24.0414 0.960814 23.853C0.772403 23.6646 0.666504 23.4088 0.666504 23.1423C0.666504 22.056 0.666504 20.2469 0.666504 18.5163C0.666504 15.9671 2.73298 13.9004 5.2822 13.9004C5.28245 13.9004 5.2827 13.9004 5.28294 13.9004Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0907 9.73791C18.5436 9.73791 20.532 7.74951 20.532 5.29669C20.532 2.84387 18.5436 0.855469 16.0907 0.855469C13.6378 0.855469 11.6494 2.84387 11.6494 5.29669C11.6494 7.74951 13.6378 9.73791 16.0907 9.73791Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1545 11.499C18.4495 11.499 19.6917 12.0136 20.6074 12.9293C21.5232 13.8451 22.0375 15.087 22.0375 16.3822C22.0375 19.9427 22.0375 24.1467 22.0375 24.1467H10.0161V16.3822C10.0161 15.087 10.5307 13.8451 11.4464 12.9293C12.3622 12.0136 13.6041 11.499 14.8994 11.499C15.6431 11.499 16.4108 11.499 17.1545 11.499Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Community
