import { setCookie } from 'cookies-next'
import { getServerSession } from 'next-auth'

import Container from '@/components/base/Container'
import Greetings from '@/components/pages/index/Greetings'
import { OnboardingModal } from '@/components/pages/index/OnboardingModal'
import { StorieSnippet } from '@/components/pages/index/StorieSnippet'
import ChallengeSnippet from '@/components/shared/ChallengeSnippet/ChallengeSnippet'
import Feed from '@/components/shared/Feed/Feed'
import InitiativesSnippet from '@/components/shared/InitiativesSnippet/InitiativesSnippet'
import Layout from '@/components/shared/Layout'
import { authOptions } from '@/pages/api/auth/[...nextauth]'

/**
 * Home function.
 * @constructor
 */
export default function Home({ token }) {
  setCookie('sunet-token', token)

  return (
    <>
      <Layout SEOProps={{ title: `Inicio` }}>
        <Container className="relative flex flex-row items-center justify-center w-full m-auto" maxWidth={700}>
          <div className="flex flex-col min-w-0 gap-4 py-4 w-full">
            <OnboardingModal />
            <Greetings />
            <StorieSnippet />

            <ChallengeSnippet />

            <InitiativesSnippet />

            <Feed />
          </div>
        </Container>
      </Layout>
    </>
  )
}

Home.requireAuth = true

export async function getServerSideProps(context) {
  const session = await getServerSession(context.req, context.res, authOptions)

  console.log('sessionToken: ', session)

  return { props: { token: session?.accessToken || '' } }
}
