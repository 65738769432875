import { isAfter } from 'date-fns'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { FiChevronRight } from 'react-icons/fi'

import Card from '@/components/base/Card'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import SkeletonBlock from '@/components/base/SkeletonBlock'
import useDrag from '@/hooks/useDrag'
import useInitiativeSnippet from '@/hooks/useInitiativeSnippet'
import { scrollVisibilityApiType } from '@/ts/types/general.types'

import LeftArrow from '../LeftArrow'
import onWheel from '../onWheel'
import RightArrow from '../RightArrow'
import InitiativesSnippetCard from './InitiativesSnippetCard'

const InitiativesSnippet = (): JSX.Element => {
  const { data, loading, error } = useInitiativeSnippet()
  const { dragStart, dragStop, dragMove, dragging } = useDrag()

  const { push } = useRouter()

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: MouseEvent) =>
      dragMove(ev, (posDiff: number) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  const handleItemClick = (itemUUID: string) => () => {
    if (dragging) {
      return false
    }

    push(`/initiatives/${itemUUID}`)
  }

  const currentInitiatives = data?.filter((item) => isAfter(new Date(item?.EndsAt), new Date())).slice(0, 5) || []

  return (
    <div>
      {loading ||
        (currentInitiatives?.length ? (
          <Link href="/initiatives">
            <div className="flex flex-row items-center w-full justify-between md:justify-start gap-2">
              <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
                Iniciativas que te necesitan
              </CustomText>
              <FiChevronRight size={24} />
            </div>
          </Link>
        ) : null)}

      <div onMouseLeave={dragStop} className="mb-4 mt-2">
        {loading && !error && (
          <div className="overflow-hidden min-w-0 flex relative" style={{ height: 286 }}>
            <div className="absolute top-0 flex gap-4">
              <Card className="!p-0" style={{ height: 278, width: 230 }}>
                <SkeletonBlock className="bg-white" width={230} height={278} />
              </Card>
              <Card className="!p-0" style={{ height: 278, width: 230 }}>
                <SkeletonBlock className="bg-white" width={230} height={278} />
              </Card>
            </div>
          </div>
        )}
        {!loading && currentInitiatives?.length ? (
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            scrollContainerClassName="pb-2"
          >
            {currentInitiatives?.map((val, index) => (
              <InitiativesSnippetCard key={index} index={index} initiative={val} onClick={handleItemClick(val.UUID)} />
            ))}
          </ScrollMenu>
        ) : null}
      </div>
    </div>
  )
}

export default InitiativesSnippet
