import clsx from 'clsx'
import { FiRefreshCcw } from 'react-icons/fi'

import Button, { ButtonAppearance } from '@/components/base/Button'
import OpacityTransition from '@/components/base/OpacityTransition'

type Props = {
  visible: boolean
  onClick?: () => void
}

const LoadNewButton = ({ visible = false, onClick }: Props): JSX.Element => {
  return (
    <div className="sticky w-full top-22 left-0 text-center pointer-events-none" style={{ zIndex: 9600 }}>
      <div className="absolute left-0 right-0 text-center mt-3">
        <div className="mx-auto">
          <OpacityTransition show={visible}>
            <Button
              appearance={ButtonAppearance.PrimaryOutline}
              className={clsx(
                'rounded-md px-6 py-2 shadow-lg  inline-flex items-center justify-center bg-white',
                visible ? 'pointer-events-auto' : 'pointer-events-none'
              )}
              onClick={onClick}
            >
              <FiRefreshCcw className="text-xl text-brand m-auto" />
              <span className="text-brand font-bold">Comprobar nuevas publicaciones</span>
            </Button>
          </OpacityTransition>
        </div>
      </div>
    </div>
  )
}

export default LoadNewButton
