import Link from 'next/link'
import { useRouter } from 'next/router'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { FiChevronRight } from 'react-icons/fi'

import Card from '@/components/base/Card'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import SkeletonBlock from '@/components/base/SkeletonBlock'
import useChallengeSnippet from '@/hooks/useChallengeSnippet'
import useSunetAuthors from '@/hooks/useSunetAuthors'

import LeftArrow from '../LeftArrow'
import RightArrow from '../RightArrow'
import ChallengeSnippetCard from './ChallengeSnippetCard'

const ChallengeSnippet = (): JSX.Element => {
  const { data, loading, error } = useChallengeSnippet()
  const { author } = useSunetAuthors()

  const { push } = useRouter()

  const handleItemClick = (itemUUID: string) => () => {
    push(`/challenges/${itemUUID}?evidenceAuthorUUID=${author.UUID}`)
  }

  const currentChallenges = data?.slice(0, 5) || []

  return (
    <div>
      {loading ||
        (currentChallenges?.length ? (
          <Link href="/challenges">
            <div className="flex flex-row items-center w-full justify-between md:justify-start gap-2">
              <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
                Desafíos con impacto
              </CustomText>
              <FiChevronRight size={24} />
            </div>
          </Link>
        ) : null)}

      <div className="mb-4 mt-2">
        {loading && !error ? (
          <div className="overflow-hidden min-w-0 flex relative" style={{ height: 214 }}>
            <div className="absolute top-0 flex gap-4">
              <Card className="!p-0" style={{ height: 206, width: 230 }}>
                <SkeletonBlock className="bg-white" width={230} height={206} />
              </Card>
              <Card className="!p-0" style={{ height: 206, width: 230 }}>
                <SkeletonBlock className="bg-white" width={230} height={206} />
              </Card>
            </div>
          </div>
        ) : null}

        {!loading && currentChallenges?.length ? (
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} scrollContainerClassName="pb-2">
            {currentChallenges?.map((val, index) => (
              <ChallengeSnippetCard key={index} challenge={val} onClick={handleItemClick(val.UUID)} />
            ))}
          </ScrollMenu>
        ) : null}
      </div>
    </div>
  )
}

export default ChallengeSnippet
