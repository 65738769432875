import { useState } from 'react'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import CustomText, { FontWeight } from '@/components/base/CustomText'
import useFollow from '@/hooks/useFollow'

import Avatar from '../Avatar'
type Props = {
  /**
   * ClassNames
   */
  className?: string
  /**
   * Style inline object
   */
  text?: string
  /**
   * Activate Lorem Ipsum for testing purposes
   */
  image?: string
  /**
   * initiative
   */
  name: string

  levelColor: string

  onClick: () => void

  size: number
}

const RecommendedProfileSnippetCard = ({
  image,
  name,
  onClick,
  size = 88,
  profile,
  levelColor,
}: Props): JSX.Element => {
  const { handleToggle } = useFollow({
    profileUUID: profile.UUID,
  })

  const [followed, setFollowed] = useState(false)

  const handleFollow = (e) => {
    e.stopPropagation()
    setFollowed(true)
    handleToggle()
  }

  return (
    <button onClick={onClick}>
      <Card className="!py-4 !px-2 hover:opacity-80 h-full flex flex-col justify-between items-center">
        <div style={{ maxHeight: size, height: size, width: size }} className="flex justify-center mb-1">
          <Avatar
            levelColor={levelColor}
            text={name}
            className="w-20 h-20 text-white text-sm !rounded-full "
            image={image}
            borderWidth="lg"
          />
        </div>
        <Button appearance={ButtonAppearance.Primary} size={ButtonSize.Medium} onClick={handleFollow}>
          {!followed ? 'Seguir' : 'Seguido'}
        </Button>
        <div className="px-2 mt-2 flex-1">
          <CustomText className="truncate-2 text-center" weight={FontWeight.SemiBold}>
            {name}
          </CustomText>
        </div>
      </Card>
    </button>
  )
}

export default RecommendedProfileSnippetCard
