import { startOfDay } from 'date-fns'

import { CompanyUser } from '@/ts/types/company.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const findCompanyByUUIDAndBlocks = (userUUID: string): Promise<CompanyUser> => {
  return axios
    .get(`/companies/${userUUID}?include=Blocks`)
    .then((res: { data: { Data: CompanyUser } }) => res.data.Data)
}

export const findCompanyByUUID = ({ companyUUID }: { companyUUID: string }): Promise<CompanyUser> => {
  return axios.get(`/companies/${companyUUID}`).then((res: { data: { Data: CompanyUser } }) => res.data.Data)
}

export const searchCompaniesByQuery = (query: string): Promise<CompanyUser> => {
  return axios
    .get(`/companies/search?Query=${query}&IsPublished=true&offset=0&limit=10&order=-Similarity`)
    .then((res: { data: { Data: CompanyUser } }) => res.data.Data)
}

export const findCompaniesByUserUUID = async (userUUID: string) => {
  const res = await axios.get(`/users/roles?UserUUID=${userUUID}`)
  const result = res.data.Data
  const companies = await Promise.all(result.map((role) => axios.get(`/companies/${role?.ResourceUUID}`)))
  return companies.map((item) => item.data.Data)
}

export const getFiveRanking = async (companyUUID: string) => {
  const res = await axios.get(`/gamification/ranking/five-positions?AuthorUUID=${companyUUID}&include=Author`)
  return res.data.Data
}

export const updateCompany = async (companyUUID, values) => {
  return await axios.put(`/companies/${companyUUID}`, values)
}

export const updateAnnualReport = async (companyUUID, values) => {
  return await axios.post(`/companies/${companyUUID}/yearly-report`, values)
}

export const getCommitment = async (companyUUID: string) => {
  const now = startOfDay(new Date()).toISOString()
  const res = await axios.get(`/companies/commitments?CompanyUUID=${companyUUID}&To~gt=${now}&order=-From&limit=1`)
  return res.data.Data
}

export const getGoalsResume = async (companyUUID, accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  const now = startOfDay(new Date()).toISOString()
  const commintmentRes = await axiosInstance.get(
    `/companies/commitments?CompanyUUID=${companyUUID}&To~gt=${now}&order=-From&limit=1`
  )

  const commintment = commintmentRes.data.Data

  const odsData = await axiosInstance.get(`/companies/commitments/${commintment[0].UUID}/goals-resume`)

  return odsData.data.Data
}

export const markCompleteMessageAsSended = async (companyGoalUUID: string) => {
  return await axios.post(`/companies/goals/${companyGoalUUID}/complete`, null)
}

export const makeCompleteActionsPost = async (companyGoalUUID: string) => {
  const res = await axios.post(`/companies/goals/${companyGoalUUID}/post`, null)
  return res.data.Data
}

export const getAllODSWithCompanyActions = async (companyUUID, accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  const res = await axiosInstance.get(`/companies/${companyUUID}/ods?include=Actions`)
  return res.data.Data
}

export const submitYearlyPlan = async (companyUUID, values) => {
  return await axios.post(`/companies/${companyUUID}/yearly-ods-actions`, values)
}

export const addYearlyProgress = async (values) => {
  const res = await axios.post(`/companies/actions`, values)
  return res.data.Data
}

export const getYearlyForm = async (companyUUID, accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  const res = await axiosInstance.get(`/companies/goals?CompanyUUID=${companyUUID}&include=ActionsDetail`)
  return res.data.Data
}
export const createCompany = async (values) => {
  const res = await axios.post(`/companies`, values)
  return res.data.Data
}

export const createUserRole = async (values) => {
  const res = await axios.post(`/users/roles`, values)
  return res.data.Data
}
