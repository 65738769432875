import { useCallback, useState } from 'react'

interface ReturnProps<T> {
  exec: (...args: unknown[]) => Promise<'ok' | NonNullable<Awaited<T>> | null>
  execWithCallback: (...args: unknown[]) => (callback: (...args: unknown[]) => void) => Promise<null>
  loading: boolean
  error: unknown
}

const useRequest = <T>(promise: (...args: unknown[]) => Promise<T>): ReturnProps<T> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const exec = useCallback(
    async (...args: unknown[]) => {
      setError(null)
      setLoading(true)

      try {
        return (await promise(...args)) || 'ok'
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }

      return null
    },
    [promise]
  )

  const execWithCallback = useCallback(
    (...args: unknown[]) =>
      async (callback: (...args: unknown[]) => void) => {
        setError(null)
        setLoading(true)

        try {
          const result = await promise(...args)
          callback && (await callback(result || 'ok'))
        } catch (e) {
          setError(e)
        } finally {
          setLoading(false)
        }

        return null
      },
    [promise]
  )

  return { exec, execWithCallback, loading, error }
}

export default useRequest
