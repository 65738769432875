import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Flag = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M19.5097 7.1875L22.6119 3.06954C23.0517 2.48592 23.1227 1.71542 22.7959 1.06087C22.4691 0.406333 21.8126 0 21.0805 0H3.83333C1.71925 0 0 1.71925 0 3.83333V22.0417C0 22.5716 0.429333 23 0.958333 23C1.48733 23 1.91667 22.5716 1.91667 22.0417V14.375H21.0814C21.8136 14.375 22.47 13.9677 22.7968 13.3141C23.1236 12.6605 23.0527 11.89 22.6128 11.3055L19.5107 7.1875H19.5097ZM1.91667 12.4583V3.83333C1.91667 2.77629 2.77629 1.91667 3.83333 1.91667H21.0814L17.5452 6.61058C17.2883 6.95175 17.2883 7.42229 17.5452 7.76346L21.0814 12.4574H1.91667V12.4583Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  )
}

export default Flag
