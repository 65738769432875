import React from 'react'

import { SvgIcon } from '../CustomIcon'

const iluFaqs = ({ size, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M60 30C60 36.7854 57.7473 43.0446 53.949 48.0707C53.3357 48.8823 51.9813 49.0002 51.2904 49.7446C50.582 50.5076 50.535 51.8952 49.7514 52.5812C44.4744 57.2005 37.5641 60 30 60C21.8756 60 14.5055 56.7704 9.10336 51.5251C8.55188 50.9897 8.61891 50.1436 8.10973 49.5674C7.60055 48.9912 6.51527 48.685 6.05098 48.0707C2.2527 43.0446 0 36.7854 0 30C0 13.4314 13.4314 0 30 0C46.5686 0 60 13.4314 60 30Z"
          fill="#10505D"
        />
        <path
          d="M51.3955 0H8.60482C7.19506 0 6.05225 1.14281 6.05225 2.55246V20.1008C6.05225 21.5105 7.19506 22.6533 8.60471 22.6533V24.911C8.60471 25.0747 8.80264 25.1566 8.91842 25.041L11.3061 22.6533H51.3954C52.805 22.6533 53.9478 21.5105 53.9478 20.1008V2.55246C53.9479 1.14281 52.8051 0 51.3955 0V0Z"
          fill="#37C47A"
        />
        <path
          d="M48.8426 12.2051H43.7998C43.3144 12.2051 42.9209 11.8117 42.9209 11.3262C42.9209 10.8407 43.3144 10.4473 43.7998 10.4473H48.8427C49.3281 10.4473 49.7216 10.8407 49.7216 11.3262C49.7216 11.8117 49.3281 12.2051 48.8426 12.2051Z"
          fill="white"
        />
        <path
          d="M40.2844 12.2051H28.6621C28.1767 12.2051 27.7832 11.8117 27.7832 11.3262C27.7832 10.8407 28.1767 10.4473 28.6621 10.4473H40.2844C40.7698 10.4473 41.1633 10.8407 41.1633 11.3262C41.1633 11.8117 40.7698 12.2051 40.2844 12.2051Z"
          fill="white"
        />
        <path
          d="M43.8 18.5859H28.6621C28.1767 18.5859 27.7832 18.1925 27.7832 17.707C27.7832 17.2215 28.1767 16.8281 28.6621 16.8281H43.8C44.2854 16.8281 44.6789 17.2215 44.6789 17.707C44.6789 18.1925 44.2854 18.5859 43.8 18.5859Z"
          fill="white"
        />
        <path
          d="M48.843 5.82422H28.6621C28.1767 5.82422 27.7832 5.43082 27.7832 4.94531C27.7832 4.4598 28.1767 4.06641 28.6621 4.06641H48.843C49.3284 4.06641 49.7219 4.4598 49.7219 4.94531C49.7219 5.43082 49.3285 5.82422 48.843 5.82422Z"
          fill="white"
        />
        <path
          d="M53.9478 31.4236C53.9478 30.0139 52.805 28.8711 51.3954 28.8711H8.60471C7.19506 28.8711 6.05225 30.0139 6.05225 31.4236V48.0705C6.98072 49.2991 8.00189 50.4535 9.10486 51.5244H48.6941L49.7505 52.5808C51.2986 51.2257 52.7059 49.7142 53.9479 48.0705V31.4236H53.9478Z"
          fill="#418BC4"
        />
        <path
          d="M34.0795 41.0742H11.1631C10.6777 41.0742 10.2842 40.6808 10.2842 40.1953C10.2842 39.7098 10.6777 39.3164 11.1631 39.3164H34.0795C34.5649 39.3164 34.9584 39.7098 34.9584 40.1953C34.9584 40.6808 34.5649 41.0742 34.0795 41.0742Z"
          fill="white"
        />
        <path
          d="M34.0791 47.4551H16.2056C15.7202 47.4551 15.3267 47.0617 15.3267 46.5762C15.3267 46.0907 15.7202 45.6973 16.2056 45.6973H34.0791C34.5645 45.6973 34.958 46.0907 34.958 46.5762C34.958 47.0617 34.5645 47.4551 34.0791 47.4551Z"
          fill="white"
        />
        <path
          d="M34.0796 34.6934H21.5239C21.0385 34.6934 20.645 34.3 20.645 33.8145C20.645 33.3289 21.0385 32.9355 21.5239 32.9355H34.0796C34.565 32.9355 34.9585 33.3289 34.9585 33.8145C34.9585 34.3 34.565 34.6934 34.0796 34.6934Z"
          fill="white"
        />
        <path
          d="M18.0081 34.6934H11.1631C10.6777 34.6934 10.2842 34.3 10.2842 33.8145C10.2842 33.3289 10.6777 32.9355 11.1631 32.9355H18.0081C18.4935 32.9355 18.887 33.3289 18.887 33.8145C18.887 34.3 18.4935 34.6934 18.0081 34.6934Z"
          fill="white"
        />
        <path
          d="M19.6542 4V7.42142H16.3949V4H19.6542ZM19.7173 9.40334C19.7173 9.79277 19.6822 10.1752 19.6121 10.5508C19.5561 10.9124 19.4089 11.2601 19.1706 11.5939C18.9463 11.9138 18.5748 12.2058 18.0561 12.4701C17.9019 12.5535 17.6776 12.6648 17.3832 12.8039C17.1028 12.943 16.8154 13.1099 16.521 13.3046C16.2266 13.4993 15.9743 13.7218 15.764 13.9722C15.5678 14.2086 15.4696 14.4729 15.4696 14.765C15.4696 15.0709 15.5958 15.3213 15.8481 15.516C16.1005 15.7107 16.3949 15.8081 16.7313 15.8081C17.0678 15.8081 17.3411 15.7246 17.5514 15.5577C17.7757 15.4047 17.9579 15.2239 18.0981 15.0153C18.2383 14.8067 18.3575 14.6398 18.4556 14.5146L21 16.3088C20.6495 17.1572 20.0748 17.8178 19.2757 18.2907C18.4907 18.7636 17.5935 19 16.5841 19C16.0514 19 15.5117 18.9235 14.965 18.7705C14.4322 18.6175 13.9416 18.3741 13.493 18.0403C13.0444 17.7065 12.6799 17.2684 12.3995 16.726C12.1332 16.1836 12 15.5229 12 14.7441C12 14.0765 12.1262 13.4715 12.3785 12.9291C12.6308 12.3727 13.0654 11.886 13.6822 11.4687C14.0888 11.1905 14.5023 10.9541 14.9229 10.7594C15.3575 10.5647 15.722 10.37 16.0164 10.1752C16.3107 9.96662 16.4579 9.70932 16.4579 9.40334H19.7173Z"
          fill="white"
        />
        <path
          d="M41.3458 48L41.3458 44.5786L44.6051 44.5786L44.6051 48L41.3458 48ZM41.2827 42.5967C41.2827 42.2072 41.3178 41.8248 41.3879 41.4492C41.4439 41.0876 41.5911 40.7399 41.8294 40.4061C42.0537 40.0862 42.4252 39.7942 42.9439 39.5299C43.0981 39.4465 43.3224 39.3352 43.6168 39.1961C43.8972 39.057 44.1846 38.8901 44.479 38.6954C44.7734 38.5007 45.0257 38.2782 45.236 38.0278C45.4322 37.7914 45.5304 37.5271 45.5304 37.235C45.5304 36.9291 45.4042 36.6787 45.1519 36.484C44.8995 36.2893 44.6051 36.1919 44.2687 36.1919C43.9322 36.1919 43.6589 36.2754 43.4486 36.4423C43.2243 36.5953 43.0421 36.7761 42.9019 36.9847C42.7617 37.1933 42.6425 37.3602 42.5444 37.4854L40 35.6912C40.3505 34.8428 40.9252 34.1822 41.7243 33.7093C42.5093 33.2364 43.4065 33 44.4159 33C44.9486 33 45.4883 33.0765 46.035 33.2295C46.5678 33.3825 47.0584 33.6259 47.507 33.9597C47.9556 34.2935 48.3201 34.7316 48.6005 35.274C48.8668 35.8164 49 36.4771 49 37.2559C49 37.9235 48.8738 38.5285 48.6215 39.0709C48.3692 39.6273 47.9346 40.114 47.3178 40.5313C46.9112 40.8095 46.4977 41.0459 46.0771 41.2406C45.6425 41.4353 45.278 41.63 44.9836 41.8248C44.6893 42.0334 44.5421 42.2907 44.5421 42.5967L41.2827 42.5967Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default iluFaqs
