import useSWR from 'swr'

import usePrevious from './usePrevious'

type PaginatedRequestProps<P extends unknown[]> = [page: number, pageSize: number, ...rest: P]

type PropsWithPaginatedResponse<T, P extends unknown[]> = {
  paginatedRequest: (...params: PaginatedRequestProps<P>) => Promise<T>
  page: number
  pageSize: number
}

type BaseProps<T, P extends unknown[]> = {
  requestKey: string
  params?: P
  request?: (...params: P) => Promise<T>
  disabled?: boolean
}

type Props<T, P extends unknown[]> = BaseProps<T, P> | (BaseProps<T, P> & PropsWithPaginatedResponse<T, P>)

type ReturnProps<T> = [
  data: T | null,
  loading: boolean,
  error: Error | null | undefined,
  revalidate: () => Promise<void>,
  isValidating: boolean
]

const useRequest2 = <T, P extends unknown[]>({
  requestKey,
  params,
  request,
  disabled,
  ...rest
}: Props<T, P>): ReturnProps<T> => {
  const parsedParams = params || []

  console.log([...parsedParams, requestKey])
  const { data, error, mutate, isValidating } = useSWR(
    request && !disabled ? [...parsedParams, requestKey] : null,
    (args: unknown[]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const params = [...args] as P
      if (request) {
        return request(...params)
      }
      return null
    }
  )

  const {
    data: paginatedData,
    error: paginatedError,
    mutate: paginatedMutate,
    isValidating: paginatedIsValidating,
  } = useSWR(
    'paginatedRequest' in rest && !disabled ? [rest.page, rest.pageSize, ...parsedParams, requestKey] : null,
    (...args: unknown[]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const params = [...args] as PaginatedRequestProps<P>
      if ('paginatedRequest' in rest) {
        return rest.paginatedRequest(...params)
      }
      return null
    }
  )

  const revalidate = async () => {
    await mutate()
    await paginatedMutate()
  }

  // Used on page change to maintain the old state.
  const previousPaginatedData = usePrevious(paginatedData, true)

  if ('paginatedRequest' in rest) {
    const loading = !paginatedData && !paginatedError
    const parsedData = (paginatedData || previousPaginatedData) as T
    return [parsedData, loading, paginatedError, revalidate, paginatedIsValidating]
  } else {
    const loading = !data && !error
    return [data || null, loading, error, revalidate, isValidating]
  }
}

export default useRequest2
