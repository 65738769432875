import clsx from 'clsx'
import Image from 'next/image'
import { ReactNode } from 'react'

type Props = {
  /**
   * Content for Card
   */
  children?: ReactNode | string
  /**
   * ClassNames
   */
  className?: string
  /**
   * Bg Color for Circle
   */
  color?: string
  /**
   * Image URL
   */
  image?: string
}

const Circle = ({ children, className, color, image, ...rest }: Props): JSX.Element => {
  return (
    <div
      className={clsx('flex items-center justify-center overflow-hidden relative w-full h-full', className)}
      style={{ background: color }}
      {...rest}
    >
      {children}
      {image ? (
        <div className="absolute w-full h-full top-0 left-0 object-cover bg-white">
          <Image src={image} alt="avatar-img" className="object-cover self-center m-auto w-full h-full" fill={true} />
        </div>
      ) : null}
    </div>
  )
}

export default Circle
