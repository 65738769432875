import Card from '@/components/base/Card'
import LoadingWrapper from '@/components/base/LoadingWrapper'
import usePostUploader from '@/hooks/usePostUploader'
import { Post as PostType } from '@/ts/types/post.types'

import DefaultContent from '../Post/Content/DefaultContent'
import PostAddons from '../Post/PostAddons'
import PostHeader from '../Post/PostHeader'

type Props = {
  formValues: PostType
  onSave: (newPost: PostType) => void
  customKey: string
  namespace: string
}

const PostUploader = ({ formValues, onSave, customKey, namespace }: Props): JSX.Element => {
  const { error } = usePostUploader({
    values: formValues,
    onSuccess: onSave,
    customKey,
    namespace,
  })

  console.log(formValues, 'Formvalues')

  return (
    <Card className="sm:rounded-lg overflow-hidden-z border border-gray-200 sm:!pb-6 !mb-4 lg:!mb-6">
      <LoadingWrapper loading={!error}>
        <div className="px-5">
          <PostHeader
            post={formValues}
            Author={formValues?.Included?.Author}
            postUUID={formValues?.UUID}
            postCreatedAt={formValues?.CreatedAt}
            ownSubscription={formValues?.Included?.OwnSubscription}
            ownBookmark={formValues?.Included?.OwnBookmark}
            index={0}
          />
          <div className="mt-5">
            <DefaultContent
              content={formValues?.Content as string}
              postUUID={formValues.UUID}
              hashtags={formValues.Hashtags}
              mentions={formValues.Mentions}
            />

            {formValues?.Metadata ? <PostAddons post={formValues} className="pt-3" /> : null}
          </div>
        </div>

        {error ? (
          <div className="absolute w-full h-full top-0 left-0 z-40 flex items-center justify-center bg-gray-600 bg-opacity-25 rounded-lg p-3">
            <div className="shadow-sunet p-3 rounded-lg bg-white">
              <p className="text-gray-700 font-medium text-center">
                <span>Ocurrió un problema. Intentalo nuevamente.</span>
              </p>
            </div>
          </div>
        ) : null}
      </LoadingWrapper>
    </Card>
  )
}

export default PostUploader
