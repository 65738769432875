import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { NoMarginModalOnboard } from '@/components/base/NoMarginModalOnboard'

import { Slide1 } from './onboarding/Slide1'
import { Slide2 } from './onboarding/Slide2'
import { Slide3 } from './onboarding/Slide3'
import { Slide4 } from './onboarding/Slide4'
import { Slide5 } from './onboarding/Slide5'
import { Slide6 } from './onboarding/Slide6'

export const OnboardingModal = () => {
  const { query } = useRouter()

  const shouldOpen = !!query?.onboard

  const [isOpen, setIsOpen] = useState(shouldOpen)
  const [currentSlide, setCurrentSlide] = useState(0)

  const slide = slides[currentSlide]

  const isLast = currentSlide === slides.length - 1
  const handleNext = () => {
    if (isLast) {
      setIsOpen(false)
    } else {
      setCurrentSlide((prev) => prev + 1)
    }
  }

  return (
    <div>
      <NoMarginModalOnboard isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="pb-8 relative max-h-[90vh]" style={{ backgroundColor: slide.bgColor }}>
          {slide.image}
          <section className="absolute bottom-6 flex flex-col justify-between items-center gap-8 sm:gap-16 px-4 sm:px-8">
            <div className="flex flex-col gap-8">
              <p style={{ color: slide.textColor }} className="text-white text-center font-extrabold text-3xl">
                {slide.title}
              </p>
              <div className="sm:min-h-[150px]">
                <p
                  style={{ color: slide.textColor }}
                  className="text-white text-center font-semibold sm:font-bold sm:text-lg"
                >
                  {slide.text}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-8 w-full">
              <div className="flex items-center gap-4">
                {slides.map((item, index) => {
                  const isCurrent = index === currentSlide

                  return (
                    <div
                      style={{ backgroundColor: slide.textColor }}
                      key={item.title}
                      className={clsx('w-2 h-2 rounded-full', isCurrent ? '!bg-black/30' : 'opacity-70')}
                    ></div>
                  )
                })}
              </div>
              <button
                className="bg-white w-full rounded-full p-4 font-semibold hover:bg-white/90"
                style={{ color: slide.buttonTextColor }}
                onClick={handleNext}
              >
                {isLast ? 'Comenzar' : 'Siguiente'}
              </button>
            </div>
          </section>
        </div>
      </NoMarginModalOnboard>
    </div>
  )
}

const slides = [
  {
    title: '¡Sé sustentable!',
    text: 'Sunet es el futuro en el que todos realizamos acciones sustentables para nuestro planeta. ¡Desde hoy, Sunet te ayuda a tomar acción!',
    textColor: '#FFF',
    buttonTextColor: '#106E8E',
    image: <Slide1 />,
    bgColor: '#00CCFF',
  },
  {
    title: '¡Aprende!',
    text: 'Sunet te enseñará sobre los Objetivos de Desarrollo Sustentable y su impacto. También podrás participar en eventos sobre variados temas de sostenibilidad.',
    textColor: '#FFF',
    buttonTextColor: '#5ACE88',
    image: <Slide2 />,
    bgColor: '#33CC66',
  },
  {
    title: '¡Participa!',
    text: 'Podrás participar en diferentes actividades que fomentarán tus buenos hábitos sustentables. Logra retos y desafíos, apoya causas benéficas y participa en eventos.',
    textColor: '#FFF',
    buttonTextColor: '#106E8E',
    image: <Slide3 />,
    bgColor: '#00CCFF',
  },
  {
    title: '¡Gana Tokens!',
    text: 'Por cada actividad que realices en Sunet, ganarás Tokens SNT, los cuales puedes utilizar para comprar en el marketplace sustentable o canjear por premios que le hacen bien al planeta.',
    textColor: '#009AC2',
    buttonTextColor: '#106E8E',
    image: <Slide4 />,
    bgColor: '#CAEFFF',
  },
  {
    title: '¡Crea grupos!',
    text: 'Crea o únete a grupos para lograr una meta en común. Ganen tokens en conjunto que el grupo podrá canjear por premios en la zona de canjes.',
    textColor: '#FFF',
    buttonTextColor: '#5ACE88',
    image: <Slide5 />,
    bgColor: '#33CC66',
  },
  {
    title: '¡Sé el cambio!',
    text: 'Preservar y cuidar nuestro planeta debemos hacerlo hoy y juntos. Bienvenido a esta iniciativa. Te invitamos a ¡ser el cambio que quieres ver en el mundo!',
    textColor: '#009AC2',
    buttonTextColor: '#106E8E',
    image: <Slide6 />,
    bgColor: '#A5F4F7',
  },
]
