import { useEffect, useRef } from 'react'

const usePrevious = <T>(value: T, omitFalsy?: boolean) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()
  // Store current value in ref
  useEffect(() => {
    if (!omitFalsy || value) {
      ref.current = value
    }
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)

  return ref.current
}

export default usePrevious
