import { formatDistanceStrict, isAfter, isBefore } from 'date-fns'
import Image from 'next/image'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import CustomText, { FontWeight } from '@/components/base/CustomText'
import useServerTime from '@/hooks/useServerTime'
import { ChallengeType } from '@/ts/types/challenges.types'
import { getDateFnsLocale } from '@/utils/dates'
import { getLocaleText } from '@/utils/i18n'

type Props = {
  challenge: ChallengeType
  onClick: () => void
}

const ChallengeSnippetCard = ({ challenge, onClick, itemId }: Props): JSX.Element => {
  const serverISOString = useServerTime()
  const StartAt = new Date(challenge.StartsAt)
  const now = new Date(serverISOString)

  const firstMilestoneEnd = new Date(challenge?.Milestones[0].ExpiresAt)
  const canParticipate = isBefore(now, firstMilestoneEnd)

  const ExpiresAt = new Date(challenge.ExpiresAt)

  const timeToEnd = formatDistanceStrict(ExpiresAt, now, {
    locale: getDateFnsLocale(),
    addSuffix: false,
  })

  const timeToStart = formatDistanceStrict(StartAt, now, {
    locale: getDateFnsLocale(),
    addSuffix: false,
  })

  const isChallengeNotStarted = isBefore(now, StartAt)
  const isChallengeFinished = isAfter(now, ExpiresAt)
  const isChallengeActive = !isChallengeNotStarted && !isChallengeFinished

  const getTimeText = () => {
    return (
      <span className="flex flex-col text-sm">
        {isChallengeFinished && 'Terminó hace '}
        {isChallengeActive && 'Termina en '}
        {isChallengeNotStarted && 'Comienza en '}
        <span className="text-black font-semibold text-lg leading-none">
          {isChallengeFinished || isChallengeActive ? timeToEnd : timeToStart}
        </span>
      </span>
    )
  }

  const Status = challenge?.Included?.Status?.Status
  const hasStartedButNotParticipating = Status === 'not-participating' && !isChallengeNotStarted

  return challenge ? (
    <div itemId={itemId} className="cursor-pointer" onClick={onClick}>
      <Card className="!py-2 !px-2 hover:opacity-80 " style={{ width: 231 }}>
        <div style={{ height: 114 }} className="relative w-full">
          <Image
            src={challenge?.Photo?.URL}
            alt={`alt-${getLocaleText(challenge.Name)}}`}
            fill
            className="rounded-lg object-cover"
          />
        </div>

        <div className="px-2 mt-1">
          <CustomText className="truncate text-left text-gray" weight={FontWeight.SemiBold}>
            {getLocaleText(challenge.Name)}
          </CustomText>

          <div className="grid grid-cols-2">
            <div className="flex flex-col ">
              <CustomText className="text-left text-gray-2">{getTimeText()}</CustomText>
            </div>
            <div className="ml-auto my-auto pointer-events-none">
              <Button appearance={ButtonAppearance.Primary} size={ButtonSize.Small}>
                {hasStartedButNotParticipating && canParticipate ? 'Participar!' : 'Revisar'}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null
}

export default ChallengeSnippetCard
