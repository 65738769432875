import React from 'react'

export const Rocket = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.35156 14.9219C6.46094 17.5703 2.92969 17.5703 2.92969 17.5703C2.92969 17.5703 2.92969 14.0391 5.57813 13.1484"
        stroke="#F8F9FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3047 8.73447L10 14.0392L6.46094 10.5001L11.7656 5.1954C13.7734 3.18759 15.7812 3.21103 16.6406 3.33603C16.7732 3.35372 16.8963 3.41456 16.9909 3.50916C17.0855 3.60376 17.1464 3.72686 17.1641 3.85947C17.2891 4.71884 17.3125 6.72665 15.3047 8.73447Z"
        stroke="#F8F9FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4219 9.61719V14.6641C14.4195 14.8289 14.3521 14.9862 14.2344 15.1016L11.7109 17.6328C11.6309 17.7128 11.5306 17.7695 11.4208 17.797C11.3111 17.8244 11.1959 17.8215 11.0876 17.7886C10.9794 17.7558 10.882 17.6941 10.8061 17.6102C10.7301 17.5264 10.6783 17.4235 10.6562 17.3125L10 14.0391"
        stroke="#F8F9FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8828 6.07812H5.83594C5.67109 6.08047 5.51384 6.14787 5.39844 6.26563L2.86719 8.78906C2.78723 8.86912 2.73049 8.96939 2.70305 9.07916C2.6756 9.18894 2.67848 9.3041 2.71136 9.41237C2.74424 9.52064 2.8059 9.61796 2.88976 9.69393C2.97361 9.7699 3.07652 9.82168 3.1875 9.84375L6.46094 10.5"
        stroke="#F8F9FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
