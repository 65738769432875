import React from 'react'

import { SvgIcon } from '../CustomIcon'

const SunetToken = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.0001 78.3902C84.7912 80.3683 83.5457 82.5046 82.4231 84.6148C81.2709 86.7806 80.314 88.7965 79.6616 90.5068C79.3373 91.3571 79.1173 92.0566 78.9836 92.6054C78.855 93.1334 78.8409 93.3823 78.8395 93.4077C78.8394 93.4099 78.8394 93.4104 78.8394 93.4092C78.8394 97.3271 82.0311 100.536 85.9999 100.536C89.9688 100.536 93.1606 97.327 93.1606 93.4092C93.1606 93.4103 93.1605 93.4098 93.1604 93.4077C93.159 93.383 93.1452 93.1337 93.0162 92.6039C92.8825 92.0549 92.6625 91.3553 92.3382 90.505C91.6858 88.7948 90.7291 86.7791 89.5769 84.6137C88.4543 82.5038 87.2089 80.368 86.0001 78.3902ZM88.3373 69.036C92.3579 75.1036 100.161 87.6165 100.161 93.4092C100.161 101.209 93.8188 107.536 85.9999 107.536C78.181 107.536 71.8394 101.209 71.8394 93.4092C71.8394 87.6204 79.6418 75.1036 83.6624 69.036C84.7694 67.3651 87.2303 67.3651 88.3373 69.036Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0371 56.468L74.572 54.3001V32.9892L42 61.1833V115.729L73.4311 140.488C73.4319 140.489 73.4326 140.489 73.4334 140.49C73.4337 140.49 73.4341 140.49 73.4344 140.491C73.8052 140.782 74.1833 141.059 74.5679 141.321V121.108C63.6862 116.619 55.9794 105.92 55.9794 93.4053C55.9794 88.2008 57.6221 81.4246 65.2684 68.5125M74.5679 113.329C76.1342 114.226 77.8157 114.946 79.5843 115.459C80.7203 115.789 81.5046 116.813 81.5679 117.982C81.5706 118.033 81.572 118.084 81.572 118.135H81.5679V147.952C81.5679 149.82 79.7613 151.187 77.9712 150.636C74.8231 149.672 71.8107 148.12 69.1029 145.99L36.0658 119.966C35.3909 119.437 35 118.628 35 117.77V59.2615C35 58.4486 35.3539 57.6768 35.9671 57.1472L76.9301 21.69C78.7448 20.1218 81.572 21.4067 81.572 23.8042V55.6406C81.572 56.2195 81.391 56.7819 81.0576 57.254L78.7613 60.4971C78.7568 60.5035 78.7493 60.5142 78.7389 60.529C78.3953 61.0181 74.8631 66.0453 71.2922 72.0781C63.9259 84.5171 62.9794 90.0103 62.9794 93.4053C62.9794 101.921 67.6499 109.364 74.5679 113.329ZM80.0169 143.942C80.0185 143.942 80.0201 143.943 80.0217 143.943L80.0293 143.946C80.0251 143.944 80.021 143.943 80.0169 143.942ZM73.0371 56.468L73.0259 56.484L73.0371 56.468ZM73.0259 56.484C73.031 56.4767 72.9247 56.6279 72.807 56.7965L73.0259 56.484ZM72.807 56.7965C72.6778 56.9816 72.5001 57.2375 72.2806 57.5566L72.807 56.7965ZM72.2806 57.5566C71.8418 58.1943 71.2359 59.0846 70.5176 60.1666L72.2806 57.5566ZM70.5176 60.1666C69.0849 62.3245 67.1872 65.2709 65.2691 68.5113L70.5176 60.1666Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0697 21.6899C93.2549 20.1176 90.4277 21.4066 90.4277 23.8041V55.6447C90.4277 56.2235 90.6088 56.7859 90.9421 57.258L93.2384 60.5011C93.2429 60.5075 93.2503 60.518 93.2605 60.5326C93.6028 61.0198 97.1358 66.0479 100.708 72.0821C108.074 84.5211 109.02 90.0141 109.02 93.4092C109.02 103.849 101.996 112.683 92.4154 115.462C91.2302 115.807 90.4277 116.908 90.4277 118.139V147.956C90.4277 149.824 92.2343 151.191 94.0245 150.641C97.1726 149.676 100.185 148.124 102.892 145.993L135.929 119.97C136.605 119.44 136.995 118.632 136.995 117.774V59.2573C136.995 58.4486 136.642 57.6767 136.028 57.143L95.0697 21.6899ZM129.995 61.1791L97.4277 32.9891V54.3042L98.9625 56.4719L98.9736 56.4877C98.9699 56.4824 99.0744 56.631 99.1927 56.8005C99.3218 56.9856 99.4996 57.2415 99.7191 57.5605C100.158 58.1982 100.764 59.0886 101.482 60.1705C102.915 62.3285 104.813 65.2749 106.731 68.5154M129.995 61.1791V115.733L98.5631 140.493C98.1918 140.785 97.8131 141.062 97.4277 141.325V121.113C108.312 116.625 116.02 105.922 116.02 93.4092C116.02 88.2047 114.378 81.4286 106.732 68.5163"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 86C7 129.63 42.3695 165 86 165C129.63 165 165 129.63 165 86C165 42.3695 129.63 7 86 7C42.3695 7 7 42.3695 7 86ZM86 0C38.5035 0 0 38.5035 0 86C0 133.496 38.5035 172 86 172C133.496 172 172 133.496 172 86C172 38.5035 133.496 0 86 0Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  )
}

export default SunetToken
