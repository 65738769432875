import Image from 'next/image'

import Person from '@/public/static/images/landing/userCards/001.png'
import Influencer from '@/public/static/images/landing/userCards/002.png'
import ONG from '@/public/static/images/landing/userCards/003.png'
import Company from '@/public/static/images/landing/userCards/004.png'

import Container from '../base/Container'
import CustomText, { FontWeight, TextSize } from '../base/CustomText'

const userCards = [
  {
    title: 'Personas Naturales',
    body: 'Pueden utilizar toda la plataforma totalmente gratis. Son los usuarios que ganan tokens y pueden canjearlos.',
    image: Person,
  },
  {
    title: 'Influencers',
    body: 'Pueden utilizar toda la plataforma totalmente gratis. Son los usuarios que ganan tokens y pueden canjearlos.',
    image: Influencer,
  },
  {
    title: 'Fundaciones y ONG',
    body: 'Pueden utilizar la plataforma para crear iniciativas y recaudar fondos asociados a algún ODS.',
    image: ONG,
  },
  {
    title: 'Empresas',
    body: 'Puedes publicar productos sustentables en el marketplace, crear grupos y medir su impacto con las huellas.',
    image: Company,
  },
]

export const Users = () => {
  return (
    <section className="w-full bg-white">
      <Container className="py-20 space-y-12" maxWidth={1200}>
        <CustomText className="md:!text-4xl !text-3xl text-center" weight={FontWeight.Bold}>
          ¿Quienes pueden usar Sunet?
        </CustomText>
        <div
          className="grid gap-8 w- lg:!grid-cols-4"
          style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))' }}
        >
          {userCards.map((item, index) => (
            <UserCard key={`${index}-reward`} item={item} />
          ))}
        </div>
      </Container>
    </section>
  )
}

const UserCard = ({ item }) => {
  return (
    <div
      className="bg-white flex flex-col rounded-3xl overflow-hidden"
      style={{ boxShadow: '34px 29px 48px 0px rgba(51, 102, 255, 0.05)' }}
    >
      <Image src={item.image} className="w-full" alt={item.title} />
      <div className="py-7 px-8 text-center space-y-2 flex-1">
        <CustomText weight={FontWeight.SemiBold} size={TextSize.SubTitle2}>
          {item.title}
        </CustomText>
        <CustomText>{item.body}</CustomText>
      </div>
    </div>
  )
}
