import { AxiosResponse } from 'axios'

import { CommentType, CountDef, Hashtag, MentionType } from '@/ts/types/general.types'
import { Post, PostLists } from '@/ts/types/post.types'
import { mergeStoriesByUUID } from '@/utils/stories'

import axios, { getAxiosByAccessToken } from '../utils/axios'
import { createUrlByDefaultFilters } from '../utils/processFilters'

export const postURLEndpoint = `/posts`

export const createPost = async (values: Post): Promise<Post> => {
  return axios.post(`${postURLEndpoint}`, values).then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findPostByUUID = async ({ postUUID, accessToken }): Promise<Post> => {
  if (!postUUID) {
    return
  }
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(
      `${postURLEndpoint}/${postUUID}?order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes,TopThreeLikes`
    )
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findPostByGroupUUID = async ({ groupUUID, accessToken }): Promise<Post> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(
      `${postURLEndpoint}/group-feed?GroupUUID=${groupUUID}&ApprovalStatus=approved&order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes`
    )
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findPendingPostByGroupUUID = async ({ groupUUID, accessToken }): Promise<Post> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(
      `${postURLEndpoint}/group-feed?GroupUUID=${groupUUID}&ApprovalStatus=pending&order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes`
    )
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findImagePostByGroupUUID = async ({ groupUUID }): Promise<Post> => {
  return axios
    .get(
      `${postURLEndpoint}/group-feed?GroupUUID=${groupUUID}&ApprovalStatus=approved&Metadata.Type=image&order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes`
    )
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findVideoPostByGroupUUID = async ({ groupUUID }): Promise<Post> => {
  return axios
    .get(
      `${postURLEndpoint}/group-feed?GroupUUID=${groupUUID}&ApprovalStatus=approved&Metadata.Type=video&order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes`
    )
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findLinkPostByGroupUUID = async ({ groupUUID }): Promise<Post> => {
  return axios
    .get(
      `${postURLEndpoint}/group-links-feed?GroupUUID=${groupUUID}&ApprovalStatus=approved&order=-CreatedAt&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes`
    )
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findPostsCountByAuthorUUID = async (uuid: string): Promise<number> => {
  return axios
    .get(`${postURLEndpoint}?limit=0&offset=0&AuthorUUID=${uuid}`)
    .then((res: { data: { Data: PostLists } }) => res.data.Data.Count)
}

export const deletePostByUUID = async (uuid: string): Promise<AxiosResponse<string>> => {
  return axios.delete(`${postURLEndpoint}/${uuid}`)
}

export const reportPostByPostUUIDAndReason = async (uuid: string, reason: string): Promise<AxiosResponse<string>> => {
  return axios.post(`${postURLEndpoint}/${uuid}/report`, { Reason: reason })
}

export const subscribeToPostByPostUUIDAndAuthorUUID = async (
  uuid: string,
  authorUUID: string
): Promise<AxiosResponse<string>> => {
  return axios.post(`${postURLEndpoint}/${uuid}/subscribe`, {
    AuthorUUID: authorUUID,
  })
}

export const unsubscribeToPostByPostUUIDAndAuthorUUID = async (
  uuid: string,
  authorUUID: string
): Promise<AxiosResponse<string>> => {
  return axios.post(`${postURLEndpoint}/${uuid}/unsubscribe`, {
    AuthorUUID: authorUUID,
  })
}

export const updatePostMetaByUUID = async (uuid: string, metaValues: object): Promise<AxiosResponse<string>> => {
  return axios.post(`${postURLEndpoint}/${uuid}/meta`, metaValues)
}

export const toggleSavePostByPostUUIDAndAuthorUUID = async (
  uuid: string,
  authorUUID: string
): Promise<AxiosResponse<string>> => {
  return axios.post(`${postURLEndpoint}/bookmarks/toggle`, {
    AuthorUUID: authorUUID,
    PostUUID: uuid,
  })
}

export const findAllPostByFilters = async (
  values: unknown[],
  filters: { ods: string | null; area: string | null; date: string | null }
): Promise<Post[]> => {
  const { ods, area, date } = filters
  const page = values[0]

  const url = createUrlByDefaultFilters(page, ods, area, date, null, null)

  return axios.get(url).then((res: { data: { Data: Post[] } }) => res.data.Data)
}

export const findAllPostsByAuthorUUIDAndPageAndFilters = async (
  values: unknown[],
  filters: {
    ods: string | null
    area: string | null
    date: string | null
    type: string | null
  }
): Promise<Post[]> => {
  const { ods, area, date, type } = filters
  const authorUUID = values[0]
  const page = values[1]

  const url = createUrlByDefaultFilters(page, ods, area, date, type, authorUUID)

  return axios.get(url).then((res: { data: { Data: Post[] } }) => res.data.Data)
}

export const findAllBookmarkedPosts = async (values: unknown[]): Promise<Post[]> => {
  const userUUID = values[0]

  return axios
    .get(
      `${postURLEndpoint}/bookmarks?order=-UpdatedAt&AuthorUUID=${userUUID}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes`
    )
    .then((res: { data: { Data: Post[] } }) => res.data.Data)
}

export const readPostByPostUUIDAndAuthorUUID = async (postUUID: string, authorUUID: string): Promise<void> => {
  return axios.get(`${postURLEndpoint}/${postUUID}/read`, {
    AuthorUUID: authorUUID,
  })
}

export const toggleLikeByPostUUIDAndUserUUID = async (
  postUUID: string,
  userUUID: string,
  slug: string
): Promise<void> => {
  return axios.post(`${postURLEndpoint}/likes/toggle`, {
    PostUUID: postUUID,
    userUUID: userUUID,
    Slug: slug,
  })
}

export const searchPostsByQuery = async (query: string): Promise<CountDef<Post>> => {
  return axios
    .get(`/posts/search?Query=${query}&offset=0&limit=30&include=Author`)
    .then((res: { data: { Data: CountDef<Post> } }) => res.data.Data)
}

export const toggleCommentLikeByCommentUUIDAndAuthorUUID = async (
  commentUUID: string,
  authorUUID: string
): Promise<null> => {
  return axios.post('/posts/comments/likes/toggle', {
    CommentUUID: commentUUID,
    AuthorUUID: authorUUID,
  })
}

export const createCommentByPostUUIDAndAuthorUUIDAndContentAndMentionsAndHashtags = async (
  postUUID: string,
  AuthorUUID: string,
  content: string,
  mentions: MentionType[],
  hashtags: Hashtag[]
): Promise<CommentType> => {
  return axios
    .post('/posts/comments', {
      PostUUID: postUUID,
      AuthorUUID: AuthorUUID,
      Content: content,
      Mentions: mentions,
      Hashtags: hashtags,
    })
    .then((res: { data: { Data: CommentType } }) => res.data.Data)
}

export const findAllCommentsByPostUUID = async (values): Promise<CommentType[]> => {
  const postUUID = values[0]
  return axios
    .get(`/posts/comments?PostUUID=${postUUID}&include=Author,OwnLike&order=CreatedAt`)
    .then((res: { data: { Data: CommentType[] } }) => res.data.Data)
}

export const findCommentByUUID = async (values): Promise<CommentType> => {
  const commentUUID = values[0]

  return axios.get(`/posts/comments/${commentUUID}`).then((res: { data: { Data: CommentType } }) => res.data.Data)
}

export const deleteCommentByUUID = async (uuid: string): Promise<AxiosResponse<string>> => {
  return axios.delete(`${postURLEndpoint}/comments/${uuid}`)
}

export const reportCommentByCommentUUIDAndReason = async (
  uuid: string,
  reason: string
): Promise<AxiosResponse<string>> => {
  return axios.post(`${postURLEndpoint}/comments/${uuid}/report`, {
    Reason: reason,
  })
}

export const updatePostApproval = async (postUUID: string, status: string): Promise<Post> => {
  return axios
    .put(`${postURLEndpoint}/${postUUID}/approval-status`, {
      Status: status,
    })
    .then((res: { data: { Data: Post } }) => res.data.Data)
}

export const findAllStories = async () => {
  const data = await axios.get(`/posts/stories`)
  const res = data.data.Data

  const result = mergeStoriesByUUID(res.AllNotSeenStories, res.AllSeenStories)
  return result
}

export const markStorieAsSeen = async ({ storieUUID, authorUUID }) => {
  await axios.put(`/posts/stories/${storieUUID}/seen`, {
    AuthorUUID: authorUUID,
  })
}

export const findPostLikesAndAuthorsByPostUUID = async (postUUID: string) => {
  const result = await axios.get(`/posts/likes?PostUUID=${postUUID}&order=-CreatedAt&include=Author`)
  return result.data.Data
}
