import { useState } from 'react'
import useSWR, { mutate } from 'swr'

import {
  findFollowByFolloweeUUIDAndFollowerUUID,
  toggleFollowByFolloweeUUIDAndFollowerUUID,
} from '@/services/followService'
import { Follow } from '@/ts/types/follower.types'

import useSunetAuthors from './useSunetAuthors'

type Props = {
  profileUUID: string
}

type ReturnProps = {
  handleToggle: () => void
  following: boolean
  loading: boolean
  followingArray?: Follow[]
  isPending?: boolean
}

const useFollow = ({ profileUUID }: Props): ReturnProps => {
  const { author } = useSunetAuthors()
  const [loading, setLoading] = useState(false)

  const { data: followingArray, mutate: mutateFollow } = useSWR(
    author ? [profileUUID, author?.UUID, '/posts/follows?FolloweeUUID&FollowerUUID'] : null,
    findFollowByFolloweeUUIDAndFollowerUUID
  )
  const following = followingArray?.length > 0

  const isPending = following && !followingArray[0].IsActive

  const handleToggle = async () => {
    setLoading(true)
    await toggleFollowByFolloweeUUIDAndFollowerUUID(profileUUID, author?.UUID as string)
    await mutateFollow()
    mutate([profileUUID, '/posts/follows?limit=0&offset=0&FolloweeUUID'])
    setLoading(false)
  }

  return { handleToggle, following, loading, followingArray, isPending }
}

export default useFollow
