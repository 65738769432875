import { AxiosResponse } from 'axios'

import { SITE_URL } from '@/config'

import {
  SunetUser,
  // UserBillingInfo,
  UserCreateValues,
  UserExistsResponse,
  UserSignInResponse,
  UserUpdateValues,
} from '../ts/types/user.types'
import axios from '../utils/axios'

export const createUser = (values: UserCreateValues): Promise<SunetUser> => {
  return axios.post('/users', values).then((res: { data: { Data: SunetUser } }) => res.data.Data)
}

export const getUserByUUID = (userUUID: string): Promise<SunetUser> => {
  return axios.get(`/users/${userUUID}`).then((res: { data: { Data: SunetUser } }) => res.data.Data)
}

export const updateUserByUUID = (
  userUUID: string,
  updateValues: UserUpdateValues
): Promise<AxiosResponse<SunetUser>> => {
  return axios.put(`/users/${userUUID}`, updateValues)
}

export const acceptTerms = (userUUID: string): Promise<AxiosResponse<SunetUser>> => {
  return axios.post(`/users/${userUUID}/accept-policy`)
}

export const signInUsingCredentials = (email: string, password: string): Promise<AxiosResponse<UserSignInResponse>> => {
  return axios.post(`/users/sign-in/local`, {
    Email: email,
    Password: password,
  })
}

export const checkIfUserExistsByEmail = (email: string): Promise<AxiosResponse<UserExistsResponse>> => {
  return axios.get(`/users/exists?Email=${encodeURIComponent(email)}`)
}

export const findUserOrCompanyByTypeAndUUID = async (type: string, uuid: string): Promise<SunetUser> => {
  let endpoint = ''
  if (type === 'company' || type === 'c') {
    endpoint = `/companies/${uuid}`
  } else if (type === 'user' || type === 'u') {
    endpoint = `/users/${uuid}`
  } else {
    endpoint = `/users/${uuid}`
  }

  const res = await axios.get(endpoint)
  return res.data.Data
}

export const certifyUserByUUID = async (uuid: string): Promise<AxiosResponse<boolean>> => {
  return axios.post(`/users/${uuid}/certify`).then((res: { data: { Data: any } }) => res.data.Data)
}

export const findUserByUUIDAndBlocks = (params: unknown[]): Promise<SunetUser> => {
  const userUUID = params[0]
  return axios.get(`/users/${userUUID}?include=Blocks`).then((res: { data: { Data: SunetUser } }) => res.data.Data)
}

export const searchUsersByQuery = (query: string): Promise<SunetUser> => {
  return axios
    .get(`/users/search?Query=${query}&offset=0&limit=10&order=-Similarity`)
    .then((res: { data: { Data: SunetUser } }) => res.data.Data)
}

export const changePassword = (values): Promise<AxiosResponse<UserSignInResponse>> => {
  return axios.post(`/users/change-password`, values)
}

export const deleteAuthor = async (uuid: string, code: string) => {
  return await axios.post(`/users/${uuid}/delete`, { Code: code })
}

export const requestDeleteCode = (uuid: string): Promise<SunetUser> => {
  return axios.post(`/users/request-delete-code`, { UserUUID: uuid })
}

export const recoverPassword = (values) => {
  return axios.post(`/users/confirm-recovery`, values)
}

export const requestPasswordRecoveryWithEmail = (values) => {
  const returnURL = SITE_URL + '/auth/recovery'
  return axios.post('/users/request-recovery', {
    Email: values.Email,
    ReturnURL: returnURL,
  })
}
