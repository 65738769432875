import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Medal = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2205 23.9989C15.4063 23.986 15.5668 23.8643 15.6295 23.6889L16.8145 20.3704L20.2157 21.2912C20.3301 21.3222 20.4521 21.3086 20.557 21.2533L20.5738 21.2444C20.8022 21.1239 20.8901 20.8414 20.7704 20.6126L18.1506 15.6022L17.3198 16.0366L19.4513 20.113L16.6413 19.3522C16.4052 19.2883 16.1596 19.4167 16.0773 19.647L15.1069 22.3645L12.6892 17.7406L11.8584 18.175L14.7726 23.7484C14.8589 23.9135 15.0347 24.0118 15.2205 23.9989Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77915 23.9989C8.96494 24.0118 9.14075 23.9135 9.22705 23.7484L12.1413 18.175L11.3105 17.7406L8.89275 22.3645L7.92232 19.647C7.84006 19.4167 7.59449 19.2883 7.35838 19.3522L4.5484 20.113L6.67986 16.0366L5.84908 15.6022L3.22923 20.6126C3.1096 20.8414 3.1975 21.1239 3.42583 21.2444L3.44271 21.2533C3.54758 21.3087 3.66954 21.3222 3.784 21.2912L7.18515 20.3704L8.37021 23.6889C8.43284 23.8643 8.59336 23.986 8.77915 23.9989Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78418 9.21527C2.78418 14.3048 6.91003 18.4307 11.9996 18.4307C17.0891 18.4307 21.2149 14.3048 21.2149 9.21527C21.2149 4.8914 18.2375 1.26415 14.2217 0.269707C13.9704 0.207476 13.7163 0.360743 13.654 0.612036C13.5918 0.863329 13.7451 1.11749 13.9964 1.17972C17.6037 2.07302 20.2774 5.33247 20.2774 9.21527C20.2774 13.787 16.5713 17.4932 11.9996 17.4932C7.4278 17.4932 3.72168 13.787 3.72168 9.21527C3.72168 5.33251 6.39536 2.07302 10.0027 1.17972C10.254 1.11749 10.4072 0.863329 10.345 0.612036C10.2828 0.360743 10.0286 0.207476 9.77733 0.269707C5.76156 1.26415 2.78418 4.89145 2.78418 9.21527Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12793 9.21527C5.12793 13.0047 8.21016 16.0869 11.9996 16.0869C15.789 16.0869 18.8712 13.0047 18.8712 9.21527C18.8712 5.42585 15.789 2.34368 11.9996 2.34368C8.21016 2.34368 5.12793 5.42585 5.12793 9.21527ZM11.9996 15.1494C8.72792 15.1494 6.06543 12.487 6.06543 9.21527C6.06543 5.94363 8.72792 3.28118 11.9996 3.28118C15.2712 3.28118 17.9337 5.94363 17.9337 9.21527C17.9337 12.487 15.2712 15.1494 11.9996 15.1494Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5165 11.704C9.4194 12.3431 10.0838 12.8257 10.6616 12.5359L11.7894 11.9702C11.7893 11.9702 11.7894 11.9702 11.7894 11.9702C11.9216 11.9039 12.0774 11.9039 12.2097 11.9702C12.2097 11.9702 12.2097 11.9702 12.2097 11.9702L13.3375 12.5359C13.9153 12.8257 14.5797 12.3431 14.4826 11.7039L14.2931 10.4566C14.2709 10.3103 14.319 10.1621 14.423 10.0568L14.423 10.0568L15.3094 9.15905C15.7638 8.69903 15.51 7.91805 14.8721 7.81286L13.6273 7.60765C13.6273 7.60765 13.6272 7.60764 13.6272 7.60764C13.4811 7.5835 13.3551 7.49189 13.2872 7.36058L12.7073 6.24007C12.7073 6.24006 12.7073 6.24008 12.7073 6.24007C12.4102 5.66589 11.5889 5.66587 11.2918 6.24004M11.2918 6.24004L10.7119 7.36053C10.7119 7.36053 10.7119 7.36052 10.7119 7.36053C10.6439 7.49195 10.5179 7.58352 10.3719 7.60764C10.3719 7.60764 10.3719 7.60764 10.3719 7.60764C10.3719 7.60764 10.3719 7.60765 10.3718 7.60765L9.12702 7.81286C8.48917 7.91804 8.2354 8.69899 8.68961 9.159L9.5761 10.0568C9.5761 10.0568 9.57611 10.0568 9.5761 10.0568C9.68002 10.1621 9.72819 10.3103 9.70597 10.4566L9.5165 11.704C9.5165 11.704 9.5165 11.7039 9.5165 11.704M10.4842 11.5761L11.369 11.1322C11.7658 10.9333 12.2333 10.9332 12.63 11.1322L13.5149 11.5761L13.3663 10.5974C13.3663 10.5974 13.3663 10.5974 13.3663 10.5974C13.2996 10.1585 13.444 9.71395 13.7559 9.39809L14.4515 8.69366L13.4746 8.53264C13.0368 8.46038 12.6586 8.18574 12.4546 7.79144C12.4545 7.79142 12.4545 7.79141 12.4545 7.79139L11.9995 6.91224L11.5445 7.79144C11.3405 8.18564 10.9624 8.46036 10.5245 8.53264L9.54764 8.69367L10.2432 9.39811C10.5551 9.71396 10.6995 10.1585 10.6328 10.5973C10.6328 10.5973 10.6328 10.5973 10.6328 10.5973L10.4842 11.5761ZM11.8746 6.67091C11.8746 6.6709 11.8747 6.67092 11.8746 6.67091V6.67091ZM14.6422 8.50045C14.6423 8.50039 14.6423 8.50033 14.6424 8.50027L14.6424 8.50032L14.6422 8.50045ZM13.5557 11.8447L13.5557 11.8445C13.5557 11.8446 13.5557 11.8447 13.5557 11.8447ZM10.4434 11.8446L10.4434 11.8447L10.4434 11.8446Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M12.4687 0.46875C12.4687 0.727641 12.2589 0.9375 12 0.9375C11.7411 0.9375 11.5312 0.727641 11.5312 0.46875C11.5312 0.209859 11.7411 1.19209e-07 12 1.19209e-07C12.2589 1.19209e-07 12.4687 0.209859 12.4687 0.46875Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  )
}

export default Medal
