import { Level } from '@/ts/types/gamification.types'

export const sunetLevels: Level[] = [
  {
    UUID: 'b61a4aa0-0513-4633-9160-9455f79f2517',
    CreatedAt: '2021-09-01T15:52:08.340855Z',
    UpdatedAt: '2021-09-01T15:52:08.340855Z',
    Name: {
      es: 'Novato de la Sostenibilidad',
      en: 'Newbie to Sustainability',
    },
    Description: {
      es: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
      en: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
    },
    Score: 0,
    MaxScore: 114,
    Number: 1,
    Color: '#336666',
    FullImage: 'https://cdn.sunet.world/assets/levels/full/Novato de la Sostenibilidad.png',
    RoundImage: 'https://cdn.sunet.world/assets/levels/round/Novato de la Sostenibilidad.png',
  },
  {
    UUID: '58690c5e-d6fd-47f7-8803-048dc88e6ce8',
    CreatedAt: '2021-09-01T15:52:38.148941Z',
    UpdatedAt: '2021-09-01T15:52:38.148941Z',
    Name: {
      es: 'Aprendiz de la Sostenibilidad',
      en: 'Sustainability Apprentice',
    },
    Description: {
      es: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
      en: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
    },
    Score: 115,
    MaxScore: 434,
    Number: 2,
    Color: '#FFDA33',
    FullImage: 'https://cdn.sunet.world/assets/levels/full/Aprendiz de la Sostenibilidad.png',
    RoundImage: 'https://cdn.sunet.world/assets/levels/round/Aprendiz de la Sostenibilidad.png',
  },
  {
    UUID: 'db6fe047-aa2a-4f74-87f1-4f0b270a8e1b',
    CreatedAt: '2021-09-01T15:53:28.620905Z',
    UpdatedAt: '2021-09-01T15:53:28.620905Z',
    Name: {
      es: 'Activista de la Sostenibilidad',
      en: 'Sustainability Activist',
    },
    Description: {
      es: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
      en: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
    },
    Score: 435,
    MaxScore: 2031,
    Number: 3,
    Color: '#A5F4F7',
    FullImage: 'https://cdn.sunet.world/assets/levels/full/Activista+de+la+Sostenibilidad.png',
    RoundImage: 'https://cdn.sunet.world/assets/levels/round/Activista+de+la+Sostenibilidad.png',
  },
  {
    UUID: '81aa5eb9-dcb9-43ba-84f5-ce6b0a06f5ec',
    CreatedAt: '2021-09-01T15:54:07.221009Z',
    UpdatedAt: '2021-09-01T15:54:07.221009Z',
    Name: {
      es: 'Influencer de la Sostenibilidad',
      en: 'Influencer of Sustainability',
    },
    Description: {
      es: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
      en: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
    },
    Score: 2032,
    MaxScore: 3935,
    Number: 4,
    Color: '#FF6B1C',
    FullImage: 'https://cdn.sunet.world/assets/levels/full/Influencer de la Sostenibilidad.png',
    RoundImage: 'https://cdn.sunet.world/assets/levels/round/Influencer de la Sostenibilidad.png',
  },
  {
    UUID: '0c948991-dcfd-4db6-9aa0-7b717bbccc69',
    CreatedAt: '2021-09-01T15:54:39.313683Z',
    UpdatedAt: '2021-09-01T15:54:39.313683Z',
    Name: {
      es: 'Superhéroe de la Sostenibilidad',
      en: 'Sustainability Superhero',
    },
    Description: {
      es: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
      en: 'Wow! Tus acciones ya son concretas y eres parte activa de la comunidad dedicada a mejorar el mundo. Sigue tomando pasos para llegar a ser un@ influencer.',
    },
    Score: 3936,
    MaxScore: 10000000,
    Number: 5,
    Color: '#33CC66',
    FullImage: 'https://cdn.sunet.world/assets/levels/full/Superhéroe de la Sostenibilidad.png',
    RoundImage: 'https://cdn.sunet.world/assets/levels/round/Superhéroe de la Sostenibilidad.png',
  },
]
