import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Bell = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.964234 25.3329C0.744268 25.6222 0.707146 26.0112 0.868501 26.337C1.02962 26.6627 1.36144 26.8691 1.72478 26.8698C6.15119 26.8936 15.8353 26.9123 20.2566 26.921C20.6465 26.9217 20.9991 26.6896 21.1528 26.3311C21.3064 25.9727 21.231 25.5573 20.9615 25.2754C19.9581 24.2263 18.8466 23.0639 18.8466 23.0639C18.8466 23.0639 20.7754 15.7693 16.9557 12.318C14.4518 10.0557 10.4538 8.48422 6.0879 11.5607C0.963304 15.1719 3.24026 22.4812 3.13845 22.8906C3.08241 23.1166 1.95898 24.3096 0.964234 25.3329Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6719 26.9101C14.7018 27.0855 14.7174 27.265 14.7174 27.4481C14.7174 29.3106 13.1107 30.8228 11.1317 30.8228C9.15243 30.8228 7.5459 29.3106 7.5459 27.4481C7.5459 27.259 7.56247 27.0733 7.59423 26.8926C9.8936 26.8993 12.39 26.9052 14.6719 26.9101Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9205 9.84696C10.9205 9.84696 8.82494 9.18099 8.07608 7.32109C7.394 5.62627 7.48484 3.2321 7.73166 3.04856C7.88764 2.93251 10.3652 4.64484 10.7488 6.08839C11.1654 7.65547 10.9445 7.89108 10.9205 9.84696Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1606 9.84731C11.1606 9.84731 14.3161 9.25139 15.5964 7.01623C16.763 4.97956 16.8548 1.9848 16.5078 1.72864C16.2885 1.56659 12.4709 3.42439 11.7687 5.18108C11.0063 7.08816 11.3099 7.40643 11.1606 9.84731Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Bell
