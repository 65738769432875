import { createUpload } from '@mux/upchunk'
import { useState } from 'react'
import { toast } from 'react-hot-toast'

import { useAuthStore } from '@/dataStores/auth'
import { getMuxVideoCheckData, getUploadURL } from '@/services/fileService'
import { MuxVideo, UploadMuxVideo } from '@/ts/types/general.types'
import { handleError } from '@/utils/alerts'

import useRequest from './useRequest'

type Props = {
  onSuccess: (values: unknown) => void
  maxDuration?: number
}

type ReturnProps = {
  handleVideoUpload: (videoFile: File) => Promise<void>
  progress: number
  statusMessage: string | null
  loading: boolean
}

const useUploadMuxVideo = ({ onSuccess, maxDuration = 90 }: Props): ReturnProps => {
  const { exec } = useRequest<UploadMuxVideo>(getUploadURL)
  const { exec: getMuxVideoCheckDataExec } = useRequest(getMuxVideoCheckData)

  const [user] = useAuthStore((state) => [state.user])

  const [progress, setProgress] = useState(0)
  const [statusMessage, setStatusMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleVideoUpload = async (videoFile: File) => {
    // create the video element but don't add it to the page
    const vid = document.createElement('video')
    // create url to use as the src of the video
    const fileURL = URL.createObjectURL(videoFile)
    vid.src = fileURL

    // wait for duration to change from NaN to the actual duration
    vid.ondurationchange = async function () {
      if (vid.duration <= maxDuration) {
        try {
          const uploadURLData = (await exec(user?.UUID)) as UploadMuxVideo
          const upload = createUpload({
            endpoint: uploadURLData.URL, // Authenticated url
            file: videoFile, // File object with your video file’s properties
            chunkSize: 5120, // Uploads the file in ~5mb chunks
          })
          setLoading(true)

          // Subscribe to events
          upload.on('error', (error) => {
            setStatusMessage(error.detail)
            setLoading(false)
            console.log('error.detail', error.detail)
          })

          upload.on('progress', (progress) => {
            setProgress(progress.detail)
            if (progress.detail !== 100) {
              setLoading(true)
            }
          })

          upload.on('success', async () => {
            const checkData = (await getMuxVideoCheckDataExec(uploadURLData.UploadID, user?.UUID)) as MuxVideo
            const metaDataValues = {
              PlaybackID: checkData.PlaybackID,
              UploadID: checkData.UploadID,
              AssetID: checkData.AssetID,
            }
            if (onSuccess) {
              onSuccess(metaDataValues)
            }
            setLoading(false)
            setProgress(0)
          })
        } catch (e) {
          console.log(e)
          toast.error('Ocurrió un problema. Inténtalo más tarde.')
        }
      } else {
        handleError('internal: excedded video max length', 'video')
      }
    }
  }
  return { handleVideoUpload, progress, statusMessage, loading }
}

export default useUploadMuxVideo
