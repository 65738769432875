import clsx from 'clsx'
import React from 'react'

type Props = {
  className?: string
  size?: number
}

const Spinner = ({ size = 40, className = 'text-white' }: Props): JSX.Element => {
  return (
    <svg
      className={clsx('Spinner', className)}
      width={size}
      height={size}
      viewBox="22 22 44 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="Path" fill="none" strokeWidth="3" strokeLinecap="round" cx="44" cy="44" r="20.2" />
    </svg>
  )
}

export default Spinner
