import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { FaArrowLeft } from 'react-icons/fa'

import Button, { ButtonAppearance, ButtonSize } from '../base/Button'

const LeftArrow = (): JSX.Element => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible))
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleElements])

  return (
    <Button
      icon={() => <FaArrowLeft />}
      size={ButtonSize.Medium}
      appearance={ButtonAppearance.Black}
      className={clsx('w-4 hidden md:flex', disabled ? 'opacity-0' : 'opacity-100')}
      onClick={() => scrollPrev()}
      disabled={disabled}
    />
  )
}

export default LeftArrow
