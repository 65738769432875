import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import Avatar from '@/components/shared/Avatar'
import LeftArrow from '@/components/shared/LeftArrow'
import MuxVideoStorie from '@/components/shared/MuxVideoStorie'
import RightArrow from '@/components/shared/RightArrow'
import useRequest2 from '@/hooks/useRequest2'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { findAllStories, markStorieAsSeen } from '@/services/postsService'
import { Author } from '@/ts/types/post.types'
import { getAuthorProfile } from '@/utils/authors'

import { StorieAnimatedBar } from './stories/StorieAnimatedBar'

export const StorieSnippet = () => {
  const [stories, loadingStories, errors, revalidate] = useRequest2({
    requestKey: `/stories`,
    request: () => findAllStories(),
  })

  const mappedStories = stories?.length ? stories?.filter((item) => item?.Stories?.length > 0) : []

  if (!loadingStories && !mappedStories.length) {
    return
  }

  return (
    <section className="space-y-4">
      <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
        Publicaciones destacadas
      </CustomText>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} scrollContainerClassName="pb-2">
        {mappedStories?.map((storie, index) => (
          <StorieItem storie={storie} index={index} storiesSet={mappedStories} key={storie?.Author?.UUID} />
        ))}
      </ScrollMenu>
    </section>
  )
}

const StorieItem = ({ storie, index, storiesSet }) => {
  const { author } = useSunetAuthors()
  const { push } = useRouter()
  // local Storie
  const [isOpen, setIsOpen] = useState(false)

  const [paused, setPaused] = useState(false)
  const pause = () => {
    setPaused(true)
  }

  const unPause = () => {
    setPaused(false)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  const storieAuthor = storie?.Author

  const notSeenIndex = storie.Stories.findIndex((item) => !item.IsSeen)
  const notSeenStorie = storie.Stories[notSeenIndex < 0 ? 0 : notSeenIndex]
  const isFirstVideo = notSeenStorie?.PlaybackID !== ''

  const thumbnail = isFirstVideo
    ? `https://image.mux.com/${notSeenStorie?.PlaybackID}/thumbnail.png`
    : notSeenStorie.ImageURL

  const containerRef = useRef()

  // Storie Set
  const [currentSetIndex, setCurrentSetIndex] = useState(index) // storieSet list
  const currentStories = storiesSet[currentSetIndex]?.Stories
  const lastIndex = currentStories?.findIndex((item) => !item.IsSeen)
  const lastSeenIndex = lastIndex !== -1 ? lastIndex : 0

  const [currentIndex, setCurrentIndex] = useState(lastSeenIndex) // storieSet

  const handleOpenModal = () => {
    setCurrentSetIndex(index)
    setCurrentIndex(lastSeenIndex)
    setIsOpen(true)
  }
  const currentSingleStorie = currentStories[currentIndex]
  const customKey = `storie-${currentSingleStorie?.UUID || 0}`

  const currentAuthor: Author = storiesSet[currentSetIndex].Author

  const totalLength = storiesSet?.length
  const localLength = currentStories?.length

  const muxVideoTitle = `${customKey}-video-${currentStories[currentIndex]?.PlaybackID || 0}`

  const handleSkipStorie = () => {
    // if (isPaused) {
    //   setIsPaused(false)
    // }
    if (currentSetIndex === totalLength - 1 && currentIndex === localLength - 1) {
      closeModal()
      return
    }
    if (currentIndex === localLength - 1) {
      setCurrentSetIndex((prev) => prev + 1)
      if (currentIndex === 0) {
      }
      setCurrentIndex(0)
      return
    }
    setCurrentIndex((prev) => prev + 1)
  }

  const handlePreviousStory = () => {
    // if (isPaused) {
    //   setIsPaused(false);
    // }
    if (currentIndex === 0 && currentSetIndex === 0) {
      closeModal()
      return
    }
    if (currentIndex === 0) {
      setCurrentSetIndex((prev) => prev - 1)
      setCurrentIndex(0)
      return
    }
    setCurrentIndex((prev) => prev - 1)
  }

  const goToAuthor = () => {
    const link = getAuthorProfile(currentAuthor)
    push(link)
  }

  const isTop = storiesSet[currentSetIndex].IsTop

  useEffect(() => {
    if (!currentSingleStorie?.IsSeen) {
      markStorieAsSeen({
        storieUUID: currentSingleStorie?.UUID,
        authorUUID: author.UUID,
      })
    }
  }, [currentIndex])

  return (
    <>
      <button
        onClick={handleOpenModal}
        className="bg-gray-2 rounded-xl overflow-hidden relative flex flex-col justify-center"
        style={{ height: 238, width: 138 }}
      >
        <Image fill src={thumbnail} alt="slider image" className="bg-gray-2 !object-cover blur-md" />
        <Avatar
          levelColor={storieAuthor.levelColor}
          borderWidth="sm"
          className="w-8 h-8 m-2 z-30 !absolute top-0"
          image={storieAuthor.Photo?.Thumbnail}
        />
        <Image width={138} height={138} src={thumbnail} alt="slider image" className="bg-gray-2 !object-cover z-20" />
        <CustomText size={TextSize.Caption} className="p-2 text-white z-20 absolute bottom-0">
          {storieAuthor.Name}
        </CustomText>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          ref={containerRef}
          as="div"
          className="relative z-50"
          onClick={(e) => {
            if (e.target?.localName === 'div') {
              closeModal()
            }
          }}
          onClose={() => {
            console.log('')
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70 backdrop-blur-sm" />
          </Transition.Child>

          <div style={{ zIndex: 100 }} className="fixed inset-0 overflow-y-auto  sm:my-4 sm:mx-4">
            <div className="flex min-h-full items-center justify-center text-center">
              <button onClick={handlePreviousStory} className="sm:block hidden rounded-full bg-black p-2 mr-4">
                <FaChevronLeft className="text-white" />
              </button>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    'w-full sm:max-w-lg flex flex-col gap-6 transform overflow-hidden sm:rounded-sm text-left align-middle shadow-xl transition-all',
                    'bg-black'
                  )}
                >
                  <div className="absolute z-20 w-full h-full grid grid-cols-2">
                    <div className="bg-transparent" onClick={handlePreviousStory} />
                    <div className="bg-transparent" onClick={handleSkipStorie} />
                  </div>
                  <div className="w-full h-full bg-black relative min-h-[100vh] sm:min-h-[95vh] min-w-min flex flex-col gap-4 !overflow-hidden">
                    <Image
                      fill
                      src={
                        currentStories[currentIndex]?.PlaybackID !== ''
                          ? `https://image.mux.com/${currentStories[currentIndex]?.PlaybackID}/thumbnail.png`
                          : currentStories[currentIndex]?.ImageURL
                      }
                      alt="background"
                      className="bg-gray-2 !object-cover blur-md"
                    />
                    {currentStories[currentIndex]?.PlaybackID !== '' ? (
                      <MuxVideoStorie
                        pause={pause}
                        unPause={unPause}
                        key={customKey}
                        id={currentStories[currentIndex]?.PlaybackID}
                        customID={customKey}
                      />
                    ) : (
                      <Image
                        fill
                        key={currentStories[currentIndex]?.UUID}
                        src={currentStories[currentIndex]?.ImageURL}
                        alt="slider image"
                        className="!object-contain"
                        onLoad={() => {
                          pause()
                        }}
                        onLoadingComplete={() => {
                          unPause()
                        }}
                      />
                    )}
                    <div className="bg-gradient-to-b to-transparent from-[#000000CC] z-20">
                      <header className="flex flex-col gap-4 p-4" style={{ zIndex: 100 }}>
                        <div className="flex items-center gap-2 w-full">
                          {currentStories.map((item, index) => {
                            return currentIndex === index && !paused ? (
                              <StorieAnimatedBar key={item.UUID} onEnd={handleSkipStorie} />
                            ) : (
                              <div
                                key={index}
                                className={clsx('w-full h-1 bg-white', index < currentIndex ? '' : 'opacity-50')}
                              />
                            )
                          })}
                        </div>
                        <div className="flex gap-2 justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <Avatar
                              onClick={goToAuthor}
                              levelColor={storieAuthor.levelColor}
                              borderWidth="sm"
                              className="w-12 h-12 cursor-pointer"
                              image={currentAuthor?.Photo?.Thumbnail}
                            />
                            <CustomText
                              onClick={goToAuthor}
                              size={TextSize.SubTitle2}
                              weight={FontWeight.SemiBold}
                              className="text-white z-20 cursor-pointer"
                            >
                              {currentAuthor?.Name || ''}
                            </CustomText>
                            {isTop && (
                              <div className="bg-primary p-1 rounded-lg">
                                <span className="text-white font-semibold">Top10</span>
                              </div>
                            )}
                          </div>
                          <button
                            onClick={() => setIsOpen(false)}
                            className="bg-gray-4 p-2 rounded-full"
                            style={{ zIndex: 100 }}
                          >
                            <FaTimes />
                          </button>
                        </div>
                      </header>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <button onClick={handleSkipStorie} className="sm:block hidden rounded-full bg-black p-2 ml-4 z-20">
                <FaChevronRight className="text-white" />
              </button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
