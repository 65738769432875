import React from 'react'
import { FiUpload } from 'react-icons/fi'

type Props = {
  isDropzoneActive?: boolean
  onlyFiles?: boolean
  defaultText?: string
}

const MultimediaDropzone = ({ isDropzoneActive, onlyFiles = false, defaultText }: Props): JSX.Element | null => {
  return isDropzoneActive ? (
    <div className="absolute top-0 left-0 w-full h-full bg-brand bg-opacity-75 rounded-xl z-20 pointer-events-none select-none overflow-hidden-z">
      <div className="flex w-full h-full flex-col items-center justify-center bg-brand bg-opacity-25 space-y-2">
        <FiUpload className="text-5xl text-white" />
        <p className="text-white font-bold text-center text-lg">
          {defaultText ? (
            <span>{defaultText}</span>
          ) : (
            <>{onlyFiles ? <span>Suelta documentos...</span> : <span>Suelta aquí fotos o documentos...</span>}</>
          )}
        </p>
      </div>
    </div>
  ) : null
}

export default MultimediaDropzone
