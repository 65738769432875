import { SvgIcon } from '../CustomIcon'

export const Mixed = ({ size, color }: SvgIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" stroke={color ? color : 'currentColor'} strokeLinecap="round" strokeLinejoin="round">
        <circle cx="17" cy="7" r="3" />
        <circle cx="7" cy="17" r="3" />
        <path d="M14 14h6v5a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM4 4h6v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z" />
      </g>
    </svg>
  )
}
