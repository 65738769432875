import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Exclamation = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M4.96094 8.00195C7.17008 8.00195 8.96094 6.21109 8.96094 4.00195C8.96094 1.79281 7.17008 0.00195312 4.96094 0.00195312C2.7518 0.00195312 0.960938 1.79281 0.960938 4.00195C0.960938 6.21109 2.7518 8.00195 4.96094 8.00195Z"
        fill={color ?? 'currentColor'}
      />
      <path
        d="M4.96082 4.80195C4.81597 4.80195 4.67673 4.74589 4.57229 4.64552C4.46784 4.54515 4.40628 4.40826 4.40051 4.26352L4.3266 2.39727C4.32364 2.32183 4.33594 2.24657 4.36276 2.176C4.38958 2.10543 4.43037 2.04101 4.48269 1.98658C4.53502 1.93216 4.59779 1.88886 4.66725 1.85928C4.73671 1.82971 4.81142 1.81446 4.88692 1.81445H5.03473C5.11022 1.81446 5.18494 1.82971 5.2544 1.85928C5.32386 1.88886 5.38663 1.93216 5.43895 1.98658C5.49127 2.04101 5.53207 2.10543 5.55889 2.176C5.58571 2.24657 5.59801 2.32183 5.59504 2.39727L5.52114 4.26352C5.51536 4.40826 5.45381 4.54515 5.34936 4.64552C5.24491 4.74589 5.10568 4.80195 4.96082 4.80195Z"
        fill="white"
      />
      <path
        d="M5.52057 5.62967C5.52057 5.32048 5.26992 5.06982 4.96072 5.06982C4.65153 5.06982 4.40088 5.32048 4.40088 5.62967C4.40088 5.93886 4.65153 6.18951 4.96072 6.18951C5.26992 6.18951 5.52057 5.93886 5.52057 5.62967Z"
        fill="white"
      />
    </svg>
  )
}

export default Exclamation
