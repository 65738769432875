import useSWR from 'swr'

import { challengeURLEndpoint, findAcceptedChallengesByPage } from '@/services/challengeService'
import { ChallengeType } from '@/ts/types/challenges.types'

type ReturnProps = {
  data: ChallengeType[]
  loading?: boolean
  error: boolean
}

const useChallengeSnippet = (): ReturnProps => {
  const { data, isLoading, error } = useSWR(
    {
      url: challengeURLEndpoint,
      page: 1,
    },
    findAcceptedChallengesByPage
  )

  return { data, loading: isLoading, error }
}

export default useChallengeSnippet
