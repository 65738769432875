export function mergeStoriesByUUID(notSeen, seen) {
  if (notSeen === null && seen === null) {
    return { FormatedStories: [] }
  }

  const mergedStories = {}

  function addStories(storiesArray) {
    storiesArray.forEach(({ Author, Stories, IsTop }) => {
      const { UUID } = Author
      if (!mergedStories[UUID]) {
        mergedStories[UUID] = { Author: { ...Author }, Stories: [], IsTop }
      }
      mergedStories[UUID].Stories.push(...Stories)
    })
  }

  if (notSeen !== null) {
    addStories(notSeen)
  }
  if (seen !== null) {
    addStories(seen)
  }

  Object.values(mergedStories).forEach((authorData) => {
    authorData.Stories.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt))
  })

  const FormatedStories = Object.values(mergedStories)

  return FormatedStories
}
