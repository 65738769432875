import { SvgIcon } from '../CustomIcon'

const Invite = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="add-user (3) 1" clip-path="url(#clip0_3122_4082)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M14.3582 10.0355C13.9739 9.85281 13.5817 9.69543 13.1827 9.56359C14.475 8.59359 15.3125 7.04902 15.3125 5.3125C15.3125 2.3832 12.9293 0 9.99998 0C7.07065 0 4.68749 2.3832 4.68749 5.3125C4.68749 7.05094 5.52686 8.59703 6.82159 9.56684C5.63534 9.95738 4.52225 10.5651 3.54803 11.3664C1.76163 12.8358 0.519555 14.8854 0.0506881 17.1376C-0.096265 17.8433 0.0797896 18.5682 0.533618 19.1262C0.985219 19.6815 1.65487 20 2.37081 20H11.9922C12.4237 20 12.7734 19.6502 12.7734 19.2188C12.7734 18.7873 12.4237 18.4375 11.9922 18.4375H2.37081C2.03729 18.4375 1.83608 18.2513 1.74584 18.1404C1.59002 17.9488 1.52971 17.6994 1.58038 17.4561C2.39112 13.5617 5.82612 10.717 9.79288 10.621C9.86159 10.6237 9.93061 10.625 9.99998 10.625C10.07 10.625 10.1398 10.6236 10.2091 10.6209C11.4248 10.6495 12.5939 10.9269 13.6874 11.4467C14.0771 11.6319 14.5431 11.4662 14.7284 11.0765C14.9136 10.6868 14.7479 10.2207 14.3582 10.0355ZM10.1905 9.0577C10.1271 9.05656 10.0636 9.05598 9.99998 9.05598C9.93694 9.05598 9.87389 9.0566 9.81092 9.05777C7.83065 8.95898 6.24999 7.31687 6.24999 5.3125C6.24999 3.24473 7.93221 1.5625 9.99998 1.5625C12.0678 1.5625 13.75 3.24473 13.75 5.3125C13.75 7.31637 12.1701 8.95816 10.1905 9.0577Z"
              fill={color ? color : 'currentColor'}
            />
          </g>
        </g>
        <g id="Group_3">
          <g id="Group_4">
            <path
              id="Vector_2"
              d="M19.2188 15.5078H17.0703V13.3594C17.0703 12.9279 16.7205 12.5781 16.2891 12.5781C15.8576 12.5781 15.5078 12.9279 15.5078 13.3594V15.5078H13.3594C12.9279 15.5078 12.5781 15.8576 12.5781 16.2891C12.5781 16.7205 12.9279 17.0703 13.3594 17.0703H15.5078V19.2188C15.5078 19.6502 15.8576 20 16.2891 20C16.7205 20 17.0703 19.6502 17.0703 19.2188V17.0703H19.2188C19.6502 17.0703 20 16.7205 20 16.2891C20 15.8576 19.6502 15.5078 19.2188 15.5078Z"
              fill={color ? color : 'currentColor'}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3122_4082">
          <rect width="20" height="20" fill={color ? color : 'currentColor'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Invite
