import { getSession } from 'next-auth/react'
import { create } from 'zustand'

import { findCompaniesByUserUUID } from '@/services/companiesService'
import { loadUserSafely } from '@/utils/auth.client'

import { SunetUser, UserSession } from '../ts/types/user.types'

interface AuthState {
  user: SunetUser | null
  authorUUID: string
  ready: boolean
  error: boolean
  loadFromStorage: () => Promise<void>
  setAuthorUUID: (authorUUID: string) => Promise<void>
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  authorUUID: null,
  companies: null,
  ready: false,
  error: false,
  loadFromStorage: async () => {
    const session = (await getSession()) as unknown as UserSession
    const user = session?.userUUID ? await loadUserSafely(session?.userUUID) : null
    const companies = session?.userUUID ? await findCompaniesByUserUUID(session?.userUUID) : null

    if (!user) {
      set({
        error: true,
        ready: true,
      })
      return
    }
    set({
      user: { ...user, Companies: companies },
      authorUUID: user.UUID,
      error: false,
      ready: true,
    })
  },
  setAuthorUUID: async (authorUUID) => {
    set({ authorUUID })
  },
}))
