import useSWR from 'swr'

import { findInitiativesByPage, initiativesURLEndpoint } from '@/services/initiativesService'
import { InitiativeType } from '@/ts/types/initiatives.types'

type ReturnProps = {
  data: InitiativeType[]
  loading?: boolean
  error?: boolean
}

const useInitiativeSnippet = (): ReturnProps => {
  const { data, isLoading, error } = useSWR(
    {
      url: initiativesURLEndpoint,
      page: 1,
    },
    findInitiativesByPage
  )

  return { data, loading: isLoading, error }
}

export default useInitiativeSnippet
