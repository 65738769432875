import React from 'react'

import { SvgIcon } from '../../CustomIcon'

const NewPost = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4925 21.5C11.1793 21.5 10.8773 21.3783 10.642 21.1571C9.75327 20.3233 8.89641 19.5398 8.14043 18.8486L8.13657 18.845C5.92015 16.8185 4.0062 15.0685 2.6745 13.3445C1.18587 11.4173 0.492493 9.58994 0.492493 7.5937C0.492493 5.6542 1.11235 3.86488 2.23776 2.55512C3.3766 1.22988 4.93925 0.5 6.63837 0.5C7.90829 0.5 9.0713 0.930762 10.095 1.78022C10.6116 2.209 11.0799 2.73377 11.4925 3.34587C11.9052 2.73377 12.3734 2.209 12.8902 1.78022C13.9138 0.930762 15.0769 0.5 16.3468 0.5C18.0457 0.5 19.6086 1.22988 20.7474 2.55512C21.8728 3.86488 22.4925 5.6542 22.4925 7.5937C22.4925 9.58994 21.7993 11.4173 20.3107 13.3443C18.979 15.0685 17.0652 16.8183 14.8491 18.8447C14.0918 19.5369 13.2336 20.3217 12.3428 21.1575C12.1077 21.3783 11.8055 21.5 11.4925 21.5ZM6.63837 1.88269C5.30348 1.88269 4.07719 2.45428 3.18509 3.49228C2.27972 4.54596 1.78105 6.00248 1.78105 7.5937C1.78105 9.27263 2.36264 10.7742 3.66664 12.4623C4.927 14.094 6.80168 15.8081 8.97227 17.7928L8.9763 17.7964C9.73514 18.4902 10.5954 19.2769 11.4906 20.1168C12.3913 19.2752 13.2529 18.4874 14.0132 17.7924C16.1836 15.8077 18.0582 14.094 19.3185 12.4623C20.6223 10.7742 21.2039 9.27263 21.2039 7.5937C21.2039 6.00248 20.7053 4.54596 19.7999 3.49228C18.908 2.45428 17.6815 1.88269 16.3468 1.88269C15.3689 1.88269 14.4711 2.2162 13.6784 2.87387C12.9719 3.46023 12.4798 4.20145 12.1912 4.7201C12.0429 4.9868 11.7817 5.146 11.4925 5.146C11.2033 5.146 10.9421 4.9868 10.7937 4.7201C10.5054 4.20145 10.0133 3.46023 9.30663 2.87387C8.51389 2.2162 7.61607 1.88269 6.63837 1.88269Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M14.548 10.5624H12.1036V8.12762C12.1036 7.7624 11.8591 7.51892 11.4925 7.51892C11.1258 7.51892 10.8814 7.7624 10.8814 8.12762V10.5624H8.43692C8.07025 10.5624 7.82581 10.8059 7.82581 11.1711C7.82581 11.5363 8.07025 11.7798 8.43692 11.7798H10.8814V14.2146C10.8814 14.5798 11.1258 14.8233 11.4925 14.8233C11.8591 14.8233 12.1036 14.5798 12.1036 14.2146V11.7798H14.548C14.9147 11.7798 15.1591 11.5363 15.1591 11.1711C15.1591 10.8059 14.9147 10.5624 14.548 10.5624Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  )
}

export default NewPost
