import { useAuthStore } from '@/dataStores/auth'

const useSunetAuthors = () => {
  const { user, authorUUID } = useAuthStore((state) => ({ user: state.user, authorUUID: state.authorUUID }))

  const isUserValid = user?.DeactivateReason || user?.IsActive
  const userAuthor = isUserValid
    ? {
        Country: user?.Country,
        Photo: user?.Photo,
        UUID: user?.UUID,
        ODS: user?.InterestODSs?.[0],
        ShortName: user?.FirstName,
        Name: `${user?.FirstName} ${user?.LastName}`,
        Type: 'user',
        Position: user?.Position,
        Latitude: user?.Latitude,
        Longitude: user?.Longitude,
      }
    : null

  const companyAuthors =
    isUserValid && user?.Companies
      ? user.Companies.map((company) => ({
          Photo: company.Logo,
          Country: company.Country,
          UUID: company.UUID,
          ShortName: company.Name,
          Name: company.Name,
          Type: 'company',
          Latitude: company?.Latitude,
          Longitude: company?.Longitude,
          IsPublished: company?.IsPublished,
        }))
      : []

  const enabledCompanies = companyAuthors.filter((c) => c.IsPublished)

  const authors = [userAuthor, ...enabledCompanies]

  const author = authors.find((author) => author?.UUID === authorUUID)

  return { authors, companyAuthors: enabledCompanies, author }
}

export default useSunetAuthors
