import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'

type Props = {
  /**
   * Content for Card
   */
  children?: ReactNode | string
  /**
   * ClassNames
   */
  className?: string
  /**
   * Style inline object
   */
  style?: object
  /**
   * Activate Lorem Ipsum for testing purposes
   */
  debug?: boolean
}

const Card = ({ children = '', className, style, debug = false, ...rest }: Props): JSX.Element => {
  const content = useMemo(() => {
    return (
      <>
        {children}{' '}
        {debug
          ? 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab assumenda culpa cumque cupiditate earum eius ex odio perferendis, praesentium quas repudiandae sapiente sint sit, tempora ullam, unde veniam vitae voluptate!'
          : null}
      </>
    )
  }, [children, debug])
  const props = useMemo(() => {
    return {
      className: clsx('bg-white sunet-shadow  rounded-2xl px-4 lg:px-6 py-4 lg:py-5 overflow-hidden', className),
      style,
      children: content,
      ...rest,
    }
  }, [className, style, content, rest])

  return useMemo(() => {
    return <div {...props} />
  }, [content, props])
}

export default Card
