import { MuxVideo, SharedFile, UploadMuxVideo } from '@/ts/types/general.types'

import axios from '../utils/axios'

export const createFileByUserUUID = async (userUUID: string, file: File): Promise<SharedFile> => {
  const formData = new FormData()
  formData.append('ResourceUUID', userUUID)
  formData.append('File', file)

  return axios.post('/files', formData).then((res: { data: { Data: SharedFile } }) => res.data.Data)
}

export const getUploadURL = async (userUUID: string): Promise<UploadMuxVideo> => {
  return axios
    .post(`/files/video`, { ResourceUUID: userUUID })
    .then((res: { data: { Data: UploadMuxVideo } }) => res.data.Data)
}

export const getMuxVideoCheckData = async (uploadID: string, userUUID: string): Promise<MuxVideo> => {
  return axios
    .post(`/files/video-check`, {
      UploadID: uploadID,
      ResourceUUID: userUUID,
    })
    .then((res: { data: { Data: MuxVideo } }) => res.data.Data)
}
export const createFileByResourceUUID = async (resourceUUID: string, file: File): Promise<SharedFile> => {
  const formData = new FormData()
  formData.append('ResourceUUID', resourceUUID)
  formData.append('File', file)

  return axios.post('/files', formData).then((res: { data: { Data: SharedFile } }) => res.data.Data)
}
