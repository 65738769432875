import Image from 'next/image'
import { useRouter } from 'next/router'
import Token from 'public/static/images/token.png'
import { FaChevronRight } from 'react-icons/fa'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { useAuthStore } from '@/dataStores/auth'
import useRequest2 from '@/hooks/useRequest2'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { getCurrentLevelActions } from '@/services/gamificationService'
import { RankingData } from '@/ts/types/gamification.types'
import { levelActionsProperties, reduceActions } from '@/utils/actions'
import axios from '@/utils/axios'
import { getLocaleText } from '@/utils/i18n'
import { sunetLevels } from '@/utils/levels'

import RankingCardSkeleton from './RankingCardSkeleton'

const Greetings = (): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])
  const { author } = useSunetAuthors()

  const router = useRouter()

  const [currentActions, loadingCurrentActions] = useRequest2({
    requestKey: '/gamification/user-level',
    params: [author.UUID],
    request: getCurrentLevelActions,
  })

  const currentActionsSum = !loadingCurrentActions ? reduceActions(currentActions, levelActionsProperties) : 0

  const [ranking, loadingRanking] = useRequest2({
    requestKey: '/ranking/greetings',
    params: [author.UUID],
    request: (userUUID) => axios.get(`/gamification/ranking?AuthorUUID=${userUUID}&Type=token&include=Level,NextLevel`),
  })

  const rankingData: RankingData = ranking?.data?.Data[0]

  const currentLevel = rankingData?.Included?.Level || sunetLevels[0]
  const nextLevel = rankingData?.Included?.NextLevel

  const newLevelActions = !loadingRanking ? reduceActions(nextLevel, levelActionsProperties) : 100

  const actionsLeft = newLevelActions - currentActionsSum

  const progress = rankingData?.TotalScore || 0
  const progressBar = ((currentActionsSum / newLevelActions) * 100).toFixed()

  return (
    <div className="flex flex-col gap-4">
      <CustomText size={TextSize.Heading2} weight={FontWeight.SemiBold}>
        {author.Type === 'user' ? `Hola, ${user.FirstName} ${user.LastName} 👋` : `Hola, ${author.Name} 👋`}
      </CustomText>

      {loadingRanking ? (
        <div className="">
          <RankingCardSkeleton />
        </div>
      ) : (
        <Card className="!p-0">
          <div className="px-4 py-4 sm:px-16 bg-gradient-to-r to-[#156D73] from-gradient-2 flex justify-between md:gap-4 sm:gap-8 text-white items-center">
            <div className="flex justify-between gap-6 items-center">
              <Image src={Token} alt="Sunet Token" height={55} width={55} />
              <div className="flex flex-col">
                <CustomText element="span" size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
                  Balance de recompensa
                </CustomText>
                <div className="flex items-end gap-1">
                  <CustomText className="leading-none" weight={FontWeight.Bold} size={TextSize.Heading1}>
                    {progress.toFixed(1)}
                  </CustomText>
                  <CustomText weight={FontWeight.SemiBold} size={TextSize.Paragraph}>
                    SNT
                  </CustomText>
                </div>
              </div>
            </div>

            <Button appearance={ButtonAppearance.Black} size={ButtonSize.Small} onClick={() => router.push(`/wallet`)}>
              Ir a Wallet
            </Button>
          </div>
          <div className="bg-white hover:bg-gray-50 cursor-pointer">
            <div
              className="p-2 flex justify-between sm:gap-4 items-center px-4 sm:px-16"
              onClick={() => router.push('/levels/progress')}
            >
              <div className="flex gap-10 items-center">
                <Image
                  src={`/static/images/levels/circle/${rankingData?.Included?.Level?.Number || 1}.png`}
                  alt={`Ilustracion de nivel ${getLocaleText(
                    rankingData?.Included?.Level?.Name || sunetLevels[0].Name
                  )}`}
                  height={60}
                  width={60}
                />
                <div className="-ml-6">
                  <CustomText size={TextSize.SubTitle2}>
                    Estás en el <span className="font-bold">nivel {currentLevel?.Number || 1}</span>
                  </CustomText>
                  <CustomText
                    className="rounded-md py-1 px-2 text-white"
                    weight={FontWeight.SemiBold}
                    size={TextSize.SubTitle2}
                    style={{ backgroundColor: rankingData?.Included?.Level?.Color || sunetLevels[0].Color }}
                  >
                    {getLocaleText(currentLevel?.Name || sunetLevels[0].Name)}
                  </CustomText>
                </div>
              </div>

              <Button appearance={ButtonAppearance.White}>
                <FaChevronRight className="hover:bg-gray-light" />
              </Button>
            </div>
            <div className="px-4 sm:px-16 pb-4">
              <div className="flex gap-2 items-center justify-between">
                <div className="h-2 bg-gray-200 rounded-full w-full overflow-hidden">
                  <div
                    className={`h-full bg-primary rounded-full`}
                    style={{
                      width: `${progressBar.toString()}%`,
                    }}
                  ></div>
                </div>
                <CustomText element="span">{isNaN(+progressBar) ? 0 : progressBar}%</CustomText>
              </div>
              <CustomText element="p">Haz {actionsLeft} acciones más para avanzar de nivel</CustomText>
            </div>
          </div>
        </Card>
      )}
    </div>
  )
}

export default Greetings
