import { SvgIcon } from '../CustomIcon'

const Chronometer = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={color ? color : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="chronometer 1">
        <g id="Group">
          <g id="_x31_2_42_">
            <g id="Group_2">
              <path
                id="Vector"
                d="M8.02757 5.33911C8.02255 5.33911 8.01772 5.33947 8.0127 5.33947V9.63343H12.2607C12.261 9.61346 12.2622 9.59379 12.2622 9.57383C12.2623 7.23508 10.3663 5.33911 8.02757 5.33911Z"
                fill="#1AC5E9"
              />
              <path id="Vector_2" d="M8.38426 12.9365H7.64062V14.0115H8.38426V12.9365Z" fill="#1AC5E9" />
              <path id="Vector_3" d="M4.94605 9.12524H3.87109V9.85248H4.94605V9.12524Z" fill="#1AC5E9" />
              <path
                id="Vector_4"
                d="M12.7037 5.14285L13.4191 4.38815C13.6076 4.18925 13.5992 3.87522 13.4004 3.68677L12.8003 3.11791C12.6014 2.92936 12.2874 2.93779 12.0989 3.13668L11.2806 3.99988C10.6353 3.61709 9.91805 3.34371 9.15436 3.20514V2.14993H10.1294C10.4035 2.14993 10.6256 1.92779 10.6256 1.65379V0.496138C10.6256 0.222103 10.4035 0 10.1294 0H5.87097C5.5969 0 5.3748 0.222103 5.3748 0.496138V1.65379C5.3748 1.92779 5.5969 2.14993 5.87097 2.14993H6.83207V3.20779C6.05764 3.35006 5.33107 3.63074 4.67933 4.02384C4.67438 4.01868 4.66978 4.0133 4.66456 4.00834L3.73446 3.12654C3.53553 2.93808 3.22156 2.94641 3.03305 3.14531L2.46418 3.74548C2.27573 3.94424 2.28406 4.25831 2.48296 4.44679L3.25938 5.18288C2.19941 6.3326 1.55078 7.86716 1.55078 9.55057C1.55081 13.1068 4.44401 16 8.00017 16C11.5564 16 14.4495 13.1068 14.4495 9.55057C14.4495 7.84753 13.7858 6.29684 12.7037 5.14285ZM8.00017 14.843C5.08196 14.843 2.70777 12.4689 2.70777 9.55057C2.70777 6.63229 5.08192 4.25811 8.00017 4.25811C10.9184 4.25811 13.2926 6.63229 13.2926 9.55057C13.2926 12.4689 10.9184 14.843 8.00017 14.843Z"
                fill="#1AC5E9"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Chronometer
