import Link from 'next/link'
import { FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'

export const Footer = () => {
  return (
    <footer className="bg-[#2D2D2D]">
      <div style={{ maxWidth: 1200 }} className="flex items-center justify-between px-4 py-4 text-white mt-12 mx-auto">
        <span>© 2024 Sunet - SustainableCo SpA</span>
        <div className="flex items-center gap-4">
          <Link href={'/privacy'} className="text-sm underline">
            Privacidad
          </Link>
          <div className="p-2 bg-[#4E4D4D] rounded-full cursor-pointer">
            <FaYoutube />
          </div>
          <div className="p-2 bg-[#4E4D4D] rounded-full cursor-pointer">
            <FaInstagram />
          </div>
          <div className="p-2 bg-[#4E4D4D] rounded-full cursor-pointer">
            <FaLinkedinIn />
          </div>
        </div>
      </div>
    </footer>
  )
}
