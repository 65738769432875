import clsx from 'clsx'
import { createElement, ReactNode, useMemo } from 'react'

export enum TextSize {
  Heading1 = 'md:text-3xl text-2xl',
  Heading2 = 'md:text-2xl text-xl',
  SubTitle1 = 'md:text-xl text-lg',
  SubTitle2 = 'md:text-lg text-base',
  Paragraph = 'md:text-base text-sm',
  Caption = 'md:text-sm text-xs',
  Tiny = 'text-xs',
}

export enum FontWeight {
  Normal = 'font-normal',
  Medium = 'font-medium',
  SemiBold = 'font-semibold',
  Bold = 'font-bold',
  ExtraBold = 'font-extrabold',
}

type Props = {
  id?: string
  size?: TextSize
  weight?: FontWeight
  children?: ReactNode | string
  className?: string
  style?: object
  /**
   * If true, shows a large text at the end of children
   */
  debug?: boolean
  element?: keyof JSX.IntrinsicElements
}

const CustomText = ({
  size = TextSize.Paragraph,
  weight = FontWeight.Normal,
  children = '',
  className,
  style,
  debug,
  element = 'div', // <div> as default
  ...rest
}: Props): JSX.Element => {
  const content = useMemo(() => {
    return (
      <>
        {children}{' '}
        {debug
          ? 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab assumenda culpa cumque cupiditate earum eius ex odio perferendis, praesentium quas repudiandae sapiente sint sit, tempora ullam, unde veniam vitae voluptate!'
          : null}
      </>
    )
  }, [children, debug])

  const props = useMemo(() => {
    return {
      className: clsx(className, weight, size),
      style,
      children: content,
      ...rest,
    }
  }, [className, weight, style, content, rest, size])

  return useMemo(() => {
    return createElement(element, props, content)
  }, [props, element, content])
}

export default CustomText
