export const Slide6 = () => {
  return (
    <svg width="374" height="777" viewBox="0 0 374 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5794_68404)">
        <rect width="374" height="777" fill="white" />
        <g clipPath="url(#clip1_5794_68404)">
          <rect width="374" height="777" fill="#A5F4F7" />
          <mask
            id="mask0_5794_68404"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="6"
            y="-15"
            width="369"
            height="386"
          >
            <path
              d="M41.1249 -14.6172C21.7264 -14.6172 6 0.595633 6 19.3614V337.02C6 355.785 21.7264 370.998 41.1249 370.998H339.577C358.975 370.998 374.702 355.785 374.702 337.02V19.3614C374.702 0.595633 358.975 -14.6172 339.577 -14.6172H41.1249Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5794_68404)">
            <path d="M395.803 -44.9531H-7.99219V382.739H395.803V-44.9531Z" fill="#A5F4F7" />
            <path
              d="M424.708 32.4663C423.916 20.1325 413.335 10.2114 400.568 9.8033C396.604 9.67618 392.841 10.4415 389.472 11.9072C383.455 14.5249 376.708 14.7085 370.569 12.5208C367.359 10.9029 363.762 9.92496 359.965 9.8033C359.607 9.79143 359.258 9.8253 358.903 9.82831C358.546 9.8253 358.197 9.79145 357.837 9.80256C354.038 9.92422 350.443 10.9036 347.231 12.5222C341.094 14.71 334.346 14.5242 328.329 11.9065C324.961 10.4415 321.197 9.6762 317.234 9.80256C304.467 10.2105 293.885 20.1303 293.093 32.4648C292.192 46.5405 303.725 58.2292 318.081 58.2292C321.903 58.2292 325.523 57.4008 328.763 55.9193C334.303 53.3831 340.71 53.1327 346.436 55.1329C350.057 57.1024 354.233 58.2292 358.683 58.2292C358.757 58.2292 358.828 58.2182 358.901 58.2174C358.974 58.2182 359.045 58.2292 359.119 58.2292C363.569 58.2292 367.744 57.1024 371.366 55.1329C377.091 53.1327 383.499 53.3831 389.039 55.9193C392.279 57.4008 395.899 58.2292 399.721 58.2292C414.076 58.2292 425.609 46.5418 424.708 32.4663Z"
              fill="white"
            />
            <path
              d="M149.665 33.8654C148.873 21.5317 138.292 11.6098 125.525 11.2017C121.561 11.0746 117.797 11.8407 114.429 13.3064C108.412 15.9241 101.664 16.1075 95.525 13.9199C92.3144 12.3026 88.7185 11.3234 84.9214 11.2017C84.563 11.19 84.2143 11.2243 83.859 11.2275C83.5022 11.2243 83.1538 11.1901 82.7937 11.201C78.9942 11.3226 75.3991 12.3026 72.187 13.9207C66.0499 16.1082 59.3016 15.9234 53.2847 13.3057C49.9168 11.8407 46.1529 11.0746 42.1906 11.201C29.4233 11.6091 18.8413 21.5294 18.0495 33.8639C17.1476 47.9395 28.6813 59.6284 43.0369 59.6284C46.859 59.6284 50.4791 58.7993 53.7189 57.3177C59.2589 54.7817 65.6666 54.5311 71.3916 56.5313C75.0133 58.501 79.1892 59.6284 83.6393 59.6284C83.713 59.6284 83.7837 59.6168 83.8567 59.6159C83.9307 59.6168 84.0012 59.6284 84.075 59.6284C88.525 59.6284 92.701 58.501 96.3227 56.5313C102.048 54.5311 108.455 54.7817 113.995 57.3177C117.235 58.7993 120.855 59.6284 124.677 59.6284C139.032 59.6284 150.566 47.9412 149.665 33.8654Z"
              fill="white"
            />
            <path
              d="M357.794 254.334C353.838 254.207 350.081 254.972 346.719 256.435C340.177 259.281 332.76 259.281 326.217 256.434C322.856 254.972 319.1 254.207 315.145 254.334C302.402 254.74 291.84 264.642 291.05 276.952C290.148 291.002 301.66 302.668 315.989 302.668C319.803 302.668 323.417 301.841 326.649 300.362C332.832 297.532 340.103 297.532 346.287 300.362C349.52 301.841 353.133 302.668 356.947 302.668C371.275 302.668 382.786 291.002 381.887 276.954C381.098 264.643 370.537 254.741 357.794 254.334Z"
              fill="white"
            />
            <path
              d="M341.68 124.729C341.68 124.729 348.296 126.499 353.333 125.289C356.559 124.514 357.535 124.089 357.535 124.089L357.285 120.839L350.754 120.86L350.753 120.664L341.68 124.729Z"
              fill="#336666"
            />
            <path
              d="M305.821 132.656C301.105 127.644 303.476 119.587 310.214 117.728C316.973 115.862 324.52 113.739 329.232 112.288C339.432 109.145 342.999 108.371 342.999 108.371L344.133 123.753C344.133 123.753 343.035 124.749 337.15 126.852C333.315 128.222 323.072 132.286 316.399 134.951C312.725 136.419 308.498 135.5 305.821 132.656Z"
              fill="#336666"
            />
            <path
              d="M350.771 120.658C351.551 119.849 362.166 116.886 362.166 116.886L362.09 114.093L352.302 115.336L352.532 114.478L362.842 112.322L362.841 109.12L351.759 111.22L351.708 110.694L362.049 108.007L361.693 105.574C357.788 105.898 349.813 107.121 349.813 107.121L349.888 106.71L358.174 105.097L357.695 102.43C357.695 102.43 357.552 101.738 349.302 103.263C343.54 104.329 339.69 109.778 339.69 109.778L341.684 124.732L350.771 120.658Z"
              fill="#336666"
            />
            <path
              d="M175.137 110.283C154.45 105.882 152.383 95.5805 141.935 89.7762C132.776 84.6882 117.065 98.4769 108.477 93.9767C96.2179 87.5526 91.1305 72.5297 81.6597 66.5512C75.0497 62.379 62.6235 62.4214 44.8824 69.0222L13.7217 141.661C22.9087 139.766 45.272 134.615 55.0981 140.92C66.0339 147.938 64.6233 166.65 78.8498 167.604C95.2357 168.702 116.282 152.006 135.805 145.862C144.541 143.112 152.178 146.477 160.324 147.591L175.137 110.283Z"
              fill="#FF6B1C"
            />
          </g>
          <mask
            id="mask1_5794_68404"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="13"
            y="63"
            width="163"
            height="105"
          >
            <path
              d="M44.9001 69.018L13.7402 141.657C22.9271 139.762 45.2906 134.61 55.1159 140.915C66.0525 147.934 64.6409 166.646 78.8684 167.6C95.2542 168.698 116.301 152.002 135.823 145.857C144.559 143.108 152.196 146.473 160.342 147.587L175.156 110.279C154.468 105.878 152.402 95.5763 141.953 89.7712C137.301 87.1881 130.959 89.4732 124.719 91.7582C118.672 93.9727 112.721 96.1868 108.495 93.9725C96.2357 87.5481 91.1491 72.5253 81.6775 66.5469C78.763 64.7076 74.7178 63.6875 69.5849 63.6875C63.0764 63.6875 54.8188 65.3277 44.9001 69.018Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_5794_68404)">
            <path
              d="M85.9949 97.3416C63.7307 79.0161 44.4379 83.4878 25.7785 87.8113L24.6644 88.0695L24.0986 85.7795L25.2112 85.522C44.4603 81.061 64.3647 76.448 87.5705 95.5488L85.9949 97.3416Z"
              fill="#336666"
            />
            <path
              d="M114.476 130.581C101.082 130.581 89.3761 125.493 77.0776 120.146C58.9989 112.286 38.5084 103.379 6.99973 107.487L6.67578 105.154C38.8755 100.953 59.6997 110.009 78.0753 117.998C92.5045 124.271 104.97 129.691 120.345 127.883L120.638 130.221C118.54 130.467 116.489 130.581 114.476 130.581Z"
              fill="#336666"
            />
          </g>
          <mask
            id="mask2_5794_68404"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="6"
            y="-15"
            width="369"
            height="386"
          >
            <path
              d="M41.1249 -14.6172C21.7264 -14.6172 6 0.595633 6 19.3614V337.02C6 355.785 21.7264 370.998 41.1249 370.998H339.577C358.975 370.998 374.702 355.785 374.702 337.02V19.3614C374.702 0.595633 358.975 -14.6172 339.577 -14.6172H41.1249Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_5794_68404)">
            <path
              d="M198.654 259.751L172.386 287.317L156.431 273.088L183.789 244.379C183.789 244.379 198.457 259.575 198.654 259.751Z"
              fill="#336666"
            />
            <path
              d="M172.373 287.319C172.373 287.319 169.922 289.892 169.779 290.998C169.636 292.104 171.636 294.593 173.215 300.583C174.794 306.573 166.767 311.268 166.767 311.268L141.816 288.797L157.418 272.043L167.387 281.464C167.387 281.464 170.468 280.334 172.68 282.218C174.892 284.102 175.138 284.322 175.138 284.322C175.138 284.322 173.281 286.367 172.373 287.319Z"
              fill="#FFDA33"
            />
            <path
              d="M141.07 291.123L164.774 312.263C165.644 313.039 166.999 312.986 167.801 312.144L169.377 310.491C170.179 309.649 170.124 308.339 169.254 307.563L145.549 286.423C144.679 285.648 143.325 285.701 142.522 286.542L140.947 288.195C140.145 289.037 140.2 290.348 141.07 291.123Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M166.299 285.797L165.217 286.966L169.717 290.866L170.799 289.698L166.299 285.797Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M168.694 283.254L167.611 284.422L172.112 288.324L173.194 287.156L168.694 283.254Z"
              fill="white"
            />
            <path
              d="M167.136 282.875C166.619 283.418 166.655 284.262 167.215 284.762C167.776 285.262 168.648 285.227 169.165 284.685C169.682 284.143 169.646 283.299 169.086 282.799C168.525 282.299 167.653 282.333 167.136 282.875Z"
              fill="white"
            />
            <path
              d="M164.723 285.418C164.206 285.961 164.242 286.805 164.803 287.305C165.363 287.805 166.236 287.77 166.752 287.228C167.269 286.686 167.234 285.842 166.673 285.342C166.113 284.842 165.239 284.876 164.723 285.418Z"
              fill="white"
            />
            <path
              d="M170.484 235.418L135.706 251.962L126.131 233.125L162.351 215.895C162.351 215.895 170.366 235.186 170.484 235.418Z"
              fill="#336666"
            />
            <path
              d="M135.716 251.974C135.716 251.974 132.471 253.519 131.925 254.501C131.379 255.482 132.316 258.502 131.555 264.639C130.794 270.777 121.559 272.355 121.559 272.355L106.666 242.69L127.463 232.508L133.249 244.772C133.249 244.772 136.544 244.794 137.904 247.324C139.265 249.853 139.412 250.143 139.412 250.143C139.412 250.143 136.918 251.402 135.716 251.974Z"
              fill="#FFDA33"
            />
            <path
              d="M105.093 244.592L119.321 272.58C119.843 273.606 121.125 274.03 122.187 273.525L124.274 272.532C125.336 272.028 125.773 270.786 125.25 269.76L111.024 241.771C110.502 240.744 109.218 240.321 108.156 240.827L106.07 241.819C105.009 242.324 104.572 243.565 105.093 244.592Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M130.616 248.414L129.171 249.126L131.914 254.336L133.359 253.624L130.616 248.414Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M133.789 246.906L132.344 247.618L135.086 252.828L136.532 252.116L133.789 246.906Z"
              fill="white"
            />
            <path
              d="M132.485 246.005C131.801 246.33 131.518 247.13 131.855 247.791C132.192 248.454 133.018 248.725 133.702 248.4C134.386 248.075 134.667 247.275 134.331 246.614C133.995 245.952 133.168 245.679 132.485 246.005Z"
              fill="white"
            />
            <path
              d="M129.273 247.528C128.589 247.854 128.306 248.654 128.643 249.315C128.98 249.977 129.806 250.25 130.49 249.924C131.174 249.599 131.456 248.799 131.119 248.137C130.783 247.476 129.956 247.202 129.273 247.528Z"
              fill="white"
            />
            <path
              d="M329.708 192.33C318.364 244.432 265.507 277.772 211.649 266.797C157.791 255.823 123.326 204.69 134.671 152.589C146.015 100.487 198.872 67.1463 252.73 78.1209C306.588 89.0948 341.052 140.229 329.708 192.33Z"
              fill="#00CCFF"
            />
          </g>
          <mask
            id="mask3_5794_68404"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="132"
            y="76"
            width="200"
            height="193"
          >
            <path
              d="M134.665 152.597C123.32 204.698 157.785 255.832 211.643 266.805C265.501 277.78 318.358 244.439 329.702 192.338C341.047 140.236 306.582 89.1036 252.724 78.129C245.798 76.7173 238.891 76.0391 232.084 76.0391C185.96 76.0398 144.55 107.195 134.665 152.597Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_5794_68404)">
            <path
              d="M204.943 83.6488C205.484 90.5996 211.636 95.8755 218.834 95.5625L245.321 94.4134C247.79 94.3059 250.243 94.8653 252.403 96.0282L266.963 103.869C272.683 106.949 279.868 105.557 283.92 100.582L293.546 88.7615C299.014 82.0454 296.338 72.1337 288.172 68.8638L277.539 64.6059C275.436 63.7639 273.145 63.4563 270.884 63.7128L216.682 69.8702C209.583 70.6768 204.405 76.7546 204.943 83.6488Z"
              fill="#FFDA33"
            />
            <path
              d="M307.593 289.657L291.808 276.712C283.499 269.898 283.516 257.493 291.844 250.701C295.3 247.882 297.503 243.885 297.997 239.538L300.506 217.421C301.072 212.431 303.885 207.937 308.194 205.139L318.561 198.407C322.66 195.746 325.415 191.542 326.156 186.82L328.437 172.272C329.707 164.17 336.762 158.091 345.228 157.805L372.42 156.885C379.515 156.645 386.064 160.552 389.03 166.793L414.833 221.091C417.837 227.415 416.562 234.87 411.614 239.921L382.246 269.891C379.664 272.526 378.021 275.89 377.555 279.493L376.086 290.846C374.722 301.393 363.815 308.168 353.333 304.978L313.693 292.911C311.456 292.23 309.382 291.123 307.593 289.657Z"
              fill="#FFDA33"
            />
            <path
              d="M141.945 122.914H158.633C165.285 122.914 170.731 128.034 170.92 134.466L171.864 166.446C172.046 172.609 177.067 177.616 183.43 177.978C191.526 178.438 196.949 186.224 194.351 193.656L183.404 224.981C182.16 228.539 182.727 232.454 184.932 235.545L192.436 246.057C199.054 255.33 189.867 267.479 178.727 264.185L114.034 245.053C111.866 244.412 109.923 243.202 108.421 241.56L90.0457 221.465C87.5377 218.723 86.4756 215.015 87.1667 211.415L92.1423 185.499C92.7166 182.505 94.4569 179.839 97.003 178.05L122.144 160.382C124.862 158.472 126.654 155.569 127.101 152.348L129.764 133.219C130.585 127.318 135.79 122.914 141.945 122.914Z"
              fill="#FFDA33"
            />
          </g>
          <path d="M279.782 146.062L281.91 144.551L291.61 149.98L281.858 158.069L279.782 146.062Z" fill="#FF6B1C" />
          <path
            d="M219.482 75.1016L185.73 76.8436L185.566 79.3345L213.587 78.7879L219.481 75.1016H219.482Z"
            fill="#FF6B1C"
          />
          <path
            d="M213.637 77.2094C213.974 72.1363 216.379 56.5626 233.255 57.5059C253.394 58.6319 253.799 77.9928 253.799 77.9928L213.584 78.7785C213.584 78.7785 213.572 78.1902 213.637 77.2094Z"
            fill="#FF6B1C"
          />
          <path
            d="M285.939 170.525C281.716 170.525 277.75 168.908 276.393 167.674L278.061 165.959C278.997 166.81 284.224 169.05 288.962 167.803C292.423 166.893 294.813 164.334 296.065 160.199L298.402 160.861C296.526 167.056 292.581 169.29 289.601 170.074C288.396 170.391 287.157 170.525 285.939 170.525Z"
            fill="#336666"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M249.292 73.293L218.562 73.8969L218.611 76.2466L249.341 75.6426L249.292 73.293Z"
            fill="#336666"
          />
          <path
            d="M243.739 127.813L242.619 125.722C245.936 124.064 249.198 122.932 252.314 122.355L252.77 124.668C249.885 125.201 246.846 126.26 243.739 127.813Z"
            fill="#336666"
          />
          <path
            d="M301.974 143.348C298.447 143.348 294.709 141.86 291.677 139.106L293.346 137.39C296.928 140.645 301.589 141.828 305.223 140.404C308.552 139.098 310.507 135.771 310.731 131.035L313.163 131.143C312.801 138.843 308.606 141.617 306.138 142.584C304.828 143.098 303.418 143.348 301.974 143.348Z"
            fill="#336666"
          />
          <path
            d="M253.733 154.462C251.054 154.462 246.135 153.408 242.784 146.482L244.989 145.485C247.067 149.779 250.195 152.153 253.782 152.108C257.702 152.083 261.543 149.268 263.567 144.938L265.787 145.906C263.373 151.073 258.667 154.43 253.798 154.462H253.733Z"
            fill="#336666"
          />
          <path
            d="M287.965 117.662L287.385 115.375C290.467 114.645 293.923 114.364 297.65 114.539L297.532 116.89C294.032 116.728 290.819 116.987 287.965 117.662Z"
            fill="#336666"
          />
          <path
            d="M129.178 192.371C129.178 192.371 126.563 198.511 122.482 201.617C119.867 203.606 118.921 204.091 118.921 204.091L116.419 201.906L120.446 196.93L120.286 196.81L129.178 192.371Z"
            fill="#336666"
          />
          <path
            d="M157.663 169.809C156.464 163.131 148.425 160 142.77 164.006C137.096 168.026 130.726 172.489 126.648 175.199C117.818 181.064 114.997 183.314 114.997 183.314L126.867 193.616C126.867 193.616 128.355 193.388 133.686 190.182C137.16 188.093 146.77 182.76 153.043 179.297C156.498 177.39 158.343 173.598 157.663 169.809Z"
            fill="#336666"
          />
          <path
            d="M120.255 196.797C119.115 196.896 110.176 203.187 110.176 203.187L107.942 201.417L114.968 194.701L114.124 194.351L106.033 200.904L103.417 198.939L111.938 191.761L111.538 191.399L102.995 197.653L101.225 195.887C103.887 193.103 109.783 187.76 109.783 187.76L109.401 187.564L102.996 192.906L101.112 190.903C101.112 190.903 100.634 190.37 106.945 185.004C111.353 181.255 118.169 181.657 118.169 181.657L129.162 192.353L120.255 196.797Z"
            fill="#336666"
          />
          <path
            d="M56.8678 224.385C52.9119 224.254 49.1542 225.045 45.792 226.557C39.2502 229.499 31.8338 229.499 25.292 226.556C21.9298 225.045 18.1741 224.254 14.2191 224.385C1.47602 224.805 -9.08626 235.04 -9.87625 247.766C-10.7774 262.289 0.734499 274.348 15.0632 274.348C18.8773 274.348 22.4906 273.493 25.7231 271.964C31.9063 269.039 39.1774 269.039 45.3608 271.964C48.5939 273.493 52.2074 274.348 56.0215 274.348C70.3494 274.348 81.8605 262.29 80.961 247.768C80.1717 235.042 69.6108 224.806 56.8678 224.385Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5794_68404">
          <rect width="374" height="777" fill="white" />
        </clipPath>
        <clipPath id="clip1_5794_68404">
          <rect width="374" height="777" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
