import 'intersection-observer'

import { MutableRefObject, useEffect, useRef, useState } from 'react'

type Props = {
  rootMargin?: string
}

type ReturnProps = {
  ref: MutableRefObject<HTMLDivElement | null>
  isIntersecting: boolean
}

const useOnScreen = ({ rootMargin = '0px' }: Props): ReturnProps => {
  const [isIntersecting, setIntersecting] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      rootMargin,
    })

    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (observer && ref.current) observer?.unobserve(ref.current as Element)
    }
  }, [ref])
  return { ref, isIntersecting }
}

export default useOnScreen
