import ContentLoader from 'react-content-loader'

const RankingCardSkeleton = (): JSX.Element => {
  return (
    <div className="flex gap-8 bg-white rounded-xl p-4 w-full h-[108px] items-center">
      <ContentLoader
        width={52}
        height={52}
        foregroundOpacity={0.9}
        uniqueKey="ImpactCardIcon"
        viewBox={`0 0 ${52} ${52}`}
      >
        <rect x="0" y="0" rx={25} ry={25} width="100%" height="100%" />
      </ContentLoader>
      <div className="flex flex-col gap-2">
        <ContentLoader
          width={80}
          height={15}
          foregroundOpacity={0.9}
          uniqueKey="ImpactCardLine1"
          viewBox={`0 0 ${80} ${15}`}
        >
          <rect x="0" y="0" rx={5} ry={5} width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          width={70}
          height={25}
          foregroundOpacity={0.9}
          uniqueKey="ImpactCardLine2"
          viewBox={`0 0 ${70} ${25}`}
        >
          <rect x="0" y="0" rx={5} ry={5} width="100%" height="100%" />
        </ContentLoader>
        <ContentLoader
          width={150}
          height={15}
          foregroundOpacity={0.9}
          uniqueKey="ImpactCardLine3"
          viewBox={`0 0 ${150} ${15}`}
        >
          <rect x="0" y="0" rx={5} ry={5} width="100%" height="100%" />
        </ContentLoader>
      </div>
    </div>
  )
}
export default RankingCardSkeleton
