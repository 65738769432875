import React from 'react'

import { SvgIcon } from '../../CustomIcon'

const Footprint = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.94345 9.1493C4.12331 9.1493 5.07978 7.25725 5.07978 4.92329C5.07978 2.58932 4.12331 0.697266 2.94345 0.697266C1.7636 0.697266 0.807129 2.58932 0.807129 4.92329C0.807129 7.25725 1.7636 9.1493 2.94345 9.1493Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.73364 6.79508C9.52607 6.79508 10.1684 5.52448 10.1684 3.95712C10.1684 2.38975 9.52607 1.11914 8.73364 1.11914C7.94122 1.11914 7.29883 2.38975 7.29883 3.95712C7.29883 5.52448 7.94122 6.79508 8.73364 6.79508Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7194 2.25299C14.4643 2.35015 14.9589 3.62565 14.823 5.09963C14.6868 6.57328 13.9716 7.69102 13.2264 7.59355C12.4815 7.49639 11.9871 6.22058 12.1231 4.74692C12.259 3.27295 12.9741 2.15552 13.7194 2.25299Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4402 5.59617C18.1207 5.83537 18.4019 7.12241 18.0679 8.46845C17.7336 9.81449 16.9101 10.7129 16.2296 10.4737C15.5491 10.2345 15.2681 8.9478 15.6021 7.60176C15.9362 6.25572 16.76 5.35697 17.4402 5.59617Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.69452 13.6474C3.73796 12.4919 4.89229 8.08289 9.55579 8.57991C13.963 9.04974 14.7897 13.2086 15.0867 13.9329C15.3839 14.657 16.487 18.9388 14.6916 21.8657C13.2008 24.296 14.4392 21.6656 10.6381 19.9608C8.31137 18.9171 6.88316 18.5973 5.58662 17.6576C4.67623 16.9978 3.62684 15.4494 3.69452 13.6474Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89105 28.6672C9.67258 28.9946 12.9598 29.5868 14.0676 26.3333C15.1145 23.2585 12.6622 21.4225 12.2725 21.0017C11.8251 20.6519 9.03284 19.3688 6.93964 18.4874C5.29094 17.9287 6.87437 19.0511 6.83556 22.1038C6.81182 23.9726 6.58379 25.0207 6.80862 26.1723C6.96646 26.9811 7.67219 28.1565 8.89105 28.6672Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Footprint
