import clsx from 'clsx'

import { Ods } from '@/ts/types/general.types'
import { Author } from '@/ts/types/post.types'
import { toAvatarString } from '@/utils/strings'

// import { odsIcon } from "../../utils/ods";
import Circle from '../base/Circle'

type Props = {
  /**
   * ClassNames
   */
  className?: string
  /**
   * Style inline object
   */
  text?: string
  /**
   * Activate Lorem Ipsum for testing purposes
   */
  image?: string
  /**
   * ClassNames
   */
  objective?: Ods

  style?: object

  user?: Author

  levelColor?: string

  borderWidth?: 'default' | 'sm' | 'lg'

  showBorder?: boolean
  onClick?: () => void
}

const Avatar = ({
  className,
  text,
  image,
  objective,
  levelColor = '#7FE4F9',
  borderWidth = 'default',
  showBorder = true,
  style,
  ...rest
}: Props): JSX.Element => {
  const avatarObjective = objective ? objective : null

  return (
    <div
      className={clsx(
        'relative overflow-hidden z-transform text-white flex-shrink-0',
        !showBorder ? `border-none` : '',
        showBorder && borderWidth === 'default' && 'border-[3px]',
        showBorder && borderWidth === 'sm' && 'border-2',
        showBorder && borderWidth === 'lg' && 'border-4',
        className
      )}
      style={{ borderRadius: '100%', borderColor: levelColor, backgroundColor: levelColor, ...style }}
      {...rest}
    >
      <Circle color={objective ? avatarObjective?.color : '#38C16F'} image={image}>
        {toAvatarString(text as string)}
      </Circle>
    </div>
  )
}

export default Avatar
