import clsx from 'clsx'
import { CSSProperties, ReactNode } from 'react'
import { FiPlusCircle } from 'react-icons/fi'
import { VscSignOut } from 'react-icons/vsc'

import Charity2 from '@/components/base/Icons/svg/Charity2'
import Exclamation from '@/components/base/Icons/svg/Exclamation'
import Medal from '@/components/base/Icons/svg/Medal'

import Badge from './svg/Badge'
import Bell from './svg/Bell'
import Calendar from './svg/Calendar'
import Chronometer from './svg/Chronometer'
import Comment from './svg/Comment'
import CommentHeart from './svg/CommentHeart'
import Community from './svg/Community'
import Ecology from './svg/Ecology'
import Flag from './svg/Flag'
import Heart from './svg/Heart'
import Home from './svg/Home'
import iluCharity from './svg/iluCharity'
import iluDonation from './svg/iluDonation'
import iluFaqs from './svg/iluFaqs'
import IluFlag from './svg/IluFlag'
import iluSolidarity from './svg/iluSolidarity'
import iluToken from './svg/iluToken'
import iluZeroEmission from './svg/iluZeroEmission'
import Invite from './svg/Invite'
import Actions from './svg/menu/Actions'
import Cart from './svg/menu/Cart'
import Chat from './svg/menu/Chat'
import EcoHouse from './svg/menu/EcoHouse'
import Footprint from './svg/menu/Footprint'
import NewPost from './svg/menu/NewPost'
import Notifications from './svg/menu/Notifications'
import ODS from './svg/menu/ODS'
import Ranking from './svg/menu/Ranking'
import { Referals } from './svg/menu/Referals'
import Search from './svg/menu/Search'
import SNT from './svg/menu/SNT'
import Store from './svg/menu/Store'
import Message from './svg/Message'
import { Mixed } from './svg/Mixed'
import Save from './svg/Save'
import Settings from './svg/Settings'
import SharePost from './svg/SharePost'
import Shop from './svg/Shop'
import Star from './svg/Star'
import SunetToken from './svg/SunetToken'
import Trophy from './svg/Trophy'
import { Wallet } from './svg/Wallet'

export enum IconsTypes {
  bell = 'bell',
  calendar = 'calendar',
  community = 'community',
  ecology = 'ecology',
  flag = 'flag',
  heart = 'heart',
  home = 'home',
  message = 'message',
  save = 'save',
  settings = 'settings',
  shop = 'shop',
  trophy = 'trophy',
  iluFlag = 'iluFlag',
  iluSolidarity = 'iluSolidarity',
  iluCharity = 'iluCharity',
  iluZeroEmission = 'iluZeroEmission',
  iluFaqs = 'iluFaqs',
  iluToken = 'iluToken',
  iluDonation = 'iluDonation',
  commentHeart = 'commentHeart',
  comment = 'comment',
  sharePost = 'sharePost',
  medal = 'medal',
  charity2 = 'charity2',
  chronometer = 'chronometer',
  star = 'star',
  VscSignOut = 'VscSignOut',
  FiPlusCircle = 'FiPlusCircle',
  Invite = 'Invite',
  SunetToken = 'SunetToken',
  Wallet = 'Wallet',
  Exclamation = 'Exclamation',
  SNT = 'SNT',
  Actions = 'Actions',
  Cart = 'Cart',
  EcoHouse = 'EcoHouse',
  NewPost = 'NewPost',
  Chat = 'Chat',
  Notifications = 'Notifications',
  Search = 'Search',
  Badge = 'Badge',
  Footprint = 'Footprint',
  ODS = 'ODS',
  Ranking = 'Ranking',
  Store = 'Store',
  Referals = 'Referals',
  Mixed = 'Mixed',
}

interface IconProps {
  name?: IconsTypes
  size?: number
  color?: string
  className?: string
  style?: CSSProperties
}

export interface SvgIcon {
  children?: ReactNode
  size?: number
  color?: string
  className?: string
}

const CustomIcon = ({ name, size = 16, color, className, ...rest }: IconProps) => {
  const Element = name ? elements[name] : null

  return Element ? (
    <div className={clsx('relative self-center flex', className)}>
      <Element size={size} color={color} {...rest} />
    </div>
  ) : null
}

const elements = {
  bell: Bell,
  calendar: Calendar,
  community: Community,
  ecology: Ecology,
  flag: Flag,
  heart: Heart,
  home: Home,
  message: Message,
  save: Save,
  settings: Settings,
  shop: Shop,
  trophy: Trophy,
  iluFlag: IluFlag,
  iluSolidarity: iluSolidarity,
  iluCharity: iluCharity,
  iluZeroEmission: iluZeroEmission,
  iluFaqs: iluFaqs,
  iluDonation: iluDonation,
  iluToken: iluToken,
  commentHeart: CommentHeart,
  comment: Comment,
  sharePost: SharePost,
  medal: Medal,
  charity2: Charity2,
  chronometer: Chronometer,
  star: Star,
  VscSignOut: VscSignOut,
  FiPlusCircle: FiPlusCircle,
  Invite: Invite,
  SunetToken: SunetToken,
  Wallet: Wallet,
  Exclamation: Exclamation,
  SNT: SNT,
  Actions: Actions,
  Cart: Cart,
  EcoHouse: EcoHouse,
  NewPost: NewPost,
  Chat: Chat,
  Notifications: Notifications,
  Search: Search,
  Badge: Badge,
  Footprint: Footprint,
  ODS: ODS,
  Ranking: Ranking,
  Store: Store,
  Referals: Referals,
  Mixed: Mixed,
}
export default CustomIcon
