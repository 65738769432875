import { CountFollows, Follow } from '@/ts/types/follower.types'

import axios from '../utils/axios'

export const findCountAuthorFollowersByAuthorUUID = async (params: unknown[]): Promise<CountFollows> => {
  const authorUUID = params[0]
  return axios
    .get(`/follows?FolloweeUUID=${authorUUID}&offset=0&limit=0&IsActive=true`)
    .then((res: { data: { Data: CountFollows } }) => res.data.Data)
}

export const findCountAuthorIsFollowingByAuthorUUID = async (params: unknown[]): Promise<CountFollows> => {
  const authorUUID = params[0]
  return axios
    .get(`/follows?FollowerUUID=${authorUUID}&offset=0&limit=0`)
    .then((res: { data: { Data: CountFollows } }) => res.data.Data)
}

export const findFollowByFolloweeUUIDAndFollowerUUID = async (params: unknown[]): Promise<Follow[]> => {
  const followeeUUID = params[0]
  const followerUUID = params[1]
  return axios
    .get(`/follows?FolloweeUUID=${followeeUUID}&FollowerUUID=${followerUUID}`)
    .then((res: { data: { Data: Follow[] } }) => res.data.Data)
}

export const toggleFollowByFolloweeUUIDAndFollowerUUID = async (
  followeeUUID: string,
  followerUUID: string
): Promise<Follow> => {
  return axios
    .post('/follows/toggle', {
      FolloweeUUID: followeeUUID,
      FollowerUUID: followerUUID,
    })
    .then((res: { data: { Data: Follow } }) => res.data.Data)
}

export const findAllAuthorFollowersByAuthorUUIDAndPage = async (
  authorUUID: string,
  page: number
): Promise<CountFollows> => {
  return axios
    .get(`/follows?FolloweeUUID=${authorUUID}&offset=${page * 5}&limit=5&include=Follower`)
    .then((res: { data: { Data: CountFollows } }) => res.data.Data)
}

export const searchFollowersByFolloweeUUIDAndFollowerNameAndPage = async (
  followeeUUID: string,
  page: number,
  query: string
): Promise<CountFollows> => {
  console.log(page)
  return axios
    .get(`/follows/search?FolloweeUUID=${followeeUUID}&offset=${page * 5}&include=Follower&Name~search=${query}`)
    .then((res: { data: { Data: CountFollows } }) => res.data.Data)
}

export const handleFollowRequest = async (followeeUUID, followerUUID, action, notificationUUID) => {
  await axios.post(`/follows/handle-request`, {
    FolloweeUUID: followeeUUID,
    FollowerUUID: followerUUID,
    Action: action,
    NotificationUUID: notificationUUID,
  })
}
