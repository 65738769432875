import { Transition } from '@headlessui/react'
import React, { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  show?: boolean
}

const OpacityTransition = ({ children, show = false }: Props): JSX.Element => {
  return (
    <Transition
      show={show}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0 hidden"
      enterTo="transform scale-100 opacity-100 block"
      leave="transition duration-100 ease-out block"
      leaveFrom="transform scale-100 opacity-100 hidden"
      leaveTo="transform scale-95 opacity-0"
    >
      {children}
    </Transition>
  )
}

export default OpacityTransition
