import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { FaArrowRight } from 'react-icons/fa'

import Button, { ButtonAppearance, ButtonSize } from '../base/Button'

const RightArrow = (): JSX.Element => {
  const { isLastItemVisible, scrollNext, visibleElements } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(!visibleElements.length && isLastItemVisible)
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible)
    }
  }, [isLastItemVisible, visibleElements])

  return (
    <Button
      icon={() => <FaArrowRight />}
      size={ButtonSize.Medium}
      appearance={ButtonAppearance.Black}
      className={clsx('w-4 hidden md:flex', disabled ? 'opacity-0' : 'opacity-100')}
      disabled={disabled}
      onClick={() => scrollNext()}
    />
  )
}

export default RightArrow
