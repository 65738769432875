/* eslint-disable valid-jsdoc */
import axiosOG, { Axios } from 'axios'
import { getSession } from 'next-auth/react'
import qs from 'qs'

import { API_URL } from '@/config'
import { useAuthStore } from '@/dataStores/auth'

/**
 * Converts a JSON to a string of query params.
 * @param {Record<string, unknown>} params
 * @return {String}
 */
function serializeParams(params: Record<string, unknown>): string {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

/**
 * Build an Axios instance.
 * @return {Axios}
 */
function getAxios(): Axios {
  const instance = axiosOG.create()

  instance.interceptors.request.use(async (request) => {
    request.baseURL = API_URL

    const session = await getSession()

    request.paramsSerializer = serializeParams
    const payload = JSON.stringify(request.data)

    console.log(
      '[REQUEST]',
      request.url,
      `method=${request.method?.toUpperCase() || 'Not defined'}`,
      `baseURL=${request.baseURL}`,
      request.data ? `payload=${payload}` : '',
      request.params ? `params=${serializeParams(request.params)}` : ''
    )

    const authorUUID = useAuthStore.getState().authorUUID
    if (authorUUID && request.headers) {
      request.headers['X-ACTING-AS'] = authorUUID
    }

    if (session?.accessToken && request.headers) {
      request.headers['Authorization'] = `Bearer ${session?.accessToken}`
    }
    return request
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const { response } = error
      const responseData = JSON.stringify(error.response?.data, null)
      console.error(
        '[REQUEST ERROR]',
        response?.config.url,
        `status=${response?.status}`,
        `method=${response?.config.method?.toUpperCase()}`,
        `baseURL=${response?.config?.baseURL}`,
        `payload=${response?.config?.data}`,
        `response=${responseData}`
      )

      throw error
    }
  )

  return instance
}

/**
 * Build an Axios instance with accessToken.
 * @return {Axios}
 */
export function getAxiosByAccessToken(accessToken: string): Axios {
  const instance = axiosOG.create()

  instance.interceptors.request.use(async (request) => {
    request.baseURL = API_URL

    request.paramsSerializer = serializeParams
    const payload = JSON.stringify(request.data)

    console.log(
      '[REQUEST]',
      request.url,
      `method=${request.method?.toUpperCase() || 'Not defined'}`,
      `baseURL=${request.baseURL}`,
      request.data ? `payload=${payload}` : '',
      request.params ? `params=${serializeParams(request.params)}` : ''
    )

    if (accessToken && request.headers) {
      request.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return request
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const { response } = error
      const responseData = JSON.stringify(error.response?.data, null)
      console.error(
        '[REQUEST ERROR]',
        response?.config.url,
        `status=${response?.status}`,
        `method=${response?.config.method?.toUpperCase()}`,
        `baseURL=${response?.config?.baseURL}`,
        `payload=${response?.config?.data}`,
        `response=${responseData}`
      )

      throw error
    }
  )

  return instance
}

const axios = getAxios()

export default axios
