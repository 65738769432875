import { getUserByUUID } from '@/services/authenticationServices'

import { SunetUser } from '../ts/types/user.types'

/**
 * Load the current user using the access token from the JWT cookie from next-auth.
 * See /utils/axios.ts for more information about the access token.
 */
export const loadUserSafely = async (userUUID: string): Promise<SunetUser | null> => {
  try {
    return await getUserByUUID(userUUID)
  } catch (e) {
    return null
  }
}
