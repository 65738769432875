/* eslint-disable prefer-promise-reject-errors */
import toast from 'react-hot-toast'

import { handleError } from './alerts'

export const parseFormatAndCheckImage = async (file: File, maxSize: number) => {
  if (['image/png', 'image/jpeg'].indexOf(file['type']) === -1) {
    handleError('internal: file is not image', 'files')
    return
  }

  if (file['size'] / 1024 / 1024 > maxSize) {
    handleError('internal: file size greater than 10MB', 'files')
    return
  }

  const preview = await getFilePreview(file)

  return { Temp: file as File, URL: preview as string }
}

export const checkFormatAndImageType = async (file: File, maxSize: number) => {
  if (['image/png', 'image/jpeg'].indexOf(file?.['type']) === -1) {
    handleError('internal: file is not image', 'files')
    return { Error: 'Error: el archivo ingresado no es una imagen' }
  }

  if (file['size'] / 1024 / 1024 > maxSize) {
    handleError('internal: file size greater than 5MB', 'files')
    return { Error: 'Error: el archivo ingresado sobrepasa los 5 MB' }
  }

  return { Error: '' }
}

export const parseFormatAndCheckFile = async (file: File, maxSize: number) => {
  if (file['type'] !== 'application/pdf') {
    handleError('internal: file is not pdf', 'files')
    return
  }

  if (file['size'] / 1024 / 1024 > maxSize) {
    handleError('internal: file size greater than 8MB', 'files')
    return
  }

  return { Temp: file }
}

export const parseFormatAndCheckVideo = async (file, maxSize, checkMaxSize) => {
  if (file['type'] !== 'video/mp4') {
    handleError('internal: file is not video', 'files')
    return
  }

  if (checkMaxSize && file['size'] / 1024 / 1024 > maxSize) {
    handleError('internal: file size greater than expected', 'files')
    return
  }

  return { Temp: file }
}

export const getFilePreview = (file) =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader()

      reader.onload = function (e) {
        resolve(e.target.result)
      }

      reader.onerror = function (e) {
        reject(e)
      }

      reader.readAsDataURL(file) // convert to base64 string
    } else {
      reject('File is missing')
    }
  })

export const checkFilesArraySize = (files, maxSizeInMB) => {
  if (!files) {
    return null
  }

  for (const file of files) {
    if (file['size'] / 1024 / 1024 > maxSizeInMB) {
      toast.error(`El tamaño del archivo no puede ser superior a ${maxSizeInMB} MB.`)
      return false
    }
  }

  return true
}
