import clsx from 'clsx'
import React, { FormEvent, ReactNode, useState } from 'react'
import { FiCheck } from 'react-icons/fi'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import Modal from '@/components/base/CustomModal'
import CustomText, { TextSize } from '@/components/base/CustomText'
import LoadingWrapper from '@/components/base/LoadingWrapper'
import TextArea from '@/components/base/TextArea'
import TextInput from '@/components/base/TextInput'
import { DefaultOption } from '@/ts/types/general.types'

type Props = {
  /**
   * Loading status for dialog content
   */
  loading?: boolean
  /**
   * Title for the dialog
   */
  title?: boolean
  /**
   * Extra Content
   */
  content?: ReactNode
  /**
   * Confirmation options
   */
  options?: DefaultOption[]
  /**
   * Area Label
   */
  textAreaLabel?: string
  /**
   * Input Label
   */
  textInputLabel?: string
  /**
   * On confirm button callback
   */
  onConfirm?: (args: unknown) => void
  /**
   * On confirm button classname
   */
  onConfirmClassName?: string
  /**
   * On cancel button callback
   */
  onCancel?: (args: unknown) => void
  /**
   * Open dialog status
   */
  open: boolean
  /**
   * On Close function for dialog
   */
  onClose: (args: unknown) => void
  zIndex: number
}

const ConfirmModal = ({
  loading,
  title,
  content,
  options,
  textAreaLabel,
  textInputLabel,
  onConfirm,
  onConfirmClassName,
  onCancel,
  open,
  onClose,
  zIndex = 50,
}: Props): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<string | null | undefined>(null)
  const [textareaValue, setTextareaValue] = useState('')
  const [textInputValue, setTextInputValue] = useState('')

  const isDisabled =
    (!selectedOption && !!options) || (!textareaValue && !!textAreaLabel) || (!textInputValue && !!textInputLabel)

  const handleClick = (callback: (arg: unknown) => void) => () => {
    let valueToSend = selectedOption
    if (textAreaLabel) {
      valueToSend = textareaValue
    }
    if (textInputLabel) {
      valueToSend = textInputValue
    }

    callback(valueToSend)
  }

  return (
    <Modal maxWidth={750} open={open} onClose={onClose} zIndex={zIndex}>
      <Card>
        <LoadingWrapper loading={loading}>
          <div>
            <CustomText size={TextSize.SubTitle1} className="mb-5">
              {title}
            </CustomText>
          </div>

          <div>
            <CustomText size={TextSize.Caption} className="leading-tight">
              {content}
            </CustomText>
          </div>

          {options ? (
            <ul className="divide-y rounded-lg overflow-hidden-z overflow-hidden-z mt-5">
              {options.map((option) => (
                <li key={option.value} className="block">
                  <button
                    onClick={() => setSelectedOption(option.value)}
                    className="flex items-center relative w-full py-3 pl-6 pr-10 text-left text-gray-700 text-sm hover:bg-gray-100 bg-opacity-75 rounded"
                  >
                    {option.label}
                    {selectedOption === option.value ? (
                      <div className="absolute top-0 h-full w-10 right-0 flex items-center justify-center text-orange-600">
                        <FiCheck className="text-lg" />
                      </div>
                    ) : null}
                  </button>
                </li>
              ))}
            </ul>
          ) : null}

          {textAreaLabel ? (
            <TextArea
              onChange={(e: FormEvent<HTMLInputElement>) => setTextareaValue(e.currentTarget.value)}
              value={textareaValue}
              label={textAreaLabel}
            />
          ) : null}

          {textInputLabel ? (
            <TextInput
              onChange={(e: FormEvent<HTMLInputElement>) => setTextInputValue(e.currentTarget.value)}
              value={textInputValue}
              label={textInputLabel}
            />
          ) : null}

          <div className="flex items-center justify-end  gap-4 mt-4">
            <Button
              appearance={ButtonAppearance.PrimaryOutline}
              size={ButtonSize.Medium}
              onClick={handleClick(onCancel as (args: unknown) => void)}
            >
              Cancelar
            </Button>

            <Button
              appearance={ButtonAppearance.Primary}
              size={ButtonSize.Medium}
              disabled={isDisabled}
              onClick={handleClick(onConfirm as (args: unknown) => void)}
              className={clsx('text-sm', isDisabled ? 'opacity-50' : null, onConfirmClassName, 'py-2 px-4')}
            >
              Confirmar
            </Button>
          </div>
        </LoadingWrapper>
      </Card>
    </Modal>
  )
}

export default ConfirmModal
