export const Slide1 = () => {
  return (
    <svg width="374" height="777" viewBox="0 0 374 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5794_68405)">
        <rect width="374" height="777" fill="white" />
        <g clipPath="url(#clip1_5794_68405)">
          <rect width="374" height="777" fill="#00CCFF" />
          <mask
            id="mask0_5794_68405"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-89"
            y="-27"
            width="588"
            height="355"
          >
            <path
              d="M-32.3439 -26.9609C-63.1969 -26.9609 -88.209 -1.951 -88.209 28.9036V34.3221L205.001 327.532L498.209 34.3221V28.9036C498.209 -1.951 473.199 -26.9609 442.347 -26.9609H-32.3427H-32.3439Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5794_68405)">
            <path d="M571.521 -136.539H-130.682V328.756H571.521V-136.539Z" fill="#A5F4F7" />
            <path d="M352.631 150.488H-18.0068V273.591H352.631V150.488Z" fill="#FF6B1C" />
            <path
              d="M180.744 71.0977C231.683 71.0977 335.628 164.485 335.628 164.485C283.402 173.809 228.976 158.533 180.245 156.995C136.261 155.607 94.9675 163.704 57.3915 163.487C0.562813 163.158 -47.7558 155.96 -83.9385 164.485C-83.9385 164.485 113.325 71.0977 180.744 71.0977Z"
              fill="#33CC66"
            />
          </g>
          <mask
            id="mask1_5794_68405"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="71"
            width="333"
            height="97"
          >
            <path
              d="M3.35645 125.902L40.6571 163.203C46.1453 163.35 51.7185 163.451 57.3795 163.483C74.9315 163.585 93.2974 161.872 112.367 160.159C134.122 158.207 156.794 156.252 180.233 156.99C228.964 158.529 283.389 173.805 335.616 164.481C335.616 164.481 231.67 71.0938 180.73 71.0938C143.1 71.0938 65.0101 100.192 3.35645 125.902Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_5794_68405)">
            <path
              d="M91.8174 81.5332C112.258 81.5332 112.257 97.0147 132.698 97.0147C153.14 97.0147 153.141 81.5332 173.581 81.5332C194.023 81.5332 194.024 97.0147 214.464 97.0147C234.908 97.0147 234.907 81.5332 255.351 81.5332C275.794 81.5332 275.795 97.0147 296.239 97.0147V38.0859H91.8174V81.5332Z"
              fill="white"
            />
          </g>
          <mask
            id="mask2_5794_68405"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-89"
            y="-27"
            width="588"
            height="355"
          >
            <path
              d="M-32.3439 -26.9609C-63.1969 -26.9609 -88.209 -1.951 -88.209 28.9036V34.3221L205.001 327.532L498.209 34.3221V28.9036C498.209 -1.951 473.199 -26.9609 442.347 -26.9609H-32.3427H-32.3439Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_5794_68405)">
            <path d="M276.573 116.612L276.79 54.9665L280.49 54.8867L283.36 116.467L276.573 116.612Z" fill="white" />
            <path
              d="M309.828 51.4978L282.712 57.1425C282.603 57.1645 282.492 57.1665 282.382 57.1485L281.141 56.9465C281.06 56.9323 280.978 56.9309 280.896 56.9399L280.402 56.9937C279.897 57.0491 279.443 56.6775 279.4 56.1694L279.34 55.4758C279.3 55.0089 279.622 54.5875 280.083 54.5052L280.812 54.374C281.14 54.3145 281.408 54.0811 281.513 53.765L282.394 51.0968C282.514 50.7343 282.848 50.4854 283.229 50.4752L309.616 49.7035C310.202 49.6868 310.649 50.2198 310.531 50.7939C310.458 51.1475 310.181 51.4244 309.828 51.4978Z"
              fill="#336666"
            />
            <path
              d="M264.528 83.4837L275.713 58.1461C275.758 58.0439 275.82 57.9508 275.899 57.8721L276.785 56.9819C276.844 56.924 276.893 56.8582 276.934 56.786L277.176 56.3536C277.425 55.9099 277.992 55.7579 278.43 56.0174L279.029 56.373C279.433 56.6114 279.587 57.1174 279.386 57.5419L279.069 58.2114C278.927 58.512 278.961 58.8667 279.157 59.1348L280.814 61.4044C281.039 61.7125 281.047 62.1284 280.834 62.4458L266.11 84.3557C265.783 84.8418 265.089 84.8952 264.692 84.4658C264.447 84.2013 264.383 83.814 264.528 83.4837Z"
              fill="#336666"
            />
            <path
              d="M262.795 27.9662L278.295 50.9187C278.356 51.0116 278.402 51.1151 278.426 51.2235L278.707 52.4481C278.726 52.528 278.755 52.6051 278.795 52.6773L279.032 53.1139C279.275 53.5613 279.102 54.1214 278.649 54.3536L278.03 54.6717C277.613 54.886 277.103 54.7484 276.852 54.3536L276.451 53.7277C276.273 53.4466 275.956 53.2866 275.624 53.3098L272.82 53.5046C272.44 53.5318 272.083 53.3171 271.928 52.9676L261.215 28.8425C260.978 28.3071 261.302 27.6899 261.877 27.5816C262.232 27.5159 262.592 27.6668 262.795 27.9662Z"
              fill="#336666"
            />
            <path
              d="M281.123 55.9275C280.709 57.2602 279.292 58.0045 277.959 57.5906C276.626 57.1763 275.882 55.7598 276.296 54.4259C276.71 53.0933 278.126 52.3489 279.46 52.7629C280.793 53.1772 281.537 54.5949 281.123 55.9275Z"
              fill="#00CCFF"
            />
            <path
              d="M120.792 332.039L529.791 320.791C529.791 320.791 436.028 69.4158 335.267 65.0541C281.448 62.725 120.791 332.039 120.791 332.039"
              fill="#336666"
            />
          </g>
          <mask
            id="mask3_5794_68405"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-89"
            y="-27"
            width="588"
            height="355"
          >
            <path
              d="M-32.3439 -26.9609C-63.1969 -26.9609 -88.209 -1.951 -88.209 28.9036V34.3221L205.001 327.532L498.209 34.3221V28.9036C498.209 -1.951 473.199 -26.9609 442.347 -26.9609H-32.3427H-32.3439Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_5794_68405)">
            <path
              d="M251.897 224.889H373.002C373.002 224.889 360.018 191.908 314.323 191.93C297.065 191.937 297.093 202.428 287.604 205.413C278.116 208.4 276.118 200.669 264.632 206.162C253.146 211.656 251.897 224.889 251.897 224.889Z"
              fill="#33CC66"
            />
            <path
              d="M208.951 20.4746C208.285 9.75117 199.385 1.12408 188.647 0.769555C185.313 0.659637 182.147 1.32537 179.314 2.60012C174.251 4.87589 168.577 5.03588 163.414 3.1329C160.713 1.72656 157.689 0.875139 154.496 0.769555C154.194 0.760266 153.899 0.788849 153.6 0.791326C153.3 0.788849 153.007 0.759028 152.705 0.769555C149.509 0.875139 146.485 1.72653 143.784 3.13411C138.622 5.03585 132.946 4.87468 127.885 2.59891C125.053 1.3254 121.887 0.659637 118.555 0.769555C107.816 1.12408 98.9133 9.74872 98.2488 20.4733C97.489 32.7114 107.191 42.8749 119.265 42.8749C122.48 42.8749 125.524 42.1537 128.249 40.8647C132.909 38.6614 138.3 38.4437 143.116 40.1826C146.162 41.8945 149.674 42.8749 153.417 42.8749C153.479 42.8749 153.538 42.8646 153.599 42.8646C153.661 42.8646 153.721 42.8749 153.783 42.8749C157.525 42.8749 161.038 41.8945 164.084 40.1826C168.9 38.4437 174.288 38.6614 178.948 40.8647C181.673 42.1537 184.72 42.8749 187.934 42.8749C200.008 42.8749 209.708 32.7127 208.951 20.4746Z"
              fill="white"
            />
            <path
              d="M28.871 69.8306C28.2053 59.1072 19.3049 50.4798 8.56728 50.125C5.23226 50.0153 2.06799 50.6814 -0.76635 51.9561C-5.82755 54.2319 -11.5032 54.3919 -16.6663 52.4889C-19.3666 51.0826 -22.3909 50.2309 -25.5851 50.125C-25.887 50.116 -26.1795 50.1443 -26.4789 50.1467C-26.778 50.1443 -27.072 50.1147 -27.3739 50.125C-30.5696 50.2309 -33.5936 51.0825 -36.2951 52.4901C-41.4582 54.3919 -47.1338 54.2307 -52.195 51.9549C-55.0269 50.6814 -58.1928 50.0153 -61.5263 50.125C-72.2642 50.4798 -81.1667 59.1044 -81.8324 69.8294C-82.591 82.0674 -72.8882 92.2309 -60.8139 92.2309C-57.599 92.2309 -54.5545 91.5097 -51.8298 90.2219C-47.1699 88.0174 -41.7799 87.7994 -36.9652 89.5386C-33.9181 91.2505 -30.4064 92.2309 -26.6639 92.2309C-26.602 92.2309 -26.542 92.2206 -26.4801 92.2206C-26.4182 92.2206 -26.3594 92.2309 -26.2975 92.2309C-22.555 92.2309 -19.0412 91.2505 -15.995 89.5386C-11.1803 87.7994 -5.79033 88.0174 -1.13041 90.2219C1.59433 91.5097 4.63972 92.2309 7.85368 92.2309C19.9277 92.2309 29.6283 82.0687 28.871 69.8306Z"
              fill="white"
            />
            <path
              d="M100.985 45.9584L119.236 49.4205L126.922 47.9825L127.241 55.1191L118.401 52.6692C118.401 52.6692 114.193 57.0184 107.055 56.0068C100.384 55.0612 96.7183 51.1716 97.1311 48.4082C97.3181 47.1517 98.9654 45.6695 100.985 45.9584Z"
              fill="#336666"
            />
            <path
              d="M113.554 51.8702L116.797 36.7668C116.797 36.7668 110.155 35.523 104.305 44.7784C102.677 47.3561 101.036 49.9853 101.036 49.9853L112.928 54.1302L113.554 51.8702Z"
              fill="#336666"
            />
            <path d="M98.1977 47.5039L93.4033 48.942L99.3165 51.6579L98.1977 47.5039Z" fill="#336666" />
            <path
              d="M65.9032 27.6264L84.1544 31.088L91.8407 29.6505L92.1612 36.7877L83.3199 34.3379C83.3199 34.3379 79.1122 38.687 71.9737 37.6755C65.3027 36.7298 61.6383 32.8397 62.051 30.0762C62.2383 28.8198 63.8841 27.3372 65.9032 27.6264Z"
              fill="#336666"
            />
            <path
              d="M78.443 33.5415L81.6845 18.4386C81.6845 18.4386 75.0436 17.1948 69.1941 26.4503C67.566 29.0279 65.9248 31.6571 65.9248 31.6571L77.8153 35.8021L78.443 33.5415Z"
              fill="#336666"
            />
            <path d="M63.1157 29.1758L58.3213 30.6145L64.2333 33.3304L63.1157 29.1758Z" fill="#336666" />
            <path
              d="M59.8955 128.999C57.1642 125.177 56.8384 120.226 58.6485 115.89C60.1437 112.31 60.9719 108.383 60.9719 104.259C60.9719 92.5097 54.2996 82.3364 44.5398 77.287C40.3084 75.0976 37.6869 70.9179 37.2962 66.2685C37.594 64.1228 37.6557 61.9001 37.399 59.6206C36.0558 47.6404 26.3698 37.9644 14.3897 36.6318C13.3344 36.5144 12.289 36.4639 11.2595 36.4691C10.2275 36.4639 9.18456 36.5144 8.12934 36.6318C-3.85207 37.9644 -13.538 47.6404 -14.8812 59.6206C-15.1379 61.9001 -15.075 64.1213 -14.7772 66.2667C-15.1679 70.9164 -17.7894 75.0976 -22.0208 77.287C-31.7806 82.3364 -38.4541 92.5097 -38.4541 104.259C-38.4541 108.383 -37.6259 112.31 -36.1307 115.89C-34.3206 120.226 -34.6465 125.177 -37.3777 128.999C-42.1732 135.712 -44.7225 144.138 -43.9162 153.186C-42.4829 169.293 -30.1316 182.668 -14.1882 185.374C-8.67304 186.309 31.1896 186.309 36.7059 185.374C52.6494 182.668 65.0019 169.293 66.434 153.186C67.2403 144.138 64.691 135.712 59.8955 128.999Z"
              fill="#33CC66"
            />
            <path d="M5.83496 217.304L9.07397 64.8828H13.4233L16.6635 217.304H5.83496Z" fill="#336666" />
            <path d="M33.1129 88.8717L13.6486 110.116L8.8457 105.327L30.0335 85.8008L33.1129 88.8717Z" fill="#336666" />
            <path
              d="M-7.54052 130.27L14.0622 149.381L8.42957 154.998L-10.6211 133.34L-7.54052 130.27Z"
              fill="#FFDA33"
            />
            <path
              d="M-7.54052 70.3555L13.7852 89.7436L8.70654 94.8071L-10.6211 73.4264L-7.54052 70.3555Z"
              fill="#336666"
            />
            <path
              d="M155.534 221.529C151.89 216.428 151.454 209.821 153.869 204.035C155.865 199.256 156.97 194.016 156.97 188.511C156.97 172.831 148.063 159.254 135.038 152.515C129.393 149.594 125.893 144.015 125.372 137.809C125.769 134.946 125.852 131.98 125.51 128.937C123.717 112.95 110.791 100.036 94.8009 98.2566C93.3921 98.1005 91.9987 98.033 90.6233 98.0395C89.247 98.033 87.8558 98.1005 86.4457 98.2566C70.4571 100.036 57.5311 112.95 55.738 128.937C55.3958 131.98 55.4786 134.945 55.8759 137.808C55.356 144.015 51.8551 149.594 46.2084 152.515C33.1832 159.254 24.278 172.831 24.278 188.511C24.278 194.016 25.3845 199.256 27.3791 204.035C29.7942 209.821 29.3583 216.428 25.7136 221.529C19.3145 230.488 15.9124 241.733 16.9872 253.809C18.9004 275.305 35.3852 293.154 56.662 296.764C64.0235 298.013 117.222 298.013 124.585 296.764C145.861 293.154 162.346 275.305 164.259 253.809C165.334 241.733 161.935 230.488 155.534 221.529Z"
              fill="#336666"
            />
            <path d="M79.6494 333.199L83.9745 129.781H89.7765L94.1016 333.199H79.6494Z" fill="#33CC66" />
            <path
              d="M116.032 161.789L90.0558 190.142L83.6455 183.749L111.923 157.691L116.032 161.789Z"
              fill="#33CC66"
            />
            <path d="M61.7844 217.059L90.615 242.566L83.0992 250.06L57.6758 221.156L61.7844 217.059Z" fill="#FFDA33" />
            <path
              d="M61.7844 137.078L90.2461 162.953L83.4681 169.711L57.6758 141.175L61.7844 137.078Z"
              fill="#33CC66"
            />
          </g>
          <mask
            id="mask4_5794_68405"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="139"
            y="64"
            width="273"
            height="264"
          >
            <path
              d="M139.131 313.041L151.644 327.027H204.631L411.291 121.041C392.791 83.5414 361.291 61.0414 332.504 65.0942C332.288 65.0851 332.063 65.08 331.843 65.08C287.894 65.0633 171.53 257.869 139.131 313.041Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_5794_68405)">
            <path
              d="M245.923 103.549C266.364 103.549 266.364 119.031 286.805 119.031C307.247 119.031 307.246 103.549 327.687 103.549C348.129 103.549 348.129 119.031 368.57 119.031C389.013 119.031 389.013 103.549 409.458 103.549C429.901 103.549 429.901 119.031 450.346 119.031V60.1016H245.923V103.549Z"
              fill="white"
            />
            <path
              d="M248.649 131.042C247.885 141.758 250.334 152.636 260.392 157.448C270.2 162.267 282.433 162.512 293.638 162.835C305.239 163.038 320.265 162.693 331.891 164.228C338.662 165.138 345.862 166.55 353.276 170.309C361.002 174.054 368.099 181.616 371.149 189.87L347.948 198.005C347.002 194.458 345.307 192.172 342.103 189.994C338.668 187.66 333.684 185.866 328.283 184.595C311.352 180.643 291.126 180.596 273.189 176.311C248.541 170.772 235.134 155.978 239.725 129.984L248.649 131.042Z"
              fill="#FFDA33"
            />
          </g>
          <path
            d="M304.691 302.265C295.95 299.15 291.816 295.018 288.701 286.276C287.62 283.241 283.347 283.241 282.266 286.276C279.149 295.018 275.019 299.15 266.277 302.265C263.241 303.348 263.241 307.62 266.277 308.702C275.019 311.818 279.149 315.95 282.266 324.69C283.347 327.726 287.62 327.726 288.701 324.69C291.816 315.95 295.95 311.818 304.691 308.702C307.727 307.62 307.727 303.348 304.691 302.265Z"
            fill="#FFDA33"
          />
          <path
            d="M364.194 235.529C359.167 233.737 356.789 231.361 354.998 226.333C354.375 224.587 351.919 224.587 351.296 226.333C349.504 231.361 347.128 233.737 342.1 235.529C340.355 236.151 340.355 238.609 342.1 239.231C347.128 241.023 349.504 243.401 351.296 248.428C351.919 250.174 354.375 250.174 354.998 248.428C356.789 243.401 359.167 241.023 364.194 239.231C365.94 238.609 365.94 236.151 364.194 235.529Z"
            fill="#FFDA33"
          />
          <path
            d="M67.6088 235.508C55.4029 231.158 49.6331 225.388 45.2828 213.183C43.7722 208.944 37.8073 208.944 36.2963 213.183C31.946 225.388 26.1763 231.158 13.9704 235.508C9.73123 237.019 9.73123 242.984 13.9704 244.495C26.1763 248.845 31.946 254.614 36.2963 266.82C37.8073 271.059 43.7722 271.059 45.2828 266.82C49.6331 254.614 55.4029 248.845 67.6088 244.495C71.8467 242.984 71.8467 237.019 67.6088 235.508Z"
            fill="#FFDA33"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5794_68405">
          <rect width="374" height="777" fill="white" />
        </clipPath>
        <clipPath id="clip1_5794_68405">
          <rect width="374" height="777" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
