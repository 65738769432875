import { throttle } from 'lodash'
import { useLayoutEffect, useState } from 'react'

const useWindowResize = (): { width: number; height: number } => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const handleWindowResize = throttle(() => {
      setWidth(document.body.clientWidth)
      setHeight(document.body.clientHeight)
    }, 100)

    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  return { width, height }
}

export default useWindowResize
