import ContentLoader from 'react-content-loader'

import Card from '@/components/base/Card'

const PostPlaceholder = (): JSX.Element => {
  return (
    <Card className="mb-5 w-full">
      <ContentLoader
        width={'100%'}
        height={'100%'}
        foregroundOpacity={0.9}
        style={{ display: 'block' }}
        uniqueKey="SkeletonBlock"
        viewBox={`0 0 ${600} ${200}`}
      >
        <circle cx="20" cy="20" r="20" />
        <rect x="50" y="0" rx={5} ry={5} width="15%" height="16" />
        <rect x="50" y="24" rx={5} ry={5} width="15%" height="16" />
        <rect x="0" y="56" rx={5} ry={5} width="100%" height="16" />
        <rect x="0" y="80" rx={5} ry={5} width="100%" height="16" />
        <rect x="0" y="104" rx={5} ry={5} width="100%" height="16" />
        <rect x="520" y="150" rx={16} ry={16} width="32" height="32" />
        <rect x="560" y="150" rx={16} ry={16} width="32" height="32" />
      </ContentLoader>
    </Card>
  )
}

export default PostPlaceholder
