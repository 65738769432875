import { setLocale } from 'yup'

export const setDefaultYupMessages = () => {
  setLocale({
    mixed: {
      required: 'Este campo es requerido.',
    },
    number: {
      integer: 'Debe ser un número entero.',
      positive: 'Debe ser un número mayor a 0.',
    },
    string: {
      email: 'El email no es válido.',
      url: 'La URL no es válida.',
    },
  })
}

export const initYup = () => {
  setDefaultYupMessages()

  /**
   *  You can add custom methods here.
   *
   *  Example:
   *
   *  yup.addMethod<yup.StringSchema>(yup.string, 'rut', function () {
   *    return this.test('rut', 'El rut no es válido.', checkRut)
   *  })
   *
   *  yup.addMethod<yup.StringSchema>(yup.string, 'phone', function () {
   *    return this.test('phone', 'El número no es válido.', (value) => {
   *      const parsed = value ? parsePhoneNumberFromString(value) : null
   *      return parsed ? parsed.isValid() : false
   *    })
   *  })
   */
}

export const valuesToFormData = (
  values: Record<string, string | number | boolean | File | null | object>
): FormData => {
  const formData = new FormData()

  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === 'boolean') {
      formData.append(key, value.toString())
    } else if (value && value instanceof File) {
      const extension = value.name.split('.').pop() || ''
      const filename = value.name.split('.').length > 0 ? value.name.split('.').slice(0, -1).join('.') : value.name
      const maxFilenameLength = 100 - extension.length - 1 // extension length  + dot
      const truncatedFilename = value.name.length > 100 ? filename.slice(0, maxFilenameLength) : filename
      formData.append(key, value, `${truncatedFilename}.${extension}`)
    } else if (value && typeof value === 'object') {
      formData.append(key, JSON.stringify(value))
    } else if (value) {
      formData.append(key, String(value))
    } else if (value === null) {
      formData.delete(key)
    }
  })

  return formData
}
