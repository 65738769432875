import useSWR from 'swr'

import { getTime } from '@/services/internalService'

const useServerTime = (): string => {
  // serverISOString is provided on page first load.
  // It is used only as a fallback or initial value.
  const serverISOString = new Date().toISOString()

  const { data } = useSWR('/api/time', getTime, { refreshInterval: 15000 })

  return data ? data.data : serverISOString
}

export default useServerTime
