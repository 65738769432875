import { yupResolver } from '@hookform/resolvers/yup'
import Image from 'next/image'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import Container from '@/components/base/Container'
import Modal from '@/components/base/CustomModal'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import SelectInput from '@/components/base/SelectInput'
import TextInput from '@/components/base/TextInput'
import { AppDownload } from '@/components/landing/AppDownload'
import { Contact } from '@/components/landing/Contact'
import { Features } from '@/components/landing/Features'
import { Footer } from '@/components/landing/Footer'
import { Hero } from '@/components/landing/Hero'
import { Navbar } from '@/components/landing/Navbar'
import { Rewards } from '@/components/landing/Rewards'
import { TokensSection } from '@/components/landing/Tokens'
import { Users } from '@/components/landing/Users'
import useDownloadWhipaper from '@/hooks/useDownloadWhipaper'
import Whitepaper from '@/public/static/images/whitepaper.jpeg'

const selectOptions = [
  { label: 'Usuario', value: 'Usuario' },
  { label: 'Inversionista', value: 'Inversionista' },
  { label: 'Influencer', value: 'Influencer' },
  { label: 'ONG', value: 'ONG' },
  { label: 'Empresa', value: 'Empresa' },
]

export default function LandingPage() {
  const [openModal, setOpenModal] = useState(false)
  const [sended, setSended] = useState(false)

  const handleOpenModal = () => {
    setSended(false)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSended(false)
  }

  const { handleSubmitForm, schema } = useDownloadWhipaper({
    onSuccess: () => {
      setSended(true)
      reset()
    },
  })

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Interest: 'Usuario',
      FullName: null,
      Email: null,
    },
  })

  const url = 'https://sunet-cdn.s3.us-west-1.amazonaws.com/assets/whitepaper/Whitepaper+Sunet+15M_compressed.pdf'

  return (
    <div className="min-h-screen scroll-smooth" style={{ backgroundColor: '#F8F9FF' }}>
      <header>
        <Navbar />
        <Hero />
      </header>

      <main className="flex flex-col items-center mt-12 gap-12 md:gap-24">
        <Features />
        <Container className="w-full" maxWidth={1200}>
          <section id="video" className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/qM9bLRMICsw?si=-ds-otvqQ8nHEaxb"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </section>
        </Container>
        <Rewards handleOpenModal={handleOpenModal} />
        <Users />
        <TokensSection />
        {/* <AppDownload /> */}
        <Contact handleOpenModal={handleOpenModal} />
      </main>
      <Modal showCloseButton maxWidth={800} open={openModal} onClose={handleCloseModal}>
        <Card className="flex gap-4 !overflow-visible">
          <Image alt="Whitepaper" src={Whitepaper} className="max-w-[300px] object-cover rounded-md" />
          {!sended ? (
            <div className="flex flex-col gap-8 min-w-[400px]">
              <CustomText size={TextSize.Heading2} weight={FontWeight.SemiBold}>
                Descarga nuestro Whitepaper
              </CustomText>
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <CustomText>Nombre</CustomText>
                <TextInput error={errors?.FullName?.message} {...register('FullName')} placeholder="Juan Perez" />
                <CustomText>Email</CustomText>
                <TextInput error={errors?.Email?.message} {...register('Email')} placeholder="juan@sunet.cl" />
                <CustomText>¿Cuál es su interés?</CustomText>
                <SelectInput
                  className="z-10"
                  isModal
                  options={selectOptions}
                  onChange={(option) => setValue('Interest', option)}
                />
                <Button
                  type="submit"
                  className="mt-8 w-full"
                  appearance={ButtonAppearance.Primary}
                  size={ButtonSize.Big}
                >
                  Descargar
                </Button>
              </form>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center text-center">
              <CustomText size={TextSize.Heading2} weight={FontWeight.SemiBold}>
                Gracias por descargar nuestro Whitepaper
              </CustomText>

              <a href={url} download="Sunet-Whitepaper" target="_blank">
                <Button className="mt-8 z-20" appearance={ButtonAppearance.Primary} size={ButtonSize.Big}>
                  Descargar
                </Button>
              </a>
            </div>
          )}
        </Card>
      </Modal>

      <Footer />
    </div>
  )
}
LandingPage.requireAuth = false
