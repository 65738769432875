import { Author } from '@/ts/types/post.types'

import { EnrollmentOutput, UserBillingInfo } from '../ts/types/user.types'
import axios from '../utils/axios'

export const getUserBillingInfoByUUID = (userUUID: string): Promise<UserBillingInfo> => {
  return axios
    .get(`/initiatives/authors/${userUUID[0]}/billing`)
    .then((res: { data: { Data: UserBillingInfo } }) => res.data.Data)
}

export const startOneClickEnrollmentByBusinessUUIDAndRedirectURL = (
  userUUID: string,
  redirectURL: string
): Promise<EnrollmentOutput> => {
  return axios
    .post('/initiatives/donations/oneclick/create', {
      AuthorUUID: userUUID,
      RedirectURL: redirectURL,
    })
    .then((res: { data: { Data: EnrollmentOutput } }) => res.data.Data)
}

export const getRecommendedAuthors = async () => {
  const res = await axios.get(`/authors/recommended`)

  return res.data.Data
}

export const changeOnlineStatus = async (uuid, values) => {
  return await axios.put(`/authors/${uuid}/online-status`, values)
}

export const getConnectedFollowers = async () => {
  return await axios.get(`/authors/connected-followers`)
}

export const getConnectedFollowees = async () => {
  return await axios.get(`/authors/connected-followees`)
}

export const getAuthorByUUID = async ({ authorUUID }: { authorUUID: string }): Promise<Author> => {
  const res = await axios.get(`/authors/${authorUUID}`)
  return res.data.Data
}

export const getReferalLink = async (values) => {
  const res = await axios.post(`/users/referred-link`, values)
  return res?.data?.Data
}

export const getReferals = async (authorUUID) => {
  const res = await axios.get(`/users/referred-users?AuthorUUID=${authorUUID}&include=Author&order=-CreatedAt`)

  return res?.data?.Data
}

export const blockUserByAuthorUUIDAndBlockedAuthorUUID = async (authorUUID, blockedAuthorUUID) => {
  return await axios.post(`/authors/block`, {
    AuthorUUID: authorUUID,
    BlockedUUID: blockedAuthorUUID,
  })
}

export const unBlockUserByAuthorUUIDAndBlockedAuthorUUID = async (authorUUID, blockedAuthorUUID) => {
  return await axios.post(`/authors/unblock`, {
    AuthorUUID: authorUUID,
    BlockedUUID: blockedAuthorUUID,
  })
}

export const reportUserByUserUUIDAndReason = async (userUUID, reason) => {
  return await axios.post(`/users/${userUUID}/report`, { Reason: reason })
}
