import '@/styles/globals.scss'
import '@/styles/contentInput.scss'
import '@/styles/cft.scss'
import '@/styles/webinarvideo.scss'
import '@/styles/muxVideoJS.scss'
import 'react-tippy/dist/tippy.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import '@mux/videojs-kit/dist/index.css'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import '../styles/swiper.css'

import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import * as Sentry from '@sentry/node'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { SessionProvider, getSession } from 'next-auth/react'
import numbro from 'numbro'
import { Toaster } from 'react-hot-toast'
import { SWRConfig } from 'swr'

import PageManager from '@/components/base/PageManager'
import AlertProvider from '@/providers/alert/context'

import AuthGateway from '../components/shared/AuthGateway'
import useManagerAuth from '../hooks/useManagerAuth'
import { initYup } from '../utils/forms'
import LandingPage from './landing'

initYup()

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

/**
 * App
 * @date 16-01-2023 - 13:18:15
 *
 * @export
 * @param {AppProps} { Component, pageProps }
 * @return {*}
 */
export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  useManagerAuth()
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const cl = require('numbro/dist/languages/es-CL.min')
  numbro.registerLanguage(cl, true)
  const router = useRouter()
  console.log(pageProps)
  const page = <Component {...pageProps} />

  // if (router.asPath === '/' && !session) {
  //   return <LandingPage />
  // }

  return (
    <SWRConfig value={{ revalidateOnFocus: false, revalidateOnMount: true }}>
      <PayPalScriptProvider options={{ clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID }}>
        <AlertProvider>
          <SessionProvider session={session}>
            {/* If requireAuth is not defined, the page will require auth as default */}
            {Component.requireAuth ?? true ? (
              <AuthGateway>
                <PageManager page={page} pathname={router.asPath} />
              </AuthGateway>
            ) : (
              <Component {...pageProps} />
            )}

            <Toaster
              containerStyle={{
                zIndex: 999999, // For the container
              }}
              toastOptions={{
                style: {
                  zIndex: 999999, // For toasts
                },
              }}
            />
          </SessionProvider>
        </AlertProvider>
      </PayPalScriptProvider>
    </SWRConfig>
  )
}
/*
App.getInitialProps = async function ({ Component, ctx }) {
  let pageProps = {};
  const lang = getUserLanguage(ctx);

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ...ctx, lang });
  }

  return {
    pageProps,
    language: lang,
    serverISOString: new Date().toISOString(),
  };
};
*/
