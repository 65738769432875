import { useSession } from 'next-auth/react'
import React, { ReactNode } from 'react'
import { shallow } from 'zustand/shallow'

import LandingPage from '@/pages/landing'

import { useAuthStore } from '../../dataStores/auth'
import FullScreenError from './FullScreenError'
import FullScreenLoader from './FullScreenLoader'

type Props = {
  children?: ReactNode
  requiredPermission?: string
}

/**
 * AuthGateway is a wrapper component that checks if the user is authenticated and has the required permission.
 * Also, displays a loader while the user is being authenticated.
 * @param {ReactNode} children
 * @return {JSX.Element}
 */
const AuthGateway = ({ children }: Props): JSX.Element => {
  // With the option 'required', the useSession hook will redirect to the sign-in page if the user is not authenticated.
  const { data: session, status } = useSession({ required: false })

  const [ready, error] = useAuthStore((state) => [state.ready, state.error], shallow)
  if (!session && status === 'unauthenticated') {
    return <LandingPage />
  }

  const isAuthReady = status === 'authenticated' && !!session && ready

  if (!isAuthReady) {
    return <FullScreenLoader />
  }

  if (error) {
    return <FullScreenError />
  }

  return <>{children}</>
}

export default AuthGateway
