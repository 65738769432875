import { endOfMonth, startOfDay, startOfMonth } from 'date-fns'

import { CountDef } from '@/ts/types/general.types'
import {
  CreateDonation,
  CreateDonationPaypalReturn,
  Donation,
  InitiativeType,
  PaypalOrderConfirm,
  SendInitiativeInvitations,
} from '@/ts/types/initiatives.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const initiativesURLEndpoint = `/initiatives`

export const createDonation = async (values: CreateDonation): Promise<Donation> => {
  return axios
    .post(`${initiativesURLEndpoint}/donations/oneclick`, values)
    .then((res: { data: { Data: Donation } }) => res.data.Data)
}

export const createDonationOrderPaypal = async (values: CreateDonation): Promise<CreateDonationPaypalReturn> => {
  return axios
    .post(`${initiativesURLEndpoint}/donations/paypal/create`, values)
    .then((res: { data: { Data: CreateDonationPaypalReturn } }) => res.data.Data)
}

export const donationPaypalConfirm = async (values: PaypalOrderConfirm): Promise<unknown> => {
  return axios
    .post(`${initiativesURLEndpoint}/donations/paypal/confirm`, values)
    .then((res: { data: { Data: unknown } }) => res.data.Data)
}

export const findInitiativeByUUID = async (initiativeUUID: string, accessToken: string): Promise<InitiativeType> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`${initiativesURLEndpoint}?UUID=${initiativeUUID}&include=Author,DonationsCount,Donators`)
    .then((res: { data: { Data: InitiativeType } }) => res.data.Data)
}

export const sendInitiativeInvitations = async (values: SendInitiativeInvitations): Promise<unknown> => {
  return axios.post(`${initiativesURLEndpoint}/invitations`, values)
}

export const findAllInitiatives = async (accessToken: string): Promise<InitiativeType> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`${initiativesURLEndpoint}?include=Author,DonationsCount,Donators`)
    .then((res: { data: { Data: InitiativeType } }) => res.data.Data)
}

export const findInitiativesByPage = async (page: number): Promise<CountDef<InitiativeType>> => {
  const end = startOfDay(new Date()).toISOString()
  return axios
    .get(
      `${initiativesURLEndpoint}?order=StartsAt&offset=${
        page * 5
      }&include=Author,DonationsCount,Donators&ApprovalStatus=accepted&IsPublic=true&EndsAt~ge=${end}`
    )
    .then((res: { data: { Data: CountDef<InitiativeType> } }) => res.data.Data)
}

export const searchInitiativesByQuery = async (query: string): Promise<CountDef<InitiativeType>> => {
  return axios
    .get(`${initiativesURLEndpoint}/search?Query=${query}&offset=0&limit=10&include=Author`)
    .then((res: { data: { Data: CountDef<InitiativeType> } }) => res.data.Data)
}

export const countDonationsByAuthorUUID = async (authorUUID: string): Promise<any> => {
  return axios
    .get(`${initiativesURLEndpoint}/donations-count?AuthorUUID=${authorUUID}&limit=0&offset=0`)
    .then((res: { data: { Data: any } }) => res.data.Data)
}

export const findAllDonationsByPageAndPageSizeAndAuthorUUID = async (
  page: number,
  pageSize: number,
  authorUUID: string
): Promise<any> => {
  return axios
    .get(
      `${initiativesURLEndpoint}/donations-count?AuthorUUID=${authorUUID}&limit=${pageSize}&offset=${page * pageSize}`
    )
    .then((res: { data: { Data: any } }) => res.data.Data)
}

export const createInitiative = async (values: InitiativeType): Promise<InitiativeType> => {
  return axios.post(`/initiatives`, values).then((res: { data: { Data: InitiativeType } }) => res.data.Data)
}

export const findMonthAcceptedInitiativesByPage = async (date) => {
  const start = startOfMonth(new Date(date)).toISOString()

  const end = endOfMonth(new Date(date)).toISOString()

  const res = await axios.get(
    `${initiativesURLEndpoint}?order=StartsAt&include=Author,DonationsCount,Donators&ApprovalStatus=accepted&IsPublic=true&EndsAt~ge=${start}&StartsAt~le=${end}`
  )

  return res.data.Data
}

export const findAcceptedInitiativesByODS = async (ods) => {
  if (!ods) {
    return
  }
  const end = startOfDay(new Date()).toISOString()
  const res = await axios.get(
    `${initiativesURLEndpoint}?order=StartsAt&include=Author,DonationsCount,Donators&ApprovalStatus=accepted&IsPublic=true&EndsAt~ge=${end}&ODS=${ods}`
  )
  return res.data.Data
}

export const findAllAcceptedInitiatives = async () => {
  const end = startOfDay(new Date()).toISOString()
  const res = await axios.get(
    `${initiativesURLEndpoint}?order=StartsAt&include=Author,DonationsCount,Donators&ApprovalStatus=accepted&IsPublic=true&EndsAt~ge=${end}`
  )

  return res.data.Data
}
