import { SvgIcon } from '../../CustomIcon'
const Store = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6814 29.8603C26.7184 30.1511 26.6287 30.4439 26.4349 30.6638C26.2412 30.8838 25.9621 31.01 25.6689 31.01C21.4027 31.01 6.59739 31.01 2.3312 31.01C2.03797 31.01 1.75895 30.8838 1.56517 30.6638C1.3714 30.4439 1.28173 30.1511 1.31872 29.8603C1.79764 26.0959 3.31255 14.1868 3.74027 10.8243C3.80519 10.3145 4.23904 9.93262 4.75299 9.93262C8.11008 9.93262 19.89 9.93262 23.2471 9.93262C23.7611 9.93262 24.1949 10.3145 24.2596 10.8243C24.6876 14.1868 26.2025 26.0959 26.6814 29.8603Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91893 9.93297C7.91893 9.93297 7.2357 0.931479 13.8558 0.989537C21.2096 1.05397 19.6972 9.84085 19.6972 9.84085"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2938 27.8067C19.2938 27.8067 13.6315 27.9966 11.2171 23.1033C8.80244 18.2104 11.56 14.0452 12.1556 13.2507C12.7515 12.4558 15.9525 16.0064 16.2943 17.067C16.6357 18.1271 18.8138 22.2292 16.1901 27.2994"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3658 27.2335C16.3658 27.2335 12.5241 24.0556 13.6259 19.8896"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Store
