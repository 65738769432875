import clsx from 'clsx'
import { forwardRef, ReactNode } from 'react'

export enum ButtonAppearance {
  Primary = 'primary',
  PrimaryAccent = 'primary-accent',
  PrimaryOutline = 'primary-outline',
  SecondaryOutline = 'secondary-outline',
  White = 'white',
  Gray = 'gray',
  Gray2 = 'gray2',
  Gray3 = 'gray3',
  Black = 'black',
  Danger = 'danger',
  Accent = 'Accent',
  Secondary = 'secondary',
}

export enum ButtonSize {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
  Tiny = 'tiny',
}

interface ButtonProps {
  children?: ReactNode | string
  className?: string
  appearance?: ButtonAppearance
  size?: ButtonSize
  disabled?: boolean
  icon?: () => JSX.Element
  block?: boolean
  onClick?: () => void
  type?: 'submit' | 'reset' | 'button' | undefined
  target?: '_blank' | '_self' | '_parent' | '_top' | undefined
  href?: string
  rel?: string
  download?: boolean
}
// Source: https://www.benmvp.com/blog/forwarding-refs-polymorphic-react-component-typescript/
export type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>['ref']

type AsProp<T extends React.ElementType> = {
  as?: T
}

export type PolymorphicComponentPropsWithRef<T extends React.ElementType, Props> = Props &
  Omit<ButtonProps, keyof ButtonProps> &
  AsProp<T>

type Props<T extends React.ElementType> = PolymorphicComponentPropsWithRef<T, ButtonProps>

type ButtonComponent = <T extends React.ElementType = 'button'>(props: Props<T>) => JSX.Element | null

const Button: ButtonComponent = forwardRef(function Button<T extends React.ElementType = 'button'>(
  {
    children,
    appearance = ButtonAppearance.Primary,
    className,
    size,
    disabled,
    icon,
    block,
    onClick,
    as,
    ...rest
  }: Props<T>,
  ref: PolymorphicRef<T>
) {
  const Component = as || 'button'

  return (
    <Component
      ref={ref}
      className={clsx(
        'inline-flex items-center justify-center no-underline box-border truncate',
        block ? 'w-full' : null,
        size ? sizeClassname(!!children, !!icon)[size] : null,
        mainClassname(disabled)[appearance],
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {icon ? icon() : null}
      <span className={clsx(!!children && !!icon ? 'ml-2' : '')}>{children}</span>
    </Component>
  )
})

const iconOnlySizeClassname = {
  [ButtonSize.Small]: 'h-6 text-sm px-0  px-1',
  [ButtonSize.Medium]: 'h-8 w-8 p-0 box-border text-base',
  [ButtonSize.Big]: 'h-10 w-10 p-0 text-base box-border text-2xl',
}

const withIconSizeClassname = {
  [ButtonSize.Small]: 'h-6 text-sm px-0 pl-4 pr-2',
  [ButtonSize.Medium]: 'h-8 text-base px-4 pt-4 pb-4',
  [ButtonSize.Big]: 'h-10 text-base py-2 px-8',
}

const defaultSizeClassname = {
  [ButtonSize.Tiny]: 'h-5 text-xs py-0 px-4',
  [ButtonSize.Small]: 'h-6 text-sm py-0 px-4',
  [ButtonSize.Medium]: 'h-8 py-2 px-6',
  [ButtonSize.Big]: 'h-10 text-base px-8',
}

const defaultDisableClassNames = 'bg-opacity-60 cursor-not-allowed pointer-events-none'
const mainClassname = (disabled?: boolean) => ({
  [ButtonAppearance.Primary]: `text-white rounded-giant border border-transparent  font-medium bg-primary hover:bg-opacity-80 active:bg-primary-active ${
    disabled ? defaultDisableClassNames : 'bg-primary pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.Secondary]: `text-white rounded-giant border border-transparent font-medium bg-secondary hover:bg-opacity-80 active:bg-secondary-active ${
    disabled ? defaultDisableClassNames : 'bg-primary pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.PrimaryAccent]: `text-white rounded-giant border border-transparent  font-medium hover:bg-opacity-80 active:bg-opacity-30 ${
    disabled ? defaultDisableClassNames : 'bg-accent-1 pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.PrimaryOutline]: ` rounded-giant border bg-transparent  font-medium hover:bg-opacity-80 active:bg-primary active:bg-opacity-30 ${
    disabled
      ? 'opacity-80 cursor-not-allowed pointer-events-none  border-gray text-gray'
      : 'bg-transparent pointer-events-auto cursor-pointer  border-primary text-primary'
  }`,
  [ButtonAppearance.SecondaryOutline]: ` rounded-giant border bg-transparent  font-medium hover:bg-opacity-80 active:bg-secondary active:bg-opacity-30 ${
    disabled
      ? 'opacity-80 cursor-not-allowed pointer-events-none  border-gray text-gray'
      : 'bg-transparent pointer-events-auto cursor-pointer  border-secondary text-secondary'
  }`,
  [ButtonAppearance.White]: `text-gray rounded-giant border border-transparent font-medium bg-white hover:bg-opacity-80 active:bg-opacity-80 ${
    disabled ? defaultDisableClassNames : 'bg-white pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.Gray]: `text-black rounded-giant border border-transparent font-bold  hover:bg-opacity-80 active:bg-opacity-80 ${
    disabled ? defaultDisableClassNames : 'bg-gray-4 pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.Gray3]: `text-gray-5 rounded-giant border border-transparent hover:bg-opacity-80 active:bg-opacity-80 ${
    disabled ? defaultDisableClassNames : 'bg-gray-3 pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.Gray2]: `text-white rounded-giant border border-transparent hover:bg-opacity-80 active:bg-opacity-80 ${
    disabled ? defaultDisableClassNames : 'bg-gray-2 pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.Black]: `text-white rounded-giant border border-transparent hover:bg-opacity-80 active:bg-opacity-80 ${
    disabled ? defaultDisableClassNames : 'bg-gray-5 pointer-events-auto cursor-pointer'
  }`,
  [ButtonAppearance.Danger]: `text-white rounded-giant border border-transparent  font-medium bg-red hover:bg-opacity-80 active:bg-primary-active${
    disabled ? defaultDisableClassNames : 'bg-gray-5 pointer-events-auto cursor-pointer'
  }`,
})

const sizeClassname = (hasText: boolean, hasIcon: boolean) => {
  if (hasText && hasIcon) {
    return withIconSizeClassname
  } else if (!hasText && hasIcon) {
    return iconOnlySizeClassname
  } else {
    return defaultSizeClassname
  }
}

export default Button
