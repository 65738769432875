import { useEffect } from 'react'

import Card from '@/components/base/Card'
import useOnScreen from '@/hooks/useOnScreen'
import usePosts from '@/hooks/usePosts'
import { Post } from '@/ts/types/post.types'

import PostPlaceholder from '../Post/PostPlaceholder'
import LoadNewButton from './LoadNewButton'

type Props = {
  customKey?: string
  namespace?: string
  request?: (requestData: unknown[]) => Promise<Post[]>
  renderPost: (item: Post, index: number) => JSX.Element
  onLoadNew: () => void
  topPosts: Post[]
  showTopPost?: boolean
}

const PostList = ({
  customKey,
  namespace = 'feed',
  request,
  renderPost,
  onLoadNew,
  topPosts,
  showTopPost,
}: Props): JSX.Element => {
  const { data, isLoadingMore, loadMore, isReachingEnd, hasNewPosts, loadNew } = usePosts({
    customKey,
    namespace,
    request,
    onLoadNew,
    topPosts,
  })

  const { ref, isIntersecting } = useOnScreen({ rootMargin: '0px' })

  useEffect(() => {
    if (isIntersecting) {
      loadMore()
    }
  }, [isIntersecting])

  return (
    <div className="relative">
      <LoadNewButton visible={hasNewPosts} onClick={loadNew} />

      {showTopPost ? topPosts?.map((data, index) => renderPost(data, index)) : null}

      {data?.map((data, index) => renderPost(data, index))}

      {isLoadingMore ? (
        <div>
          <PostPlaceholder />
          <PostPlaceholder />
          <PostPlaceholder />
          <PostPlaceholder />
          <PostPlaceholder />
        </div>
      ) : null}

      {isReachingEnd ? (
        <Card className="mb-5">
          <p className="text-center text-gray-600 tracking-wide text-sm">Has llegado al final</p>
        </Card>
      ) : null}

      <div ref={ref} />
    </div>
  )
}

export default PostList
