import Image from 'next/image'

import Challenges from '@/public/static/images/landing/challenges.png'
import Footprint from '@/public/static/images/landing/footprint.png'
import Initiatives from '@/public/static/images/landing/initiatives.png'
import Interact from '@/public/static/images/landing/interact.png'
import Levels from '@/public/static/images/landing/levels.png'
import Medals from '@/public/static/images/landing/medals.png'
import Token from '@/public/static/images/landing/token-side.png'

import Container from '../base/Container'
import CustomText, { FontWeight, TextSize } from '../base/CustomText'
import Button, { ButtonAppearance, ButtonSize } from '../base/Button'

const rewardItems = [
  {
    title: 'Interactúa en la plataforma',
    body: 'Realiza acciones que ayuden a fomentar la comunidad de personas como tu. Comenta, comparte, únete a grupos.',
    image: Interact,
  },
  {
    title: 'Realiza desafíos',
    body: 'Participa en desafíos como limpiar basura de las playas, reciclar tus plásticos, crear tu estación de reciclaje en casa, etc.',
    image: Challenges,
  },
  {
    title: 'Apoya iniciativas',
    body: 'Difunde o dona a iniciativas de fundaciones que necesitan apoyo para lograr su objetivo.',
    image: Initiatives,
  },
  {
    title: 'Mide tu Huella',
    body: 'Lleva un registro de tu huella de carbono e hídrica para que puedas ver en qué aspectos debes disminuir tus emisiones.',
    image: Footprint,
  },
  {
    title: 'Gana medallas',
    body: 'Completa acciones dentro de la plataforma para ganar medallas que te recompensan por tu esfuerzo.',
    image: Medals,
  },
  {
    title: 'Sube de Nivel',
    body: 'Cada cierto número de tokens, podrás subir de nivel, lo que te dará una insignia difetente en la plataforma.',
    image: Levels,
  },
]

export const Rewards = ({ handleOpenModal }) => {
  return (
    <Container maxWidth={1200}>
      <section className="space-y-12 text-center w-full">
        <CustomText className="md:!text-4xl !text-3xl" weight={FontWeight.Bold}>
          Sunet recompensa tus acciones con Tokens de Impacto (SNT)
        </CustomText>
        <div
          className="grid gap-8 w- lg:!grid-cols-3"
          style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))' }}
        >
          {rewardItems.map((item, index) => (
            <RewardCard key={`${index}-reward`} item={item} />
          ))}
        </div>
        <button onClick={handleOpenModal} className="bg-primary text-white rounded-xl !font-semibold px-8 py-6">
          Descarga un PDF con 10 cosas que hacer en Sunet
        </button>
      </section>
    </Container>
  )
}

const RewardCard = ({ item }) => {
  return (
    <div
      className="bg-white flex flex-col rounded-3xl overflow-hidden"
      style={{ boxShadow: '34px 29px 48px 0px rgba(51, 102, 255, 0.05)' }}
    >
      <Image src={item.image} className="w-full" alt={item.title} />
      <div className="p-4 px-8 text-center space-y-2 flex-1">
        <CustomText weight={FontWeight.SemiBold} size={TextSize.SubTitle2}>
          {item.title}
        </CustomText>
        <CustomText>{item.body}</CustomText>
      </div>
      <div className="p-4 md:px-8 border-t border-[#D8D8D8] flex items-center gap-4 justify-center">
        <Image alt="SNT token" src={Token} />
        <CustomText>Sumas SNT por cada acción</CustomText>
      </div>
    </div>
  )
}
