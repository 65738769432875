import { signOut } from 'next-auth/react'
import React from 'react'
import { VscWarning } from 'react-icons/vsc'

import { environment, VERSION_NUMBER } from '@/config'

import Button, { ButtonAppearance, ButtonSize } from '../base/Button'
import Card from '../base/Card'
import Container from '../base/Container'
import CustomText, { FontWeight, TextSize } from '../base/CustomText'
import Placeholder from '../base/Placeholder'

const FullScreenError = (): JSX.Element => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-background">
      <Container className="flex flex-col items-center" maxWidth={800}>
        <Card>
          <Placeholder
            icon={
              <div className="flex items-center justify-center mb-4 text-yellow-500">
                <VscWarning size={32} />
              </div>
            }
            title="Ocurrió un error al cargar la sesión"
            description="Por favor, inténtalo nuevamente. Si el problema persiste, por favor contacta a un administrador."
          >
            <div className="flex flex-row gap-2 mt-4 items-center justify-center">
              <Button appearance={ButtonAppearance.Primary} size={ButtonSize.Medium} onClick={() => signOut()}>
                Cerrar sesión
              </Button>
              <Button
                appearance={ButtonAppearance.Primary}
                size={ButtonSize.Medium}
                onClick={() => window.location.reload()}
              >
                Recargar página
              </Button>
            </div>
          </Placeholder>
        </Card>
      </Container>

      <div className="absolute bottom-0 left-0 w-full px-8 text-center">
        <CustomText size={TextSize.Caption} weight={FontWeight.Normal}>
          {environment} Versión {VERSION_NUMBER || '0.0.0'}
        </CustomText>
      </div>
    </div>
  )
}

export default FullScreenError
