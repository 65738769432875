import React from 'react'

import { SvgIcon } from '../../CustomIcon'

const Search = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4224_1870)">
        <path
          d="M5.69384 3.86884C5.44866 3.62347 5.05089 3.62347 4.80572 3.86884C3.89542 4.77914 3.44801 6.04723 3.57803 7.3482C3.61047 7.67229 3.8835 7.91388 4.20235 7.91388C4.2233 7.91388 4.24442 7.91282 4.26537 7.91073C4.61062 7.87619 4.86246 7.5682 4.82793 7.22319C4.73539 6.29865 5.05112 5.39967 5.69384 4.75693C5.93921 4.51179 5.93921 4.11398 5.69384 3.86884Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M8.18104 0C4.2214 0 1 3.2214 1 7.18104C1 11.1407 4.2214 14.3621 8.18104 14.3621C12.1407 14.3621 15.3621 11.1407 15.3621 7.18104C15.3621 3.2214 12.1406 0 8.18104 0ZM8.18104 13.1059C4.91398 13.1059 2.25617 10.4481 2.25617 7.18104C2.25617 3.91398 4.91398 1.25617 8.18104 1.25617C11.4479 1.25617 14.1059 3.91398 14.1059 7.18104C14.1059 10.4481 11.4481 13.1059 8.18104 13.1059Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M17.816 15.9278L13.252 11.3637C13.0066 11.1183 12.6092 11.1183 12.3638 11.3637C12.1185 11.6089 12.1185 12.0067 12.3638 12.2518L16.9279 16.8159C17.0506 16.9386 17.2112 16.9999 17.372 16.9999C17.5328 16.9999 17.6933 16.9386 17.816 16.8159C18.0614 16.5707 18.0614 16.1729 17.816 15.9278Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4224_1870">
          <rect width="17" height="17" fill={color ? color : 'currentColor'} transform="translate(1)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Search
