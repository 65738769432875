import React, { createContext, ReactNode, useContext, useState } from 'react'

import ConfirmModal from './components/ConfirmModal'

const AlertContext = createContext({
  show: (args) => {},
  showConfirm: (args) => {},
})
export const useAlert = () => useContext(AlertContext)

type Props = {
  /**
   * Children
   */
  children?: ReactNode | string
}

const AlertProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(null)

  // TODO: Implement informative alert
  const show = () => {
    setOptions('information')
  }

  const showConfirm = ({
    title,
    content,
    options,
    textareaLabel,
    textInputLabel,
    onConfirm,
    onCancel,
    onConfirmClassName,
    zIndex,
  }) => {
    setOptions({
      type: 'confirmation',
      title,
      content,
      options,
      textareaLabel,
      textInputLabel,
      onConfirmClassName,
      onConfirm: (v) => close(v, onConfirm),
      onCancel: (v) => close(v, onCancel),
      zIndex,
    })
  }

  const close = async (v, callback) => {
    if (callback) {
      setLoading(true)
      await callback(v)
      setLoading(false)
    }
    setOptions(null)
  }

  return (
    <AlertContext.Provider value={{ show: show, showConfirm: showConfirm }}>
      <ConfirmModal
        open={!!options}
        onClose={options?.onCancel ? options?.onCancel : () => setOptions(null)}
        title={options?.title}
        content={options?.content}
        onCancel={options?.onCancel}
        onConfirm={options?.onConfirm}
        options={options?.options}
        zIndex={options?.zIndex}
      />
      {children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
