import { FaDownload, FaEnvelope } from 'react-icons/fa'

import Container from '../base/Container'
import CustomText from '../base/CustomText'

export const Contact = ({ handleOpenModal }) => {
  return (
    <Container className="w-full" maxWidth={1200}>
      <section className="flex flex-col sm:flex-row gap-8 bg-white justify-center items-center rounded-xl w-full p-8 md:p-12">
        <div className="text-center md:text-start space-y-6">
          <CustomText className="!text-4xl !font-bold">¿Quieres apoyar el proyecto?</CustomText>
          <CustomText className="">
            Te invitamos a ayudarnos a crear un mundo mejor. Si eres inversionista o influencer, contáctanos.
          </CustomText>
        </div>
        <div className="space-y-4">
          <div className="flex gap-4 items-center cursor-pointer" onClick={handleOpenModal}>
            <div className="">
              <FaDownload className="text-[#009379]" />
            </div>
            <span className="underline font-semibold">Descarga nuestro Whitepaper</span>
          </div>
          <div className="flex gap-4 items-center">
            <div className="">
              <FaEnvelope className="text-[#009379]" />
            </div>
            <span>contacto@sunet.world</span>
          </div>
        </div>
      </section>
    </Container>
  )
}
