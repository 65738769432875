import { useEffect } from 'react'

import { useAuthStore } from '@/dataStores/auth'
import { changeOnlineStatus } from '@/services/authorService'

const ONLINE_STATUS_FETCH_TIME = 60000

const useOnlineStatus = () => {
  const { user } = useAuthStore()
  const updateOnlineStatus = async () => {
    try {
      if (user) {
        console.log(user, 'Sending online')
        await changeOnlineStatus(user.UUID, { onlineStatus: 'online' })
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateOnlineStatus()
    }, ONLINE_STATUS_FETCH_TIME)

    return () => clearInterval(intervalId)
  }, [])

  return {}
}

export default useOnlineStatus
