import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Message = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M20.0627 10.9403C20.0661 12.183 19.7759 13.4089 19.2157 14.5182C18.5514 15.8473 17.5301 16.9653 16.2662 17.7468C15.0024 18.5283 13.5459 18.9425 12.06 18.943C10.8173 18.9464 9.59138 18.6562 8.4821 18.096L3.11572 19.8845L4.90422 14.5182C4.34406 13.4089 4.05385 12.183 4.05722 10.9403C4.05772 9.45432 4.47192 7.99785 5.25344 6.73401C6.03496 5.47016 7.15292 4.44887 8.4821 3.78453C9.59138 3.22437 10.8173 2.93416 12.06 2.93753H12.5307C14.4932 3.0457 16.3467 3.87398 17.7365 5.26374C19.1263 6.65351 19.9545 8.50708 20.0627 10.4695V10.9403Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Message
