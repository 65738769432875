import JSConfetti from 'js-confetti'
import { useState } from 'react'
import * as yup from 'yup'

import { uploadWhitepaperUser } from '@/services/generic'

const useDownloadWhipaper = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmitForm = async (values) => {
    setLoading(true)
    try {
      const res = await uploadWhitepaperUser(values)
      onSuccess && onSuccess()
      if (window !== undefined) {
        const jsConfetti = new JSConfetti()
        jsConfetti.addConfetti()
      }
      return res
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const schema = yup.object().shape({
    Email: yup.string().email().required(`Correo es requerido`),
    FullName: yup.string().required(`Nombre es requerido`),
    Interest: yup.string().required('Interés es requerido'),
  })

  return {
    loading,
    handleSubmitForm,
    schema,
  }
}

export default useDownloadWhipaper
