import { useCallback, useRef } from 'react'

import { parseFormatAndCheckVideo } from '@/utils/files'

type Props = {
  onChange: (temp: File) => void
  maxSize?: number
  checkMaxSize?: boolean
}

type ReturnProps = {
  handleCreatePayment: () => void
}

const useVideoFileInput = ({ onChange, maxSize = 5, checkMaxSize = false }: Props): ReturnProps => {
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = useCallback(
    async (e) => {
      if (e.target.files?.length > 0) {
        const file = e.target.files[0]

        const value = await parseFormatAndCheckVideo(file, maxSize, checkMaxSize)

        if (value) {
          onChange && onChange(value?.Temp)
        }
      }
    },
    [onChange]
  )

  const handleOpen = useCallback(() => {
    if (ref?.current) {
      ref.current.click()
    }
  }, [])

  return { ref, handleOpen, handleChange }
}

export default useVideoFileInput
