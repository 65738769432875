import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  maxWidth?: number | string
  style?: object
  className?: string
}

const Container = ({ children, maxWidth, style, className }: Props): JSX.Element => {
  return (
    <div className={`mx-auto px-4 ${className}`} style={{ maxWidth: maxWidth, ...style }}>
      {children}
    </div>
  )
}

Container.defaultProps = {
  maxWidth: 1450,
}

export default Container
