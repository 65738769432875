import { useEffect, useMemo, useState } from 'react'
import useSWR, { mutate } from 'swr'
import useSWRInfinite from 'swr/infinite'

import { useAuthStore } from '@/dataStores/auth'
import { Post } from '@/ts/types/post.types'

type Props = {
  customKey?: string
  namespace?: string
  request?: (requestData: any[]) => Promise<Post[]>
  onLoadNew: () => void
  topPosts: Post[]
}

type ReturnProps = {
  data: Post[]
  hasNewPosts: boolean
  isLoadingMore: boolean
  isReachingEnd: boolean
  hasNewPost?: boolean
  loadMore: () => void
  loadNew: () => void
}

const usePosts = ({ customKey, namespace, request, onLoadNew, topPosts, formRef }: Props): ReturnProps => {
  const [user] = useAuthStore((state) => [state.user])
  const [key, setKey] = useState(Date.now())
  const [hasNewPosts, setHasNewPosts] = useState(false)

  const { data: firstPage } = useSWR([0, 'check', user?.UUID, customKey, namespace, '/posts'], request, {
    refreshInterval: 10000,
  })

  const {
    data: pages,
    error,
    size,
    setSize,
  } = useSWRInfinite(
    (pageIndex) => {
      return [pageIndex, key, user?.UUID, customKey, namespace, '/posts']
    },
    request,
    { revalidateOnFocus: false }
  )

  const isLoadingInitialData = !pages && !error
  const isLoadingMore = isLoadingInitialData || (size > 0 && pages && typeof pages[size - 1] === 'undefined')
  const isEmpty = pages?.[0]?.Items ? pages[0].Items.length === 0 : true
  const isReachingEnd = isEmpty || (pages && pages[pages.length - 1]?.Items?.length === 0)

  const data = useMemo(() => {
    return pages
      ? pages.reduce((previous, current) => [...previous, ...(current.Items ? current.Items : current)], [])
      : null
  }, [pages])

  const postsCount = pages?.length > 0 ? pages[0]?.Count || 0 : 0

  const scrollToComponent = () => {
    if (window.location.hash === 'post-form') {
      formRef.current.scrollIntoView()
      formRef.current.focus()
    }
  }

  const loadMore = () => {
    if (!isReachingEnd) {
      setSize(size + 1)
    }
  }

  const loadNew = async () => {
    window.scrollTo({ top: 750, behavior: 'smooth' })
    setHasNewPosts(false)
    setSize(1)
    setKey(Date.now())
    await mutate([0, 'check', user?.UUID, namespace, '/posts'])

    onLoadNew && onLoadNew()
  }

  useEffect(() => {
    const firstPageCount = firstPage?.Count || 0
    const topPostsCount = topPosts?.length || 0

    if (firstPageCount !== postsCount + topPostsCount) {
      setHasNewPosts(true)
    } else {
      setHasNewPosts(false)
    }
  }, [firstPage, topPosts, postsCount])

  return { data, isLoadingMore, isReachingEnd, hasNewPosts, loadMore, loadNew }
}

export default usePosts
