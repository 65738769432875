import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Save = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39445 26.7483C1.08848 26.7483 0.840332 26.5002 0.840332 26.194C0.840332 22.9909 0.840332 6.50288 0.840332 2.21391C0.840332 1.94322 0.947867 1.68379 1.13919 1.49223C1.33076 1.3009 1.59043 1.19336 1.86113 1.19336C5.3336 1.19336 16.6662 1.19336 20.1387 1.19336C20.7024 1.19336 21.1595 1.65023 21.1595 2.21391C21.1595 6.32846 21.1595 21.6718 21.1595 25.7861C21.1595 26.35 20.7024 26.8069 20.1387 26.8069C19.7465 26.8069 19.3913 26.8069 19.3913 26.8069C15.3762 26.5073 12.0375 24.1085 10.8009 20.8648C9.44816 24.2883 5.74542 26.7483 1.39472 26.7483C1.39472 26.7483 1.39469 26.7483 1.39445 26.7483Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.56934 9.5166C4.56934 9.5166 5.03845 9.65526 5.71262 9.85491C8.54596 10.6932 10.493 13.2919 10.5013 16.2467C10.5035 16.9498 10.5048 17.439 10.5048 17.439C10.5048 17.439 10.0356 17.3003 9.36148 17.1007C6.52813 16.2624 4.58108 13.6637 4.57251 10.7089C4.57055 10.0058 4.56934 9.5166 4.56934 9.5166Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9672 9.5166C16.9672 9.5166 16.498 9.65526 15.8239 9.85491C12.9905 10.6932 11.0435 13.2919 11.0352 16.2467C11.0332 16.9498 11.0317 17.439 11.0317 17.439C11.0317 17.439 11.5009 17.3003 12.175 17.1007C15.0084 16.2624 16.9554 13.6637 16.964 10.7089C16.9659 10.0058 16.9672 9.5166 16.9672 9.5166Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4193 15.2245L10.7902 15.7228L11.1047 15.3005M12.7472 11.7976C13.0152 10.0669 12.6003 8.25361 11.5026 6.77961L10.7902 5.82324L10.078 6.77961C8.96708 8.271 8.55552 10.1102 8.84312 11.8593"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Save
