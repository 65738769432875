import React from 'react'

import { SvgIcon } from '../../CustomIcon'

const Notifications = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4224_1905)">
        <path
          d="M16.1692 12.7006C16.076 11.73 15.2096 10.9677 14.2005 10.9677C14.0799 10.9677 13.9839 10.8717 13.9839 10.7511V7.52109C13.9839 5.12189 12.4484 3.02981 10.3344 2.24836C10.359 2.12496 10.3727 1.99886 10.3727 1.8727C10.3728 0.839049 9.53377 0 8.50002 0C7.46627 0 6.62727 0.839049 6.62727 1.87275C6.62727 1.99615 6.64096 2.11951 6.66289 2.23742C5.9363 2.49517 5.26452 2.9037 4.69147 3.45758C3.61115 4.49952 3.01616 5.90064 3.01616 7.40322V10.7511C3.01616 10.869 2.92019 10.9678 2.79953 10.9678C1.78776 10.9678 0.924032 11.73 0.830804 12.7007C0.778696 13.2409 0.956955 13.7782 1.32162 14.1786C1.68354 14.5762 2.20179 14.8065 2.74193 14.8065H6.04872C6.18581 16.0376 7.23325 17 8.50002 17C9.76679 17 10.8142 16.0376 10.9513 14.8065H14.2581C14.7983 14.8065 15.3165 14.5762 15.6784 14.1786C16.0403 13.7782 16.2213 13.2408 16.1692 12.7006ZM8.50002 1.09675C8.92774 1.09675 9.27597 1.44498 9.27597 1.8727C9.27597 1.90833 9.27048 1.94125 9.26498 1.97688C9.07853 1.94945 8.88932 1.93026 8.70012 1.92477C8.37382 1.91378 8.05302 1.93576 7.73497 1.97962C7.72947 1.944 7.72398 1.91108 7.72398 1.87545C7.72407 1.44498 8.0723 1.09675 8.50002 1.09675ZM8.50002 15.9032C7.83923 15.9032 7.28536 15.4316 7.15646 14.8064H9.84353C9.71473 15.4316 9.16086 15.9032 8.50002 15.9032ZM14.8668 13.441C14.7078 13.6137 14.4939 13.7096 14.2581 13.7096H2.74197C2.50616 13.7096 2.29232 13.6137 2.13324 13.441C1.97422 13.2682 1.90018 13.0406 1.92486 12.8048C1.96323 12.3908 2.34988 12.0645 2.80228 12.0645C3.52617 12.0645 4.11566 11.475 4.11566 10.7511V7.40322C4.11566 6.20225 4.59274 5.08082 5.45647 4.24726C6.27904 3.44938 7.35391 3.01612 8.50002 3.01612C8.55488 3.01612 8.60694 3.01612 8.66179 3.01887C10.9897 3.1011 12.8871 5.12194 12.8871 7.52113V10.7511C12.8871 11.475 13.4766 12.0645 14.2005 12.0645C14.6529 12.0645 15.0396 12.3908 15.0779 12.8048C15.0999 13.0406 15.0258 13.2655 14.8668 13.441Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M1.39837 6.58073C1.65063 6.58073 1.8782 6.40527 1.93306 6.15026C2.19901 4.94655 2.79949 3.84979 3.67691 2.97787C3.8908 2.76398 3.8908 2.41575 3.67691 2.20192C3.46303 1.98803 3.1148 1.98803 2.90096 2.20192C1.8755 3.22183 1.1708 4.50784 0.860986 5.91715C0.795187 6.21327 0.981642 6.50665 1.27776 6.56974C1.32163 6.57799 1.36 6.58073 1.39837 6.58073Z"
          fill={color ? color : 'currentColor'}
        />
        <path
          d="M15.6016 6.5806C15.64 6.5806 15.6811 6.5751 15.7195 6.56691C16.0156 6.50111 16.2021 6.21043 16.1363 5.91431C15.8264 4.50495 15.1218 3.22174 14.0963 2.19899C13.8824 1.9851 13.5341 1.9851 13.3203 2.19899C13.1065 2.41287 13.1064 2.7611 13.3203 2.97494C14.195 3.84686 14.7982 4.94366 15.0642 6.14734C15.1218 6.40509 15.3493 6.5806 15.6016 6.5806Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4224_1905">
          <rect width="17" height="17" fill={color ? color : 'currentColor'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Notifications
