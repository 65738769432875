export function reduceActions(object, propertiesToSum: string[]) {
  return propertiesToSum.reduce((total, property) => {
    if (typeof object?.[property] === 'number') {
      return total + object[property]
    }
    return total
  }, 0)
}

export const levelActionsProperties = [
  'ChallengeComplete',
  'ChallengeJoin',
  'EventJoin',
  'GetFollowed',
  'InitiativeDonation',
  'InitiativeShare',
  'PostComment',
  'PostCreate',
  'PostLike',
  'PostShare',
  'UserFollow',
  'UserReferred',
]
