import React, { ReactNode } from 'react'

import CustomText, { FontWeight, TextSize } from '../base/CustomText'
import Container from './Container'

type Props = {
  title: string
  description?: string
  children?: ReactNode
  icon?: ReactNode
  className?: string
}

const Placeholder = ({ title, description, icon, children, className }: Props): JSX.Element => {
  return (
    <Container className={className}>
      {icon ? <div>{icon}</div> : null}

      <div className="text-center">
        <CustomText size={TextSize.Paragraph} weight={FontWeight.Bold}>
          {title}
        </CustomText>
        {description ? (
          <CustomText size={TextSize.Tiny} weight={FontWeight.Normal}>
            {description}
          </CustomText>
        ) : null}
      </div>

      {children ? <div>{children}</div> : null}
    </Container>
  )
}

export default Placeholder
