export const Slide3 = () => {
  return (
    <svg width="374" height="777" viewBox="0 0 374 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="374" height="777" fill="#00CCFF" />
      <g clipPath="url(#clip0_5794_68401)">
        <path d="M127.73 18.3359H117.578V24.9549H127.73L123.247 21.5844L127.73 18.3359Z" fill="#FF6B1C" />
        <path d="M118.911 18.3359H116.231V35.4984H118.911V18.3359Z" fill="#FFDA33" />
        <path
          d="M13.2839 149.335H207.495L206.881 98.6759C165.454 69.3487 129.221 32.6342 116.999 32.9157C99.4643 33.319 41.3073 97.3467 12.9771 129.346L13.2839 149.335V149.335Z"
          fill="#336666"
        />
        <mask
          id="mask0_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="12"
          y="32"
          width="196"
          height="118"
        >
          <path
            d="M116.993 32.9158C99.4583 33.3192 41.301 97.3468 12.9707 129.346L13.2776 149.335H207.489L206.875 98.6759C165.607 69.4618 129.492 32.9164 117.134 32.9141C117.086 32.9141 117.04 32.9145 116.993 32.9158Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5794_68401)">
          <path
            d="M89.5537 44.5452C95.8326 44.5452 95.8324 49.3008 102.111 49.3008C108.391 49.3008 108.391 44.5452 114.67 44.5452C120.949 44.5452 120.949 49.3008 127.228 49.3008C133.508 49.3008 133.508 44.5452 139.788 44.5452C146.067 44.5452 146.067 49.3008 152.347 49.3008V31.1992H89.5537V44.5452Z"
            fill="white"
          />
        </g>
        <path
          d="M197.894 134.927L185.477 134.726L185.331 26.7368L197.894 26.6484C227.747 26.6484 252.033 50.9354 252.033 80.7877C252.033 110.64 227.747 134.927 197.894 134.927Z"
          fill="#FFDA33"
        />
        <mask
          id="mask1_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="185"
          y="26"
          width="68"
          height="109"
        >
          <path
            d="M185.33 26.7364L185.475 134.726L197.893 134.927C227.746 134.927 252.032 110.64 252.032 80.7877C252.032 50.9351 227.746 26.6484 197.893 26.6484L185.33 26.7368V26.7364Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_5794_68401)">
          <path d="M253.384 28.668H188.545V31.0458H253.384V28.668Z" fill="#E8BD0A" />
          <path d="M254.078 45.3398H189.239V47.7176H254.078V45.3398Z" fill="#E8BD0A" />
          <path d="M254.773 62.0078H189.934V64.3856H254.773V62.0078Z" fill="#E8BD0A" />
          <path d="M255.467 78.6797H190.628V81.0575H255.467V78.6797Z" fill="#E8BD0A" />
          <path d="M256.162 95.3477H191.322V97.7254H256.162V95.3477Z" fill="#E8BD0A" />
          <path d="M256.856 112.02H192.017V114.397H256.856V112.02Z" fill="#E8BD0A" />
          <path d="M257.56 128.688H192.721V131.065H257.56V128.688Z" fill="#E8BD0A" />
        </g>
        <path
          d="M188.402 134.79C173.941 134.79 160.345 129.159 150.12 118.934C139.894 108.708 134.263 95.1121 134.263 80.6513C134.263 66.1901 139.894 52.5943 150.12 42.3691C160.345 32.1434 173.941 26.5117 188.402 26.5117C202.863 26.5117 216.459 32.1434 226.684 42.3691C236.91 52.5943 242.541 66.1901 242.541 80.6513C242.541 95.1121 236.91 108.708 226.684 118.934C216.459 129.159 202.863 134.79 188.402 134.79Z"
          fill="#E8BD0A"
        />
        <path
          d="M237.166 80.6503C237.166 53.7144 215.33 31.8789 188.395 31.8789C161.459 31.8789 139.623 53.7144 139.623 80.6503C139.623 107.586 161.459 129.422 188.395 129.422C215.33 129.422 237.166 107.586 237.166 80.6503Z"
          fill="#FFDA33"
        />
        <path
          d="M197.457 86.3161C197.457 82.6025 192.466 74.5751 189.893 70.6835C189.184 69.6107 187.611 69.6107 186.901 70.6835C184.328 74.5751 179.337 82.6025 179.337 86.3161C179.337 91.3198 183.393 95.3763 188.397 95.3763C193.401 95.3763 197.457 91.3198 197.457 86.3161Z"
          fill="#E8BD0A"
        />
        <path
          d="M185.568 102.175C185.568 101.385 185.054 100.679 184.297 100.459C178.167 98.6768 173.673 93.0121 173.673 86.3151C173.673 84.1379 174.278 80.6152 178.992 72.6369C181.347 68.651 183.673 65.3475 183.771 65.2088L185.239 63.1294C185.453 62.8266 185.568 62.4652 185.568 62.0947V41.6727C185.568 40.1347 183.759 39.3096 182.598 40.3174L156.39 63.0577C155.997 63.3985 155.771 63.8927 155.771 64.4126V101.938C155.771 102.487 156.023 103.006 156.454 103.346L177.592 120.036C179.324 121.404 181.251 122.398 183.266 123.018C184.411 123.37 185.568 122.495 185.568 121.297V102.175Z"
          fill="#E8BD0A"
        />
        <path
          d="M191.236 41.6765V62.0985C191.236 62.469 191.351 62.8304 191.565 63.1332L193.033 65.2125C193.131 65.3513 195.457 68.6548 197.812 72.6407C202.526 80.619 203.13 84.1416 203.13 86.3189C203.13 93.0159 198.637 98.6806 192.507 100.462C191.75 100.683 191.236 101.389 191.236 102.178V121.3C191.236 122.499 192.393 123.374 193.538 123.021C195.553 122.401 197.479 121.408 199.212 120.04L220.35 103.35C220.781 103.009 221.032 102.491 221.032 101.942V64.4164C221.032 63.8965 220.807 63.4023 220.414 63.0615L194.206 40.3216C193.044 39.3134 191.236 40.1385 191.236 41.6765Z"
          fill="#E8BD0A"
        />
        <path
          d="M382.675 157.573H350.384L262.406 156.604H176.499C176.499 156.604 176.499 102.305 218.839 79.1465C242.861 66.0069 258.622 69.681 258.622 69.681C258.622 69.681 262.55 51.4985 285.493 51.9254C302.087 52.2341 307.674 67.9056 307.674 67.9056C307.674 67.9056 312.493 64.2291 318.738 67.501C324.236 70.3804 324.497 84.6471 324.497 84.6471C324.497 84.6471 331.023 78.1387 342.298 81.8984C353.574 85.6585 354.219 112.429 354.219 112.429C354.219 112.429 366.186 106.911 374.889 115.798C383.592 124.685 382.675 157.573 382.675 157.573Z"
          fill="#336666"
        />
        <path
          d="M232.438 187.527L270.578 107.234C271.768 104.728 275.396 104.922 276.313 107.54L304.308 187.527H232.438Z"
          fill="#FF6B1C"
        />
        <path
          d="M273.773 173.018L313.653 89.0606C314.898 86.4407 318.692 86.6436 319.65 89.3812L348.923 173.018H273.773Z"
          fill="#FF6B1C"
        />
        <path
          d="M310.42 189.22L346.95 112.315C348.09 109.915 351.565 110.101 352.443 112.609L379.257 189.22H310.42Z"
          fill="#FF6B1C"
        />
        <path
          d="M194.498 220.223H391.266C391.266 220.223 388.096 200.791 384.925 194.655C381.755 188.519 375.312 183.106 363.858 183.106C352.404 183.106 338.945 154.015 323.333 155.559C314.768 156.406 312.617 164.989 301.829 165.556C291.041 166.122 290.034 154.617 269.061 159.755C248.088 164.895 194.498 220.223 194.498 220.223"
          fill="#A5F4F7"
        />
        <path d="M154.164 207.258H145.602V284.32H154.164V207.258Z" fill="#FFDA33" />
        <path d="M172.014 257.465H130.486L121.924 249.973L130.486 242.48H172.014V257.465Z" fill="#336666" />
        <mask
          id="mask2_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="121"
          y="242"
          width="52"
          height="16"
        >
          <path d="M130.48 242.48L121.917 249.972L130.48 257.465H172.007V242.48H130.48Z" fill="white" />
        </mask>
        <g mask="url(#mask2_5794_68401)">
          <path
            d="M146.316 262.948C146.236 262.33 146.191 261.497 146.149 260.692C146.106 259.894 146.062 259.068 145.984 258.465C145.938 258.113 145.908 257.74 145.88 257.38C145.772 256.037 145.661 254.648 144.722 253.829C143.499 252.764 142.759 252.393 141.039 252.439C139.635 252.476 135.657 253.784 134.316 254.466C130.984 256.16 127.283 256.831 123.002 256.515L123.031 256.12C127.238 256.429 130.87 255.773 134.136 254.112C135.392 253.474 139.459 252.085 141.028 252.043C142.74 251.999 143.59 252.318 144.982 253.531C146.043 254.455 146.161 255.926 146.275 257.349C146.305 257.72 146.333 258.071 146.377 258.415C146.457 259.032 146.501 259.865 146.544 260.671C146.587 261.469 146.631 262.294 146.709 262.898L146.316 262.948Z"
            fill="#FFDA33"
          />
          <path
            d="M149.112 263.198C148.385 262.051 148.942 260.156 149.588 257.962C150.59 254.554 151.727 250.692 147.93 249.107C146.335 248.441 144.831 248.45 143.089 248.456C142.85 248.458 142.608 248.459 142.361 248.458C140.937 248.466 139.243 249.032 137.593 249.591C136.48 249.968 135.428 250.324 134.484 250.502C133.121 250.759 131.811 251.053 130.545 251.337C126.893 252.155 123.442 252.928 119.293 252.731L119.312 252.335C123.408 252.529 126.833 251.763 130.458 250.95C131.728 250.665 133.041 250.371 134.41 250.113C135.328 249.94 136.366 249.588 137.466 249.216C139.147 248.646 140.881 248.06 142.362 248.062C142.607 248.062 142.849 248.061 143.087 248.06C144.798 248.053 146.414 248.044 148.083 248.741C152.207 250.463 150.966 254.683 149.968 258.074C149.375 260.088 148.816 261.991 149.447 262.986L149.112 263.198Z"
            fill="#FFDA33"
          />
          <path
            d="M132.201 246.277C131.988 246.277 131.779 246.265 131.573 246.239C130.704 246.133 129.884 245.799 129.09 245.476C128.168 245.101 127.297 244.746 126.356 244.715L126.37 244.319C127.382 244.352 128.326 244.737 129.24 245.109C130.009 245.422 130.805 245.746 131.621 245.846C132.768 245.987 134.057 245.703 135.564 244.978C137.278 244.154 138.816 243.041 140.136 241.672L140.421 241.947C139.068 243.351 137.492 244.49 135.735 245.336C134.424 245.967 133.26 246.277 132.201 246.277Z"
            fill="#FFDA33"
          />
          <path
            d="M170.636 262.111C165.963 259.18 162.116 255.061 159.512 250.198C159.326 249.851 159.143 249.496 158.959 249.14C157.84 246.971 156.683 244.728 154.579 243.648C154.264 243.486 153.914 243.341 153.576 243.202C152.55 242.778 151.49 242.34 151.028 241.315L151.39 241.152C151.786 242.034 152.729 242.423 153.727 242.835C154.073 242.978 154.43 243.126 154.76 243.295C156.976 244.433 158.163 246.734 159.312 248.959C159.495 249.313 159.677 249.666 159.861 250.011C162.433 254.813 166.231 258.881 170.846 261.775L170.636 262.111Z"
            fill="#FFDA33"
          />
          <path
            d="M178.587 249.56C173.96 249.287 169.558 248.802 166.542 245.904C166.041 245.422 165.589 244.878 165.152 244.352C164.41 243.458 163.709 242.613 162.771 242.023L162.981 241.688C163.973 242.311 164.727 243.22 165.457 244.099C165.887 244.617 166.331 245.152 166.816 245.618C169.736 248.423 174.061 248.896 178.61 249.165L178.587 249.56Z"
            fill="#FFDA33"
          />
        </g>
        <path d="M129.119 218.641H170.647L179.209 226.133L170.647 233.625H129.119V218.641Z" fill="#FF6B1C" />
        <mask
          id="mask3_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="129"
          y="218"
          width="51"
          height="16"
        >
          <path d="M129.119 218.641V233.625H170.647L179.21 226.133L170.647 218.641H129.119Z" fill="white" />
        </mask>
        <g mask="url(#mask3_5794_68401)">
          <path
            d="M132.686 236.579L132.296 236.506C132.785 233.859 134.15 231.402 136.139 229.587L136.327 229.416C137.359 228.481 138.529 227.421 138.218 226.19C138.073 225.615 137.612 225.156 137.206 224.75C136.347 223.892 135.372 222.92 134.144 222.541C133.516 222.347 132.864 222.317 132.172 222.285C131.878 222.271 131.584 222.257 131.292 222.23C129.741 222.087 128.26 221.575 126.828 221.08L120.781 218.988L120.911 218.613L126.958 220.705C128.367 221.193 129.825 221.697 131.329 221.836C131.614 221.862 131.902 221.875 132.191 221.889C132.876 221.92 133.585 221.953 134.261 222.162C135.583 222.57 136.594 223.579 137.487 224.47C137.907 224.889 138.43 225.412 138.602 226.093C138.972 227.554 137.653 228.749 136.593 229.71L136.406 229.88C134.48 231.636 133.159 234.015 132.686 236.579Z"
            fill="#FFDA33"
          />
          <path
            d="M164.938 225.184C163.805 225.184 162.672 224.889 161.567 224.601C160.769 224.393 159.943 224.177 159.133 224.078C157.717 223.905 156.258 224.08 154.848 224.25C154.095 224.341 153.316 224.434 152.546 224.475C150.925 224.561 147.961 224.401 146.325 222.347C145.986 221.922 145.728 221.443 145.478 220.978C145.238 220.532 144.99 220.071 144.677 219.676C144.079 218.921 143.004 218.372 142.19 218.842L141.992 218.498C142.998 217.918 144.286 218.545 144.988 219.43C145.323 219.853 145.579 220.329 145.827 220.79C146.069 221.239 146.318 221.703 146.635 222.1C148.161 224.015 150.98 224.163 152.525 224.08C153.282 224.04 154.054 223.946 154.801 223.856C156.234 223.684 157.717 223.506 159.181 223.685C160.018 223.787 160.856 224.006 161.667 224.217C163.224 224.623 164.696 225.006 166.17 224.646C167.007 224.442 167.778 224 168.523 223.574L176.114 219.225L176.311 219.569L168.72 223.917C167.951 224.358 167.156 224.813 166.264 225.031C165.823 225.139 165.38 225.184 164.938 225.184Z"
            fill="#FFDA33"
          />
          <path
            d="M148.998 237.511L148.718 237.23C152.719 233.248 158.128 231.168 163.378 229.514C164.247 229.24 165.122 228.973 165.998 228.706C170.369 227.375 174.889 225.997 178.832 223.609L179.037 223.948C175.052 226.362 170.508 227.746 166.113 229.085C165.239 229.352 164.366 229.618 163.496 229.892C158.295 231.531 152.938 233.589 148.998 237.511Z"
            fill="#FFDA33"
          />
          <path
            d="M150.339 237.046C147.91 236.496 146.425 235.589 146.045 234.423C145.771 233.581 146.062 232.661 146.912 231.686C147.191 231.367 147.554 230.888 147.495 230.406C147.459 230.111 147.26 229.835 146.904 229.587C145.689 228.738 142.948 230.144 141.826 230.788C140.403 231.604 139.173 233.396 139.696 235.11L139.317 235.225C138.734 233.316 140.073 231.336 141.629 230.444C143.104 229.598 145.779 228.319 147.131 229.262C147.583 229.578 147.837 229.946 147.888 230.358C147.966 230.997 147.539 231.571 147.211 231.947C146.459 232.809 146.193 233.6 146.422 234.3C146.756 235.326 148.141 236.142 150.426 236.66L150.339 237.046Z"
            fill="#FFDA33"
          />
        </g>
        <path
          d="M71.083 144.8L89.7281 105.546C90.3099 104.321 92.0838 104.416 92.5317 105.696L106.218 144.8H71.083Z"
          fill="#FF6B1C"
        />
        <path
          d="M89.791 151.635L108.437 112.382C109.018 111.157 110.792 111.252 111.24 112.532L124.926 151.635H89.791Z"
          fill="#FF6B1C"
        />
        <path
          d="M109.184 140.491L127.83 101.238C128.411 100.013 130.185 100.108 130.633 101.388L144.319 140.491H109.184Z"
          fill="#FF6B1C"
        />
        <path
          d="M38.4404 173.561H218.845C218.845 173.561 186.732 105.066 158.608 106.6C130.484 108.134 126.47 123.88 116.575 128.076C100.416 134.928 89.6777 126.81 71.7806 134.928C53.8834 143.047 38.4408 173.561 38.4408 173.561"
          fill="#33CC66"
        />
        <path
          d="M260.771 296.913H382.729C382.729 296.913 383.572 285.331 377.053 277.891C370.533 270.451 364.473 272.522 361.175 270.528C357.877 268.533 350.667 256.875 336.592 257.488C322.517 258.102 307.56 269.224 302.498 271.908C297.435 274.593 285.47 272.369 277.186 278.351C268.902 284.334 260.771 296.913 260.771 296.913Z"
          fill="white"
        />
        <path
          d="M197.865 204.885H190.962C190.962 202.979 192.507 201.434 194.413 201.434C196.32 201.434 197.865 202.979 197.865 204.885Z"
          fill="#336666"
        />
        <path
          d="M186.414 215.003H179.511C179.511 213.096 181.056 211.551 182.962 211.551C184.869 211.551 186.414 213.096 186.414 215.003Z"
          fill="#336666"
        />
        <path
          d="M250.195 264.159C250.195 263.34 249.531 262.676 248.712 262.676C247.893 262.676 247.229 263.34 247.229 264.159C247.229 264.978 247.893 265.642 248.712 265.642C249.531 265.642 250.195 264.978 250.195 264.159Z"
          fill="#336666"
        />
        <path
          d="M247.789 255.772C247.789 254.953 247.126 254.289 246.307 254.289C245.488 254.289 244.823 254.953 244.823 255.772C244.823 256.591 245.488 257.255 246.307 257.255C247.126 257.255 247.789 256.591 247.789 255.772Z"
          fill="#336666"
        />
        <path
          d="M239.971 258.739C239.971 258.089 239.445 257.562 238.795 257.562C238.146 257.562 237.619 258.089 237.619 258.739C237.619 259.388 238.146 259.915 238.795 259.915C239.445 259.915 239.971 259.388 239.971 258.739Z"
          fill="#336666"
        />
        <path
          d="M2.36426 145.975L21.0098 106.722C21.5915 105.497 23.3651 105.592 23.8129 106.872L37.4989 145.975H2.36426Z"
          fill="#FF6B1C"
        />
        <path
          d="M21.083 152.815L39.7281 113.562C40.3098 112.337 42.0838 112.432 42.5317 113.712L56.2176 152.815H21.083Z"
          fill="#FF6B1C"
        />
        <path
          d="M43.5957 169.569L62.2412 130.316C62.823 129.091 64.5966 129.186 65.0444 130.466L78.7307 169.569L43.5957 169.569Z"
          fill="#FF6B1C"
        />
        <path
          d="M-11.2646 183.107H91.8235C91.8235 183.107 80.4205 165.765 73.5685 162.287C66.7162 158.81 62.2161 161.47 58.9946 158.913C55.7731 156.356 55.5685 148.021 51.7847 146.487C48.0005 144.953 42.9383 147.97 38.2849 146.64C33.6316 145.311 29.8479 122.914 15.5812 123.936C1.31454 124.959 -11.2646 183.107 -11.2646 183.107Z"
          fill="#A5F4F7"
        />
        <path
          d="M83.6753 27.0196C83.3143 21.2023 78.4865 16.5232 72.6614 16.3306C70.853 16.2708 69.1362 16.6322 67.5989 17.3234C64.8535 18.5578 61.7749 18.6446 58.9743 17.6126C57.5092 16.8498 55.8688 16.3881 54.1363 16.3306C53.9726 16.3255 53.8131 16.341 53.6514 16.3425C53.4885 16.341 53.3291 16.3251 53.1651 16.3306C51.4317 16.3877 49.791 16.8498 48.3259 17.6131C45.5257 18.6451 42.4472 18.5578 39.702 17.323C38.1656 16.6322 36.4483 16.2708 34.6403 16.3306C28.8153 16.5228 23.9867 21.2012 23.6256 27.0187C23.2139 33.6575 28.4763 39.17 35.0264 39.17C36.77 39.17 38.4219 38.7792 39.8997 38.0805C42.4276 36.8845 45.3513 36.7665 47.9637 37.7101C49.6158 38.6386 51.5212 39.17 53.5515 39.17C53.5851 39.17 53.617 39.1648 53.6503 39.1648C53.684 39.1648 53.7162 39.17 53.7499 39.17C55.7801 39.17 57.6855 38.6386 59.3376 37.7101C61.9501 36.7665 64.8733 36.8845 67.4013 38.0805C68.8791 38.7792 70.5309 39.17 72.2746 39.17C78.8242 39.17 84.0862 33.6579 83.6753 27.0196Z"
          fill="white"
        />
        <path d="M120.236 100.414L120.357 65.8651L122.431 65.8203L124.04 100.332L120.236 100.414Z" fill="white" />
        <path
          d="M132.239 51.8559L123.768 64.8623C123.734 64.915 123.69 64.9606 123.64 64.9971L123.069 65.4092C123.032 65.4361 122.998 65.4683 122.97 65.5043L122.796 65.722C122.618 65.945 122.291 65.9783 122.072 65.7948L121.773 65.544C121.571 65.3756 121.531 65.0819 121.681 64.8655L121.916 64.5235C122.022 64.3701 122.036 64.1712 121.951 64.0052L121.24 62.6003C121.143 62.4096 121.176 62.1786 121.322 62.0229L131.442 51.2309C131.666 50.9916 132.055 51.0244 132.236 51.2979C132.348 51.4667 132.349 51.6863 132.239 51.8559Z"
          fill="#FFDA33"
        />
        <path
          d="M127.037 82.4881L121.385 68.0315C121.363 67.9732 121.351 67.9114 121.35 67.8488L121.347 67.1454C121.347 67.0991 121.34 67.053 121.328 67.0087L121.251 66.7412C121.173 66.4666 121.337 66.1816 121.613 66.1103L121.992 66.0124C122.246 65.9466 122.508 66.0849 122.597 66.3318L122.738 66.7229C122.801 66.8981 122.955 67.0249 123.139 67.053L124.696 67.2912C124.908 67.3237 125.076 67.4847 125.118 67.6943L128.008 82.2035C128.072 82.5252 127.819 82.8228 127.491 82.811C127.289 82.8034 127.11 82.6767 127.037 82.4881Z"
          fill="#FFDA33"
        />
        <path
          d="M104.285 61.2551L119.532 64.1647C119.593 64.1762 119.652 64.1992 119.705 64.2321L120.303 64.6038C120.342 64.6284 120.385 64.647 120.428 64.6601L120.696 64.7382C120.97 64.8182 121.124 65.1079 121.037 65.3802L120.919 65.7527C120.839 66.0028 120.582 66.151 120.326 66.0951L119.919 66.0063C119.738 65.9663 119.549 66.0293 119.427 66.1704L118.397 67.3617C118.257 67.5233 118.03 67.5804 117.831 67.5039L104.009 62.2293C103.702 62.1123 103.585 61.7398 103.77 61.4688C103.884 61.3011 104.086 61.2171 104.285 61.2551Z"
          fill="#FFDA33"
        />
        <path
          d="M122.851 65.9597C122.851 66.742 122.217 67.3765 121.434 67.3765C120.652 67.3765 120.018 66.742 120.018 65.9597C120.018 65.1775 120.652 64.543 121.434 64.543C122.217 64.543 122.851 65.1775 122.851 65.9597"
          fill="#00CCFF"
        />
        <path d="M68.1631 118.312L68.2842 83.7635L70.3577 83.7188L71.966 118.23L68.1631 118.312Z" fill="white" />
        <path
          d="M80.1562 69.7544L71.6857 82.7608C71.6512 82.8135 71.6082 82.8591 71.5575 82.8955L70.9872 83.3077C70.9496 83.3346 70.9158 83.3668 70.8873 83.4028L70.7142 83.6203C70.5362 83.8435 70.2092 83.8768 69.9901 83.6933L69.6906 83.4424C69.4893 83.274 69.4491 82.9803 69.5981 82.764L69.8344 82.4219C69.9403 82.2686 69.9537 82.0697 69.8693 81.9036L69.1575 80.4987C69.0608 80.3081 69.0938 80.0771 69.24 79.9214L79.3596 69.1294C79.5839 68.89 79.9732 68.9229 80.1543 69.1964C80.2661 69.3652 80.2668 69.5847 80.1562 69.7544Z"
          fill="#FFDA33"
        />
        <path
          d="M74.9628 100.386L69.3115 85.93C69.2889 85.8717 69.2769 85.8099 69.2766 85.7473L69.2732 85.0439C69.2728 84.9975 69.2665 84.9515 69.2539 84.9071L69.1777 84.6397C69.0996 84.365 69.263 84.0801 69.5392 84.0088L69.9178 83.9108C70.1718 83.8451 70.4342 83.9834 70.5234 84.2303L70.6642 84.6214C70.7273 84.7966 70.8816 84.9234 71.0655 84.9515L72.6225 85.1897C72.8338 85.2222 73.0027 85.3831 73.0442 85.5928L75.9347 100.102C75.9985 100.424 75.7457 100.721 75.4175 100.709C75.215 100.702 75.0365 100.575 74.9628 100.386Z"
          fill="#FFDA33"
        />
        <path
          d="M52.2119 79.1575L67.4587 82.067C67.5201 82.0785 67.5788 82.1016 67.6318 82.1345L68.2296 82.5062C68.2688 82.5307 68.3113 82.5493 68.3552 82.5625L68.6223 82.6405C68.8962 82.7205 69.0506 83.0102 68.9638 83.2825L68.8456 83.655C68.7663 83.905 68.5091 84.0533 68.2527 83.9974L67.8462 83.9087C67.6644 83.8686 67.4756 83.9316 67.3536 84.0727L66.3233 85.264C66.1834 85.4257 65.9572 85.4827 65.7575 85.4063L51.9355 80.1315C51.6292 80.0146 51.512 79.6421 51.6966 79.371C51.8104 79.2034 52.0131 79.1194 52.2119 79.1575Z"
          fill="#FFDA33"
        />
        <path
          d="M70.7682 83.8621C70.7682 84.6444 70.1339 85.2789 69.3516 85.2789C68.5693 85.2789 67.9346 84.6444 67.9346 83.8621C67.9346 83.0798 68.5693 82.4453 69.3516 82.4453C70.1339 82.4453 70.7682 83.0798 70.7682 83.8621Z"
          fill="#00CCFF"
        />
        <path
          d="M334.778 27.1424C333.173 27.0893 331.649 27.4099 330.285 28.0233C327.631 29.217 324.622 29.2166 321.968 28.0229C320.604 27.4099 319.08 27.0893 317.476 27.1424C312.306 27.3128 308.021 31.4648 307.701 36.6277C307.335 42.5195 312.005 47.4117 317.818 47.4117C319.366 47.4117 320.832 47.065 322.144 46.4448C324.652 45.2583 327.602 45.2583 330.11 46.4448C331.422 47.065 332.887 47.4117 334.435 47.4117C340.247 47.4117 344.918 42.5199 344.552 36.6285C344.232 31.4656 339.948 27.3132 334.778 27.1424Z"
          fill="white"
        />
        <path
          d="M91.9686 84.2007C91.9686 83.4663 91.3735 82.8711 90.6393 82.8711C89.9049 82.8711 89.3096 83.4663 89.3096 84.2007C89.3096 84.935 89.9049 85.5302 90.6393 85.5302C91.3735 85.5302 91.9686 84.935 91.9686 84.2007Z"
          fill="#33CC66"
        />
        <path
          d="M88.6018 89.5328C88.6018 88.7984 88.0064 88.2031 87.2721 88.2031C86.5378 88.2031 85.9424 88.7984 85.9424 89.5328C85.9424 90.267 86.5378 90.8623 87.2721 90.8623C88.0064 90.8623 88.6018 90.267 88.6018 89.5328Z"
          fill="#33CC66"
        />
        <path
          d="M94.6317 93.2514C94.6317 92.5171 94.0367 91.9219 93.3024 91.9219C92.568 91.9219 91.9727 92.5171 91.9727 93.2514C91.9727 93.9858 92.568 94.581 93.3024 94.581C94.0367 94.581 94.6317 93.9858 94.6317 93.2514Z"
          fill="#33CC66"
        />
        <path
          d="M71.7764 219.68L78.0538 218.378C81.1088 220.063 84.26 221.068 87.9372 218.378L87.365 209.43L77.5864 210.899L71.2422 211.333L71.7764 219.68Z"
          fill="#FFDA33"
        />
        <path
          d="M46.4204 190.57C51.7232 194.259 55.4856 202.04 58.6611 210.349L75.5404 209.696L76.9184 220.724C76.9184 220.724 58.5741 224.572 53.6002 221.693C50.3304 219.8 48.2584 216.761 48.2584 216.761L46.4204 190.57Z"
          fill="#336666"
        />
        <path d="M12.4651 292.059L10.3545 307.394L23.0191 307.05L24.613 291.844L12.4651 292.059Z" fill="#FFDA33" />
        <path
          d="M29.6805 296.414C29.4246 296.414 29.1824 296.483 28.9497 296.623C27.179 297.684 26.5626 302.304 26.4029 303.867C27.6758 302.816 31.4915 299.492 31.264 297.583C31.2121 297.146 30.9374 296.822 30.4251 296.591C30.1635 296.473 29.9159 296.414 29.6805 296.414ZM25.8779 304.851L25.9117 304.355C25.9304 304.081 26.3941 297.635 28.7212 296.241C29.2943 295.898 29.929 295.879 30.6078 296.185C31.2657 296.482 31.6351 296.934 31.7061 297.53C32.0069 300.056 26.5045 304.364 26.2703 304.546L25.8779 304.851Z"
          fill="#336666"
        />
        <path
          d="M26.314 304.302C27.9408 304.578 32.951 305.293 34.2469 303.873C34.5433 303.548 34.6095 303.128 34.4494 302.59C34.2786 302.015 33.9553 301.657 33.4607 301.494C31.501 300.848 27.6015 303.401 26.314 304.302ZM30.7137 305.168C28.3656 305.168 25.8857 304.68 25.7133 304.646L25.2266 304.548L25.6237 304.25C25.8429 304.085 31.0249 300.221 33.6001 301.071C34.2345 301.28 34.6639 301.749 34.8759 302.462C35.0815 303.154 34.9807 303.729 34.5761 304.173C33.8778 304.938 32.3266 305.168 30.7137 305.168Z"
          fill="#336666"
        />
        <path
          d="M10.3535 301.409H18.5487C19.6132 301.409 20.4766 302.272 20.4766 303.336V304.898H21.2519C21.2841 302.731 22.1292 301.034 24.9992 300.504C25.3653 306.965 42.1437 307.482 42.273 314.461L8.45801 313.642L10.3535 301.409Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask4_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="300"
          width="35"
          height="15"
        >
          <path
            d="M21.2535 304.898H20.4781V303.336C20.4781 302.271 19.615 301.408 18.5507 301.408H10.3555L8.45996 313.642L42.2749 314.461C42.1458 307.482 25.3677 306.965 25.0015 300.504C22.1315 301.034 21.286 302.731 21.2535 304.898Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_5794_68401)">
          <path
            d="M21.1679 303.174L20.4788 304.897L7.14648 304.983L9.45107 300.18L20.7585 300.008L21.1679 303.174Z"
            fill="#336666"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.1975 299.91L24.4404 306.781L25.6802 307.278L28.4377 300.408L27.1975 299.91Z"
            fill="#336666"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.2874 301.297L28.5303 308.167L29.7701 308.665L32.5275 301.794L31.2874 301.297Z"
            fill="#336666"
          />
          <path
            d="M51.2806 312.479C51.2806 317.213 47.4426 321.051 42.708 321.051C37.9738 321.051 34.1357 317.213 34.1357 312.479C34.1357 307.744 37.9738 303.906 42.708 303.906C47.4426 303.906 51.2806 307.744 51.2806 312.479Z"
            fill="#A5F4F7"
          />
        </g>
        <path
          d="M8.46081 313.469L7.91504 318.078H13.9319V317.389C13.9319 316.889 14.3369 316.484 14.8362 316.484C15.3359 316.484 15.7409 316.889 15.7409 317.389V318.078H21.2116V317.389C21.2116 316.889 21.617 316.484 22.1163 316.484C22.616 316.484 23.021 316.889 23.021 317.389V318.078H28.4917V317.389C28.4917 316.889 28.8967 316.484 29.396 316.484C29.8957 316.484 30.3007 316.889 30.3007 317.389V318.078H35.7711V317.389C35.7711 316.889 36.1764 316.484 36.6761 316.484C37.1758 316.484 37.5808 316.889 37.5808 317.389V318.078H42.391C42.4694 316.317 42.4597 314.679 42.1383 313.469H8.46081Z"
          fill="#336666"
        />
        <path d="M9.03223 293.612L27.2508 293.072L41.3109 244.218L25.1565 229.199L9.03223 293.612Z" fill="#336666" />
        <mask
          id="mask5_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="229"
          width="33"
          height="65"
        >
          <path d="M9.03223 293.612L27.2508 293.072L41.3109 244.217L25.1569 229.199L9.03223 293.612Z" fill="white" />
        </mask>
        <g mask="url(#mask5_5794_68401)">
          <path
            d="M41.3132 262.079C41.3132 258.474 38.3898 255.551 34.7843 255.551C31.1785 255.551 28.2559 258.474 28.2559 262.079C28.2559 265.685 31.1785 268.608 34.7843 268.608C38.3898 268.608 41.3132 265.685 41.3132 262.079Z"
            fill="#A5F4F7"
          />
        </g>
        <path d="M43.747 292.059L41.6367 307.394L54.301 307.05L55.8948 291.844L43.747 292.059Z" fill="#FFDA33" />
        <path
          d="M38.7732 295.864L56.5931 295.679C65.553 254.096 67.2763 254.555 47.863 225.723L23.1657 226.297C22.6698 235.059 29.2434 246.088 44.7065 259.215C42.1769 269.384 40.8969 282.386 38.7732 295.864Z"
          fill="#A5F4F7"
        />
        <mask
          id="mask6_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="23"
          y="225"
          width="41"
          height="71"
        >
          <path
            d="M23.1656 226.297C22.6699 235.059 29.2438 246.088 44.7064 259.215C42.1773 269.384 40.8969 282.386 38.7732 295.864L56.5935 295.679C65.5533 254.096 67.2763 254.556 47.8629 225.723L23.1656 226.297Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_5794_68401)">
          <path
            d="M71.1775 257.638C71.1775 254.032 68.2544 251.109 64.649 251.109C61.043 251.109 58.1201 254.032 58.1201 257.638C58.1201 261.243 61.043 264.167 64.649 264.167C68.2544 264.167 71.1775 261.243 71.1775 257.638Z"
            fill="#336666"
          />
        </g>
        <path
          d="M35.2921 241.212L33.7744 239.291C36.8147 236.888 39.0086 234.127 40.4812 230.848L42.7141 231.851C41.0992 235.447 38.6024 238.596 35.2921 241.212Z"
          fill="#336666"
        />
        <path
          d="M14.6336 233.637L9.49312 232.352C5.92255 231.459 3.75113 227.84 4.64394 224.269L13.2088 190.011C14.1017 186.44 17.7203 184.269 21.2914 185.162L26.4318 186.446C30.0029 187.339 32.1735 190.958 31.2806 194.529L22.7158 228.787C21.823 232.358 18.2046 234.53 14.6336 233.637Z"
          fill="#FFDA33"
        />
        <mask
          id="mask7_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="184"
          width="28"
          height="50"
        >
          <path
            d="M13.217 190.011L4.65176 224.27C3.75895 227.841 5.93027 231.459 9.50131 232.352L14.6418 233.637C18.2123 234.53 21.8311 232.359 22.724 228.788L31.2884 194.529C32.1816 190.958 30.0107 187.34 26.4396 186.447L21.2992 185.162C20.7563 185.026 20.2125 184.961 19.6775 184.961C16.6914 184.961 13.9739 186.983 13.217 190.011Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_5794_68401)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.07123 193.734L-2.17773 234.73L23.8203 241.23L34.0693 200.234L8.07123 193.734Z"
            fill="#336666"
          />
        </g>
        <path
          d="M35.3686 188.993C37.4111 189.075 44.2497 189.345 46.4249 190.572C48.6001 191.8 47.516 197.579 47.516 197.924C47.516 198.269 30.8623 196.23 30.8623 196.23C30.8623 196.23 33.3261 188.91 35.3686 188.993Z"
          fill="#FFDA33"
        />
        <path d="M48.5977 173.156L52.3657 176.015L49.4704 178.217L48.5977 173.156Z" fill="#FF6B1C" />
        <path
          d="M47.4186 168.704C48.2695 169.849 48.9863 171.897 49.679 174.628C50.3718 177.36 50.7464 180.265 50.7464 181.338C50.7464 183.483 42.478 183.282 42.478 183.282L38.4258 171.081C38.4258 171.081 46.5717 167.564 47.4186 168.704Z"
          fill="#FFDA33"
        />
        <path
          d="M49.1303 175.11C47.6894 176.087 46.4319 175.994 44.9102 174.476C46.8215 173.426 47.6446 173.366 49.1303 175.11Z"
          fill="white"
        />
        <mask
          id="mask8_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="44"
          y="173"
          width="6"
          height="3"
        >
          <path
            d="M44.918 174.476C46.4394 175.994 47.6968 176.087 49.1378 175.11C48.3174 174.147 47.6994 173.734 46.9885 173.734C46.4118 173.734 45.7736 174.006 44.918 174.476Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask8_5794_68401)">
          <path
            d="M49.5967 174.432C49.5967 175.235 48.9455 175.887 48.1418 175.887C47.3381 175.887 46.6865 175.235 46.6865 174.432C46.6865 173.628 47.3381 172.977 48.1418 172.977C48.9455 172.977 49.5967 173.628 49.5967 174.432Z"
            fill="#336666"
          />
        </g>
        <path
          d="M47.6281 171.547C47.6654 171.599 47.9629 172.729 47.9629 172.729L45.3053 173.354L44.1744 173.812L43.8096 173.031L44.9981 172.268L47.6281 171.547Z"
          fill="#336666"
        />
        <path d="M34.4072 191.779L38.2046 180.16L46.843 182.211L46.4068 193.161L34.4072 191.779Z" fill="#FFDA33" />
        <path
          d="M40.1328 179.756L41.9481 184.04C42.365 185.024 43.3258 185.666 44.3942 185.678C45.8736 185.696 47.3499 185.616 48.8221 185.448C50.3336 185.275 51.6416 184.427 51.3868 182.444L50.1971 176.992L46.5993 178.103C46.3809 178.183 46.2381 178.393 46.2452 178.626L46.3024 180.509C46.3135 180.879 46.0161 181.186 45.6455 181.186H44.1925C43.9377 181.186 43.7062 181.039 43.5977 180.809L42.1888 177.812L40.1328 179.756Z"
          fill="#336666"
        />
        <mask
          id="mask9_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="40"
          y="176"
          width="12"
          height="10"
        >
          <path
            d="M46.6012 178.099C46.3828 178.179 46.2404 178.389 46.2475 178.622L46.3044 180.505C46.3154 180.876 46.018 181.182 45.6475 181.182H44.1952C43.9403 181.182 43.7083 181.035 43.5996 180.805L42.1908 177.808L40.1348 179.752L41.9504 184.036C42.367 185.02 43.3282 185.662 44.3962 185.674C45.8759 185.692 47.3518 185.612 48.8244 185.444C50.3356 185.271 51.6435 184.423 51.3887 182.441L50.1998 176.988L46.6012 178.099Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask9_5794_68401)">
          <path
            d="M50.9951 177.496L48.1001 178.355C47.3341 178.582 46.848 179.365 47.0353 180.141C47.0429 180.173 47.0508 180.204 47.0591 180.235C47.2577 180.976 47.9816 181.453 48.7421 181.351C49.6572 181.228 50.6037 180.923 51.5758 180.468L50.9947 177.496H50.9951Z"
            fill="#FFDA33"
          />
          <path
            d="M50.2346 179.392C49.6199 179.392 49.0051 179.226 48.3955 178.892L48.5928 178.531C49.5645 179.063 50.5077 179.126 51.4766 178.723L51.6345 179.102C51.1697 179.296 50.7021 179.392 50.2346 179.392Z"
            fill="#336666"
          />
        </g>
        <path
          d="M42.0451 179.098L42.2476 177.939L41.9968 175.74C41.9512 175.342 41.7544 174.977 41.4473 174.721L40.3327 173.793C39.6194 173.198 39.9153 172.043 40.8272 171.865C43.3528 171.373 47.1463 170.552 47.8402 169.936C48.8979 168.997 48.8396 165.769 46.2359 165.601C45.0815 165.527 43.9267 166.674 43.0032 166.867C42.3886 166.996 39.1049 166.914 36.7794 168.677C35.6095 169.563 34.8274 170.685 34.4236 172.702C33.9745 174.944 34.5846 176.909 35.4861 178.122C37.0074 180.169 39.4529 180.915 39.4529 180.915L42.0451 179.098Z"
          fill="#336666"
        />
        <path
          d="M41.0654 182.181H37.7135C36.3724 182.181 35.2852 181.094 35.2852 179.753C35.2852 178.411 36.3724 177.324 37.7135 177.324H39.382L41.0654 182.181Z"
          fill="#FFDA33"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4319 179.43L37.3613 179.835L39.4174 180.192L39.4876 179.787L37.4319 179.43Z"
          fill="#336666"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.2942 171.379L47.8848 171.416L48.0037 172.723L48.4131 172.686L48.2942 171.379Z"
          fill="#336666"
        />
        <path
          d="M22.585 227.932L49.4167 227.19C50.7022 215.178 54.3324 202.196 46.4255 190.571C46.4255 190.571 43.9556 191.576 41.2275 191.289C38.4993 191.002 35.3692 188.992 35.3692 188.992C34.1708 188.923 29.8789 189.148 26.7736 190.237C12.965 195.079 24.2866 218.944 24.2866 218.944L22.585 227.932Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask10_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="19"
          y="188"
          width="33"
          height="40"
        >
          <path
            d="M26.7695 190.237C12.961 195.08 24.2829 218.945 24.2829 218.945L22.5813 227.932L49.413 227.191C50.6987 215.178 54.3284 202.196 46.4215 190.571C46.4215 190.571 43.9519 191.576 41.2238 191.289C38.4958 191.002 35.3652 188.992 35.3652 188.992C35.2392 188.984 35.0787 188.98 34.8893 188.98C33.2768 188.98 29.5484 189.262 26.7695 190.237Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_5794_68401)">
          <path
            d="M37.0322 214.324L42.4599 213.979V210.921C42.4599 210.921 40.9524 210.77 39.1429 211.61C37.3338 212.45 37.0322 214.324 37.0322 214.324Z"
            fill="#FFDA33"
          />
          <path
            d="M20.9676 227.806C19.7089 227.806 19.0198 227.649 18.8506 227.605L19.6412 224.591C19.6555 224.594 23.411 225.4 31.5635 222.163C38.7845 219.296 40.5855 215.434 40.7908 214.939C43.1492 204.07 37.4849 194.203 30.8049 189.235L32.6644 186.734C40.1722 192.319 46.5301 203.447 43.8095 215.718L43.7723 215.856C43.7001 216.082 41.9011 221.411 32.713 225.059C26.9576 227.344 23.1254 227.806 20.9676 227.806Z"
            fill="#336666"
          />
        </g>
        <path
          d="M33.9128 214.07L39.965 213.639L40.5895 212.734L45.6509 213.208L45.9314 214.757L45.5349 215.237L45.6876 215.826L46.2758 215.965L46.4511 217.702L46.0372 217.925L46.0584 218.334L46.4047 218.528L46.2765 220.036L45.7084 220.37L45.6438 220.887C45.6438 220.887 46.123 220.975 46.0584 221.061C45.9938 221.147 45.9061 222.217 45.9061 222.217C42.7496 222.687 39.886 222.926 38.3496 221.264C38.3496 221.264 33.7622 221.242 33.9128 221.242C34.0634 221.242 33.9128 214.07 33.9128 214.07Z"
          fill="#FFDA33"
        />
        <path d="M22.8426 213.831L35.4107 213.551L36.8566 221.585L21.4888 224.921L22.8426 213.831Z" fill="#FF6B1C" />
        <path
          d="M30.4317 192.136C32.6565 193.401 33.0495 203.654 30.8901 205.682C28.7304 207.711 21.9571 213.568 21.9571 213.568L25.6873 213.47L22.5791 224.792C22.5791 224.792 14.7909 225.577 11.6498 224.465C8.5084 223.352 5.69438 217.397 10.4717 209.544C15.2491 201.69 22.8403 187.816 30.4317 192.136Z"
          fill="#FF6B1C"
        />
        <path
          d="M20.7779 225.382L20.7051 224.993C27.6117 223.702 32.6833 222.573 36.6395 221.445L35.4005 213.518L21.4782 213.762L32.3952 204.18L32.6568 204.478L22.5519 213.346L35.7389 213.116L37.085 221.73L36.9171 221.778C32.9239 222.926 27.7956 224.071 20.7779 225.382Z"
          fill="#336666"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.7237 176.34L80.0605 176.543L90.6862 315.939L93.3494 315.736L82.7237 176.34Z"
          fill="#FF6B1C"
        />
        <path
          d="M77.2832 211.934C78.0877 207.791 82.3012 207.462 87.4096 208.166L87.634 210.398L77.2832 211.934Z"
          fill="#FFDA33"
        />
        <path
          d="M81.7256 210.764L88.3369 209.996C90.6858 211.676 89.3198 217.298 88.3711 218.212L82.3048 218.001L81.7256 210.764Z"
          fill="#FFDA33"
        />
        <path
          d="M81.5352 213.442L81.592 210.944L88.3022 209.77L88.3817 210.223L82.0441 211.333L82.0074 212.957L88.2238 212.642L88.2472 213.102L81.5352 213.442Z"
          fill="#336666"
        />
        <path
          d="M81.3545 215.259L81.3727 213.056L83.0115 212.922L83.0487 213.381L81.83 213.481L81.8193 214.781L88.3273 214.54L88.3444 215L81.3545 215.259Z"
          fill="#336666"
        />
        <path
          d="M81.6593 216.851L81.6348 215.026L82.0958 215.02L82.114 216.39L88.0897 216.387L88.0901 216.848L81.6593 216.851Z"
          fill="#336666"
        />
        <path
          d="M82.1347 218.304L82.0801 216.719L82.5404 216.703L82.5798 217.835L88.3373 217.734L88.3455 218.194L82.1347 218.304Z"
          fill="#336666"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.7987 242.633L86.0576 253.093L88.49 254.196L93.2311 243.735L90.7987 242.633Z"
          fill="#FF6B1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.7289 186.605L76.6914 188.332L81.6546 194.189L83.6917 192.462L78.7289 186.605Z"
          fill="#FF6B1C"
        />
        <path
          d="M60.9624 296.414C60.7063 296.414 60.4645 296.483 60.2323 296.623C58.462 297.683 57.8449 302.303 57.6848 303.867C58.9577 302.816 62.7736 299.492 62.5462 297.583C62.4943 297.146 62.2196 296.822 61.7073 296.591C61.4453 296.473 61.1978 296.414 60.9624 296.414ZM57.1602 304.851L57.1936 304.355C57.2122 304.081 57.6761 297.635 60.0031 296.241C60.5761 295.898 61.2108 295.879 61.8897 296.185C62.5475 296.482 62.917 296.934 62.9879 297.53C63.2887 300.056 57.787 304.364 57.5525 304.546L57.1602 304.851Z"
          fill="#336666"
        />
        <path
          d="M57.6054 304.302C59.2322 304.578 64.2424 305.293 65.5383 303.873C65.8347 303.548 65.9009 303.128 65.7408 302.59C65.5699 302.015 65.2467 301.657 64.7521 301.494C62.7928 300.848 58.8929 303.401 57.6054 304.302ZM62.005 305.168C59.657 305.168 57.1771 304.68 57.0047 304.646L56.5176 304.548L56.9147 304.25C57.134 304.085 62.3155 300.221 64.8914 301.071C65.5259 301.28 65.9552 301.749 66.1673 302.462C66.3729 303.154 66.2718 303.729 65.8671 304.173C65.1689 304.938 63.6179 305.168 62.005 305.168Z"
          fill="#336666"
        />
        <path
          d="M41.6455 301.409H49.8411C50.9055 301.409 51.7686 302.272 51.7686 303.336V304.898H52.5439C52.5761 302.731 53.4212 301.034 56.2912 300.504C56.6573 306.965 73.4358 307.482 73.565 314.461L39.75 313.642L41.6455 301.409Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask11_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="39"
          y="300"
          width="35"
          height="15"
        >
          <path
            d="M52.5351 304.898H51.7594V303.336C51.7594 302.271 50.8967 301.408 49.8323 301.408H41.6367L39.7412 313.642L73.5566 314.461C73.427 307.482 56.6489 306.965 56.2827 300.504C53.4128 301.034 52.5677 302.731 52.5351 304.898Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask11_5794_68401)">
          <path
            d="M52.4498 303.174L51.7606 304.897L38.4287 304.983L40.7329 300.18L52.0407 300.008L52.4498 303.174Z"
            fill="#336666"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.4895 299.91L55.7324 306.781L56.9722 307.278L59.7297 300.408L58.4895 299.91Z"
            fill="#336666"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.57 301.297L59.8125 308.167L61.0523 308.665L63.8098 301.794L62.57 301.297Z"
            fill="#336666"
          />
          <path
            d="M82.5625 312.479C82.5625 317.213 78.7248 321.051 73.9902 321.051C69.2561 321.051 65.418 317.213 65.418 312.479C65.418 307.744 69.2561 303.906 73.9902 303.906C78.7248 303.906 82.5625 307.744 82.5625 312.479Z"
            fill="#A5F4F7"
          />
        </g>
        <path
          d="M39.743 313.469L39.1973 318.078H45.2138V317.389C45.2138 316.889 45.6188 316.484 46.1181 316.484C46.6178 316.484 47.0231 316.889 47.0231 317.389V318.078H52.4939V317.389C52.4939 316.889 52.8988 316.484 53.3982 316.484C53.8979 316.484 54.3028 316.889 54.3028 317.389V318.078H59.7736V317.389C59.7736 316.889 60.1786 316.484 60.6779 316.484C61.1777 316.484 61.5829 316.889 61.5829 317.389V318.078H67.0533V317.389C67.0533 316.889 67.4586 316.484 67.958 316.484C68.4577 316.484 68.863 316.889 68.863 317.389V318.078H73.6732C73.7517 316.317 73.7416 314.679 73.4202 313.469H39.743Z"
          fill="#336666"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5535 313.426L38.5552 317.961L38.9416 318.046L39.9395 313.511L39.5535 313.426Z"
          fill="#A5F4F7"
        />
        <path
          d="M165.67 71.7787C162.355 70.5969 160.788 69.03 159.606 65.715C159.196 64.5638 157.576 64.5638 157.165 65.715C155.984 69.03 154.417 70.5969 151.102 71.7787C149.95 72.1889 149.95 73.8093 151.102 74.2195C154.417 75.4013 155.984 76.9682 157.165 80.2832C157.576 81.4345 159.196 81.4345 159.606 80.2832C160.788 76.9682 162.355 75.4013 165.67 74.2195C166.821 73.8093 166.821 72.1889 165.67 71.7787Z"
          fill="white"
        />
        <path
          d="M250.358 31.3296C247.043 30.1477 245.475 28.5808 244.294 25.2658C243.883 24.1145 242.263 24.1145 241.853 25.2658C240.671 28.5808 239.104 30.1477 235.789 31.3295C234.638 31.7397 234.638 33.3601 235.789 33.7703C239.104 34.9521 240.671 36.519 241.853 39.834C242.263 40.9852 243.883 40.9852 244.294 39.834C245.475 36.519 247.043 34.9521 250.358 33.7703C251.509 33.3601 251.509 31.7397 250.358 31.3295"
          fill="#FFDA33"
        />
        <path
          d="M171.995 88.7444C169.972 88.0232 169.015 87.0664 168.294 85.0426C168.043 84.34 167.055 84.34 166.804 85.0426C166.082 87.0664 165.126 88.0232 163.102 88.7444C162.4 88.9948 162.4 89.984 163.102 90.2345C165.126 90.9557 166.082 91.9123 166.804 93.9358C167.055 94.6389 168.043 94.6389 168.294 93.9358C169.015 91.9123 169.972 90.9557 171.995 90.2345C172.698 89.984 172.698 88.9948 171.995 88.7444Z"
          fill="white"
        />
        <path
          d="M228.929 14.0061C226.905 13.2849 225.949 12.3282 225.227 10.3044C224.977 9.60167 223.988 9.60167 223.738 10.3044C223.016 12.3282 222.059 13.2849 220.036 14.0061C219.333 14.2566 219.333 15.2458 220.036 15.4962C222.059 16.2174 223.016 17.1741 223.738 19.1976C223.988 19.9006 224.977 19.9006 225.227 19.1976C225.949 17.1741 226.905 16.2174 228.929 15.4962C229.632 15.2458 229.632 14.2566 228.929 14.0061Z"
          fill="#FFDA33"
        />
        <path
          d="M162.295 145.192C161.178 145.348 160.108 144.688 159.747 143.62L159.239 142.117C158.768 140.724 159.68 139.246 161.137 139.042L161.722 138.961C163.178 138.757 164.461 139.929 164.39 141.398L164.313 142.982C164.258 144.108 163.411 145.036 162.295 145.192Z"
          fill="#336666"
        />
        <path
          d="M158.423 145.162C157.365 145.55 156.18 145.13 155.601 144.163L154.787 142.801C154.033 141.539 154.613 139.901 155.993 139.395L156.548 139.191C157.929 138.685 159.43 139.56 159.67 141.011L159.929 142.576C160.113 143.688 159.481 144.774 158.423 145.162Z"
          fill="#FF6B1C"
        />
        <path
          d="M158.186 144.749C157.413 145.033 156.548 144.726 156.126 144.02L155.531 143.026C154.98 142.104 155.404 140.909 156.412 140.539L156.817 140.391C157.825 140.021 158.921 140.659 159.096 141.719L159.286 142.861C159.42 143.673 158.958 144.466 158.186 144.749Z"
          fill="#FFDA33"
        />
        <path
          d="M112.732 159C112.732 159 111.233 164.996 111.374 167.262C111.515 169.529 110.491 173.513 110.491 173.513L106.394 178.558L105.749 185.228L112.975 189.755C112.975 189.755 114.887 187.892 113.085 185.538L111.281 183.184L117.345 177.02L123.964 164.134L112.732 159Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask12_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="105"
          y="159"
          width="19"
          height="31"
        >
          <path
            d="M111.371 167.263C111.512 169.529 110.489 173.513 110.489 173.513L106.392 178.558L105.746 185.228L112.973 189.755C112.973 189.755 114.885 187.892 113.082 185.538L111.28 183.184L117.343 177.02L123.961 164.134L112.73 159C112.73 159 111.231 164.996 111.371 167.263Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask12_5794_68401)">
          <path
            d="M127.598 180.791L115.744 172.456C115.183 172.062 115.048 171.287 115.443 170.727C115.837 170.166 116.611 170.031 117.172 170.425L129.026 178.76C129.587 179.155 129.722 179.929 129.328 180.49C128.934 181.051 128.159 181.186 127.598 180.791Z"
            fill="#FFDA33"
          />
          <path
            d="M111.153 176.596L99.2989 168.261C98.7377 167.867 98.6033 167.092 98.9976 166.531C99.3919 165.971 100.166 165.836 100.727 166.23L112.581 174.565C113.142 174.959 113.277 175.734 112.882 176.295C112.488 176.856 111.714 176.99 111.153 176.596Z"
            fill="#336666"
          />
          <path
            d="M111.923 190.277L111.652 190.017C113.145 188.464 112.576 187.092 112.551 187.034L112.895 186.883C112.924 186.949 113.598 188.535 111.923 190.277Z"
            fill="#336666"
          />
          <path
            d="M110.887 189.543L110.616 189.283C112.119 187.72 111.522 186.314 111.516 186.301L111.859 186.148C111.888 186.215 112.563 187.8 110.887 189.543Z"
            fill="#336666"
          />
        </g>
        <path
          d="M147.162 163.906C147.162 163.906 146.873 166.884 144.639 172.094L142.406 177.305L142.323 184.252C142.323 184.252 146.376 185.906 146.459 187.808L138.354 187.726L136.948 182.598L134.797 175.237L136.451 166.47L147.162 163.906Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask13_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="134"
          y="163"
          width="14"
          height="25"
        >
          <path
            d="M136.452 166.47L134.798 175.237L136.948 182.598L138.354 187.726L146.46 187.809C146.377 185.906 142.325 184.252 142.325 184.252L142.407 177.305L144.64 172.094C146.873 166.884 147.163 163.906 147.163 163.906L136.452 166.47Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask13_5794_68401)">
          <path
            d="M154.172 177.26L140.51 172.428C139.864 172.199 139.525 171.49 139.754 170.843C139.983 170.197 140.692 169.858 141.339 170.087L155 174.919C155.646 175.148 155.985 175.857 155.757 176.504C155.528 177.15 154.818 177.489 154.172 177.26Z"
            fill="#336666"
          />
          <path
            d="M138.012 176.684L123.589 175.281C122.907 175.215 122.408 174.608 122.474 173.926C122.54 173.244 123.147 172.744 123.829 172.811L138.252 174.213C138.935 174.279 139.434 174.886 139.368 175.568C139.302 176.251 138.695 176.75 138.012 176.684Z"
            fill="#336666"
          />
          <path
            d="M138.631 182.02L124.208 180.617C123.525 180.551 123.026 179.944 123.092 179.262C123.158 178.58 123.765 178.08 124.448 178.146L138.871 179.549C139.553 179.615 140.052 180.222 139.986 180.904C139.92 181.587 139.313 182.086 138.631 182.02Z"
            fill="#336666"
          />
          <path
            d="M145.526 189.219L145.152 189.187C145.335 187.04 144.03 186.332 143.975 186.303L144.147 185.969C144.212 186.002 145.732 186.811 145.526 189.219Z"
            fill="#336666"
          />
          <path
            d="M144.395 189.348L144.021 189.316C144.204 187.169 142.898 186.461 142.843 186.432L143.015 186.098C143.08 186.131 144.601 186.94 144.395 189.348Z"
            fill="#336666"
          />
        </g>
        <path
          d="M148.798 147.985C146.232 148.653 141.727 148.978 136.64 148.233C131.554 147.489 116.294 143.891 112.945 151.707C109.595 159.523 111.256 166.324 115.302 167.711C119.644 169.199 124.549 168.303 133.125 168.621C142.058 168.951 146.215 170.004 151.775 167.959C155.374 166.636 158.227 162.252 158.723 159.895C159.219 157.538 161.204 153.692 161.204 153.692L154.547 144.863C154.547 144.863 153.326 146.807 148.798 147.985Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask14_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="111"
          y="144"
          width="51"
          height="26"
        >
          <path
            d="M148.797 147.985C146.23 148.653 141.726 148.978 136.639 148.234C131.552 147.489 116.294 143.892 112.944 151.707C109.594 159.523 111.254 166.323 115.301 167.711C118.178 168.698 121.302 168.637 125.578 168.576C127.755 168.545 130.231 168.514 133.124 168.621C142.056 168.952 146.214 170.004 151.775 167.959C155.372 166.636 158.226 162.252 158.722 159.895C159.218 157.538 161.203 153.692 161.203 153.692L154.545 144.863C154.545 144.863 153.325 146.807 148.797 147.985Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask14_5794_68401)">
          <path
            d="M124.174 164.358C124.174 164.358 130.625 161.629 138.192 163.614C145.76 165.599 147.001 173.58 147.001 173.58L122.188 172.422L124.174 164.358Z"
            fill="#FFDA33"
          />
          <path
            d="M128.396 154.795V140.304C128.396 139.618 128.952 139.062 129.638 139.062C130.323 139.062 130.879 139.618 130.879 140.304V154.795C130.879 155.481 130.323 156.036 129.638 156.036C128.952 156.036 128.396 155.481 128.396 154.795Z"
            fill="#336666"
          />
          <path
            d="M121.111 156.943V142.452C121.111 141.766 121.667 141.211 122.353 141.211C123.038 141.211 123.594 141.766 123.594 142.452V156.943C123.594 157.629 123.038 158.185 122.353 158.185C121.667 158.185 121.111 157.629 121.111 156.943Z"
            fill="#336666"
          />
          <path
            d="M135.92 157.565V143.073C135.92 142.388 136.476 141.832 137.161 141.832C137.847 141.832 138.403 142.388 138.403 143.073V157.565C138.403 158.25 137.847 158.806 137.161 158.806C136.476 158.806 135.92 158.25 135.92 157.565Z"
            fill="#336666"
          />
          <path
            d="M142.54 155.17L142.54 140.679C142.54 139.993 143.096 139.438 143.781 139.438C144.467 139.438 145.023 139.993 145.023 140.679V155.17C145.023 155.856 144.467 156.411 143.781 156.411C143.096 156.411 142.54 155.856 142.54 155.17"
            fill="#336666"
          />
          <path
            d="M166.86 161.069L153.198 156.236C152.551 156.008 152.213 155.298 152.442 154.652C152.67 154.006 153.38 153.667 154.026 153.895L167.688 158.728C168.334 158.957 168.673 159.666 168.444 160.313C168.215 160.959 167.506 161.297 166.86 161.069Z"
            fill="#336666"
          />
          <path
            d="M165.034 166.256L151.372 161.424C150.725 161.195 150.387 160.486 150.615 159.839C150.844 159.193 151.554 158.855 152.2 159.083L165.861 163.916C166.508 164.144 166.846 164.854 166.618 165.5C166.389 166.146 165.68 166.485 165.034 166.256Z"
            fill="#336666"
          />
        </g>
        <path
          d="M111.629 163.329C111.629 163.329 113.283 169.284 114.523 171.186C115.764 173.089 116.839 177.058 116.839 177.058L115.764 183.468L118.493 189.588L127.012 189.961C127.012 189.961 127.757 187.397 125.027 186.239L122.298 185.081L124.531 176.727L123.931 162.254L111.629 163.329Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask15_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="111"
          y="162"
          width="17"
          height="28"
        >
          <path
            d="M111.628 163.325C111.628 163.325 113.282 169.28 114.523 171.183C115.763 173.085 116.839 177.054 116.839 177.054L115.763 183.465L118.493 189.585L127.012 189.957C127.012 189.957 127.756 187.393 125.026 186.235L122.297 185.077L124.531 176.724L123.931 162.25L111.628 163.325H111.628Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask15_5794_68401)">
          <path
            d="M135.31 174.942L120.887 173.539C120.204 173.473 119.705 172.866 119.772 172.184C119.838 171.502 120.445 171.002 121.127 171.068L135.55 172.471C136.233 172.537 136.732 173.144 136.666 173.826C136.599 174.509 135.993 175.008 135.31 174.942Z"
            fill="#336666"
          />
          <path
            d="M119.599 169.035L105.122 168.376C104.438 168.345 103.908 167.764 103.939 167.08C103.97 166.395 104.551 165.864 105.235 165.896L119.712 166.555C120.396 166.586 120.926 167.166 120.895 167.851C120.864 168.536 120.283 169.066 119.599 169.035Z"
            fill="#336666"
          />
          <path
            d="M118.932 179.411L104.509 178.008C103.827 177.942 103.327 177.335 103.394 176.653C103.46 175.97 104.067 175.471 104.749 175.537L119.173 176.94C119.855 177.006 120.354 177.613 120.288 178.295C120.221 178.977 119.615 179.477 118.932 179.411Z"
            fill="#336666"
          />
          <path
            d="M126.304 190.517L125.929 190.489C126.091 188.341 124.779 187.646 124.723 187.617L124.892 187.281C124.957 187.314 126.485 188.107 126.304 190.517Z"
            fill="#336666"
          />
          <path
            d="M125.039 190.603L124.664 190.575C124.826 188.427 123.514 187.731 123.458 187.703L123.627 187.367C123.692 187.4 125.221 188.193 125.039 190.603Z"
            fill="#336666"
          />
        </g>
        <path
          d="M156.902 163.711C156.902 163.711 156.953 166.885 154.72 172.095L152.487 177.306L152.404 184.253C152.404 184.253 156.457 185.907 156.54 187.81L148.434 187.727L147.028 182.599L144.878 175.238L146.532 166.471L156.902 163.711Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask16_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="144"
          y="163"
          width="13"
          height="25"
        >
          <path
            d="M146.525 166.471L144.871 175.238L147.021 182.6L148.427 187.727L156.533 187.81C156.45 185.908 152.397 184.253 152.397 184.253L152.48 177.306L154.713 172.096C156.946 166.885 156.894 163.711 156.894 163.711L146.525 166.471Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask16_5794_68401)">
          <path
            d="M164.254 177.26L150.592 172.428C149.946 172.199 149.607 171.49 149.836 170.843C150.065 170.197 150.774 169.858 151.421 170.087L165.082 174.919C165.729 175.148 166.067 175.857 165.839 176.504C165.61 177.15 164.9 177.489 164.254 177.26Z"
            fill="#336666"
          />
          <path
            d="M148.085 176.684L133.662 175.281C132.979 175.215 132.48 174.608 132.546 173.926C132.612 173.244 133.219 172.744 133.902 172.811L148.325 174.213C149.007 174.279 149.507 174.886 149.44 175.568C149.374 176.251 148.767 176.75 148.085 176.684Z"
            fill="#336666"
          />
          <path
            d="M148.703 182.02L134.28 180.617C133.597 180.551 133.098 179.944 133.164 179.262C133.231 178.58 133.837 178.08 134.52 178.146L148.943 179.549C149.626 179.615 150.125 180.222 150.059 180.904C149.992 181.587 149.385 182.086 148.703 182.02Z"
            fill="#336666"
          />
          <path
            d="M155.294 188.957L154.92 188.925C155.103 186.779 153.798 186.07 153.742 186.041L153.915 185.707C153.979 185.74 155.5 186.549 155.294 188.957Z"
            fill="#336666"
          />
          <path
            d="M154.125 189L153.751 188.968C153.934 186.821 152.629 186.113 152.573 186.084L152.745 185.75C152.81 185.783 154.331 186.592 154.125 189Z"
            fill="#336666"
          />
        </g>
        <path
          d="M154.01 145.965C154.01 145.965 154.533 144.186 156.104 143.244C157.674 142.302 159.558 141.883 161.756 142.04C163.954 142.197 165.106 143.244 167.461 146.227C169.816 149.21 170.496 149.995 170.392 151.513C170.287 153.031 169.711 153.607 169.502 154.077C169.292 154.549 164.477 154.13 162.279 154.13C160.081 154.13 159.721 155.366 159.721 155.366L153.644 153.292L154.01 145.965Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask17_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="153"
          y="142"
          width="18"
          height="14"
        >
          <path
            d="M156.102 143.244C154.532 144.186 154.009 145.966 154.009 145.966L153.643 153.293L159.72 155.366C159.72 155.366 160.08 154.13 162.278 154.13C164.476 154.13 169.291 154.549 169.5 154.078C169.71 153.607 170.285 153.031 170.39 151.513C170.495 149.996 169.814 149.21 167.459 146.228C165.104 143.244 163.953 142.198 161.754 142.04C161.453 142.019 161.157 142.008 160.868 142.008C159.045 142.008 157.457 142.431 156.102 143.244Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask17_5794_68401)">
          <path
            d="M165.347 154.944C165.347 154.944 165.297 150.436 169.373 150.206L173.449 149.977L173.08 155.248L165.347 154.944Z"
            fill="#FFDA33"
          />
          <path
            d="M166.926 146.184C167.324 145.881 167.883 145.913 168.244 146.26L171.149 149.057C171.842 149.725 171.777 150.853 171.011 151.437L170.903 151.52C170.137 152.102 169.032 151.865 168.573 151.019L166.649 147.474C166.411 147.034 166.528 146.487 166.926 146.184Z"
            fill="#FFDA33"
          />
          <path
            d="M168.257 150.385L171.449 150.367C171.449 150.367 171.432 151.501 169.862 151.518C168.292 151.536 168.257 150.385 168.257 150.385Z"
            fill="#336666"
          />
          <path
            d="M170.026 152.774C168.948 152.774 168.263 151.983 168.23 151.944L168.464 151.746C168.497 151.785 169.295 152.7 170.492 152.411L170.564 152.71C170.375 152.755 170.196 152.774 170.026 152.774Z"
            fill="#336666"
          />
        </g>
        <path
          d="M161.895 147.223C161.895 147.223 163.482 146.395 164.666 146.921C165.901 147.47 166.258 149.007 166.258 149.007C166.258 149.007 165.05 149.665 163.431 149.034C162.377 148.623 161.895 147.223 161.895 147.223Z"
          fill="white"
        />
        <mask
          id="mask18_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="161"
          y="146"
          width="6"
          height="4"
        >
          <path
            d="M161.895 147.223C161.895 147.223 162.377 148.623 163.431 149.034C165.05 149.665 166.258 149.007 166.258 149.007C166.258 149.007 165.901 147.47 164.666 146.921C164.38 146.795 164.072 146.746 163.767 146.746C162.809 146.746 161.895 147.223 161.895 147.223Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask18_5794_68401)">
          <path
            d="M167.164 148.318C167.164 149.241 166.416 149.989 165.493 149.989C164.571 149.989 163.823 149.241 163.823 148.318C163.823 147.396 164.571 146.648 165.493 146.648C166.416 146.648 167.164 147.396 167.164 148.318Z"
            fill="#336666"
          />
        </g>
        <path
          d="M97.0195 167.869C94.9678 167.869 92.911 167.322 90.9212 166.234C88.3346 164.819 86.7583 162.956 86.6921 162.877C86.0263 162.082 86.1309 160.897 86.9262 160.231C87.72 159.567 88.9012 159.67 89.5678 160.461C89.7679 160.695 94.3448 165.959 100.297 163.417C107.043 160.537 113.87 150.24 113.938 150.136C114.508 149.269 115.673 149.028 116.539 149.598C117.406 150.167 117.647 151.331 117.078 152.198C116.775 152.66 109.565 163.545 101.772 166.872C100.214 167.537 98.6181 167.869 97.0195 167.869Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask19_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="86"
          y="149"
          width="32"
          height="19"
        >
          <path
            d="M113.939 150.136C113.871 150.239 107.045 160.537 100.298 163.417C94.3462 165.959 89.7689 160.695 89.5691 160.461C88.9026 159.669 87.7206 159.567 86.9272 160.231C86.1318 160.897 86.0274 162.082 86.6931 162.877C86.7593 162.955 88.3356 164.819 90.9222 166.234C92.9121 167.323 94.9689 167.869 97.0205 167.869C98.6191 167.869 100.214 167.537 101.773 166.872C109.566 163.545 116.776 152.66 117.079 152.198C117.648 151.331 117.407 150.167 116.54 149.598C116.222 149.389 115.865 149.289 115.511 149.289C114.9 149.289 114.3 149.587 113.939 150.136Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask19_5794_68401)">
          <path
            d="M89.5436 170.643L93.6579 156.749C93.8525 156.091 94.5434 155.716 95.2008 155.911C95.8583 156.105 96.2332 156.796 96.0386 157.454L91.9243 171.349C91.7297 172.006 91.0389 172.381 90.3814 172.186C89.724 171.992 89.349 171.301 89.5436 170.643Z"
            fill="#336666"
          />
          <path
            d="M96.2588 171.916V157.425C96.2588 156.739 96.8145 156.184 97.5001 156.184C98.1857 156.184 98.7417 156.739 98.7417 157.425V171.916C98.7417 172.602 98.1857 173.157 97.5001 173.157C96.8145 173.157 96.2588 172.602 96.2588 171.916Z"
            fill="#336666"
          />
          <path
            d="M103.049 171.277L100.427 157.025C100.303 156.35 100.749 155.703 101.423 155.579C102.098 155.455 102.745 155.901 102.869 156.576L105.49 170.828C105.615 171.502 105.169 172.149 104.494 172.273C103.82 172.397 103.173 171.951 103.049 171.277Z"
            fill="#336666"
          />
        </g>
        <path
          d="M116.765 178.006H116.458V176.148C115.508 175.119 111.637 165.608 111.435 163.756L111.739 163.723C111.934 165.504 115.927 175.342 116.707 175.963L116.765 176.009V178.006Z"
          fill="#336666"
        />
        <path
          d="M162.228 149.766C162.2 149.758 159.413 148.953 156.59 149.194L156.558 148.82C159.453 148.571 162.305 149.397 162.333 149.406L162.228 149.766Z"
          fill="#336666"
        />
        <path
          d="M156.683 150.93L156.597 150.564C159.426 149.898 162.367 150.299 162.396 150.304L162.344 150.676C162.315 150.672 159.442 150.28 156.683 150.93Z"
          fill="#336666"
        />
        <path
          d="M156.844 152.65L156.707 152.3C159.413 151.237 162.38 151.215 162.41 151.215L162.411 151.591C162.382 151.591 159.482 151.613 156.844 152.65Z"
          fill="#336666"
        />
        <path d="M306.278 278.941H285.206V306.836H306.278V278.941Z" fill="#FFDA33" />
        <path
          d="M259.117 323.486L279.594 326.597L307.414 323.573L306.595 301.453L303.624 301.528C303.253 301.536 302.895 301.666 302.605 301.899L301.501 302.787C301.205 303.025 300.838 303.156 300.458 303.159L295.573 303.194C295.189 303.197 294.816 303.068 294.515 302.83L294.32 302.675C294.017 302.434 293.819 302.094 293.721 301.719C293.193 299.692 291.012 298.114 288.152 298.094C286.24 298.08 284.13 298.921 284.094 300.144C284.051 300.864 284.061 300.899 283.723 302.349C283.021 305.356 281.229 307.481 278.913 309.133C275.436 311.613 270.779 313.03 266.855 314.774C262.563 316.681 259.148 318.979 259.117 323.486Z"
          fill="#336666"
        />
        <mask
          id="mask20_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="259"
          y="298"
          width="49"
          height="29"
        >
          <path
            d="M284.092 300.145C284.048 300.864 284.059 300.899 283.721 302.349C283.019 305.356 281.226 307.48 278.91 309.133C275.434 311.613 270.777 313.03 266.853 314.774C262.56 316.681 259.146 318.979 259.114 323.486L279.591 326.597L307.412 323.573L306.593 301.454L303.621 301.528C303.25 301.536 302.892 301.667 302.603 301.899L301.499 302.787C301.203 303.025 300.835 303.156 300.455 303.159L295.571 303.194C295.187 303.197 294.813 303.068 294.513 302.83L294.317 302.675C294.014 302.435 293.817 302.094 293.719 301.719C293.19 299.692 291.009 298.114 288.15 298.094C288.134 298.094 288.119 298.094 288.104 298.094C286.204 298.094 284.128 298.93 284.092 300.145Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask20_5794_68401)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.83 304.023L280.763 304.921L288.732 305.509L288.798 304.611L280.83 304.023Z"
            fill="#FF6B1C"
          />
          <path
            d="M289.771 305.06C289.771 304.507 289.322 304.059 288.769 304.059C288.216 304.059 287.768 304.507 287.768 305.06C287.768 305.613 288.216 306.062 288.769 306.062C289.322 306.062 289.771 305.613 289.771 305.06Z"
            fill="#FF6B1C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M279.85 307.555L279.643 308.43L287.417 310.274L287.624 309.398L279.85 307.555Z"
            fill="#FF6B1C"
          />
          <path
            d="M288.505 309.996C288.593 309.45 288.221 308.937 287.675 308.849C287.129 308.761 286.615 309.133 286.527 309.679C286.44 310.225 286.811 310.739 287.357 310.826C287.904 310.914 288.417 310.543 288.505 309.996Z"
            fill="#FF6B1C"
          />
        </g>
        <path
          d="M279.062 291.547L306.941 291.285C303.537 201.497 309.494 205.382 309.689 197.571C309.951 187.1 310.687 178.88 304.47 171.07L280.894 171.393C278.211 188.375 279.417 274.751 279.062 291.547Z"
          fill="#336666"
        />
        <path
          d="M306.064 330.393H299.59C298.769 330.393 297.99 330.032 297.459 329.407L297.321 329.244C296.804 328.635 296.05 328.276 295.251 328.258L293.9 328.229C293.015 328.21 292.174 328.611 291.631 329.311C291.102 329.993 290.287 330.393 289.423 330.393H260.861C258.856 330.393 257.105 328.88 256.975 326.879C256.833 324.686 258.569 322.863 260.731 322.863H306.193C308.355 322.863 310.091 324.686 309.95 326.879C309.82 328.88 308.069 330.393 306.064 330.393Z"
          fill="#FF6B1C"
        />
        <path
          d="M278.102 286.487V292.072H307.595V286.487C307.595 286.487 278.102 286.051 278.102 286.487Z"
          fill="#FF6B1C"
        />
        <path
          d="M298.57 144.629L291.208 153.654L260.276 140.121L256.59 150.103C266.592 157.887 275.347 163.756 286.093 167.04C290.251 168.31 296.032 169.635 299.762 167.669C304.413 165.217 309.34 155.989 310.768 155.51L298.57 144.629Z"
          fill="#FFDA33"
        />
        <path
          d="M223.859 127.798L238.343 117.567L229.581 108.882L244.722 100.484L261.392 164.195L250.447 168.922L246.569 169.726L240.183 185.497L223.859 127.798Z"
          fill="#00CCFF"
        />
        <path d="M315.705 278.941H294.632V306.836H315.705V278.941Z" fill="#FFDA33" />
        <path
          d="M268.543 323.486L289.02 326.597L316.84 323.573L316.021 301.453L313.049 301.528C312.678 301.536 312.321 301.666 312.031 301.899L310.927 302.787C310.631 303.025 310.264 303.156 309.884 303.159L304.999 303.194C304.615 303.197 304.242 303.068 303.941 302.83L303.746 302.675C303.442 302.434 303.245 302.094 303.147 301.719C302.619 299.692 300.437 298.114 297.578 298.094C295.666 298.08 293.556 298.921 293.52 300.144C293.477 300.864 293.487 300.899 293.149 302.349C292.447 305.356 290.655 307.481 288.339 309.133C284.862 311.613 280.205 313.03 276.281 314.774C271.989 316.681 268.574 318.979 268.543 323.486Z"
          fill="#336666"
        />
        <mask
          id="mask21_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="268"
          y="298"
          width="49"
          height="29"
        >
          <path
            d="M293.518 300.145C293.474 300.864 293.484 300.899 293.146 302.349C292.444 305.356 290.652 307.48 288.336 309.133C284.86 311.613 280.202 313.03 276.279 314.774C271.986 316.681 268.571 318.979 268.54 323.486L289.017 326.597L316.838 323.573L316.018 301.454L313.047 301.528C312.676 301.536 312.318 301.667 312.028 301.899L310.925 302.787C310.629 303.025 310.261 303.156 309.881 303.159L304.997 303.194C304.613 303.197 304.239 303.068 303.938 302.83L303.743 302.675C303.439 302.435 303.242 302.094 303.145 301.719C302.616 299.692 300.435 298.114 297.575 298.094C297.56 298.094 297.545 298.094 297.53 298.094C295.629 298.094 293.554 298.93 293.518 300.145Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask21_5794_68401)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M290.255 304.023L290.189 304.921L298.157 305.509L298.223 304.611L290.255 304.023Z"
            fill="#FF6B1C"
          />
          <path
            d="M299.196 305.06C299.196 304.507 298.748 304.059 298.195 304.059C297.642 304.059 297.193 304.507 297.193 305.06C297.193 305.613 297.642 306.062 298.195 306.062C298.748 306.062 299.196 305.613 299.196 305.06Z"
            fill="#FF6B1C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M289.276 307.555L289.068 308.43L296.842 310.274L297.05 309.398L289.276 307.555Z"
            fill="#FF6B1C"
          />
          <path
            d="M297.93 309.996C298.018 309.45 297.647 308.937 297.101 308.849C296.555 308.761 296.041 309.133 295.953 309.679C295.866 310.225 296.237 310.739 296.783 310.826C297.329 310.914 297.843 310.543 297.93 309.996Z"
            fill="#FF6B1C"
          />
        </g>
        <path
          d="M288.478 291.547L316.357 291.285C312.954 201.497 318.91 205.382 319.105 197.571C319.367 187.1 320.103 178.88 313.886 171.07H284.187C281.505 188.052 288.834 274.75 288.478 291.548"
          fill="#FF6B1C"
        />
        <path
          d="M271.244 144.993L290.418 161.162L305.466 138.287L290.948 123.566C283.274 130.324 276.779 135.62 271.244 144.993Z"
          fill="#336666"
        />
        <path
          d="M290.361 123.681C291.312 122.966 301.587 122.045 305.511 122.814C309.436 123.583 309.436 130.051 309.436 130.051L288.05 130.769C288.05 130.769 289.411 124.395 290.361 123.681Z"
          fill="#FFDA33"
        />
        <path d="M290.941 106.799L290.118 127.971L306.42 125.617L301.309 104.906L290.941 106.799Z" fill="#FFDA33" />
        <mask
          id="mask22_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="290"
          y="104"
          width="17"
          height="24"
        >
          <path d="M290.941 106.799L290.118 127.971L306.421 125.617L301.31 104.906L290.941 106.799Z" fill="white" />
        </mask>
        <g mask="url(#mask22_5794_68401)">
          <path d="M289.648 119.311L296.755 115.901L295.907 112.348L289.384 113.408L289.648 119.311Z" fill="#336666" />
        </g>
        <path
          d="M315.052 172.367L281.672 174.536L279.361 152.205C279.361 152.205 277.299 144.348 278.46 139.518C279.826 133.839 290.357 123.679 290.357 123.679C290.357 123.679 289.703 126.654 297.87 126.152C306.037 125.65 305.507 122.812 305.507 122.812C321.38 125.365 316.454 150.805 315.052 172.367Z"
          fill="white"
        />
        <path d="M280.727 86.6449L281.399 85.6367L290.096 90.9372L289.186 92.9433L280.727 86.6449Z" fill="#A5F4F7" />
        <path d="M286.833 99.8047L281.339 101.651L285.404 104.929L286.833 99.8047Z" fill="#FF6B1C" />
        <path
          d="M288.678 93.1673C287.685 94.2167 286.728 97.5817 285.612 101.064C284.225 105.387 282.856 110.017 283.973 112.111C284.903 113.855 288.103 114.733 291.281 115.173C294.993 115.687 299.095 113.942 299.095 113.942L303.904 95.6244C303.904 95.6244 290.904 90.8153 288.678 93.1673Z"
          fill="#FFDA33"
        />
        <mask
          id="mask23_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="283"
          y="92"
          width="21"
          height="24"
        >
          <path
            d="M288.686 93.1674C287.692 94.2169 286.736 97.5818 285.619 101.064C284.234 105.387 282.865 110.017 283.982 112.111C284.911 113.855 288.111 114.733 291.289 115.173C295.001 115.686 299.104 113.942 299.104 113.942L303.912 95.6245C303.912 95.6245 295.519 92.5199 291.066 92.5195C289.977 92.5195 289.124 92.7054 288.686 93.1674Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask23_5794_68401)">
          <path
            d="M283.883 106.583C283.071 106.583 282.274 106.462 281.5 106.22L281.737 105.463C283.247 105.936 284.854 105.896 286.516 105.344L286.766 106.096C285.788 106.421 284.824 106.583 283.883 106.583Z"
            fill="#336666"
          />
        </g>
        <path
          d="M286.348 93.9667L299.614 100.468L298.977 104.769L301.462 105.39L302.819 111.632C305.806 110.405 307.824 107.499 308 101.401L297.835 90.7535L289.855 90.6484C288.135 91.214 287.051 92.4041 286.348 93.9667Z"
          fill="#336666"
        />
        <path
          d="M297.314 105.891C297.314 104.157 295.908 102.75 294.173 102.75C292.438 102.75 291.032 104.157 291.032 105.891C291.032 107.626 292.438 109.032 294.173 109.032C295.908 109.032 297.314 107.626 297.314 105.891Z"
          fill="#FF6B1C"
        />
        <path
          d="M286.956 101.428C289.14 102.907 291.492 102.779 293.171 100.296C290.446 98.6032 287.882 99.1833 286.956 101.428Z"
          fill="white"
        />
        <mask
          id="mask24_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="286"
          y="99"
          width="8"
          height="4"
        >
          <path
            d="M286.956 101.427C289.141 102.908 291.491 102.78 293.17 100.296C292.099 99.6308 291.053 99.3168 290.122 99.3164C288.683 99.3164 287.518 100.065 286.956 101.427Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask24_5794_68401)">
          <path
            d="M285.815 99.5565C285.943 98.1687 287.173 97.1478 288.561 97.2766C289.948 97.405 290.969 98.6348 290.841 100.022C290.712 101.41 289.483 102.431 288.095 102.302C286.707 102.174 285.686 100.944 285.815 99.5565Z"
            fill="#336666"
          />
        </g>
        <path
          d="M289.087 95.9883L288.549 97.2203C290.392 97.6028 292.163 98.3062 293.911 99.1123L294.189 98.5047C292.972 97.3714 291.268 96.5339 289.087 95.9883Z"
          fill="#336666"
        />
        <path
          d="M303.162 110.032H299.477L301.577 103.543H303.162C304.954 103.543 306.407 104.995 306.407 106.787C306.407 108.579 304.954 110.032 303.162 110.032Z"
          fill="#FFDA33"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M303.791 105.871L301.355 106.606L301.584 107.365L304.02 106.63L303.791 105.871Z"
          fill="#336666"
        />
        <path
          d="M308.005 101.766L289.648 90.661C289.648 90.661 295.706 86.9078 301.242 89.7226C311.07 94.7211 308.005 101.766 308.005 101.766Z"
          fill="#A5F4F7"
        />
        <mask
          id="mask25_5794_68401"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="289"
          y="88"
          width="20"
          height="14"
        >
          <path
            d="M289.647 90.6613L308.004 101.766C308.004 101.766 311.07 94.721 301.241 89.7229C299.731 88.9548 298.183 88.6758 296.729 88.6758C292.852 88.6763 289.647 90.6613 289.647 90.6613Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask25_5794_68401)">
          <path
            d="M310.23 101.669L305.371 98.6402C304.988 98.4012 304.871 97.8971 305.11 97.5139C305.349 97.1311 305.854 97.0138 306.237 97.2528L311.094 100.282C311.478 100.521 311.595 101.025 311.356 101.408C311.117 101.791 310.613 101.908 310.23 101.669Z"
            fill="#336666"
          />
        </g>
        <path
          d="M321.909 113.213C321.909 108.485 318.076 104.652 313.348 104.652C308.62 104.652 304.787 108.485 304.787 113.213C304.787 117.942 308.62 121.775 313.348 121.775C318.076 121.775 321.909 117.942 321.909 113.213Z"
          fill="#336666"
        />
        <path
          d="M296.572 101.689C296.572 101.511 296.428 101.367 296.25 101.367C296.072 101.367 295.928 101.511 295.928 101.689C295.928 101.867 296.072 102.012 296.25 102.012C296.428 102.012 296.572 101.867 296.572 101.689Z"
          fill="#336666"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288.278 95.8164L288.04 97.1068L288.467 97.1856L288.705 95.8953L288.278 95.8164Z"
          fill="#336666"
        />
        <path d="M229.623 108.832L236.255 139.896L243.759 137.278L238.341 117.566L229.623 108.832Z" fill="#FF6B1C" />
        <path d="M223.859 127.798L238.343 117.566L254.722 173.599L240.183 185.497L223.859 127.798Z" fill="white" />
        <path d="M223.859 127.797L237.828 136.58L250.655 184.222L240.183 185.496L223.859 127.797Z" fill="#00CCFF" />
        <path
          d="M253.116 161.779C253.116 161.779 253.13 163.76 252.444 167.436C251.758 171.112 246.044 171.564 241.745 170.605C237.446 169.646 233.641 168.168 233.641 168.168L233.916 166.133L240.85 166.957L240.871 166.623L231.644 164.908L231.815 162.952L241.276 163.587L241.334 163.2L230.936 161.777L231.286 159.186L241.766 159.89L241.822 159.591L232.179 158.341L232.473 156.247L245.791 156.814L246.085 156.454L243.042 155.985L242.148 152.592C242.148 152.592 245.607 152.477 248.343 153.771C251.225 155.133 253.386 157.998 253.116 161.779Z"
          fill="#FFDA33"
        />
        <path
          d="M289.571 149.754L285.776 158.392L251.711 157.056L251.222 167.686C263.111 172.075 273.232 175.016 284.468 174.891C288.816 174.842 294.726 174.354 297.686 171.35C301.376 167.605 303.277 157.318 304.492 156.429L289.571 149.754Z"
          fill="#FFDA33"
        />
        <path
          d="M257.567 149.337C260.621 143.666 261.867 141.729 260.645 138.588C259.424 135.446 256.893 131.52 255.497 130.735C254.101 129.95 246.597 127.855 246.597 127.855L245.375 130.473L252.879 136.406L257.567 149.337Z"
          fill="#FFDA33"
        />
        <path
          d="M315.49 330.393H309.015C308.195 330.393 307.416 330.032 306.885 329.407L306.746 329.244C306.229 328.635 305.476 328.276 304.677 328.258L303.326 328.229C302.441 328.21 301.599 328.611 301.057 329.311C300.527 329.993 299.712 330.393 298.848 330.393H270.287C268.281 330.393 266.53 328.88 266.401 326.879C266.258 324.686 267.995 322.863 270.157 322.863H315.619C317.781 322.863 319.517 324.686 319.375 326.879C319.246 328.88 317.495 330.393 315.49 330.393Z"
          fill="#FF6B1C"
        />
        <path
          d="M287.517 286.487V292.072H317.01V286.487C317.01 286.487 287.517 286.051 287.517 286.487Z"
          fill="#336666"
        />
        <path
          d="M268.343 323.487C268.217 316.88 274.641 314.74 280.31 312.853C280.688 312.727 281.064 312.601 281.435 312.476C286.938 310.615 289.373 308.287 291.031 306.256C292.094 304.953 293.314 300.142 293.326 300.094L293.71 300.19C293.659 300.392 292.456 305.135 291.337 306.506C290.313 307.762 287.915 310.703 281.562 312.851C281.19 312.977 280.814 313.102 280.435 313.229C274.896 315.073 268.619 317.163 268.739 323.48L268.343 323.487Z"
          fill="#FFDA33"
        />
        <path
          d="M286.89 152.94L310.394 160.466C312.697 152.863 314.696 145.439 316.181 138.316C317.189 133.484 314.547 128.634 309.947 126.842C305.547 125.128 300.552 126.692 297.9 130.599C292.524 138.519 289.507 144.302 286.89 152.94Z"
          fill="#336666"
        />
        <path
          d="M179.138 294.82L187.322 292.161L183.968 281.835L175.783 284.495L180.842 277.533L172.058 271.151L167 278.113V269.508H156.143V278.113L151.086 271.151L142.302 277.533L147.36 284.495L139.177 281.835L135.821 292.161L144.005 294.82H179.138Z"
          fill="#FF6B1C"
        />
        <path
          d="M135.605 189.273H224.823C224.823 189.273 225.441 180.8 220.672 175.357C215.902 169.914 211.469 171.429 209.056 169.97C206.643 168.511 201.369 159.982 191.072 160.431C180.776 160.88 169.834 169.017 166.13 170.98C162.427 172.944 153.673 171.317 147.613 175.694C141.554 180.07 135.605 189.273 135.605 189.273Z"
          fill="#336666"
        />
        <path
          d="M308.052 192.992C296.821 191.922 296.958 179.106 296.96 178.977L297.753 178.993C297.743 179.491 297.632 191.203 308.127 192.203L308.052 192.992Z"
          fill="#336666"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M266.298 157.152L266.264 157.944L285.757 158.789L285.792 157.997L266.298 157.152Z"
          fill="#336666"
        />
      </g>
      <defs>
        <clipPath id="clip0_5794_68401">
          <rect width="374" height="360" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
