import { Transition } from '@headlessui/react'

export default function VerticalReveal({ open, children }) {
  return (
    <Transition
      show={open}
      appear={true}
      enter="in-out-cubic duration-100"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 connected-max-height"
      leave="out-expo duration-100"
      leaveFrom="opacity-100 connected-max-height"
      leaveTo="opacity-0 max-h-0"
    >
      {children}
    </Transition>
  )
}
