import React, { useEffect, useState } from 'react'

import { modalRoutes } from '@/config'
import useOnlineStatus from '@/hooks/useOnlineStatus'

import Modal from './CustomModal'

type Props = {
  page: JSX.Element
  label?: string
  pathname?: string
}

const PageManager = ({ page, pathname }: Props): JSX.Element => {
  const [stack, setStack] = useState([{ page, pathname }])
  useOnlineStatus()
  useEffect(() => {
    const existingIndex = stack.findIndex((item) => item.pathname === pathname)
    // If the new route do not exist in the stack
    if (existingIndex === -1) {
      // Checks if the new route should be displayed as a modal
      if (modalRoutes.reduce((base, current) => (new RegExp(current).test(pathname) ? 1 : 0) + base, 0) > 0) {
        setStack([...stack, { page, pathname }])
      } else {
        setStack([{ page, pathname }])
      }
    } else {
      // Remove all routes on top of the existing route
      const newStack = [...stack]
      newStack.length = existingIndex + 1
      setStack(newStack)
    }
  }, [page, pathname])

  return (
    <>
      {stack.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {index === 0 ? (
              <div aria-hidden={stack.length > index}>{item.page}</div>
            ) : (
              <Modal
                maxWidth={1000}
                open
                onClose={() => window.history.back()}
                closeOnOutsideClick={false}
                showCloseButton={true}
              >
                {React.cloneElement(item.page, {
                  modal: true,
                  onClose: () => window.history.back(),
                })}
              </Modal>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default PageManager
