import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Comment = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 15 15" {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 0C3.35742 0 0 3.11719 0 6.96429C0 8.625 0.626953 10.1451 1.66992 11.3404C1.30371 13.0279 0.0791016 14.5312 0.0644531 14.548C0 14.625 -0.0175781 14.7388 0.0205078 14.8393C0.0585938 14.9397 0.140625 15 0.234375 15C2.17676 15 3.63281 13.9353 4.35352 13.279C5.31152 13.6908 6.375 13.9286 7.5 13.9286C11.6426 13.9286 15 10.8114 15 6.96429C15 3.11719 11.6426 0 7.5 0Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  )
}

export default Comment
