type Props = {
  width?: number
  height?: number
}

const SunetLogo = ({ width, height }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 715 212" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2948_3706)">
        <path
          d="M106.935 117.163C106.935 108.19 94.8485 88.8072 88.6206 79.4084C86.9058 76.8202 83.0938 76.8202 81.3791 79.4084C75.1511 88.8072 63.0649 108.196 63.0649 117.163C63.0649 129.245 72.8882 139.045 84.9998 139.045C97.1115 139.045 106.935 129.245 106.935 117.163Z"
          fill="#1AC5E9"
        />
        <path
          d="M78.141 155.464C78.141 153.556 76.8979 151.852 75.062 151.318C60.2221 147.013 49.3407 133.334 49.3407 117.157C49.3407 111.898 50.8068 103.389 62.2173 84.1208C67.9162 74.493 73.5513 66.5186 73.7871 66.1816L77.3441 61.1579C77.8605 60.4266 78.141 59.5554 78.141 58.6587V9.34351C78.141 5.62977 73.7616 3.63936 70.9505 6.06855L7.49802 60.9925C6.54821 61.8129 6 63.0084 6 64.2675V154.898C6 156.227 6.60558 157.48 7.65101 158.3L58.826 198.611C63.0205 201.911 67.6867 204.315 72.5632 205.809C75.3362 206.662 78.1346 204.544 78.1346 201.651V155.464H78.141Z"
          fill="#38C16F"
        />
        <path
          d="M91.8589 9.34339V58.665C91.8589 59.5616 92.1394 60.4328 92.6557 61.1641L96.2127 66.1878C96.4486 66.5249 102.084 74.4992 107.783 84.127C119.193 103.395 120.659 111.904 120.659 117.163C120.659 133.334 109.778 147.019 94.9378 151.324C93.1019 151.858 91.8589 153.563 91.8589 155.47V201.657C91.8589 204.55 94.6573 206.668 97.4303 205.816C102.307 204.321 106.973 201.918 111.167 198.617L162.342 158.306C163.388 157.486 163.993 156.233 163.993 154.904V64.261C163.993 63.0083 163.445 61.8127 162.495 60.986L99.0494 6.06843C96.2382 3.63287 91.8589 5.62965 91.8589 9.34339Z"
          fill="#FED444"
        />
      </g>
      <path
        d="M689.27 162.223C692.525 162.223 695.682 161.897 698.742 161.246C701.867 160.595 704.96 159.781 708.02 158.805V180.973C704.829 182.405 700.858 183.577 696.105 184.488C691.418 185.465 686.275 185.953 680.676 185.953C674.165 185.953 668.306 184.911 663.098 182.828C657.954 180.68 653.885 177.001 650.891 171.793C647.961 166.52 646.496 159.195 646.496 149.82V97.1836H632.238V84.5859L648.645 74.625L657.238 51.5781H676.281V74.8203H706.848V97.1836H676.281V149.82C676.281 153.987 677.453 157.112 679.797 159.195C682.206 161.214 685.363 162.223 689.27 162.223Z"
        fill="#FED444"
      />
      <path
        d="M584.129 72.7695C594.22 72.7695 602.911 74.7227 610.203 78.6289C617.495 82.4701 623.126 88.069 627.098 95.4258C631.069 102.783 633.055 111.767 633.055 122.379V136.832H562.645C562.97 145.23 565.477 151.839 570.164 156.656C574.917 161.409 581.492 163.785 589.891 163.785C596.857 163.785 603.237 163.069 609.031 161.637C614.826 160.204 620.783 158.056 626.902 155.191V178.238C621.499 180.908 615.835 182.861 609.91 184.098C604.051 185.335 596.922 185.953 588.523 185.953C577.586 185.953 567.885 183.935 559.422 179.898C551.023 175.862 544.415 169.71 539.598 161.441C534.845 153.173 532.469 142.757 532.469 130.191C532.469 117.431 534.617 106.819 538.914 98.3555C543.276 89.8268 549.331 83.4466 557.078 79.2148C564.826 74.918 573.842 72.7695 584.129 72.7695ZM584.324 93.9609C578.53 93.9609 573.712 95.8164 569.871 99.5273C566.095 103.238 563.914 109.065 563.328 117.008H605.125C605.06 112.581 604.246 108.642 602.684 105.191C601.186 101.741 598.908 99.0065 595.848 96.9883C592.853 94.9701 589.012 93.9609 584.324 93.9609Z"
        fill="#FED444"
      />
      <path
        d="M483.676 72.7695C495.329 72.7695 504.704 75.9596 511.801 82.3398C518.897 88.6549 522.445 98.8112 522.445 112.809V184H492.66V120.23C492.66 112.418 491.228 106.526 488.363 102.555C485.564 98.5833 481.137 96.5977 475.082 96.5977C465.967 96.5977 459.75 99.6901 456.43 105.875C453.109 112.06 451.449 120.979 451.449 132.633V184H421.664V74.8203H444.418L448.422 88.7852H450.082C452.426 85.0091 455.323 81.9492 458.773 79.6055C462.289 77.2617 466.163 75.5365 470.395 74.4297C474.691 73.3229 479.118 72.7695 483.676 72.7695Z"
        fill="#1AC5E9"
      />
      <path
        d="M404.414 74.8203V184H381.562L377.559 170.035H375.996C373.652 173.746 370.723 176.773 367.207 179.117C363.691 181.461 359.818 183.186 355.586 184.293C351.354 185.4 346.96 185.953 342.402 185.953C334.59 185.953 327.786 184.586 321.992 181.852C316.198 179.052 311.673 174.723 308.418 168.863C305.228 163.004 303.633 155.387 303.633 146.012V74.8203H333.418V138.59C333.418 146.402 334.818 152.294 337.617 156.266C340.417 160.237 344.876 162.223 350.996 162.223C357.051 162.223 361.803 160.855 365.254 158.121C368.704 155.322 371.113 151.253 372.48 145.914C373.913 140.51 374.629 133.935 374.629 126.188V74.8203H404.414Z"
        fill="#38C16F"
      />
      <path
        d="M294.684 144.352C294.684 152.815 292.633 160.172 288.531 166.422C284.43 172.672 278.44 177.49 270.562 180.875C262.75 184.26 253.245 185.953 242.047 185.953C237.099 185.953 232.249 185.628 227.496 184.977C222.809 184.326 218.284 183.382 213.922 182.145C209.625 180.842 205.523 179.247 201.617 177.359V149.234C208.388 152.229 215.419 154.931 222.711 157.34C230.003 159.749 237.229 160.953 244.391 160.953C249.339 160.953 253.31 160.302 256.305 159C259.365 157.698 261.578 155.908 262.945 153.629C264.312 151.35 264.996 148.746 264.996 145.816C264.996 142.236 263.792 139.176 261.383 136.637C258.974 134.098 255.654 131.721 251.422 129.508C247.255 127.294 242.535 124.918 237.262 122.379C233.941 120.816 230.328 118.928 226.422 116.715C222.516 114.436 218.805 111.669 215.289 108.414C211.773 105.159 208.876 101.22 206.598 96.5977C204.384 91.9102 203.277 86.3112 203.277 79.8008C203.277 71.2721 205.23 63.9805 209.137 57.9258C213.043 51.8711 218.609 47.2487 225.836 44.0586C233.128 40.8034 241.721 39.1758 251.617 39.1758C259.039 39.1758 266.103 40.0547 272.809 41.8125C279.579 43.5052 286.643 45.9792 294 49.2344L284.234 72.7695C277.659 70.1003 271.767 68.0495 266.559 66.6172C261.35 65.1198 256.044 64.3711 250.641 64.3711C246.865 64.3711 243.642 64.9896 240.973 66.2266C238.303 67.3984 236.285 69.0911 234.918 71.3047C233.551 73.4531 232.867 75.9596 232.867 78.8242C232.867 82.2096 233.844 85.0742 235.797 87.418C237.815 89.6966 240.81 91.9102 244.781 94.0586C248.818 96.207 253.831 98.7135 259.82 101.578C267.112 105.029 273.329 108.642 278.473 112.418C283.681 116.129 287.685 120.523 290.484 125.602C293.284 130.615 294.684 136.865 294.684 144.352Z"
        fill="#38C16F"
      />
      <defs>
        <clipPath id="clip0_2948_3706">
          <rect width="158" height="201" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SunetLogo
