import clsx from 'clsx'
import React from 'react'
import Select, { OnChangeValue } from 'react-select'

import { DefaultOption } from '@/ts/types/general.types'

type Props = {
  selected?: string
  options: DefaultOption[]
  className?: string
  placeholder?: string
  onChange: (val: string) => void
  isModal?: boolean
}

const SelectInput = ({
  selected,
  options,
  className,
  placeholder,
  onChange,
  isModal = false,
  ...rest
}: Props): JSX.Element => {
  const onSelectChange = (selectedOptions: OnChangeValue<DefaultOption, false>) => {
    onChange(selectedOptions?.value || '')
  }

  const index = options.findIndex((o) => o.value === selected)

  return (
    index !== undefined && (
      <Select
        menuPosition={isModal ? 'absolute' : 'fixed'}
        defaultValue={options[Math.max(index, 0)]}
        options={options}
        onChange={onSelectChange}
        placeholder={placeholder}
        className={clsx('w-full', className)}
        {...rest}
      />
    )
  )
}

export default SelectInput
