import clsx from 'clsx'
import React, { CSSProperties, ReactNode } from 'react'

import Spinner from './Spinner'

type Props = {
  children?: ReactNode
  loading?: boolean
  style?: CSSProperties
  className?: string
  label?: string
}

const LoadingWrapper = ({ children, loading, style, className, label }: Props): JSX.Element => {
  return (
    <div className={clsx('relative', className)} style={style}>
      {children}
      {loading ? (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 z-30 flex items-center justify-center fade-in">
          {label ? <p className="text-gray-700"> {label} </p> : null}
          <Spinner className="text-primary" size={30} />
        </div>
      ) : null}
    </div>
  )
}

export default LoadingWrapper
