import clsx from 'clsx'
import { motion } from 'framer-motion'

export const StorieAnimatedBar = ({ onEnd, key }) => {
  return (
    <div className="bg-transparent w-full overflow-hidden">
      <motion.div
        key={key}
        className={clsx('h-1 bg-white')}
        initial={{ width: 0 }}
        animate={{ width: `${100}%` }}
        transition={{ duration: 6 }}
        onAnimationComplete={onEnd}
      />
    </div>
  )
}
