import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import useComponentWillMount from '../../hooks/useComponentWillMount'
import { activate } from '../../utils/i18n'

const LanguageContext = createContext({ enable: () => null })

export const useLanguage = () => useContext(LanguageContext)

export const globalI18n = { locale: 'es' }

type Props = {
  /**
   * Children
   */
  children?: ReactNode | string
  initialLocale: any
}

const LanguageProvider = ({ children, initialLocale }: Props) => {
  const [locale, setLocale] = useState(initialLocale)

  useComponentWillMount(() => {
    activate(initialLocale)
    globalI18n.locale = initialLocale
  })

  useEffect(() => {
    activate(initialLocale)
    setLocale(initialLocale)
    globalI18n.locale = initialLocale
  }, [])

  const enable = (locale: any) => {
    activate(locale)
    setTimeout(() => location.reload(), 200)
  }

  return (
    <LanguageContext.Provider value={{ locale, enable }}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
