import clsx from 'clsx'
import { ChangeEventHandler, forwardRef, KeyboardEventHandler, ReactNode, useEffect, useRef } from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { FaTimes } from 'react-icons/fa'

import Button, { ButtonAppearance, ButtonSize } from './Button'
import CustomText, { FontWeight, TextSize } from './CustomText'
type Props = {
  label?: string
  type?: string
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  value?: string
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
  enableError?: boolean
  disabled?: boolean
  help?: string
  renderAccessory?: () => ReactNode
  lightTheme?: boolean
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
}

const TextArea = forwardRef<HTMLTextAreaElement, Props>(function TextInput(
  { label, placeholder, disabled, enableError = true, error, help, renderAccessory, ...rest },
  ref
) {
  const innerRef = useRef<HTMLTextAreaElement>(null)

  const resetInput = () => {
    if (innerRef.current && Object.getOwnPropertyDescriptor) {
      // Be careful, this method of clearing the input is anti-pattern.
      // Reference: https://stackoverflow.com/a/46012210
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
      if (nativeInputValueSetter && nativeInputValueSetter.set) {
        nativeInputValueSetter.set.call(innerRef.current, '')
      }

      innerRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
  }

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(innerRef.current)
      } else {
        ref.current = innerRef.current
      }
    }
  }, [ref, innerRef])

  return (
    <div className="w-full">
      {label ? (
        <CustomText className="mb-2" size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
          {label}
        </CustomText>
      ) : null}

      <div className="relative w-full">
        <textarea
          ref={innerRef}
          placeholder={placeholder}
          disabled={disabled}
          className={clsx(
            'rounded-lg bg-white border pl-4 py-3 pr-8 text-md outline-none w-full box-border',
            error ? 'border-red-600' : 'border-gray-1',
            'disabled:pointer-events-none disabled:opacity-50',
            'focus:border-primary',
            'hover:border-primary-active'
          )}
          {...rest}
        />
        {rest.value || renderAccessory ? (
          <div className="absolute top-0 bottom-0 right-0 flex items-center justify-start w-8 ">
            {renderAccessory ? (
              renderAccessory()
            ) : (
              <Button
                onClick={resetInput}
                icon={() => <FaTimes />}
                size={ButtonSize.Small}
                appearance={ButtonAppearance.PrimaryOutline}
              />
            )}
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-between">
        {enableError ? (
          <CustomText
            size={TextSize.Caption}
            weight={FontWeight.Normal}
            className={clsx('block text-red mb-2 truncate', error ? 'opacity-100' : 'opacity-0')}
          >
            {(error as ReactNode) || 'error'}
          </CustomText>
        ) : null}

        {help ? (
          <CustomText size={TextSize.Caption} weight={FontWeight.Normal} className="block text-gray mb-2 truncate">
            {help}
          </CustomText>
        ) : null}
      </div>
    </div>
  )
})

export default TextArea
