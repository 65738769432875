import { SvgIcon } from '../../CustomIcon'

const Ranking = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80502 20.0693V28.8396H1.95561C1.39193 28.8396 0.935059 28.3825 0.935059 27.8188C0.935059 26.1312 0.935059 22.7777 0.935059 21.0901C0.935059 20.5265 1.39193 20.0693 1.95561 20.0693C4.08515 20.0693 8.80502 20.0693 8.80502 20.0693Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8811 14.9502C18.445 14.9502 18.9019 15.4073 18.9019 15.971C18.9019 19.1747 18.9019 28.819 18.9019 28.819H8.80957V15.971C8.80957 15.4073 9.26669 14.9502 9.83037 14.9502C11.7654 14.9502 15.9463 14.9502 17.8811 14.9502Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.044 22.5684C26.3147 22.5684 26.5744 22.6759 26.7659 22.8675C26.9572 23.0588 27.0648 23.3185 27.0648 23.5892C27.0648 24.7572 27.0648 26.6591 27.0648 27.8272C27.0648 28.0979 26.9572 28.3575 26.7659 28.5489C26.5744 28.7404 26.3147 28.848 26.044 28.848C23.9147 28.848 19.1948 28.848 19.1948 28.848V22.5684H26.044Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9893 1.63494C13.1755 1.33436 13.5037 1.15137 13.8572 1.15137C14.2107 1.15137 14.5392 1.33436 14.7252 1.63494C15.3278 2.60846 16.0225 3.73141 16.0225 3.73141C16.0225 3.73141 17.2849 3.99328 18.423 4.22919C18.7848 4.3044 19.0778 4.56897 19.1891 4.92124C19.3003 5.27351 19.2126 5.65836 18.9597 5.92758C18.1947 6.74211 17.3608 7.62989 17.3608 7.62989C17.3608 7.62989 17.4889 8.85867 17.6038 9.96006C17.6413 10.3177 17.4875 10.6688 17.1991 10.8841C16.9108 11.0992 16.5304 11.1467 16.1979 11.0088C15.1085 10.5575 13.8572 10.0392 13.8572 10.0392C13.8572 10.0392 12.6059 10.5575 11.5165 11.0088C11.1841 11.1467 10.8039 11.0992 10.5156 10.8841C10.227 10.6688 10.0734 10.3177 10.1106 9.96006C10.2255 8.85867 10.3537 7.62989 10.3537 7.62989C10.3537 7.62989 9.52001 6.74211 8.75497 5.92758C8.50191 5.65836 8.41421 5.27351 8.52542 4.92124C8.63689 4.56897 8.92986 4.3044 9.29144 4.22919C10.4296 3.99328 11.6919 3.73141 11.6919 3.73141C11.6919 3.73141 12.3869 2.60846 12.9893 1.63494Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Ranking
