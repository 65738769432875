import { useEffect } from 'react'

import { useAuthStore } from '@/dataStores/auth'
import { createFileByUserUUID } from '@/services/fileService'
import { handleError } from '@/utils/alerts'

import useRequest from './useRequest'

type ReturnProps = {
  saveFile: (file: File) => Promise<unknown>
  loading?: boolean
}

const useFileUploader = (): ReturnProps => {
  const [user] = useAuthStore((state) => [state.user])

  const { exec, error: errorFile, loading } = useRequest(createFileByUserUUID)

  useEffect(() => {
    errorFile && handleError(errorFile, 'files')
  }, [errorFile])

  const saveFile = async (file: File) => {
    return await exec(user?.UUID, file)
  }

  return { saveFile, loading }
}

export default useFileUploader
