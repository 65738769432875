export const Slide4 = () => {
  return (
    <svg width="374" height="777" viewBox="0 0 374 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="374" height="777" fill="#CAEFFF" />
      <g clipPath="url(#clip0_5794_68402)">
        <path
          d="M411.53 122.024H166.138C166.138 122.024 198.521 75.6929 243.953 59.1393C286.624 43.5908 318.412 5.58373 343.808 11.6302C364.8 16.6283 363.614 52.8929 368.858 57.2389C374.102 61.585 391.144 70.5413 400.128 83.1528C409.111 95.7641 411.53 122.024 411.53 122.024"
          fill="#336666"
        />
        <mask
          id="mask0_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="166"
          y="10"
          width="246"
          height="113"
        >
          <path
            d="M243.955 59.1389C198.523 75.6925 166.14 122.023 166.14 122.023H411.531C411.531 122.023 409.113 95.7641 400.129 83.1523C391.146 70.5408 374.104 61.5845 368.86 57.2385C363.616 52.8925 364.802 16.6277 343.81 11.6301C341.965 11.191 340.089 10.9839 338.175 10.9844C313.741 10.9862 283.527 44.7199 243.955 59.1389Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5794_68402)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M307.973 4.39062L307.089 5.39662L335.594 30.4467L336.478 29.4407L307.973 4.39062Z"
            fill="#33CC66"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M301.187 12.3516L300.303 13.3576L328.808 38.4077L329.692 37.4016L301.187 12.3516Z"
            fill="#33CC66"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.399 20.3086L293.515 21.3144L322.02 46.3647L322.904 45.3589L294.399 20.3086Z"
            fill="#33CC66"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M287.612 28.2695L286.729 29.2753L315.234 54.3256L316.118 53.3198L287.612 28.2695Z"
            fill="#33CC66"
          />
          <path
            d="M341.244 123.938L337.202 121.151C343.303 112.302 352.224 105.4 362.322 101.717C363.26 101.376 364.211 101.059 365.132 100.753C367.919 99.8247 370.552 98.9487 372.747 97.4388C375.161 95.7786 377.215 92.6827 376.47 89.9763C375.891 87.8734 373.632 86.0467 370.428 85.0898C368.986 84.6591 367.477 84.3896 365.879 84.1044C363.962 83.7616 361.979 83.4077 360.019 82.7445C355.154 81.0986 351.862 77.7308 351.214 73.7352C350.558 69.6909 352.641 66.1235 354.765 63.1672C360.788 54.7854 368.573 47.4237 377.278 41.8789L379.915 46.0193C371.732 51.232 364.414 58.1523 358.752 66.032C357.214 68.1734 355.691 70.6695 356.06 72.9492C356.475 75.5029 359.189 77.2814 361.592 78.0941C363.204 78.6394 364.923 78.9465 366.742 79.2714C368.405 79.5682 370.124 79.8753 371.833 80.3859C376.741 81.8515 380.156 84.872 381.203 88.6731C382.628 93.8475 379.38 98.8358 375.529 101.484C372.762 103.387 369.671 104.416 366.682 105.411C365.753 105.72 364.876 106.011 364.004 106.329C354.854 109.666 346.772 115.919 341.244 123.938Z"
            fill="white"
          />
        </g>
        <path
          d="M190.651 68.0447C190.245 61.4935 184.808 56.224 178.248 56.0071C176.211 55.9402 174.278 56.3468 172.546 57.1252C169.455 58.5154 165.988 58.613 162.834 57.4509C161.184 56.5919 159.337 56.0718 157.385 56.0071C157.201 56.0014 157.022 56.0192 156.84 56.0205C156.656 56.0192 156.477 56.0009 156.292 56.0071C154.34 56.0714 152.492 56.5918 150.842 57.4514C147.689 58.6136 144.221 58.5154 141.13 57.1247C139.399 56.3468 137.466 55.9402 135.43 56.0071C128.87 56.2236 123.432 61.4922 123.025 68.0437C122.562 75.5201 128.488 81.7282 135.865 81.7282C137.828 81.7282 139.688 81.288 141.353 80.5012C144.2 79.1543 147.492 79.0213 150.434 80.084C152.294 81.1296 154.44 81.7282 156.727 81.7282C156.765 81.7282 156.801 81.7223 156.838 81.7223C156.876 81.7223 156.912 81.7282 156.95 81.7282C159.237 81.7282 161.382 81.1296 163.243 80.084C166.185 79.0213 169.477 79.1543 172.324 80.5012C173.989 81.288 175.849 81.7282 177.812 81.7282C185.188 81.7282 191.114 75.5205 190.651 68.0447Z"
          fill="white"
        />
        <path
          d="M291.651 20.0446C291.245 13.4934 285.808 8.22404 279.248 8.00714C277.211 7.94019 275.278 8.34668 273.546 9.12506C270.455 10.5153 266.988 10.613 263.834 9.45089C262.184 8.59175 260.337 8.07183 258.386 8.00714C258.201 8.00124 258.022 8.0187 257.84 8.02052C257.656 8.0187 257.477 8.00082 257.292 8.00714C255.34 8.0714 253.493 8.59175 251.842 9.45132C248.689 10.6135 245.221 10.5152 242.13 9.12462C240.4 8.34668 238.466 7.94019 236.43 8.00714C229.87 8.2235 224.432 13.4921 224.025 20.0437C223.562 27.5201 229.488 33.7281 236.865 33.7281C238.828 33.7281 240.688 33.288 242.353 32.5012C245.199 31.1543 248.492 31.0212 251.434 32.0839C253.294 33.1295 255.44 33.7281 257.727 33.7281C257.765 33.7281 257.801 33.7223 257.838 33.7223C257.876 33.7223 257.912 33.7281 257.95 33.7281C260.237 33.7281 262.382 33.1295 264.243 32.0839C267.185 31.0212 270.477 31.1543 273.324 32.5012C274.988 33.288 276.849 33.7281 278.812 33.7281C286.188 33.7281 292.114 27.5205 291.651 20.0446Z"
          fill="white"
        />
        <path
          d="M90.5163 5.00642C88.7092 4.94655 86.9924 5.30762 85.4562 5.99848C82.4674 7.34273 79.0786 7.3423 76.0897 5.99805C74.5541 5.30762 72.8381 4.94655 71.031 5.00642C65.2091 5.19826 60.3837 9.87462 60.0227 15.6885C59.6107 22.3236 64.8703 27.8331 71.4166 27.8331C73.1594 27.8331 74.8102 27.4426 76.2875 26.7442C79.1121 25.408 82.4344 25.408 85.2594 26.7442C86.7362 27.4426 88.3871 27.8331 90.1298 27.8331C96.6756 27.8331 101.935 22.324 101.524 15.6894C101.164 9.87505 96.3382 5.19879 90.5163 5.00642Z"
          fill="white"
        />
        <path
          d="M183.588 128.589L155.946 156.576C155.946 156.576 107.055 130.489 108.265 128.589C109.474 126.689 148.345 104.402 148.345 104.402L183.588 128.589Z"
          fill="#D49A43"
        />
        <mask
          id="mask1_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="108"
          y="104"
          width="76"
          height="53"
        >
          <path
            d="M108.263 128.588C107.054 130.489 155.945 156.576 155.945 156.576L183.586 128.588L148.344 104.402C148.344 104.402 109.473 126.688 108.263 128.588Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_5794_68402)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.146 118.016L136.394 119.123L178.893 147.974L179.645 146.866L137.146 118.016Z"
            fill="#754600"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.727 124.609L129.975 125.717L172.474 154.568L173.226 153.461L130.727 124.609Z"
            fill="#754600"
          />
        </g>
        <path
          d="M236.839 122.641C239.316 116.551 240.683 109.908 240.191 103.92C236.567 107.173 233.534 111.575 231.245 116.387C231.652 111.084 231.204 105.766 229.665 101.147C227.221 104.691 225.447 108.998 224.319 113.545C223.843 108.689 222.691 103.983 220.764 99.9609C218.731 104.203 217.56 109.203 217.136 114.338C215.985 109.391 214.082 104.683 211.406 100.876C209.847 105.69 209.478 111.263 210.024 116.778C207.591 111.789 204.357 107.228 200.51 103.93C200.166 109.729 201.57 116.099 204.023 121.965C200.796 118.811 196.978 116.269 192.773 114.933C194.293 122.654 199.756 129.589 205.964 134.198C208.193 135.786 210.644 137.199 213.332 137.968H228.196C230.884 137.198 233.334 135.787 235.564 134.198C241.772 129.589 247.235 122.653 248.754 114.933C244.255 116.364 240.203 119.178 236.839 122.641Z"
          fill="#FF6B1C"
        />
        <path
          d="M179.27 118.367L163.808 119.361L169.077 132.879L185.835 144.051L199.31 136.449L179.27 118.367Z"
          fill="#FFE7C3"
        />
        <path
          d="M399.823 169.766V137.968C399.823 132.971 395.938 128.716 390.944 128.539C385.703 128.352 381.395 132.547 381.395 137.747V124.717C381.395 119.721 377.51 115.466 372.516 115.289C367.275 115.102 362.967 119.296 362.967 124.496V105.155C362.967 96.889 356.202 89.967 347.938 90.125C339.928 90.2781 333.483 96.8181 333.483 104.864V94.1416C333.483 89.1454 329.598 84.8904 324.605 84.7127C319.364 84.5262 315.055 88.7201 315.055 93.9208V68.7072C315.055 60.4409 308.29 53.5184 300.025 53.6764C292.016 53.8295 285.57 60.37 285.57 68.4158V85.9709C285.57 80.8822 281.445 76.7571 276.356 76.7571C271.272 76.7571 267.151 80.8746 267.143 85.957V64.7303C267.143 56.5885 260.542 49.9883 252.401 49.9883H252.4C244.259 49.9883 237.658 56.5885 237.658 64.7303V169.766H399.822H399.823Z"
          fill="#33CC66"
        />
        <path
          d="M143.687 178.746H412.408C412.408 178.746 408.855 159.358 402.475 156.229C398.069 154.07 397.551 158.303 392.454 156.575C387.358 154.848 387.099 144.482 380.102 142.668C370.556 140.193 370.082 146.562 363.517 145.26C356.952 143.956 358.622 133.339 347.105 133.339C335.588 133.339 331.355 151.68 308.608 151.105C285.862 150.528 294.739 107.637 254.707 109.181C225.748 110.299 143.687 178.746 143.687 178.746"
          fill="#336666"
        />
        <path
          d="M-13.8564 129.804L100.49 130.49L138.756 107.858C138.756 107.858 123.554 80.7062 115.434 79.0071C112.183 78.3269 108.904 79.8948 106.141 82.0191C102.692 84.6715 97.6815 83.0991 96.4439 78.9277C92.9766 67.2374 85.7038 50.1562 72.8053 50.1562C53.1974 50.1562 -13.856 129.804 -13.856 129.804"
          fill="#336666"
        />
        <mask
          id="mask2_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-14"
          y="50"
          width="153"
          height="81"
        >
          <path
            d="M-13.8564 129.804L100.49 130.49L138.756 107.858C138.756 107.858 123.553 80.7062 115.433 79.0075C112.182 78.3269 108.904 79.8948 106.141 82.0196C102.691 84.6714 97.681 83.0996 96.4434 78.9272C92.9762 67.2374 85.7033 50.1562 72.8049 50.1562C53.1968 50.1562 -13.8564 129.804 -13.8564 129.804Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_5794_68402)">
          <path
            d="M28.7412 60.0104C35.8124 60.0104 35.8124 65.366 42.8834 65.366C49.955 65.366 49.955 60.0104 57.0261 60.0104C64.0977 60.0104 64.0977 65.366 71.1688 65.366C78.2408 65.366 78.2408 60.0104 85.3132 60.0104C92.3852 60.0104 92.3852 65.366 99.4577 65.366V44.9805H28.7412V60.0104Z"
            fill="#A5F4F7"
          />
          <path
            d="M106.255 81.6982C108.132 81.6982 108.132 83.1201 110.01 83.1201C111.888 83.1201 111.888 81.6982 113.766 81.6982C115.643 81.6982 115.643 83.1201 117.521 83.1201C119.399 83.1201 119.399 81.6982 121.277 81.6982C123.155 81.6982 123.155 83.1201 125.033 83.1201V77.707H106.255V81.6982Z"
            fill="#A5F4F7"
          />
        </g>
        <path
          d="M191.396 234.296L179.898 234.11L179.764 134.125L191.396 134.043C219.035 134.043 241.522 156.53 241.522 184.17C241.522 211.809 219.035 234.296 191.396 234.296Z"
          fill="#FFDA33"
        />
        <mask
          id="mask3_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="179"
          y="134"
          width="63"
          height="101"
        >
          <path
            d="M179.762 134.124L179.897 234.109L191.394 234.295C219.034 234.295 241.52 211.809 241.52 184.169C241.52 156.529 219.034 134.043 191.394 134.043L179.762 134.124Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_5794_68402)">
          <path d="M242.773 135.91H182.739V138.112H242.773V135.91Z" fill="#E8BD0A" />
          <path d="M243.416 151.344H183.382V153.545H243.416V151.344Z" fill="#E8BD0A" />
          <path d="M244.061 166.781H184.027V168.983H244.061V166.781Z" fill="#E8BD0A" />
          <path d="M244.704 182.211H184.671V184.412H244.704V182.211Z" fill="#E8BD0A" />
          <path d="M245.348 197.648H185.314V199.85H245.348V197.648Z" fill="#E8BD0A" />
          <path d="M245.992 213.082H185.959V215.284H245.992V213.082Z" fill="#E8BD0A" />
          <path d="M246.637 228.516H186.603V230.717H246.637V228.516Z" fill="#E8BD0A" />
        </g>
        <path
          d="M182.606 234.167C169.217 234.167 156.629 228.953 147.161 219.485C137.694 210.017 132.479 197.43 132.479 184.04C132.479 170.651 137.694 158.063 147.161 148.595C156.629 139.128 169.217 133.914 182.606 133.914C195.996 133.914 208.583 139.128 218.051 148.595C227.519 158.063 232.732 170.651 232.732 184.04C232.732 197.43 227.519 210.017 218.051 219.485C208.583 228.953 195.996 234.167 182.606 234.167Z"
          fill="#E8BD0A"
        />
        <path
          d="M227.762 184.043C227.762 159.104 207.545 138.887 182.606 138.887C157.666 138.887 137.449 159.104 137.449 184.043C137.449 208.982 157.666 229.199 182.606 229.199C207.545 229.199 227.762 208.982 227.762 184.043Z"
          fill="#FFDA33"
        />
        <path
          d="M190.994 189.289C190.994 185.851 186.373 178.418 183.99 174.815C183.334 173.822 181.877 173.822 181.22 174.815C178.838 178.418 174.217 185.851 174.217 189.289C174.217 193.922 177.972 197.678 182.605 197.678C187.238 197.678 190.994 193.922 190.994 189.289Z"
          fill="#E8BD0A"
        />
        <path
          d="M179.982 203.971C179.982 203.24 179.507 202.586 178.805 202.382C173.13 200.733 168.97 195.488 168.97 189.287C168.97 187.271 169.529 184.009 173.894 176.623C176.074 172.932 178.228 169.873 178.319 169.745L179.678 167.82C179.876 167.54 179.982 167.205 179.982 166.862V147.954C179.982 146.529 178.308 145.766 177.233 146.699L152.967 167.753C152.603 168.069 152.395 168.526 152.395 169.008V203.752C152.395 204.26 152.628 204.74 153.026 205.055L172.598 220.509C174.202 221.775 175.985 222.695 177.851 223.269C178.912 223.595 179.982 222.785 179.982 221.675V203.971Z"
          fill="#E8BD0A"
        />
        <path
          d="M185.23 147.953V166.862C185.23 167.205 185.337 167.539 185.534 167.82L186.894 169.745C186.984 169.874 189.139 172.932 191.319 176.622C195.684 184.01 196.243 187.271 196.243 189.287C196.243 195.487 192.083 200.732 186.407 202.382C185.706 202.586 185.23 203.24 185.23 203.971V221.675C185.23 222.785 186.301 223.595 187.362 223.269C189.228 222.695 191.011 221.775 192.615 220.509L212.186 205.055C212.585 204.74 212.818 204.26 212.818 203.751V169.008C212.818 168.526 212.609 168.069 212.246 167.753L187.98 146.699C186.904 145.766 185.23 146.53 185.23 147.953Z"
          fill="#E8BD0A"
        />
        <path
          d="M-23.2051 125.622L-22.761 192.998C11.7016 192.691 53.27 186.938 85.8068 175.895C104.268 184.841 119.685 182.276 137.98 172.958L171.496 178.314L173.223 168.466L144.403 146.316C138.621 141.872 137.872 133.435 142.781 128.042C146.938 123.475 153.813 122.64 158.942 126.08L169.077 132.878L179.27 118.366C179.27 118.366 158.193 96.4258 149.987 96.4258C141.781 96.4258 90.2122 128.04 90.2122 128.04L-23.2045 125.622H-23.2051Z"
          fill="#FFE7C3"
        />
        <path
          d="M73.8853 142.423C73.8853 141.827 73.4019 141.344 72.8056 141.344C72.209 141.344 71.7256 141.827 71.7256 142.423C71.7256 143.02 72.209 143.503 72.8056 143.503C73.4019 143.503 73.8853 143.02 73.8853 142.423Z"
          fill="#336666"
        />
        <path
          d="M68.876 135.814C68.876 134.812 68.0637 134 67.0618 134C66.0603 134 65.248 134.812 65.248 135.814C65.248 136.816 66.0603 137.628 67.0618 137.628C68.0637 137.628 68.876 136.816 68.876 135.814Z"
          fill="#336666"
        />
        <path
          d="M55.7461 141.342C55.7461 140.34 54.9338 139.527 53.9319 139.527C52.9304 139.527 52.1182 140.34 52.1182 141.342C52.1182 142.344 52.9304 143.156 53.9319 143.156C54.9338 143.156 55.7461 142.344 55.7461 141.342Z"
          fill="#336666"
        />
        <path
          d="M-38.4081 227.894H82.0051C82.0051 227.894 80.5659 220.638 75.7861 216.665C71.0062 212.691 65.2476 215.052 62.1378 212.864C59.0285 210.675 60.3817 201.346 56.0338 199.389C51.6861 197.431 47.0504 200.886 40.5429 199.561C34.0359 198.237 27.9315 166.91 2.3631 169.847C-23.2052 172.784 -38.4082 227.894 -38.4082 227.894"
          fill="#33CC66"
        />
        <path
          d="M194.98 249.282L197.121 262.132L157.271 254.53L153.816 263.975L148.749 261.902L143.688 268.582C182.89 304.029 227.871 310.837 273.44 283.163L372.876 301.752V228.271L279.816 233.338C272.482 227.474 255.144 226.558 243.592 228.162C233 229.633 227.86 234.514 210.967 241.723C204.759 244.373 202.059 245.907 194.98 249.282"
          fill="#FFE7C3"
        />
        <path
          d="M199.933 262.726C198.808 262.726 197.674 262.713 196.523 262.686L196.554 261.348C212.205 261.71 225.409 259.592 238.112 254.68L238.594 255.928C226.658 260.544 214.292 262.726 199.933 262.726Z"
          fill="#754600"
        />
        <path
          d="M208.708 282.321C189.47 282.321 177.83 276.928 153.512 264.569L154.119 263.375C186.842 280.005 195.896 283.76 230.829 279.282L230.999 280.61C222.253 281.732 215.064 282.321 208.708 282.321Z"
          fill="#754600"
        />
        <path d="M93.0044 91.5V103.881H85.7847L83.3877 98.8709L93.0044 91.5Z" fill="#00CCFF" />
        <path d="M85.7846 103.881H55.2852L62.5054 91.5H93.0043L85.7846 103.881Z" fill="#A5F4F7" />
        <mask
          id="mask4_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="55"
          y="91"
          width="39"
          height="13"
        >
          <path d="M62.5053 91.5L55.2852 103.881H85.7846L93.0043 91.5H62.5053Z" fill="white" />
        </mask>
        <g mask="url(#mask4_5794_68402)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.6547 88.0312L58.6484 106.899L59.4221 107.351L70.4284 88.4825L69.6547 88.0312Z"
            fill="#00CCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M87.0591 88.0312L76.0527 106.899L76.8264 107.351L87.8327 88.4825L87.0591 88.0312Z"
            fill="#00CCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M78.215 87.9805L67.2087 106.849L67.9824 107.3L78.9887 88.4318L78.215 87.9805Z"
            fill="#00CCFF"
          />
          <path d="M91.0465 97.5039H57.1279V98.3965H91.0465V97.5039Z" fill="#00CCFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M92.6527 93.2812L60.0281 93.3302L60.0294 94.223L92.6541 94.174L92.6527 93.2812Z"
            fill="#00CCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.1326 101.242L55.5078 101.291L55.5092 102.184L88.1339 102.135L88.1326 101.242Z"
            fill="#00CCFF"
          />
        </g>
        <path
          d="M90.0279 222.43C92.5048 216.34 93.8713 209.697 93.3795 203.709C89.7556 206.962 86.7225 211.364 84.4336 216.176C84.8406 210.873 84.3929 205.554 82.8537 200.936C80.4097 204.48 78.6352 208.787 77.507 213.334C77.0312 208.478 75.8793 203.771 73.9522 199.75C71.9193 203.992 70.7483 208.992 70.3243 214.127C69.1733 209.18 67.2707 204.472 64.5948 200.665C63.0349 205.479 62.6663 211.052 63.2121 216.567C60.7794 211.578 57.5454 207.017 53.6989 203.719C53.3547 209.518 54.7579 215.888 57.2117 221.754C53.9845 218.6 50.1665 216.059 45.9619 214.722C47.4815 222.443 52.9442 229.378 59.1527 233.987C61.3814 235.575 63.8329 236.988 66.5205 237.757H81.384C84.0725 236.987 86.5221 235.575 88.7523 233.987C94.9603 229.378 100.423 222.442 101.942 214.722C97.443 216.153 93.3912 218.967 90.0279 222.43Z"
          fill="#FF6B1C"
        />
        <path
          d="M259.401 119.652C259.401 119.204 259.038 118.84 258.589 118.84C258.141 118.84 257.777 119.204 257.777 119.652C257.777 120.1 258.141 120.463 258.589 120.463C259.038 120.463 259.401 120.1 259.401 119.652Z"
          fill="#33CC66"
        />
        <path
          d="M255.916 123.226C255.916 122.777 255.552 122.414 255.104 122.414C254.656 122.414 254.292 122.777 254.292 123.226C254.292 123.674 254.656 124.038 255.104 124.038C255.552 124.038 255.916 123.674 255.916 123.226Z"
          fill="#33CC66"
        />
        <path
          d="M260.711 123.374C260.711 122.926 260.348 122.562 259.9 122.562C259.452 122.562 259.088 122.926 259.088 123.374C259.088 123.822 259.452 124.186 259.9 124.186C260.348 124.186 260.711 123.822 260.711 123.374Z"
          fill="#33CC66"
        />
        <path
          d="M209.171 162.239C206.348 161.233 205.013 159.898 204.007 157.075C203.657 156.095 202.278 156.095 201.928 157.075C200.922 159.898 199.588 161.233 196.764 162.239C195.784 162.588 195.784 163.968 196.764 164.317C199.588 165.324 200.922 166.658 201.928 169.481C202.278 170.462 203.657 170.462 204.007 169.481C205.013 166.658 206.348 165.324 209.171 164.317C210.152 163.968 210.152 162.588 209.171 162.239Z"
          fill="white"
        />
        <path
          d="M137.978 217.512C135.155 216.506 133.82 215.172 132.814 212.348C132.464 211.368 131.085 211.368 130.735 212.348C129.729 215.172 128.395 216.506 125.571 217.512C124.591 217.862 124.591 219.242 125.571 219.591C128.395 220.597 129.729 221.932 130.735 224.755C131.085 225.736 132.464 225.736 132.814 224.755C133.82 221.932 135.155 220.597 137.978 219.591C138.958 219.242 138.958 217.862 137.978 217.512Z"
          fill="#FFDA33"
        />
        <path
          d="M168.666 148.855C166.942 148.241 166.128 147.426 165.514 145.703C165.301 145.104 164.458 145.104 164.245 145.703C163.63 147.426 162.816 148.241 161.092 148.855C160.494 149.068 160.494 149.91 161.092 150.124C162.816 150.738 163.63 151.553 164.245 153.276C164.458 153.875 165.301 153.875 165.514 153.276C166.128 151.553 166.942 150.738 168.666 150.124C169.265 149.91 169.265 149.068 168.666 148.855Z"
          fill="white"
        />
        <path
          d="M98.6598 258.254C98.2121 258.741 98.2438 259.498 98.7308 259.946L103.742 264.553C104.229 265.001 104.986 264.969 105.434 264.482C105.882 263.995 105.85 263.238 105.363 262.79L100.352 258.183C99.8648 257.736 99.1074 257.767 98.6598 258.254Z"
          fill="#FFDA33"
        />
        <path
          d="M93.1811 253.217C92.7335 253.704 91.9761 253.736 91.4892 253.288L91.1849 253.009C90.6979 252.561 90.6662 251.804 91.1139 251.317C91.5615 250.83 92.3188 250.798 92.8058 251.246L93.1102 251.526C93.597 251.973 93.6287 252.731 93.1811 253.217Z"
          fill="#FFDA33"
        />
        <path
          d="M95.1025 254.081C95.1025 254.081 95.9612 244.911 99.3571 245.821C102.753 246.732 95.1025 254.081 95.1025 254.081Z"
          fill="#00CCFF"
        />
        <path
          d="M95.7627 254.524C95.7627 254.524 102.374 248.112 104.364 251.01C106.353 253.908 95.7627 254.524 95.7627 254.524Z"
          fill="#00CCFF"
        />
        <path
          d="M94.0641 254.985C94.0641 254.985 84.9431 253.706 85.0458 257.22C85.1484 260.734 94.0641 254.985 94.0641 254.985Z"
          fill="#00CCFF"
        />
        <path
          d="M94.3387 255.727C94.3387 255.727 86.5749 260.681 88.9362 263.286C91.2976 265.89 94.3387 255.727 94.3387 255.727Z"
          fill="#00CCFF"
        />
        <path
          d="M99.0103 257.873L98.3101 258.635C97.8625 259.122 97.1047 259.153 96.6178 258.706L92.9023 255.29C92.4155 254.843 92.3838 254.085 92.8314 253.598L93.5316 252.836C93.9793 252.349 94.7366 252.318 95.2235 252.765L98.9394 256.181C99.4263 256.629 99.4579 257.386 99.0103 257.873Z"
          fill="#33CC66"
        />
        <path
          d="M91.2477 251.715C90.9764 250.593 90.8871 249.681 90.9661 248.844L91.5615 248.9C91.4887 249.669 91.574 250.519 91.8292 251.575L91.2477 251.715Z"
          fill="#FFDA33"
        />
        <path
          d="M91.2842 252.132C90.2649 251.757 89.4303 251.575 88.6582 251.559L88.6707 250.961C89.511 250.979 90.4072 251.172 91.4904 251.571L91.2842 252.132Z"
          fill="#FFDA33"
        />
        <path
          d="M270.02 183.657C259.96 183.657 251.776 191.841 251.776 201.901C251.776 211.96 259.96 220.145 270.02 220.145C280.08 220.145 288.264 211.96 288.264 201.901C288.264 191.841 280.08 183.657 270.02 183.657ZM270.02 222.75C258.523 222.75 249.17 213.397 249.17 201.901C249.17 190.404 258.523 181.051 270.02 181.051C281.517 181.051 290.87 190.404 290.87 201.901C290.87 213.397 281.517 222.75 270.02 222.75Z"
          fill="#FF6B1C"
        />
        <path
          d="M346.608 183.657C336.548 183.657 328.364 191.841 328.364 201.901C328.364 211.96 336.548 220.145 346.608 220.145C356.668 220.145 364.852 211.96 364.852 201.901C364.852 191.841 356.668 183.657 346.608 183.657M346.608 222.75C335.111 222.75 325.758 213.397 325.758 201.901C325.758 190.404 335.111 181.051 346.608 181.051C358.105 181.051 367.458 190.404 367.458 201.901C367.458 213.397 358.105 222.75 346.608 222.75Z"
          fill="#FF6B1C"
        />
        <path
          d="M270.429 204.072C270.333 204.072 270.235 204.056 270.14 204.022C269.687 203.862 269.451 203.365 269.61 202.913L284.187 161.712C284.348 161.26 284.844 161.023 285.296 161.182C285.748 161.342 285.986 161.839 285.825 162.291L271.248 203.493C271.122 203.849 270.787 204.072 270.429 204.072Z"
          fill="#00CCFF"
        />
        <path
          d="M301.292 204.384L305.193 206.133H309.698C309.998 197.203 308.454 185.857 308.42 183.604C308.387 181.352 310.832 173.008 310.647 168.406L297.728 169.818C297.728 169.818 298.568 184.075 298.736 186.026C298.905 187.976 300.71 197.282 301.292 204.384Z"
          fill="#FFDA33"
        />
        <mask
          id="mask5_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="297"
          y="168"
          width="14"
          height="39"
        >
          <path
            d="M297.73 169.822C297.73 169.822 298.571 184.079 298.738 186.029C298.907 187.979 300.711 197.286 301.294 204.388L305.194 206.137H309.7C310 197.207 308.456 185.861 308.422 183.608C308.388 181.356 310.834 173.012 310.649 168.41L297.73 169.822Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_5794_68402)">
          <path d="M310.657 195.512H299.813V207.303H310.657V195.512Z" fill="#00CCFF" />
        </g>
        <path
          d="M289.394 213.316L298.112 215.23L310.051 213.525L309.675 204.69L304.117 204.629C304.044 201.365 299.823 201.66 299.937 202.862C300.878 210.592 289.639 210.077 289.394 213.316Z"
          fill="#336666"
        />
        <mask
          id="mask6_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="289"
          y="202"
          width="22"
          height="14"
        >
          <path
            d="M299.938 202.862C300.878 210.592 289.639 210.076 289.394 213.316L298.111 215.229L310.051 213.524L309.675 204.69L304.117 204.629C304.074 202.713 302.602 202.023 301.417 202.023C300.583 202.023 299.89 202.365 299.938 202.862Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_5794_68402)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M298.636 205.688L298.608 206.067L301.976 206.315L302.004 205.936L298.636 205.688Z"
            fill="#FF6B1C"
          />
          <path
            d="M302.413 206.127C302.413 205.893 302.223 205.703 301.99 205.703C301.756 205.703 301.566 205.893 301.566 206.127C301.566 206.361 301.756 206.55 301.99 206.55C302.223 206.55 302.413 206.361 302.413 206.127Z"
            fill="#FF6B1C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M298.221 207.18L298.133 207.55L301.419 208.329L301.506 207.959L298.221 207.18Z"
            fill="#FF6B1C"
          />
          <path
            d="M301.88 208.209C301.917 207.979 301.76 207.761 301.53 207.724C301.299 207.687 301.081 207.844 301.044 208.075C301.007 208.306 301.164 208.523 301.395 208.56C301.626 208.597 301.843 208.44 301.88 208.209Z"
            fill="#FF6B1C"
          />
        </g>
        <path
          d="M289.223 216.409H310.253L310.052 212.876C310.052 212.876 309.391 212.597 307.922 212.679C306.453 212.762 306.26 213.646 305.321 213.637C304.382 213.627 304.263 212.842 302.744 212.802C301.226 212.762 301.186 213.676 299.849 213.661C298.511 213.646 298.303 212.943 296.805 212.949C295.309 212.956 294.953 213.83 293.787 213.784C292.622 213.738 291.827 212.385 289.395 213.097C288.844 214.221 288.939 215.316 289.223 216.409Z"
          fill="#FF6B1C"
        />
        <path
          d="M346.608 202.767H319.817L283.044 168.946C282.691 168.621 282.668 168.071 282.993 167.718C283.317 167.365 283.867 167.342 284.22 167.667L320.495 201.029H346.608C347.087 201.029 347.476 201.419 347.476 201.898C347.476 202.378 347.087 202.767 346.608 202.767"
          fill="#00CCFF"
        />
        <path
          d="M320.156 202.769C320.081 202.769 320.006 202.759 319.93 202.739C319.466 202.614 319.192 202.137 319.317 201.674L328.822 166.436C328.947 165.972 329.423 165.699 329.887 165.823C330.35 165.948 330.624 166.425 330.499 166.888L320.994 202.126C320.89 202.514 320.539 202.769 320.156 202.769Z"
          fill="#00CCFF"
        />
        <path
          d="M335.036 167.759H321.969C321.141 167.759 320.47 167.088 320.47 166.26C320.47 165.541 320.979 164.924 321.684 164.787C325.548 164.037 330.263 163.805 335.057 163.849C336.129 163.859 336.991 164.734 336.991 165.804C336.991 166.884 336.116 167.759 335.036 167.759Z"
          fill="#A5F4F7"
        />
        <path
          d="M287.629 161.888C287.629 160.44 286.455 159.266 285.006 159.266C283.558 159.266 282.384 160.44 282.384 161.888C282.384 163.337 283.558 164.511 285.006 164.511C286.455 164.511 287.629 163.337 287.629 161.888Z"
          fill="#FF6B1C"
        />
        <path
          d="M346.609 202.767C346.367 202.767 346.126 202.667 345.955 202.47L325.989 179.656L294.414 179.104C293.935 179.096 293.552 178.701 293.561 178.221C293.569 177.741 293.975 177.353 294.444 177.368L326.789 177.932L347.262 201.327C347.578 201.688 347.542 202.237 347.181 202.553C347.016 202.696 346.812 202.767 346.609 202.767Z"
          fill="#00CCFF"
        />
        <path
          d="M305.764 208.849L309.665 210.598H314.17C314.471 201.668 312.927 190.322 312.893 188.069C312.859 185.816 315.304 177.473 315.119 172.871L302.2 174.283C302.2 174.283 303.041 188.54 303.209 190.49C303.377 192.441 305.182 201.747 305.764 208.849Z"
          fill="#FFDA33"
        />
        <mask
          id="mask7_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="302"
          y="172"
          width="14"
          height="39"
        >
          <path
            d="M302.201 174.284C302.201 174.284 303.042 188.54 303.21 190.49C303.378 192.441 305.184 201.747 305.766 208.849L309.666 210.598H314.172C314.472 201.668 312.927 190.323 312.894 188.07C312.861 185.817 315.305 177.473 315.121 172.871L302.201 174.284Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_5794_68402)">
          <path d="M315.131 199.973H304.286V211.764H315.131V199.973Z" fill="#00CCFF" />
        </g>
        <path
          d="M294.291 218.203L303.009 220.117L314.948 218.412L314.572 209.577L309.015 209.516C308.941 206.252 304.721 206.546 304.835 207.749C305.776 215.479 294.537 214.963 294.291 218.203Z"
          fill="#336666"
        />
        <mask
          id="mask8_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="294"
          y="206"
          width="21"
          height="15"
        >
          <path
            d="M304.836 207.748C305.776 215.479 294.537 214.963 294.292 218.203L303.01 220.117L314.949 218.411L314.573 209.577L309.016 209.515C308.973 207.6 307.501 206.91 306.315 206.91C305.481 206.91 304.788 207.252 304.836 207.748Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask8_5794_68402)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M303.532 210.574L303.504 210.953L306.873 211.202L306.9 210.823L303.532 210.574Z"
            fill="#FF6B1C"
          />
          <path
            d="M307.31 211.013C307.31 210.779 307.12 210.59 306.886 210.59C306.653 210.59 306.463 210.779 306.463 211.013C306.463 211.247 306.653 211.436 306.886 211.436C307.12 211.436 307.31 211.247 307.31 211.013Z"
            fill="#FF6B1C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M303.117 212.066L303.029 212.436L306.315 213.216L306.403 212.846L303.117 212.066Z"
            fill="#FF6B1C"
          />
          <path
            d="M306.777 213.1C306.814 212.869 306.657 212.652 306.426 212.615C306.195 212.578 305.978 212.735 305.941 212.966C305.904 213.196 306.061 213.414 306.291 213.451C306.522 213.488 306.74 213.331 306.777 213.1Z"
            fill="#FF6B1C"
          />
        </g>
        <path
          d="M294.119 221.296H315.15L314.949 217.762C314.949 217.762 314.288 217.483 312.819 217.566C311.35 217.649 311.156 218.532 310.217 218.523C309.279 218.514 309.159 217.729 307.641 217.689C306.123 217.649 306.082 218.563 304.745 218.548C303.408 218.532 303.199 217.83 301.702 217.836C300.205 217.842 299.849 218.717 298.684 218.671C297.518 218.624 296.724 217.272 294.291 217.983C293.74 219.108 293.836 220.202 294.119 221.296Z"
          fill="#FF6B1C"
        />
        <path
          d="M299.131 154.604C296.396 159.654 296.004 166.954 295.543 174.591L307.156 174.33L306.797 153.887L299.131 154.604Z"
          fill="#336666"
        />
        <path
          d="M298.978 175.245L317.329 175.441C317.255 169.914 318.009 166.994 317.55 163.723C316.96 159.522 316.15 157.68 313.522 154.379H300.968C299.834 161.557 299.128 168.145 298.978 175.245Z"
          fill="white"
        />
        <path
          d="M294.13 132.185L289.658 130.894L294.886 121.049C295.825 120.68 297.17 119.538 297.969 117.929L298.755 117.168L301.531 116.382L301.405 115.574L303.069 115.235L302.919 114.195L297.055 114.465C294.274 115.03 292.613 116.451 292.214 118.836C292.214 118.836 279.313 129.092 282.093 133.978C284.872 138.865 293.525 139.627 293.525 139.627L294.13 132.185Z"
          fill="#FFDA33"
        />
        <path
          d="M293.619 130.895L290.398 140.995L301.763 143.181L305.171 135.133C301.292 133.226 298.174 131.543 293.619 130.895Z"
          fill="#00CCFF"
        />
        <path
          d="M303.575 134.345C303.977 134.043 308.32 133.653 309.979 133.978C311.638 134.304 311.638 137.037 311.638 137.037L302.598 137.341C302.598 137.341 303.173 134.646 303.575 134.345Z"
          fill="#FFDA33"
        />
        <path d="M303.821 127.209L303.474 136.159L310.365 135.164L307.828 126.492L303.821 127.209Z" fill="#FFDA33" />
        <mask
          id="mask9_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="303"
          y="126"
          width="8"
          height="11"
        >
          <path d="M303.82 127.21L303.473 136.159L310.363 135.164L307.827 126.492L303.82 127.21Z" fill="white" />
        </mask>
        <g mask="url(#mask9_5794_68402)">
          <path d="M303.276 132.498L306.28 131.056L305.921 129.555L303.164 130.003L303.276 132.498Z" fill="#336666" />
        </g>
        <path
          d="M314.014 154.927L296.419 155.623L298.928 146.404C298.928 146.404 298.056 143.084 298.547 141.042C299.124 138.641 303.576 134.347 303.576 134.347C303.576 134.347 303.3 135.604 306.752 135.392C310.204 135.18 309.979 133.98 309.979 133.98C316.689 135.06 314.607 145.813 314.014 154.927Z"
          fill="#A5F4F7"
        />
        <path
          d="M310.048 169.484H308.209C306.216 169.484 304.601 167.868 304.601 165.875V157.828H313.657V165.875C313.657 167.868 312.041 169.484 310.048 169.484Z"
          fill="#336666"
        />
        <mask
          id="mask10_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="304"
          y="157"
          width="10"
          height="13"
        >
          <path
            d="M304.601 157.832V165.88C304.601 167.872 306.216 169.488 308.21 169.488H310.048C312.041 169.488 313.656 167.872 313.656 165.88V157.832H304.601Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask10_5794_68402)">
          <path d="M314.417 157.203H303.837V161.283H314.417V157.203Z" fill="#FF6B1C" />
        </g>
        <path
          d="M304.6 146.645L288.236 155.679L291.397 159.086C299.395 157.57 308.248 156.025 311.079 149.301L304.6 146.645Z"
          fill="#FFDA33"
        />
        <path
          d="M289.401 155.542C286.494 155.117 283.754 155.17 281.6 156.91L279.852 163.365L282.385 163.769L284.626 159.779L286.263 159.656C287.406 161.51 291.275 160.994 292.363 158.901L289.401 155.542Z"
          fill="#FFDA33"
        />
        <mask
          id="mask11_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="279"
          y="155"
          width="14"
          height="9"
        >
          <path
            d="M281.6 156.91L279.852 163.365L282.385 163.769L284.626 159.779L286.263 159.655C287.406 161.51 291.275 160.994 292.363 158.901L289.401 155.542C288.478 155.407 287.572 155.32 286.697 155.32C284.813 155.32 283.07 155.722 281.6 156.91Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask11_5794_68402)">
          <path
            d="M280.453 164.13L280.146 163.994L282.759 158.089L286.248 157.309L286.321 157.635L282.996 158.379L280.453 164.13Z"
            fill="#336666"
          />
        </g>
        <path
          d="M302.599 147.188L312.041 149.895C313.015 146.681 313.86 143.543 314.488 140.532C314.913 138.49 313.797 136.44 311.852 135.682C309.992 134.958 307.881 135.619 306.76 137.27C304.487 140.618 302.538 144.174 302.599 147.188Z"
          fill="#00CCFF"
        />
        <path d="M300.491 124.781L298.179 126.663L300.54 127.912L300.491 124.781Z" fill="#FF6B1C" />
        <path
          d="M300.776 120.851C299.826 121.59 299.847 123.571 299.875 125.493C299.914 128.087 299.656 130.791 301.82 131.02C304.708 131.324 307.881 129.473 307.881 129.473L306.681 120.65C306.681 120.65 303.539 118.7 300.776 120.851Z"
          fill="#FFDA33"
        />
        <mask
          id="mask12_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="299"
          y="119"
          width="9"
          height="13"
        >
          <path
            d="M300.776 120.851C299.827 121.59 299.848 123.571 299.876 125.493C299.915 128.086 299.657 130.791 301.821 131.02C304.708 131.324 307.882 129.473 307.882 129.473L306.682 120.65C306.682 120.65 305.377 119.84 303.709 119.84C302.789 119.84 301.759 120.086 300.776 120.851Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask12_5794_68402)">
          <path
            d="M299.162 128.563L299.161 128.228C300.033 128.226 300.553 128.024 301.08 127.48L301.32 127.713C300.638 128.416 299.964 128.561 299.162 128.563Z"
            fill="#336666"
          />
        </g>
        <path
          d="M300.767 120.492C300.189 121.095 300.561 122.103 301.622 122.398C302.683 122.693 304.45 122.562 304.649 122.854C304.848 123.147 304.837 123.866 305.03 124.09C305.251 124.348 305.628 124.309 305.794 124.542C305.96 124.775 306.065 126.167 306.065 126.167L307.469 126.344L308.666 129.606C308.666 129.606 310.745 128.241 310.8 125.326C310.821 124.253 310.567 122.97 309.835 121.468C308.703 119.143 306.254 118.648 304.124 118.987C302.698 119.214 301.416 119.815 300.767 120.492Z"
          fill="#FF6B1C"
        />
        <path
          d="M300.51 122.987L300.497 123.645C301.33 123.563 302.685 123.379 303.527 123.422L303.482 123.028C302.708 122.753 301.435 122.87 300.51 122.987Z"
          fill="#FF6B1C"
        />
        <path
          d="M303.423 124.407C301.94 123.861 301 124.221 300.604 125.485C301.821 125.922 302.761 125.563 303.423 124.407Z"
          fill="white"
        />
        <mask
          id="mask13_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="300"
          y="124"
          width="4"
          height="2"
        >
          <path
            d="M300.604 125.486C301.82 125.923 302.759 125.563 303.422 124.407C302.975 124.243 302.577 124.16 302.228 124.16C301.422 124.16 300.88 124.602 300.604 125.486Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask13_5794_68402)">
          <path
            d="M300.286 125.201C300.226 124.616 300.652 124.093 301.236 124.033C301.822 123.973 302.345 124.398 302.405 124.983C302.465 125.568 302.039 126.091 301.454 126.151C300.869 126.211 300.346 125.786 300.286 125.201Z"
            fill="#336666"
          />
        </g>
        <path
          d="M307.999 128.205L306.176 128.392L307.178 125.146L307.679 125.094C308.538 125.006 309.306 125.631 309.394 126.49C309.483 127.349 308.858 128.117 307.999 128.205Z"
          fill="#FFDA33"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M308.353 126.254L307.346 126.72L307.487 127.024L308.494 126.558L308.353 126.254Z"
          fill="#336666"
        />
        <path
          d="M325.554 115.77C323.339 115.446 321.235 116.141 319.688 117.485C318.616 118.416 317.056 118.46 315.866 117.686C315.506 117.452 315.101 117.272 314.66 117.16C312.618 116.643 310.473 117.83 309.834 119.838C309.127 122.058 310.433 124.388 312.658 124.967C313.322 125.139 313.99 125.136 314.613 124.985C315.964 124.657 317.39 125.287 318.056 126.508C319.179 128.57 321.274 130.056 323.788 130.296C327.881 130.688 331.509 127.63 331.788 123.5C332.044 119.721 329.301 116.318 325.554 115.77Z"
          fill="#FF6B1C"
        />
        <path
          d="M310.568 121.547C310.526 121.14 310.162 120.845 309.756 120.887C309.35 120.928 309.054 121.292 309.096 121.698C309.138 122.105 309.501 122.4 309.907 122.358C310.314 122.317 310.609 121.953 310.568 121.547Z"
          fill="#A5F4F7"
        />
        <path
          d="M324.477 203.3C324.477 206.013 322.277 208.213 319.564 208.213C316.85 208.213 314.65 206.013 314.65 203.3C314.65 200.587 316.85 198.387 319.564 198.387C322.277 198.387 324.477 200.587 324.477 203.3Z"
          fill="#336666"
        />
        <path
          d="M305.444 207.544C305.439 207.5 304.941 203.116 304.708 201.506C304.512 200.157 304.314 198.755 304.126 197.417C303.889 195.734 303.666 194.154 303.479 192.908C302.953 189.405 302.688 186.607 302.593 183.554C302.539 181.797 302.204 175.367 302.2 175.303L302.534 175.285C302.538 175.35 302.873 181.783 302.928 183.543C303.022 186.583 303.287 189.368 303.81 192.859C303.997 194.105 304.22 195.686 304.457 197.37C304.645 198.708 304.843 200.109 305.039 201.458C305.273 203.073 305.771 207.462 305.776 207.506L305.444 207.544Z"
          fill="#336666"
        />
        <path
          d="M302.864 212.919L302.623 212.686C302.638 212.671 304.089 211.163 304.32 210.424C304.555 209.671 304.558 207.206 304.558 207.182V207.048L305.326 206.871L305.402 207.197L304.892 207.315C304.888 207.852 304.859 209.819 304.639 210.523C304.385 211.338 302.926 212.855 302.864 212.919Z"
          fill="#FFDA33"
        />
        <path
          d="M296.281 116.62L296.182 116.3L298.092 115.704L301.391 115.406L301.421 115.74L298.156 116.031L296.281 116.62Z"
          fill="#336666"
        />
        <path
          d="M309.727 134.735C309.727 134.735 312.047 131.381 318.849 132.001C325.651 132.62 326.247 137.07 334.674 135.638C340.376 134.67 344.33 128.147 350.08 127.006C355.544 125.922 357.931 127.843 361.14 128.337C365.291 128.975 367.067 128.853 367.067 128.853L368.261 154.82C368.261 154.82 358.887 148.916 352.306 149.233C345.725 149.551 336.662 156.975 328.863 154.166C322.786 151.977 320.688 145.875 317.291 140.027C315.139 136.324 310.14 135.017 310.14 135.017L309.727 134.735Z"
          fill="#FF6B1C"
        />
        <mask
          id="mask14_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="309"
          y="126"
          width="60"
          height="29"
        >
          <path
            d="M350.08 127.006C344.33 128.146 340.375 134.67 334.674 135.638C326.247 137.069 325.65 132.62 318.848 132.001C312.046 131.381 309.727 134.735 309.727 134.735L310.139 135.017C310.139 135.017 315.139 136.324 317.29 140.027C320.688 145.875 322.786 151.977 328.863 154.166C336.661 156.975 345.724 149.551 352.305 149.233C358.886 148.916 368.261 154.82 368.261 154.82L367.066 128.853C367.066 128.853 365.291 128.975 361.14 128.337C358.618 127.949 356.604 126.68 353.206 126.68C352.28 126.68 351.25 126.774 350.08 127.006Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask14_5794_68402)">
          <path
            d="M369.538 143.67C360.444 135.983 351.479 134.125 342.893 138.148L342.514 137.339C351.445 133.156 360.731 135.056 370.114 142.989L369.538 143.67Z"
            fill="#336666"
          />
          <path
            d="M369.453 149.911C360.323 143.825 356.482 142.121 336.489 146.768C331.242 147.987 327.425 144.931 323.734 141.976C320.163 139.116 316.79 136.415 312.262 137.436L312.066 136.565C317.013 135.449 320.714 138.413 324.292 141.279C327.979 144.231 331.462 147.019 336.286 145.899C356.638 141.168 360.574 142.919 369.948 149.168L369.453 149.911Z"
            fill="#336666"
          />
        </g>
        <path
          d="M391.634 180.721V178.904C391.634 167.812 382.643 158.82 371.551 158.82C360.459 158.82 351.468 167.812 351.468 178.904V180.721C351.468 196.183 345.932 211.002 336.402 223.177C333.463 226.932 331.711 231.661 331.711 236.8C331.711 243.163 334.398 248.897 338.698 252.932C343.629 257.558 346.049 264.168 345.767 270.923C345.758 271.14 345.753 271.359 345.753 271.578C345.753 280.1 352.661 287.008 361.183 287.008H381.919C390.441 287.008 397.349 280.1 397.349 271.578C397.349 271.359 397.344 271.14 397.335 270.923C397.053 264.167 399.473 257.558 404.404 252.932C408.705 248.897 411.391 243.163 411.391 236.8C411.391 231.662 409.639 226.933 406.7 223.178C397.17 211.002 391.634 196.183 391.634 180.721Z"
          fill="#33CC66"
        />
        <path d="M375.698 192.863L378.181 326.58H370.147L372.63 192.863H375.698Z" fill="#FFDA33" />
        <path d="M386.152 229.424L374.17 240.698L371.579 237.628L384.706 227.711L386.152 229.424Z" fill="#FFDA33" />
        <path d="M361.08 214.301L374.17 224.261L371.579 227.331L359.562 216.099L361.08 214.301Z" fill="#FFDA33" />
        <path d="M360.975 243.453L374.171 253.289L371.58 256.358L359.668 245.001L360.975 243.453Z" fill="#FFDA33" />
        <path
          d="M10.5096 55.9258L26.184 58.899L32.7866 57.6641L33.061 63.7935L25.4677 61.6892C25.4677 61.6892 21.8541 65.4252 15.7242 64.5559C9.99505 63.7435 6.84641 60.4035 7.20084 58.0301C7.36194 56.951 8.77538 55.6781 10.5096 55.9258Z"
          fill="#336666"
        />
        <path
          d="M21.2839 61.019L24.0688 48.0479C24.0688 48.0479 18.3639 46.9803 13.3412 54.9284C11.9421 57.1416 10.5332 59.3998 10.5332 59.3998L20.7453 62.9604L21.2839 61.019Z"
          fill="#336666"
        />
        <path d="M8.11672 57.2656L4 58.5006L9.07707 60.8338L8.11672 57.2656Z" fill="#336666" />
        <path
          d="M33.5107 33.9258L49.185 36.8991L55.7875 35.6642L56.0615 41.7936L48.4687 39.6893C48.4687 39.6893 44.855 43.4252 38.7252 42.5558C32.996 41.7436 29.8475 38.4036 30.2018 36.0301C30.3629 34.9509 31.7763 33.6781 33.5107 33.9258Z"
          fill="#336666"
        />
        <path
          d="M44.284 39.019L47.0689 26.0479C47.0689 26.0479 41.3639 24.9803 36.3412 32.9284C34.9421 35.1416 33.5332 37.3998 33.5332 37.3998L43.7453 40.9604L44.284 39.019Z"
          fill="#336666"
        />
        <path d="M31.1166 35.2656L27 36.5005L32.0771 38.8337L31.1166 35.2656Z" fill="#336666" />
        <path
          d="M177.595 22.7066C177.595 28.5679 172.843 33.3195 166.982 33.3195C161.121 33.3195 156.369 28.5679 156.369 22.7066C156.369 16.8454 161.121 12.0938 166.982 12.0938C172.843 12.0938 177.595 16.8454 177.595 22.7066Z"
          fill="#FFDA33"
        />
        <path d="M370.433 39H359V46.4541H370.433L365.383 42.6583L370.433 39Z" fill="#FF6B1C" />
        <path d="M360.017 39H357V58.3278H360.017V39Z" fill="#FFDA33" />
        <mask
          id="mask15_5794_68402"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="143"
          y="227"
          width="230"
          height="75"
        >
          <path
            d="M243.589 228.162C232.998 229.633 227.858 234.513 210.965 241.723C204.757 244.372 202.056 245.907 194.978 249.282L197.119 262.132L157.269 254.531L153.815 263.975L148.747 261.902L143.686 268.581C182.887 304.029 227.87 310.836 273.438 283.163L372.874 301.751V228.271L279.814 233.339C274.537 229.118 264.079 227.461 254.324 227.461C250.523 227.461 246.829 227.712 243.589 228.162Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask15_5794_68402)">
          <path d="M301.214 253.609H299.875V295.072H301.214V253.609Z" fill="#754600" />
          <path d="M313.653 258.684H312.314V300.146H313.653V258.684Z" fill="#754600" />
          <path d="M326.091 257.07H324.752V298.533H326.091V257.07Z" fill="#754600" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5794_68402">
          <rect width="374" height="360" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
