import React from 'react'

import { SvgIcon } from '../CustomIcon'

const Heart = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M16.7708 1.83691C15.6917 1.8537 14.6361 2.15494 13.7106 2.71022C12.7852 3.26549 12.0226 4.05514 11.5 4.99941C10.9774 4.05514 10.2148 3.26549 9.28935 2.71022C8.36389 2.15494 7.3083 1.8537 6.22917 1.83691C4.5089 1.91166 2.88814 2.6642 1.72096 3.93014C0.553783 5.19607 -0.0649269 6.8725 7.18981e-07 8.59316C7.18981e-07 12.9507 4.58658 17.7098 8.43333 20.9365C9.29221 21.6582 10.3781 22.0539 11.5 22.0539C12.6219 22.0539 13.7078 21.6582 14.5667 20.9365C18.4134 17.7098 23 12.9507 23 8.59316C23.0649 6.8725 22.4462 5.19607 21.279 3.93014C20.1119 2.6642 18.4911 1.91166 16.7708 1.83691ZM13.3352 19.4702C12.8215 19.9028 12.1716 20.14 11.5 20.14C10.8284 20.14 10.1785 19.9028 9.66479 19.4702C4.74088 15.3389 1.91667 11.3752 1.91667 8.59316C1.85116 7.3806 2.26777 6.19132 3.07564 5.28471C3.88351 4.3781 5.0171 3.82771 6.22917 3.75358C7.44124 3.82771 8.57482 4.3781 9.38269 5.28471C10.1906 6.19132 10.6072 7.3806 10.5417 8.59316C10.5417 8.84733 10.6426 9.09109 10.8224 9.27081C11.0021 9.45053 11.2458 9.5515 11.5 9.5515C11.7542 9.5515 11.9979 9.45053 12.1776 9.27081C12.3574 9.09109 12.4583 8.84733 12.4583 8.59316C12.3928 7.3806 12.8094 6.19132 13.6173 5.28471C14.4252 4.3781 15.5588 3.82771 16.7708 3.75358C17.9829 3.82771 19.1165 4.3781 19.9244 5.28471C20.7322 6.19132 21.1488 7.3806 21.0833 8.59316C21.0833 11.3752 18.2591 15.3389 13.3352 19.4664V19.4702Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  )
}

export default Heart
