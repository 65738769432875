import React from 'react'

import { SvgIcon } from '../CustomIcon'
//
const Calendar = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7001 5.00684H28.1243C28.3949 5.00684 28.6546 5.11438 28.846 5.3057C29.0375 5.49702 29.1451 5.75669 29.1451 6.02739C29.1451 9.9266 29.1451 23.8572 29.1451 27.7564C29.1451 28.3201 28.6879 28.7769 28.1243 28.7769C23.7104 28.7769 6.28919 28.7769 1.87529 28.7769C1.31136 28.7769 0.854492 28.3201 0.854492 27.7564C0.854492 23.8572 0.854492 9.9266 0.854492 6.02739C0.854492 5.46371 1.31136 5.00684 1.87529 5.00684C3.61679 5.00684 6.97633 5.00684 6.97633 5.00684V6.75642C6.97633 7.3201 7.43321 7.77698 7.99689 7.77698H9.30087C9.86455 7.77698 10.3217 7.3201 10.3217 6.75642V5.00684H19.3545V6.75642C19.3545 7.3201 19.8116 7.77698 20.3753 7.77698H21.6793C22.243 7.77698 22.7001 7.3201 22.7001 6.75642V5.00684Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.18408 10.7607L28.8725 10.9704"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1308 15.4221C14.317 15.1215 14.6453 14.9385 14.9988 14.9385C15.3525 14.9385 15.6807 15.1215 15.8667 15.4221C16.4693 16.3956 17.1641 17.5185 17.1641 17.5185C17.1641 17.5185 18.4266 17.7801 19.5645 18.0163C19.9263 18.0913 20.2194 18.3558 20.3306 18.7081C20.442 19.0604 20.3543 19.4452 20.1013 19.7147C19.3362 20.529 18.5023 21.4168 18.5023 21.4168C18.5023 21.4168 18.6307 22.6455 18.7456 23.7469C18.7828 24.1048 18.629 24.4559 18.3407 24.671C18.0523 24.8863 17.6719 24.9336 17.3397 24.7959C16.2501 24.3444 14.9988 23.8261 14.9988 23.8261C14.9988 23.8261 13.7477 24.3444 12.658 24.7959C12.3256 24.9336 11.9454 24.8863 11.6571 24.671C11.3688 24.4559 11.2149 24.1048 11.2521 23.7469C11.367 22.6455 11.4954 21.4168 11.4954 21.4168C11.4954 21.4168 10.6615 20.529 9.89648 19.7147C9.64343 19.4452 9.55572 19.0604 9.66718 18.7081C9.7784 18.3558 10.0714 18.0913 10.433 18.0163C11.5711 17.7801 12.8335 17.5185 12.8335 17.5185C12.8335 17.5185 13.5284 16.3956 14.1308 15.4221Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.30013 1.22363C9.86381 1.22363 10.3209 1.68075 10.3209 2.24443C10.3209 3.47395 10.3209 5.52656 10.3209 6.75607C10.3209 7.31975 9.86381 7.77663 9.30013 7.77663C8.88661 7.77663 8.4099 7.77663 7.99614 7.77663C7.43246 7.77663 6.97559 7.31975 6.97559 6.75607C6.97559 5.52656 6.97559 3.47395 6.97559 2.24443C6.97559 1.68075 7.43246 1.22363 7.99614 1.22363C8.4099 1.22363 8.88661 1.22363 9.30013 1.22363Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6783 1.22363C22.242 1.22363 22.6991 1.68075 22.6991 2.24443C22.6991 3.47395 22.6991 5.52656 22.6991 6.75607C22.6991 7.31975 22.242 7.77663 21.6783 7.77663C21.2645 7.77663 20.7881 7.77663 20.3743 7.77663C19.8106 7.77663 19.3535 7.31975 19.3535 6.75607C19.3535 5.52656 19.3535 3.47395 19.3535 2.24443C19.3535 1.68075 19.8106 1.22363 20.3743 1.22363C20.7881 1.22363 21.2645 1.22363 21.6783 1.22363Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Calendar
