const setToMonday = (date) => {
  const day = date.getUTCDay() || 7
  if (day !== 1) date.setUTCHours(-24 * (day - 1))
  return date
}

const getDateFilterValue = (dateValue) => {
  let dateToFilter
  const today = new Date()
  switch (dateValue) {
    case 'Ultima Hora':
      // code block
      dateToFilter = new Date(today.getTime() - 1000 * 60 * 60)
      break
    case 'Hoy':
      // code block
      dateToFilter = new Date(today.setUTCHours(0, 0, 0, 0))
      break
    case 'Esta Semana':
      // code block
      dateToFilter = new Date(setToMonday(today).setUTCHours(0, 0, 0, 0))
      break
    case 'Este mes':
      // code block
      dateToFilter = new Date(
        new Date(today.getUTCFullYear(), today.getUTCMonth(), 1, 0, 0, 0, 0).setUTCHours(0, 0, 0, 0)
      )
      break
    case 'Este año':
      // code block
      dateToFilter = new Date(new Date(today.getUTCFullYear(), 0, 1, 0, 0, 0, 0).setUTCHours(0, 0, 0, 0))
      break
    default:
      // code block
      dateToFilter = null
  }

  return dateToFilter
}

export const createUrlByDefaultFilters = (pageValue, odsName, areaName, dateValue, type = null, authorUUID = null) => {
  const dateToFilter = getDateFilterValue(dateValue)

  const dateQuery = dateToFilter ? `&CreatedAt~gt=${dateToFilter.toISOString()}` : ''
  const odsQuery = odsName ? `&Extra.ODS=${odsName}` : ''
  const areaQuery = areaName ? `&Extra.Area=${areaName}` : ''
  const postTypeQuery = type ? `&Type=${type}` : ''
  const authorUUIDQuery = authorUUID ? `&AuthorUUID=${authorUUID}` : ''

  return `/posts?order=-Score,-CreatedAt${authorUUIDQuery}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes&offset=${
    pageValue * 5
  }&limit=5${postTypeQuery}${odsQuery}${areaQuery}${dateQuery}`
}

export const createUrlByInitiativeFilters = (url, stageName, collectionName) => {
  const stageQuery = stageName !== 'default' ? `&Extra.Stage=${stageName}` : ''
  const collectionQuery = collectionName !== 'default' ? `&Extra.Collection=${collectionName}` : ''
  return `${url}${stageQuery}${collectionQuery}`
}

export const createUrlByLessonFilters = (url, impactName, countryOfLesson) => {
  const impactQuery = impactName !== 'default' ? `&Extra.ImpactLevel=${impactName}` : ''
  const countryOfLessonQuery = countryOfLesson !== 'default' ? `&Extra.Country=${countryOfLesson}` : ''
  return `${url}${impactQuery}${countryOfLessonQuery}`
}

export const createUrlByDefaultFiltersForMultimedia = (
  pageValue,
  odsName,
  areaName,
  dateValue,
  type = null,
  authorUUID = null
) => {
  const dateToFilter = getDateFilterValue(dateValue)

  const dateQuery = dateToFilter ? `&CreatedAt~gt=${dateToFilter.toISOString()}` : ''
  const odsQuery = odsName ? `&Extra.ODS=${odsName}` : ''
  const areaQuery = areaName ? `&Extra.Area=${areaName}` : ''
  const postTypeQuery = type ? `&Type=${type}` : ''
  const authorUUIDQuery = authorUUID ? `&AuthorUUID=${authorUUID}` : ''
  return `/posts?order=-CreatedAt${authorUUIDQuery}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes&Metadata.Type=image&Metadata.Type=youtube${postTypeQuery}${odsQuery}${areaQuery}${dateQuery}`
}

export const createUrlByDefaultFiltersForDocuments = (
  pageValue,
  odsName,
  areaName,
  dateValue,
  type = null,
  authorUUID = null
) => {
  const dateToFilter = getDateFilterValue(dateValue)

  const dateQuery = dateToFilter ? `&CreatedAt~gt=${dateToFilter.toISOString()}` : ''
  const odsQuery = odsName ? `&Extra.ODS=${odsName}` : ''
  const areaQuery = areaName ? `&Extra.Area=${areaName}` : ''
  const postTypeQuery = type ? `&Type=${type}` : ''
  const authorUUIDQuery = authorUUID ? `&AuthorUUID=${authorUUID}` : ''
  return `/posts?order=-CreatedAt${authorUUIDQuery}&include=LikeCount,CommentCount,OwnLike,LastComment,Author,OwnSubscription,OwnBookmark,LatestLikes&Metadata.Type=file${postTypeQuery}${odsQuery}${areaQuery}${dateQuery}`
}
