import { SvgIcon } from '../../CustomIcon'

export const Referals = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7132 1.54395C20.6001 1.54395 26.2279 7.50263 26.7342 14.268C27.4809 24.2406 14.0229 30.4555 14.0229 30.4555C14.0229 30.4555 13.7701 26.1266 13.7132 26.1288C7.55513 26.3684 1.23535 20.6209 1.23535 13.8364C1.23535 7.05189 6.82659 1.54395 13.7132 1.54395Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1042 12.9878C15.9894 12.9878 17.5176 11.4596 17.5176 9.57447C17.5176 7.68933 15.9894 6.16113 14.1042 6.16113C12.2191 6.16113 10.6909 7.68933 10.6909 9.57447C10.6909 11.4596 12.2191 12.9878 14.1042 12.9878Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3577 15.0586C15.6009 15.0586 16.7932 15.5525 17.6722 16.4314C18.5511 17.3106 19.045 18.5027 19.045 19.7459C19.045 20.74 19.045 21.4965 19.045 21.4965H10.6907C10.42 21.4965 10.1604 21.3889 9.96903 21.1973C9.77747 21.006 9.66992 20.7463 9.66992 20.4757C9.66992 20.2554 9.66992 20.009 9.66992 19.7459C9.66992 18.5027 10.1638 17.3106 11.0427 16.4314C11.9219 15.5525 13.114 15.0586 14.3572 15.0586H14.3577Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
