export const odsList = [
  {
    index: 1,
    print: '/static/images/ods/01.png',
    name: 'Fin de la pobreza',
    description:
      'La pobreza extrema priva a millones de personas de sus derechos fundamentales y es una de las formas de injusticia más evidentes en el mundo.',
    goal: 'busca eliminar la pobreza extrema fomentando un desarrollo económico inclusivo y sostenible, estableciendo redes de seguridad social y garantizando el acceso a servicios básicos para todos',
    keywords: 'Acceso a la educación, la salud, el agua potable, los servicios básicos, etc.',
    challenges: 'Desigualdades económicas, acceso desigual a recursos, exclusión social, etc.',
    image: '/static/images/ods/ods-01.svg',
    bgColor: 'bg-ods-01',
    textColor: 'text-ods-01',
    color: '#DA263D',
  },
  {
    index: 2,
    print: '/static/images/ods/02.png',
    name: 'Hambre cero',
    description:
      'El hambre es un desafío global que afecta a millones de personas, especialmente en regiones donde la seguridad alimentaria es frágil.',
    goal: 'tiene como objetivo poner fin al hambre, logrando la seguridad alimentaria, mejorando la nutrición y promoviendo la agricultura sostenible.',
    keywords: 'Seguridad alimentaria, nutrición, agricultura sostenible, acceso a alimentos nutritivos, etc.',
    challenges: 'Desigualdades en el acceso a alimentos, cambio climático, pérdida de biodiversidad, etc.',
    image: '/static/images/ods/ods-02.svg',
    bgColor: 'bg-ods-02',
    textColor: 'text-ods-02',
    color: '#CA9435',
  },
  {
    index: 3,
    print: '/static/images/ods/03.png',
    name: 'Salud y bienestar',
    description: 'La salud es fundamental para el desarrollo humano y el progreso económico de los países.',
    goal: 'busca garantizar una vida sana y promover el bienestar para todos en todas las edades.',
    keywords: 'Acceso a servicios de salud, prevención de enfermedades, salud materna e infantil, salud mental, etc.',
    challenges: 'Acceso desigual a atención médica, enfermedades epidémicas, falta de infraestructura sanitaria, etc',
    image: '/static/images/ods/ods-03.svg',
    bgColor: 'bg-ods-03',
    textColor: 'text-ods-03',
    color: '#439439',
  },
  {
    index: 4,
    print: '/static/images/ods/04.png',
    name: 'Educación de calidad',
    description: 'La educación es fundamental para erradicar la pobreza y promover la igualdad de género.',
    goal: 'busca garantizar una educación inclusiva, equitativa y de calidad, promoviendo oportunidades de aprendizaje para todos.',
    keywords: 'Acceso a servicios de salud, prevención de enfermedades, salud materna e infantil, salud mental, etc.',
    challenges: 'Acceso desigual a atención médica, enfermedades epidémicas, falta de infraestructura sanitaria, etc.',
    image: '/static/images/ods/ods-04.svg',
    bgColor: 'bg-ods-04',
    textColor: 'text-ods-04',
    color: '#BC182D',
  },
  {
    index: 5,
    print: '/static/images/ods/05.png',
    name: 'Igualdad de género',
    description: 'La igualdad de género es fundamental para construir sociedades justas y sostenibles.',
    goal: 'busca lograr la igualdad de género y empoderar a todas las mujeres y niñas.',
    keywords:
      'Derechos de las mujeres, participación política, acceso a la educación y la atención médica, igualdad salarial, etc.',
    challenges:
      'Discriminación de género, violencia contra las mujeres, falta de representación femenina en puestos de liderazgo, etc.',
    image: '/static/images/ods/ods-05.svg',
    bgColor: 'bg-ods-05',
    textColor: 'text-ods-05',
    color: '#EB382F',
  },

  {
    index: 6,
    print: '/static/images/ods/06.png',
    name: 'Agua limpia y saneamiento',
    description:
      'El acceso al agua potable y al saneamiento es esencial para la salud, la nutrición y el desarrollo humano.',
    goal: 'tiene como objetivo garantizar la disponibilidad y la gestión sostenible del agua y el saneamiento para todos.',
    keywords: 'Acceso al agua potable, saneamiento básico, gestión sostenible de los recursos hídricos, higiene, etc.',
    challenges: 'Escasez de agua, contaminación del agua, acceso desigual al agua y al saneamiento, etc.',
    image: '/static/images/ods/ods-06.svg',
    bgColor: 'bg-ods-06',
    textColor: 'text-ods-06',
    color: '#15A5D0',
  },
  {
    index: 7,
    print: '/static/images/ods/07.png',
    name: 'Energía asequible y no contaminante',
    description:
      'El acceso a la energía es fundamental para el desarrollo económico y social, así como para la lucha contra el cambio climático.',
    goal: 'busca garantizar el acceso a una energía asequible, fiable, sostenible y moderna para todos.',
    keywords: 'Energías renovables, eficiencia energética, acceso a servicios energéticos, electrificación rural, etc.',
    challenges: 'Dependencia de combustibles fósiles, acceso desigual a la energía, contaminación del aire, etc.',
    image: '/static/images/ods/ods-07.svg',
    bgColor: 'bg-ods-07',
    textColor: 'text-ods-07',
    color: '#FBAD31',
  },
  {
    index: 8,
    print: '/static/images/ods/08.png',
    name: 'Trabajo decente y crecimiento económico',
    description:
      'El empleo digno y el crecimiento económico son fundamentales para reducir la pobreza y promover el desarrollo humano.',
    goal: 'busca promover un crecimiento económico sostenido, inclusivo y sostenible, y un trabajo decente para todos.',
    keywords:
      'Creación de empleo, trabajo digno, igualdad salarial, desarrollo de habilidades, protección social, etc.',
    challenges: 'Desempleo, trabajo informal, trabajo infantil, brecha salarial de género, etc.',
    image: '/static/images/ods/ods-08.svg',
    bgColor: 'bg-ods-08',
    textColor: 'text-ods-08',
    color: '#831732',
  },
  {
    index: 9,
    print: '/static/images/ods/09.png',
    name: 'Industria, innovación y infraestructura',
    description:
      'La infraestructura sólida y la innovación son fundamentales para el desarrollo sostenible y la creación de empleo.',
    goal: 'busca construir infraestructuras resilientes, promover la industrialización inclusiva y fomentar la innovación.',
    keywords: 'Desarrollo de infraestructuras, industrialización, innovación tecnológica, acceso a internet, etc.',
    challenges: 'Falta de infraestructura en áreas rurales, brecha digital, desarrollo industrial desigual, etc.',
    image: '/static/images/ods/ods-09.svg',
    bgColor: 'bg-ods-09',
    textColor: 'text-ods-09',
    color: '#F0612F',
  },
  {
    index: 10,
    print: '/static/images/ods/10.png',
    name: 'Reducción de las desigualidades',
    description:
      'La desigualdad económica, social y de género es un obstáculo para el desarrollo sostenible y la estabilidad social.',
    goal: 'busca reducir la desigualdad dentro de los países y entre ellos.',
    keywords: 'Desigualdad de ingresos, desigualdad de género, discriminación, inclusión social, etc.',
    challenges: 'Brecha salarial, falta de acceso a servicios básicos, discriminación racial y de género, etc.',
    image: '/static/images/ods/ods-10.svg',
    bgColor: 'bg-ods-10',
    textColor: 'text-ods-10',
    color: '#DC1678',
  },
  {
    index: 11,
    print: '/static/images/ods/11.png',
    name: 'Ciudades y comunidades sostentibles',
    description:
      'La urbanización rápida plantea desafíos para la planificación urbana, la vivienda adecuada y el acceso a servicios básicos.',
    goal: 'busca hacer que las ciudades y los asentamientos humanos sean inclusivos, seguros, resilientes y sostenibles.',
    keywords:
      'Planificación urbana, vivienda asequible, transporte sostenible, gestión de desastres, inclusión social, etc.',
    challenges:
      'Crecimiento urbano no planificado, falta de vivienda asequible, congestión del tráfico, vulnerabilidad a desastres naturales, etc',
    image: '/static/images/ods/ods-11.svg',
    bgColor: 'bg-ods-11',
    textColor: 'text-ods-11',
    color: '#F79235',
  },
  {
    index: 12,
    print: '/static/images/ods/12.png',
    name: 'Producción y consumo responsable',
    description:
      'El consumo excesivo y la producción insostenible tienen un impacto significativo en el medio ambiente y los recursos naturales.',
    goal: 'busca garantizar patrones de producción y consumo sostenibles.',
    keywords: 'Eficiencia en el uso de recursos, gestión de residuos, consumo responsable, etiquetado ecológico, etc.',
    challenges:
      'Explotación de recursos naturales, contaminación, desperdicio de alimentos, obsolescencia programada, etc.',
    image: '/static/images/ods/ods-12.svg',
    bgColor: 'bg-ods-12',
    textColor: 'text-ods-12',
    color: '#C78133',
  },
  {
    index: 13,
    print: '/static/images/ods/13.png',
    name: 'Acción por el clima',
    description:
      'El cambio climático es una de las mayores amenazas para el desarrollo sostenible y la seguridad global.',
    goal: 'busca tomar medidas urgentes para combatir el cambio climático y sus efectos.',
    keywords:
      'Reducción de emisiones de gases de efecto invernadero, adaptación al cambio climático, sensibilización ambiental, etc.',
    challenges: 'Aumento de la temperatura global, fenómenos climáticos extremos, acidificación de los océanos, etc.',
    image: '/static/images/ods/ods-13.svg',
    bgColor: 'bg-ods-13',
    textColor: 'text-ods-13',
    color: '#3F6B3A',
  },
  {
    index: 14,
    print: '/static/images/ods/14.png',
    name: 'Vida submarina',
    description: 'Los océanos son fundamentales para la vida en la Tierra y son vitales para la salud del planeta.',
    goal: 'busca conservar y utilizar de manera sostenible los océanos, mares y recursos marinos.',
    keywords:
      'Conservación marina, pesca sostenible, protección de ecosistemas marinos, reducción de la contaminación marina, etc.',
    challenges:
      'Sobrepesca, acidificación de los océanos, contaminación plástica, degradación de arrecifes de coral, etc.',
    image: '/static/images/ods/ods-14.svg',
    bgColor: 'bg-ods-14',
    textColor: 'text-ods-14',
    color: '#1172B0',
  },
  {
    index: 15,
    print: '/static/images/ods/15.png',
    name: 'Vida de ecosistemas terrestres',
    description:
      'Los ecosistemas terrestres son vitales para el suministro de alimentos, agua, aire limpio y la regulación del clima, además de ser el hogar de una gran cantidad de biodiversidad.',
    goal: 'busca proteger, restablecer y promover el uso sostenible de los ecosistemas terrestres, gestionar de forma sostenible los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras y detener la pérdida de biodiversidad.',
    keywords:
      'Conservación de la biodiversidad, gestión forestal sostenible, lucha contra la desertificación, restauración de ecosistemas degradados, etc.',
    challenges: 'Deforestación, degradación del suelo, pérdida de biodiversidad, expansión urbana no planificada, etc.',
    image: '/static/images/ods/ods-15.svg',
    bgColor: 'bg-ods-15',
    textColor: 'text-ods-15',
    color: '#37A647',
  },
  {
    index: 16,
    print: '/static/images/ods/16.png',
    name: 'Paz, justicia y instituciones solidas',
    description:
      'La paz, la justicia y las instituciones sólidas son fundamentales para el desarrollo sostenible y el respeto de los derechos humanos.',
    goal: 'busca promover sociedades pacíficas e inclusivas para el desarrollo sostenible, facilitar el acceso a la justicia para todos y construir instituciones eficaces, responsables e inclusivas a todos los niveles.',
    keywords:
      'Estado de derecho, acceso a la justicia, derechos humanos, prevención de conflictos, lucha contra la corrupción, etc',
    challenges: 'Conflictos armados, violaciones de derechos humanos, falta de acceso a la justicia, corrupción, etc.',
    image: '/static/images/ods/ods-16.svg',
    bgColor: 'bg-ods-16',
    textColor: 'text-ods-16',
    color: '#0D4C7D',
  },
  {
    index: 17,
    print: '/static/images/ods/17.png',
    name: 'Alianzas para lograr objetivos',
    description:
      'La cooperación internacional, la financiación y la tecnología son fundamentales para alcanzar todos los demás Objetivos de Desarrollo Sostenible (ODS).',
    goal: 'busca fortalecer los medios de implementación y revitalizar la alianza mundial para el desarrollo sostenible.',
    keywords:
      'Cooperación internacional, financiación para el desarrollo, transferencia de tecnología, intercambio de conocimientos, etc.',
    challenges: 'Falta de financiación, barreras comerciales, brecha tecnológica, falta de voluntad política, etc.',
    image: '/static/images/ods/ods-17.svg',
    bgColor: 'bg-ods-17',
    textColor: 'text-ods-17',
    color: '#19305B',
  },
]
