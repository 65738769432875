export const Slide2 = () => {
  return (
    <svg width="374" height="777" viewBox="0 0 374 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5794_68497)">
        <rect width="374" height="777" fill="white" />
        <rect width="374" height="777" fill="white" />
        <path d="M402.737 -55.7461H-44.5664V179.748H402.737V-55.7461Z" fill="#A5F4F7" />
        <path
          d="M263.039 92.1423C262.985 91.9957 257.59 77.3629 245.008 63.3751C228.678 45.2199 208.582 36.3948 183.573 36.3948C125.186 36.3948 107.065 89.1003 106.322 91.3442L106.363 91.2149L78.4092 82.4393C78.6549 81.6578 84.6018 63.097 100.763 44.449C121.939 20.0131 150.574 7.09766 183.573 7.09766C216.896 7.09766 244.894 19.4397 266.79 43.7817C283.09 61.9024 289.907 80.2556 290.63 82.2883L263.039 92.1423Z"
          fill="#FF6B1C"
        />
        <path
          d="M73.48 80.8916L45.5303 72.1071C46.3505 69.497 54.1231 45.9285 74.2514 22.3756C102.064 -10.1672 139.868 -27.3672 183.576 -27.3672C227.102 -27.3672 265.137 -10.2871 293.57 22.0291C314.164 45.4353 322.464 68.8294 323.343 71.4204L295.597 80.8294C295.529 80.6348 288.631 60.7674 271.575 41.3817C248.866 15.5721 218.437 1.92997 183.576 1.92997C148.7 1.92997 118.598 15.5822 96.5244 41.41C79.9513 60.8033 73.5428 80.6935 73.48 80.8916Z"
          fill="#FFDA33"
        />
        <path
          d="M282.148 85.4001C282.097 85.2545 275.842 67.4851 260.362 50.1459C240.217 27.5841 214.388 16.1447 183.59 16.1447C152.866 16.1447 127.398 27.5382 107.896 50.0078C92.8999 67.2865 87.1051 85.0044 87.049 85.1812L64.8633 78.1748C65.139 77.2942 71.84 56.3818 89.6608 35.5297C106.289 16.0726 136.083 -7.12109 183.59 -7.12109C221.127 -7.12109 253.906 7.58375 278.383 35.4028C296.677 56.1954 303.883 77.0455 304.18 77.9227L282.148 85.4001Z"
          fill="#33CC66"
        />
        <path
          d="M205.815 192.879V118.829C205.815 118.829 223.383 68.5731 255.627 54.1189C276.64 44.6998 293.153 49.4479 293.153 49.4479C293.153 49.4479 298.039 29.2145 314.636 19.5401C331.234 9.86581 344.574 11.3372 344.574 11.3372C353.032 -13.0979 369.88 -30.542 402.727 -29.4943V192.879H205.815Z"
          fill="#336666"
        />
        <path
          d="M152.336 192.879L152.335 118.829C152.335 118.829 134.768 68.5731 102.524 54.1189C81.5119 44.6998 64.9977 49.4479 64.9977 49.4479C64.9977 49.4479 60.112 29.2145 43.514 19.5401C26.9167 9.86581 13.5763 11.3372 13.5763 11.3372C5.11866 -13.0979 -11.7281 -30.542 -44.5762 -29.4943V192.879H152.335H152.336Z"
          fill="#336666"
        />
        <path
          d="M402.737 5.30192V155.738L183.588 250.469V119.839C183.588 119.839 186.924 111.515 202.491 106.497C218.056 101.478 232.733 106.497 232.733 106.497C232.733 106.497 241.307 88.3587 261.814 80.3536C282.322 72.3477 303.504 80.3536 303.504 80.3536C303.504 80.3536 305.005 59.9248 326.353 44.4547C347.7 28.9847 363.044 32.5431 363.044 32.5431C363.044 32.5431 369.806 12.3071 383.058 7.41523C396.31 2.52238 402.737 5.30192 402.737 5.30192Z"
          fill="white"
        />
        <path
          d="M-44.5664 5.30192V155.738L174.583 250.469V119.839C174.583 119.839 171.247 111.515 155.681 106.497C140.116 101.478 125.438 106.497 125.438 106.497C125.438 106.497 116.864 88.3587 96.3566 80.3536C75.849 72.3477 54.668 80.3536 54.668 80.3536C54.668 80.3536 53.1667 59.9248 31.819 44.4547C10.4713 28.9847 -4.87314 32.5431 -4.87314 32.5431C-4.87314 32.5431 -11.6346 12.3071 -24.8858 7.41523C-38.138 2.52238 -44.5664 5.30192 -44.5664 5.30192Z"
          fill="white"
        />
        <path
          d="M228.275 213.784V406.916H402.728V123.221C402.728 123.221 387.884 119.593 374.374 126.39C360.865 133.188 358.196 143.402 358.196 143.402C358.196 143.402 343.181 137.398 328.677 146.403C314.174 155.41 312.16 168.419 312.16 168.419C312.16 168.419 295.655 159.246 278.977 169.753C262.299 180.261 262.113 199.397 262.113 199.397C262.113 199.397 251.321 193.175 241.49 197.237C231.66 201.299 228.275 213.784 228.275 213.784"
          fill="#00CCFF"
        />
        <path
          d="M129.875 213.784V406.916H-44.5762V123.221C-44.5762 123.221 -29.7331 119.593 -16.2234 126.39C-2.71473 133.188 -0.0463625 143.402 -0.0463625 143.402C-0.0463625 143.402 14.9706 137.398 29.4738 146.403C43.9769 155.41 45.9917 168.419 45.9917 168.419C45.9917 168.419 62.4964 159.246 79.1738 169.753C95.8519 180.261 96.0374 199.397 96.0374 199.397C96.0374 199.397 106.83 193.175 116.661 197.237C126.492 201.299 129.875 213.784 129.875 213.784"
          fill="#00CCFF"
        />
        <path d="M231.26 188H126.741L-376 777H807.5L231.26 188Z" fill="#33CC66" />
        <path
          d="M327.356 355.987H443.085L463.532 338.123L437.373 330.882L448.005 305.91L421.294 310.74L420.078 283.622L398.111 299.561L385.221 275.68L372.331 299.561L350.363 283.622L349.147 310.741L322.436 305.911L333.068 330.882L306.91 338.123L327.356 355.987Z"
          fill="#336666"
        />
        <path
          d="M285.832 330.002H261.495C261.495 325.232 266.942 321.363 273.663 321.363C280.383 321.363 285.832 325.232 285.832 330.002Z"
          fill="#336666"
        />
        <path
          d="M312.155 277.462H287.817C287.817 272.692 293.266 268.824 299.987 268.824C306.707 268.824 312.155 272.692 312.155 277.462Z"
          fill="#336666"
        />
        <path
          d="M88.122 275.021H63.7842C63.7842 270.251 69.2323 266.383 75.9527 266.383C82.6731 266.383 88.122 270.251 88.122 275.021Z"
          fill="#336666"
        />
        <path
          d="M335.257 109.307C325.27 105.748 320.548 101.026 316.989 91.0382C315.752 87.5706 310.87 87.5706 309.635 91.0382C306.075 101.026 301.354 105.748 291.366 109.307C287.899 110.544 287.899 115.425 291.366 116.661C301.354 120.221 306.075 124.942 309.635 134.929C310.871 138.397 315.752 138.397 316.989 134.929C320.548 124.942 325.27 120.221 335.257 116.661C338.725 115.425 338.725 110.544 335.257 109.307Z"
          fill="#FFDA33"
        />
        <path
          d="M74.4103 310.035C69.2858 308.208 66.8644 305.787 65.0377 300.662C64.4034 298.883 61.8993 298.883 61.2651 300.662C59.4392 305.787 57.017 308.208 51.8925 310.035C50.114 310.669 50.114 313.173 51.8925 313.807C57.017 315.634 59.4392 318.055 61.2651 323.18C61.8993 324.959 64.4034 324.959 65.0377 323.18C66.8644 318.055 69.2858 315.634 74.4103 313.807C76.1898 313.173 76.1898 310.669 74.4103 310.035Z"
          fill="white"
        />
        <path
          d="M135.356 30.0583C128.148 27.4887 124.74 24.0818 122.171 16.8735C121.279 14.3703 117.756 14.3703 116.864 16.8735C114.294 24.0818 110.887 27.4887 103.679 30.0583C101.176 30.95 101.176 34.474 103.679 35.3658C110.887 37.9354 114.294 41.3425 116.864 48.5505C117.756 51.0537 121.279 51.0537 122.171 48.5505C124.74 41.3425 128.148 37.9354 135.356 35.3658C137.859 34.474 137.859 30.95 135.356 30.0583Z"
          fill="white"
        />
        <path
          d="M133.723 256.512L126.354 297.018L149.033 301.145L156.708 258.958C156.708 258.958 134.003 256.563 133.723 256.512Z"
          fill="#336666"
        />
        <path
          d="M126.36 297.026C126.36 297.026 125.673 300.807 124.794 301.659C123.916 302.512 120.454 302.461 114.191 305.082C107.929 307.703 108.947 317.614 108.947 317.614L144.608 323.921L149.32 299.613L134.684 297.384C134.684 297.384 133.704 294.023 130.637 293.393C127.57 292.762 127.22 292.699 127.22 292.699C127.22 292.699 126.615 295.626 126.36 297.026Z"
          fill="#FFDA33"
        />
        <path
          d="M143.05 326.102L109.353 319.971C108.116 319.746 107.297 318.562 107.522 317.326L107.964 314.896C108.189 313.659 109.373 312.84 110.609 313.064L144.306 319.196C145.542 319.421 146.362 320.604 146.137 321.841L145.695 324.271C145.47 325.507 144.286 326.327 143.05 326.102Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M125.305 299.941L124.973 301.632L131.272 302.87L131.604 301.18L125.305 299.941Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M125.968 296.242L125.635 297.932L131.934 299.171L132.267 297.481L125.968 296.242Z"
          fill="white"
        />
        <path
          d="M133.598 298.533C133.453 299.329 132.69 299.857 131.893 299.713C131.097 299.568 130.569 298.805 130.713 298.009C130.859 297.213 131.622 296.684 132.419 296.829C133.215 296.973 133.743 297.737 133.598 298.533Z"
          fill="white"
        />
        <path
          d="M132.916 302.279C132.771 303.075 132.008 303.603 131.212 303.459C130.415 303.314 129.886 302.55 130.031 301.754C130.176 300.958 130.939 300.43 131.736 300.575C132.532 300.719 133.06 301.483 132.916 302.279Z"
          fill="white"
        />
        <path
          d="M224.966 256.512L232.335 297.018L209.655 301.145L201.979 258.958C201.979 258.958 224.686 256.563 224.966 256.512Z"
          fill="#336666"
        />
        <path
          d="M232.328 297.026C232.328 297.026 233.016 300.807 233.894 301.659C234.773 302.512 238.234 302.461 244.497 305.082C250.759 307.703 249.742 317.614 249.742 317.614L214.079 323.921L209.368 299.613L224.004 297.384C224.004 297.384 224.984 294.023 228.052 293.393C231.119 292.762 231.468 292.699 231.468 292.699C231.468 292.699 232.073 295.626 232.328 297.026Z"
          fill="#FFDA33"
        />
        <path
          d="M215.638 326.102L249.335 319.971C250.572 319.746 251.391 318.562 251.166 317.326L250.724 314.896C250.499 313.659 249.314 312.84 248.079 313.064L214.382 319.196C213.146 319.421 212.326 320.604 212.551 321.841L212.993 324.271C213.218 325.507 214.402 326.327 215.638 326.102Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M233.404 299.941L227.104 301.177L227.437 302.87L233.736 301.635L233.404 299.941Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M232.721 296.242L226.422 297.477L226.754 299.171L233.053 297.935L232.721 296.242Z"
          fill="white"
        />
        <path
          d="M225.089 298.533C225.234 299.329 225.998 299.857 226.795 299.713C227.591 299.568 228.12 298.805 227.975 298.009C227.829 297.213 227.067 296.684 226.27 296.829C225.473 296.973 224.945 297.737 225.089 298.533Z"
          fill="white"
        />
        <path
          d="M225.772 302.279C225.917 303.075 226.68 303.603 227.476 303.459C228.274 303.314 228.802 302.55 228.657 301.754C228.511 300.958 227.748 300.43 226.951 300.575C226.155 300.719 225.627 301.483 225.772 302.279Z"
          fill="white"
        />
        <path
          d="M268.499 162.889C270.915 159.368 275.278 157.723 279.409 158.806C288.062 161.076 302.201 165.734 306.703 172.812C315.955 187.354 291.622 206.991 291.622 206.991L283.795 191.907C283.795 191.907 290.911 184.935 289.346 182.658C288.362 181.227 281.862 179.779 275.153 178.634C267.854 177.389 264.309 168.993 268.499 162.889Z"
          fill="#336666"
        />
        <path
          d="M94.4083 162.889C91.9922 159.368 87.6286 157.723 83.4976 158.806C74.8444 161.076 60.7063 165.734 56.2031 172.812C46.9518 187.354 71.2847 206.991 71.2847 206.991L79.1118 191.907C79.1118 191.907 71.9954 184.935 73.5612 182.658C74.5452 181.227 81.0451 179.779 87.7541 178.634C95.0528 177.389 98.598 168.993 94.4083 162.889Z"
          fill="#336666"
        />
        <path
          d="M288.125 163.221C288.125 221.691 240.725 269.091 182.255 269.091C123.784 269.091 76.3857 221.691 76.3857 163.221C76.3857 104.75 123.784 57.3516 182.255 57.3516C240.725 57.3516 288.125 104.75 288.125 163.221Z"
          fill="#00CCFF"
        />
        <mask
          id="mask0_5794_68497"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="76"
          y="57"
          width="213"
          height="213"
        >
          <path
            d="M76.375 163.221C76.3748 221.691 123.774 269.091 182.245 269.091C240.715 269.091 288.114 221.691 288.114 163.221C288.114 104.75 240.715 57.3516 182.244 57.3516C123.774 57.3516 76.375 104.75 76.375 163.221Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5794_68497)">
          <path
            d="M156.636 72.0599C156.743 79.7136 162.911 85.8977 170.564 86.0234L198.725 86.4895C201.351 86.5327 203.913 87.3058 206.125 88.7216L221.037 98.2638C226.894 102.013 234.607 100.954 239.238 95.766L250.24 83.4375C256.491 76.4327 254.322 65.3947 245.883 61.2784L234.895 55.9176C232.722 54.8577 230.314 54.3719 227.9 54.5063L170.012 57.7215C162.431 58.143 156.531 64.4684 156.636 72.0599Z"
            fill="#FFDA33"
          />
          <path
            d="M251.727 290.57L232.389 280.116C222.209 274.613 219.418 261.28 226.538 252.157C229.493 248.371 230.878 243.594 230.408 238.815L228.01 214.5C227.469 209.014 229.377 203.567 233.222 199.617L242.476 190.114C246.134 186.358 248.047 181.236 247.747 176.001L246.826 159.868C246.312 150.884 252.27 142.808 261.006 140.646L289.064 133.705C296.385 131.893 304.078 134.657 308.574 140.713L347.685 193.41C352.24 199.547 352.602 207.838 348.601 214.349L324.857 252.984C322.769 256.381 321.822 260.356 322.153 264.329L323.196 276.851C324.165 288.483 314.36 298.151 302.743 297.018L258.804 292.73C256.325 292.489 253.918 291.754 251.727 290.57Z"
            fill="#FFDA33"
          />
          <path
            d="M71.0804 144.98H88.8085C95.8753 144.98 101.66 150.603 101.862 157.666L102.864 192.786C103.058 199.554 108.391 205.052 115.151 205.449C123.751 205.955 129.513 214.505 126.753 222.667L115.123 257.064C113.802 260.971 114.404 265.272 116.747 268.666L124.718 280.21C131.749 290.393 121.989 303.734 110.155 300.117L41.4294 279.106C39.1262 278.402 37.0627 277.075 35.4667 275.271L15.9461 253.204C13.2818 250.192 12.1534 246.12 12.8876 242.167L18.1733 213.708C18.7834 210.42 20.6322 207.493 23.337 205.528L50.045 186.125C52.9325 184.028 54.8357 180.84 55.3113 177.304L58.1396 156.297C59.0117 149.817 64.542 144.98 71.0804 144.98Z"
            fill="#FFDA33"
          />
        </g>
        <path d="M185.942 154.163L183.512 152.766L173.942 159.833L185.217 167.511L185.942 154.163Z" fill="#FF6B1C" />
        <path
          d="M158.788 115.595C155.974 114.987 153.443 115.185 150.237 115.437L149.269 115.513L149.072 112.936L150.036 112.861C153.314 112.602 156.149 112.38 159.333 113.068L158.788 115.595Z"
          fill="#336666"
        />
        <path
          d="M213.343 110.34C212.456 108.917 210.778 108.127 208.828 108.22C207.083 108.305 205.596 109.093 205.036 110.225L202.719 109.08C203.703 107.091 205.995 105.771 208.701 105.637C211.575 105.485 214.145 106.741 215.536 108.971L213.343 110.34Z"
          fill="#336666"
        />
        <path d="M198.205 58.7812L234.064 59.9704L234.291 62.7025H204.543L198.205 58.7812Z" fill="#FF6B1C" />
        <path
          d="M161.849 62.7134H204.544C204.544 62.7134 204.544 38.1121 183.196 39.7804C161.848 41.4477 161.849 62.7134 161.849 62.7134Z"
          fill="#FF6B1C"
        />
        <path
          d="M184.039 176.707C183.392 176.707 182.74 176.669 182.081 176.591C176.428 175.928 172.096 172.687 171.403 171.568L173.586 170.185C174.035 170.784 177.924 173.712 183.027 174.086C187.101 174.381 190.766 172.941 193.919 169.801L195.744 171.633C192.387 174.975 188.372 176.707 184.039 176.707Z"
          fill="#336666"
        />
        <path
          d="M284.63 191.367C284.63 191.367 277.35 191.77 272.703 194.738C269.727 196.639 268.893 197.412 268.893 197.412L270.284 200.71L276.851 198.472L276.921 198.675L284.63 191.367Z"
          fill="#336666"
        />
        <path
          d="M276.895 198.689C276.394 199.796 266.748 206.483 266.748 206.483L267.804 209.361L277.222 204.747L277.29 205.718L267.667 211.461L268.793 214.792L279.212 208.846L279.447 209.376L269.979 215.681L271.191 218.092C275.008 216.43 282.608 212.452 282.608 212.452L282.678 212.905L274.901 217.394L276.32 220.008C276.32 220.008 276.707 220.679 284.476 216.292C289.902 213.229 291.867 206.252 291.867 206.252L284.616 191.367L276.895 198.689Z"
          fill="#336666"
        />
        <path
          d="M78.2773 191.367C78.2773 191.367 85.5582 191.77 90.2043 194.738C93.1807 196.639 94.0141 197.412 94.0141 197.412L92.6222 200.71L86.056 198.472L85.9858 198.675L78.2773 191.367Z"
          fill="#336666"
        />
        <path
          d="M86.0119 198.689C86.5134 199.796 96.16 206.483 96.16 206.483L95.1042 209.361L85.6864 204.747L85.6177 205.718L95.2399 211.461L94.1162 214.792L83.6958 208.846L83.4608 209.376L92.9287 215.681L91.7169 218.092C87.8994 216.43 80.2989 212.452 80.2989 212.452L80.2302 212.905L88.0065 217.394L86.5879 220.008C86.5879 220.008 86.2011 220.679 78.432 216.292C73.0059 213.229 71.041 206.252 71.041 206.252L78.2922 191.367L86.0119 198.689Z"
          fill="#336666"
        />
        <path d="M199.168 57.4375H166.543V60.0226H199.168V57.4375Z" fill="#336666" />
        <path
          d="M172.581 142.396C172.581 154.974 162.385 165.17 149.807 165.17C137.228 165.17 127.031 154.974 127.031 142.396C127.031 129.818 137.228 119.621 149.807 119.621C162.385 119.621 172.581 129.818 172.581 142.396Z"
          fill="#336666"
        />
        <path
          d="M236.175 142.396C236.175 154.974 225.979 165.17 213.4 165.17C200.821 165.17 190.625 154.974 190.625 142.396C190.625 129.818 200.821 119.621 213.4 119.621C225.979 119.621 236.175 129.818 236.175 142.396Z"
          fill="#336666"
        />
        <path
          d="M191.856 145.085C183.508 139.867 171.009 144.944 170.883 144.995L169.895 142.608C170.456 142.375 183.759 136.978 193.226 142.893L191.856 145.085Z"
          fill="#336666"
        />
        <path
          d="M128.284 138.715L113.102 126.641C111.605 125.451 109.599 125.017 107.748 125.481L105.07 126.151L104.441 123.644L107.119 122.973C109.751 122.316 112.589 122.931 114.711 124.619L129.892 136.693L128.284 138.715Z"
          fill="#336666"
        />
        <path
          d="M234.769 138.716L233.16 136.693L248.341 124.62C250.463 122.932 253.3 122.315 255.933 122.973L258.611 123.644L257.983 126.151L255.305 125.482C253.453 125.018 251.448 125.451 249.95 126.643L234.769 138.716Z"
          fill="#336666"
        />
        <path d="M185.942 154.163L183.512 152.766L173.942 159.833L185.217 167.511L185.942 154.163Z" fill="#FF6B1C" />
        <path
          d="M158.788 115.595C155.974 114.987 153.443 115.185 150.237 115.437L149.269 115.513L149.072 112.936L150.036 112.861C153.314 112.602 156.149 112.38 159.333 113.068L158.788 115.595Z"
          fill="#336666"
        />
        <path
          d="M213.343 110.34C212.456 108.917 210.778 108.127 208.828 108.22C207.083 108.305 205.596 109.093 205.036 110.225L202.719 109.08C203.703 107.091 205.995 105.771 208.701 105.637C211.575 105.485 214.145 106.741 215.536 108.971L213.343 110.34Z"
          fill="#336666"
        />
        <path d="M198.205 58.7812L234.064 59.9704L234.291 62.7025H204.543L198.205 58.7812Z" fill="#FF6B1C" />
        <path
          d="M161.849 62.7134H204.544C204.544 62.7134 204.544 38.1121 183.196 39.7804C161.848 41.4477 161.849 62.7134 161.849 62.7134Z"
          fill="#FF6B1C"
        />
        <path
          d="M184.039 176.707C183.392 176.707 182.74 176.669 182.081 176.591C176.428 175.928 172.096 172.687 171.403 171.568L173.586 170.185C174.035 170.784 177.924 173.712 183.027 174.086C187.101 174.381 190.766 172.941 193.919 169.801L195.744 171.633C192.387 174.975 188.372 176.707 184.039 176.707Z"
          fill="#336666"
        />
        <path
          d="M284.63 191.367C284.63 191.367 277.35 191.77 272.703 194.738C269.727 196.639 268.893 197.412 268.893 197.412L270.284 200.71L276.851 198.472L276.921 198.675L284.63 191.367Z"
          fill="#336666"
        />
        <path
          d="M276.895 198.689C276.394 199.796 266.748 206.483 266.748 206.483L267.804 209.361L277.222 204.747L277.29 205.718L267.667 211.461L268.793 214.792L279.212 208.846L279.447 209.376L269.979 215.681L271.191 218.092C275.008 216.43 282.608 212.452 282.608 212.452L282.678 212.905L274.901 217.394L276.32 220.008C276.32 220.008 276.707 220.679 284.476 216.292C289.902 213.229 291.867 206.252 291.867 206.252L284.616 191.367L276.895 198.689Z"
          fill="#336666"
        />
        <path
          d="M78.2773 191.367C78.2773 191.367 85.5582 191.77 90.2043 194.738C93.1807 196.639 94.0141 197.412 94.0141 197.412L92.6222 200.71L86.056 198.472L85.9858 198.675L78.2773 191.367Z"
          fill="#336666"
        />
        <path
          d="M86.0119 198.689C86.5134 199.796 96.16 206.483 96.16 206.483L95.1042 209.361L85.6864 204.747L85.6177 205.718L95.2399 211.461L94.1162 214.792L83.6958 208.846L83.4608 209.376L92.9287 215.681L91.7169 218.092C87.8994 216.43 80.2989 212.452 80.2989 212.452L80.2302 212.905L88.0065 217.394L86.5879 220.008C86.5879 220.008 86.2011 220.679 78.432 216.292C73.0059 213.229 71.041 206.252 71.041 206.252L78.2922 191.367L86.0119 198.689Z"
          fill="#336666"
        />
        <path d="M199.168 57.4375H166.543V60.0226H199.168V57.4375Z" fill="#336666" />
        <path
          d="M172.581 142.396C172.581 154.974 162.385 165.17 149.807 165.17C137.228 165.17 127.031 154.974 127.031 142.396C127.031 129.818 137.228 119.621 149.807 119.621C162.385 119.621 172.581 129.818 172.581 142.396Z"
          fill="#336666"
        />
        <path
          d="M236.175 142.396C236.175 154.974 225.979 165.17 213.4 165.17C200.821 165.17 190.625 154.974 190.625 142.396C190.625 129.818 200.821 119.621 213.4 119.621C225.979 119.621 236.175 129.818 236.175 142.396Z"
          fill="#336666"
        />
        <path
          d="M191.856 145.085C183.508 139.867 171.009 144.944 170.883 144.995L169.895 142.608C170.456 142.375 183.759 136.978 193.226 142.893L191.856 145.085Z"
          fill="#336666"
        />
        <path
          d="M128.284 138.715L113.102 126.641C111.605 125.451 109.599 125.017 107.748 125.481L105.07 126.151L104.441 123.644L107.119 122.973C109.751 122.316 112.589 122.931 114.711 124.619L129.892 136.693L128.284 138.715Z"
          fill="#336666"
        />
        <path
          d="M234.769 138.716L233.16 136.693L248.341 124.62C250.463 122.932 253.3 122.315 255.933 122.973L258.611 123.644L257.983 126.151L255.305 125.482C253.453 125.018 251.448 125.451 249.95 126.643L234.769 138.716Z"
          fill="#336666"
        />
      </g>
      <defs>
        <clipPath id="clip0_5794_68497">
          <rect width="374" height="777" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
