import Image from 'next/image'

import HeroImage from '@/public/static/images/landing/hero.svg'
import { scrollToVideo } from '@/utils/landing'

import Button, { ButtonAppearance, ButtonSize } from '../base/Button'
import Container from '../base/Container'
import CustomText, { FontWeight, TextSize } from '../base/CustomText'
import { Rocket } from './Rocket'

export const Hero = () => {
  return (
    <section className="pb-12" style={{ background: 'linear-gradient(180deg, #F8F9FF 0%, #FFFFFF 100%)' }}>
      <Container maxWidth={1200} className="flex flex-col-reverse md:flex-row w-full items-center justify-between">
        <div className="w-full md:w-1/2 space-y-8">
          <CustomText weight={FontWeight.ExtraBold} className="md:!text-4xl !text-3xl">
            Construye un mundo mejor y consigue tokens de impacto
          </CustomText>
          <CustomText size={TextSize.Heading2}>
            Sunet recompensa las buenas acciones con tokens que puedes canjear por productos y servicios.
          </CustomText>
          <div className="gap-4 flex justify-center md:justify-start items-center w-full flex-wrap">
            <p className="text-accent-1 font-semibold">Pronto</p>
            {/* <a href="https://onelink.to/etk387">
              <Button className="rounded-xl" appearance={ButtonAppearance.Primary} size={ButtonSize.Big}>
                <span className="flex gap-1 items-center">
                  <Rocket />
                  <span>Descarga la App</span>
                </span>
              </Button>
            </a>
            <Button
              onClick={scrollToVideo}
              className="rounded-xl"
              appearance={ButtonAppearance.PrimaryOutline}
              size={ButtonSize.Big}
            >
              Ver video
            </Button> */}
          </div>
        </div>
        <div className="relative h-full flex items-center md:w-1/2">
          <Image src={HeroImage} alt="Tokens illustration" />
        </div>
      </Container>
    </section>
  )
}
