import { i18n } from '@lingui/core'
import { format } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

export const simpleFormatDate = (date: string) => {
  return format(new Date(date), 'dd/MM/yyyy', { locale: es })
}

const locales = {
  en: enUS,
  es: es,
}

export const getDateFnsLocale = () => {
  if (i18n?.locale && Object.prototype.hasOwnProperty.call(locales, i18n.locale)) {
    return locales[i18n.locale]
  } else {
    return locales['es']
  }
}

// Remember that the month is 0-based so February is actually 1.
export const isValidDate = (year, month, day) => {
  const d = new Date(year, month, day)
  if (d) {
    return d?.getFullYear() === parseInt(year) && d?.getMonth() === parseInt(month) && d?.getDate() === parseInt(day)
  } else {
    return false
  }
}

// Transform Date to LocaleSTring Data - (month, year, day) => December, 2012
// https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
export const formatDateToLocaleString = (month, year, day = 1, withDay = false, withUTC = true) => {
  const optionsWithoutDay = { year: 'numeric', month: 'long' }
  const optionsWithDay = { year: 'numeric', month: 'long', day: 'numeric' }
  const locale = getDateFnsLocale()

  if (withUTC) {
    const dateToChangeUTC = new Date(Date.UTC(year, month, day))
    return dateToChangeUTC.toLocaleDateString(locale.code, withDay ? optionsWithDay : optionsWithoutDay)
  } else {
    const dateToChange = new Date(year, month, day)
    return dateToChange.toLocaleDateString(locale.code, withDay ? optionsWithDay : optionsWithoutDay)
  }
}

export const formatMonthToLocaleString = (month) => {
  const options = { month: 'long' }
  const today = new Date()
  const locale = getDateFnsLocale()
  const dateToChange = new Date(Date.UTC(today.getFullYear(), month, 1))
  return dateToChange.toLocaleDateString(locale.code, options)
}

// Convert string in ISO8601 format to date object
// e.g. 2013-02-08T02:40:00Z
//

/**
 * Convert string in ISO8601 format to date object e.g. 2013-02-08T02:40:00Z
 * @param {string } s
 * @return {Date}
 */
export const isoToObj = (s) => {
  const b = s.split(/[-TZ:]/i)
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]))
}

// https://stackoverflow.com/questions/14764029/javascript-time-until
/**
 * Convert date string in ISO8601 to Object with days, hours, minutes, seconds
 * @param {string } s
 * @return {Object}
 */
export const timeToGo = (s) => {
  // Convert string to date object
  const d = isoToObj(s)
  let diff = d - new Date()

  const diffSign = Math.sign(diff)
  if (diffSign === -1) {
    return 'ended'
  }

  diff = Math.abs(diff)

  // Get time components
  const hours = (diff / 3.6e6) | 0
  const days = Math.floor(hours / 24)
  const restHours = Math.floor(hours % 24)

  const mins = ((diff % 3.6e6) / 6e4) | 0
  const secs = Math.round((diff % 6e4) / 1e3)

  return { days: days, hours: restHours, minutes: mins, secs: secs }
}

/*
export const getTimeByMin = (date) => {
  const maxDate = isoToObj(date)
  const today = new Date()

  const minutes = differenceInMinutes(maxDate, today)
  let hours = minutesToHours(minutes)

  if (hours >= 24) {
    let days = Math.floor(hours / 24)
    let restHoursComplete = truncateToDecimals(Math.abs(hours / 24 - days) * 24)
    let restHours = Math.floor(restHoursComplete)
    //let restHours = Math.round(hours % 24)
   // console.log('restHoursComplete ', restHoursComplete)
   // console.log('restHours ', restHours)
   // console.log('(hours / 24)-days) * 24', (hours / 24 - days) * 24)
    let restMins = hoursToMinutes(Math.abs(restHours - restHoursComplete))
   // console.log('restMins ', restMins)

    return { days: days, hours: restHours, minutes: restMins }
  } else if (hours >= 1) {
    let restMin = minutes - hoursToMinutes(hours)
    return { days: 0, hours: hours, minutes: restMin }
  } else return { days: 0, hours: 0, minutes: minutes }
}
*/
