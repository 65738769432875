import { SvgIcon } from '../../CustomIcon'

const EcoHouse = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0288 1.66895L16.1302 13.0988"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16.5C16 16.5 19 21.2987 19 23.3555C19 25.0911 17.6557 26.5 16 26.5C14.3443 26.5 13 25.0911 13 23.3555C13 21.2987 16 16.5 16 16.5Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.35884 13.269C1.99604 13.2704 1.65969 13.0796 1.47523 12.767C1.29076 12.4547 1.28611 12.0679 1.46273 11.7509C2.93232 9.11645 5.87737 3.83658 6.80826 2.16734C6.98709 1.84716 7.32393 1.64727 7.69065 1.64408C10.3731 1.62032 21.352 1.5238 24.0508 1.50004C24.422 1.49685 24.7654 1.69528 24.9482 2.01815C25.8979 3.69719 28.8922 8.99127 30.3807 11.6228C30.559 11.938 30.5571 12.3241 30.375 12.6374C30.1933 12.9507 29.8591 13.1443 29.4968 13.146C24.1101 13.1702 7.75385 13.2445 2.35884 13.269Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.32227 6.62502L27.4481 6.47363"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.15626 13.5C4.15626 13.5 4.04124 24.2218 4.0001 28.0628C3.99628 28.4189 4.09911 28.7627 4.28588 29.0168C4.47241 29.2709 4.72734 29.4142 4.9935 29.4155C8.89894 29.4305 23.0091 29.485 26.8803 29.5C27.4275 29.5022 27.8735 28.9134 27.8797 28.1815C27.911 24.3594 28 13.5707 28 13.5707"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EcoHouse
