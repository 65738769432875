import React from 'react'

import { SvgIcon } from '../CustomIcon'

const iluToken = ({ size, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 55 55" {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 27.5C0.5 42.3891 12.6109 54.5 27.5 54.5C42.3879 54.5 54.5 42.3891 54.5 27.5C54.5 12.612 42.388 0.5 27.5 0.5C12.6109 0.5 0.5 12.6121 0.5 27.5Z"
        fill="#FFC843"
        stroke="#474D54"
      />
      <path
        d="M27.4918 50.1997C14.9705 50.1997 4.78491 40.0141 4.78491 27.4928C4.78491 14.9725 14.9705 4.78589 27.4918 4.78589C40.0121 4.78589 50.1987 14.9725 50.1987 27.4928C50.1987 40.0141 40.0121 50.1997 27.4918 50.1997Z"
        fill="#E37F31"
      />
      <path
        d="M47.0456 27.4925C47.0456 38.2925 38.2901 47.0471 27.4911 47.0471C16.6911 47.0471 7.93652 38.2925 7.93652 27.4925C7.93652 16.6935 16.6911 7.93799 27.4911 7.93799C38.2901 7.93799 47.0456 16.6935 47.0456 27.4925Z"
        fill="#FFC843"
      />
      <path
        d="M27.5007 19.9079C25.1438 18.3724 21.9459 18.6375 19.8782 20.7052C17.5067 23.0776 17.5067 26.9373 19.8782 29.3097L25.8356 35.2661C26.2952 35.7247 26.898 35.954 27.5007 35.954C28.1045 35.954 28.7083 35.7247 29.1679 35.2651L35.1233 29.3097C36.2727 28.1603 36.9055 26.6325 36.9055 25.007C36.9055 23.3824 36.2727 21.8546 35.1242 20.7052C33.0565 18.6375 29.8597 18.3714 27.5007 19.9079ZM32.4963 26.6818L27.5007 31.6774L22.5062 26.6818C21.5821 25.7588 21.5821 24.2561 22.5052 23.3321C22.9677 22.8706 23.5744 22.6393 24.181 22.6393C24.7877 22.6393 25.3934 22.8706 25.8559 23.3321L26.1868 23.664C26.5351 24.0123 27.0083 24.2078 27.5007 24.2078C27.9932 24.2078 28.4664 24.0123 28.8147 23.664L29.1466 23.3321C30.0706 22.409 31.5732 22.4081 32.4963 23.3321C32.9443 23.7791 33.1901 24.3742 33.1901 25.007C33.1901 25.6398 32.9433 26.2348 32.4963 26.6818Z"
        fill="white"
      />
      <path
        d="M27.4555 16.5864H27.4661C28.4869 16.5864 29.3181 15.7611 29.3229 14.7384L29.3258 14.2246C29.3316 13.199 28.5043 12.363 27.4778 12.3572C27.4749 12.3572 27.4719 12.3572 27.4681 12.3572C26.4463 12.3572 25.6162 13.1825 25.6103 14.2052L25.6074 14.719C25.6026 15.7456 26.4299 16.5816 27.4555 16.5864Z"
        fill="white"
      />
      <path
        d="M27.4778 37.333C27.4749 37.333 27.4719 37.333 27.4681 37.333C26.4463 37.333 25.6162 38.1583 25.6103 39.1811L25.6074 39.6958C25.6026 40.7214 26.4299 41.5574 27.4555 41.5632H27.4661C28.4869 41.5632 29.3181 40.7369 29.3229 39.7152L29.3258 39.2004C29.3316 38.1748 28.5043 37.3388 27.4778 37.333Z"
        fill="white"
      />
    </svg>
  )
}

export default iluToken
