import { useState } from 'react'
import useSWR from 'swr'

import Card from '@/components/base/Card'
import CustomText from '@/components/base/CustomText'
import usePostVisibility from '@/hooks/usePostVisibility'
import { findPostByUUID, postURLEndpoint } from '@/services/postsService'
import { Post as PostType } from '@/ts/types/post.types'

import DefaultContent from './Content/DefaultContent'
import PostAddons from './PostAddons'
import PostHeader from './PostHeader'
import PostInteractors from './PostInteractors'

type Props = {
  /**
   * Content for Card
   */
  addon: PostType
  index: number
  showComments?: boolean
  canRepost?: boolean
}

const Post = ({ addon, index, showComments = false, canRepost = true }: Props): JSX.Element => {
  const [deleted, setDeleted] = useState(false)
  const { data: post, mutate: mutatePost } = useSWR(
    addon.UUID
      ? {
          page: postURLEndpoint,
          postUUID: addon.UUID,
        }
      : null,
    findPostByUUID,
    {
      fallbackData: addon,
    }
  )

  const handleDelete = () => {
    setDeleted(true)
  }

  const metadata = !post.Image ? post.Metadata : null

  const { isInViewport } = usePostVisibility({ post })

  return (
    <Card className="border w-full border-gray-200 sm:!pb-6 !mb-4 lg:!mb-6 relative !overflow-visible">
      {!deleted ? (
        <div>
          <PostHeader
            post={post}
            Author={post?.Included?.Author}
            postUUID={post?.UUID}
            postCreatedAt={post?.CreatedAt}
            ownSubscription={post?.Included?.OwnSubscription}
            ownBookmark={post?.Included?.OwnBookmark}
            index={index}
            onDelete={handleDelete}
          />
          <div className="mt-5">
            <DefaultContent
              content={post?.Content as string}
              postUUID={post.UUID}
              hashtags={post.Hashtags}
              mentions={post.Mentions}
            />

            {metadata ? <PostAddons post={post} className="pt-3" /> : null}
          </div>

          <div>
            <PostInteractors
              showComments={showComments}
              post={post}
              isInViewport={isInViewport}
              mutatePost={mutatePost}
              ods={post.Extra?.ODS || 0}
              canRepost={canRepost}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <CustomText>Publicación eliminada</CustomText>
        </div>
      )}
    </Card>
  )
}

export default Post
