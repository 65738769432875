import Image from 'next/image'

import Tokens from '@/public/static/images/landing/tokens.svg'

import Container from '../base/Container'
import CustomText, { FontWeight, TextSize } from '../base/CustomText'

export const TokensSection = () => {
  return (
    <Container maxWidth={1200}>
      <section className="w-full space-y-12 md:space-y-2">
        <CustomText className="md:!text-4xl !text-3xl text-center" weight={FontWeight.Bold}>
          ¿Para qué sirven los Tokens de Impacto (SNT)?
        </CustomText>
        <div className="w-full flex flex-col gap-8 md:flex-row justify-between items-center">
          <div
            className="bg-white rounded-2xl p-8 py-12 md:p-12 md:py-16 space-y-4 w-full md:w-1/2"
            style={{ boxShadow: '34px 29px 48px 0px rgba(51, 102, 255, 0.05)' }}
          >
            <CustomText size={TextSize.Heading2} weight={FontWeight.SemiBold}>
              Actualmente puedes canjear tus tokens por Productos o Servicios en nuestra App
            </CustomText>
            <CustomText>
              Como recompensa por tu esfuerzo, podrás canjear tus Tokens por productos, servicios, descuentos y mucho
              más en nuestra app. Los canjes y promociones varían con el tiempo.
            </CustomText>
          </div>
          <div className="relative h-full flex items-center w-full md:w-1/2">
            <Image src={Tokens} alt="Tokens illustration" />
          </div>
        </div>
      </section>{' '}
    </Container>
  )
}
