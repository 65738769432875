import React from 'react'
import { toast } from 'react-hot-toast'

export const getStringFromServerError = (error) => {
  if (!error) {
    return error
  }

  const _error = error?.response?.data?.Error || error

  if (_error.Data?.Message) {
    return _error.Data.Message
  } else if (_error.Message) {
    return _error.Message
  } else if (_error.message) {
    return _error.message
  }

  return _error
}

const getFromParams = (from) => {
  const regex = /{([^{}]*)}/gm
  const matches = []
  let match = regex.exec(from)

  while (match) {
    matches.push(match[1])
    match = regex.exec(from)
  }

  return matches
}

const getTo = (to, from) => {
  if (typeof to === 'string') {
    return to
  } else if (typeof to === 'function') {
    return to(getFromParams(from))
  } else {
    return to
  }
}

export const serverErrorToHumanError = (e, context) => {
  const error = getStringFromServerError(e)
  return errorToHumanizedString(error, context)
}

export const handleError = (e, context) => {
  toast.error(serverErrorToHumanError(e, context))
}

export const handleSuccess = (message) => {
  toast.success(message)
}

export const errorToHumanizedString = (from, context) => {
  console.log('Error handler', from)
  if (typeof from === 'string') {
    const error = alerts.find((e) => from.indexOf(e.from) > -1 && (context ? e.context === context : true))
    return error ? getTo(error.to, from) : <div>Ocurrió un problema. Inténtalo más tarde.</div>
  } else {
    return from
  }
}

const alerts = [
  {
    context: 'auth',
    from: 'not found',
    to: <div>Correo electrónico y/o contraseña incorrectos.</div>,
  },
  {
    context: 'sign-up',
    from: 'duplicated: input error',
    to: <div>Ya existe una cuenta registrada con este correo electrónico.</div>,
  },
  {
    context: 'sign-in',
    from: 'wrong credentials: unauthorized',
    to: <div>La contraseña es incorrecta.</div>,
  },
  {
    context: 'sign-in',
    from: 'users not verified: unauthorized',
    to: (
      <div>
        Debes <b>verificar tu cuenta</b> para acceder a Sunet.
      </div>
    ),
  },
  {
    context: 'account-verify',
    from: 'internal: missing verify token',
    to: <div>Token de verificación no encontrado.</div>,
  },
  {
    context: 'files',
    from: 'internal: file is not image',
    to: <span>El archivo seleccionado no es una imagen.</span>,
  },
  {
    context: 'files',
    from: 'internal: file size greater than 10MB',
    to: <span>El tamaño del archivo no puede ser superior a 10MB.</span>,
  },
  {
    context: 'files',
    from: 'internal: file size greater than 8MB',
    to: <span>El tamaño del archivo no puede ser superior a 8MB.</span>,
  },
  {
    context: 'password-recovery',
    from: 'not found',
    to: <span>El enlace de recuperación expiró o ya fue utilizado.</span>,
  },
  {
    context: 'ods',
    from: 'you must pick at least 1 objetive',
    to: (
      <div>
        <span>Debes seleccionar al menos un objetivo.</span>
      </div>
    ),
  },
  {
    context: 'area',
    from: 'you must pick 1 area',
    to: (
      <div>
        <span>Debes seleccionar un área.</span>
      </div>
    ),
  },
  {
    context: 'area',
    from: 'you must pick a category to make a post',
    to: (
      <div>
        <span>Debes seleccionar una categoría para crear una publicación.</span>
      </div>
    ),
  },
  {
    context: 'ods',
    from: 'you must pick an ODS to make a post',
    to: (
      <div>
        <span>Debes seleccionar un ODS para crear una publicación.</span>
      </div>
    ),
  },
  {
    context: 'goals',
    from: 'error at creating goal',
    to: (
      <div>
        <span>Error al crear la meta, intentelo denuevo mas tarde o edite la existente.</span>
      </div>
    ),
  },
  {
    context: 'tos',
    from: 'error at updating tos',
    to: (
      <div>
        <span>Error al actualizar los términos y condiciones, intentelo denuevo mas tarde o edite la existente.</span>
      </div>
    ),
  },
  {
    context: 'delete-user',
    from: 'error at requesting delete code',
    to: (
      <div>
        <span>Error al enviar el codigo de confirmacion para la eliminacion de la cuenta.</span>
      </div>
    ),
  },
  {
    context: 'delete-user',
    from: 'delete code is invalid: conflict',
    to: (
      <div>
        <span>El código que se ingresó es inválido.</span>
      </div>
    ),
  },
  {
    context: 'challenge',
    from: 'this milestone has not started yet: conflict',
    to: (
      <div>
        <span>No se ha comenzado aún el hito.</span>
      </div>
    ),
  },
]
