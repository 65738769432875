import dynamic from 'next/dynamic'
import React, { ReactNode, useEffect } from 'react'

const Container = dynamic(() => import('./ModalContainer'), {
  ssr: false,
})

type Props = {
  open: boolean
  onClose: () => void
  children?: ReactNode | string
  maxWidth?: number
  animate?: boolean
  showCloseButton?: boolean
  verticalSpacingClassName?: string
  closeOnOutsideClick?: boolean
  zIndex?: number
}

const Modal = ({
  open,
  onClose,
  children,
  maxWidth = 640,
  showCloseButton,
  verticalSpacingClassName,
  closeOnOutsideClick = true,
  zIndex = 50,
  ...rest
}: Props): JSX.Element => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        e.preventDefault()
        onClose && onClose()
        return false
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  const classList = 'overflow-hidden'

  useEffect(() => {
    if (open) {
      document.body.classList.add(classList)
    } else {
      document.body.classList.remove(classList)
    }

    return () => {
      document.body.classList.remove(classList)
    }
  }, [open])

  return (
    <Container
      centered={true}
      zIndex={zIndex}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      showCloseButton={showCloseButton}
      verticalSpacingClassName={verticalSpacingClassName || ''}
      closeOnOutsideClick={closeOnOutsideClick}
      {...rest}
    >
      {children}
    </Container>
  )
}

export default Modal
