import Image from 'next/image'

import CarbonFootprint from '@/public/static/images/landing/icons/carbon-footprint.png'
import Goal from '@/public/static/images/landing/icons/goal.png'
import Group from '@/public/static/images/landing/icons/group.png'
import Handshake from '@/public/static/images/landing/icons/handshake.png'
import MobileMockup from '@/public/static/images/landing/phone.png'

import Container from '../base/Container'
import CustomText, { FontWeight } from '../base/CustomText'

const featureList = [
  { text: 'Participa en desafíos', image: Goal },
  { text: 'Apoya iniciativas', image: Handshake },
  { text: 'Únete a grupos de tu interés', image: Group },
  { text: 'Mide tus emisiones', image: CarbonFootprint },
]

export const Features = () => {
  return (
    <Container className="w-full" maxWidth={1200}>
      <section className="flex flex-col md:flex-row gap-8 md:gap-4 w-full justify-between items-center">
        <div className="w-full md:w-[500px]">
          <Image src={MobileMockup} alt="Sunet profile app" className="px-8 md:px-0" />
        </div>
        <div className="w-full md:w-1/2 space-y-12">
          <CustomText className="md:!text-4xl !text-3xl text-center md:text-left" weight={FontWeight.Bold}>
            Ayúdanos a construir un mundo mejor
          </CustomText>
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {featureList.map((item, index) => (
                <FeatureItem key={`${index}-feature`} item={item} />
              ))}
            </div>
          </div>
        </div>
      </section>{' '}
    </Container>
  )
}

const FeatureItem = ({ item }) => {
  return (
    <article
      className="flex bg-white rounded-xl p-4 items-center gap-4"
      style={{ boxShadow: '34px 29px 48px 0px rgba(51, 102, 255, 0.05)' }}
    >
      <div className="bg-[#E5F4F2] p-2 rounded-lg">
        <Image alt={item.text} src={item.image} />
      </div>
      <CustomText>{item.text}</CustomText>
    </article>
  )
}
