import Image from 'next/image'
import numbro from 'numbro'
import { useContext } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

import Button, { ButtonAppearance, ButtonSize } from '@/components/base/Button'
import Card from '@/components/base/Card'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import PercentageBar from '@/components/base/PercentageBar'
import { InitiativeType } from '@/ts/types/initiatives.types'
import { getLocaleText } from '@/utils/i18n'
type Props = {
  /**
   * ClassNames
   */
  className?: string
  /**
   * Style inline object
   */
  text?: string
  /**
   * Activate Lorem Ipsum for testing purposes
   */
  image?: string
  /**
   * initiative
   */
  initiative: InitiativeType
}

const InitiativesSnippetCard = ({
  index,
  text,
  image,
  objective,
  initiative,
  onClick,
  ...rest
}: Props): JSX.Element => {
  const visibility = useContext(VisibilityContext)

  const visible = visibility?.isItemVisible ? visibility?.isItemVisible(index) : false

  const percentage = (initiative.Current * 100) / initiative.Goal

  return (
    <button onClick={onClick}>
      <Card className="!py-2 !px-2 hover:opacity-80" style={{ width: 231 }} key={index}>
        <div style={{ height: 114 }} className="relative w-full">
          <Image
            src={initiative?.Banner?.URL}
            alt={`alt-${getLocaleText(initiative.Title)}}`}
            fill
            className="rounded-lg object-cover"
          />
        </div>

        <div className="px-2 mt-1">
          <CustomText className="mb-2 truncate-2 text-left" weight={FontWeight.SemiBold}>
            {getLocaleText(initiative.Title)}
          </CustomText>

          <div className="mb-2">
            <PercentageBar percentage={percentage} />
          </div>

          <div className="grid grid-cols-2 items-center">
            <div className="flex flex-col ">
              <CustomText size={TextSize.Caption} className="text-left text-gray-2">
                Donado
              </CustomText>
              <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold} className="text-left">
                {numbro(initiative.Goal || 0).formatCurrency()}
              </CustomText>
            </div>
            <div className="ml-auto pointer-events-none">
              <Button appearance={ButtonAppearance.Primary} size={ButtonSize.Small}>
                Donar
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </button>
  )
}

export default InitiativesSnippetCard
