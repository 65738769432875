export const Slide5 = () => {
  return (
    <svg width="374" height="777" viewBox="0 0 374 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5794_68403)">
        <rect width="374" height="777" fill="white" />
        <g clipPath="url(#clip1_5794_68403)">
          <rect width="374" height="777" fill="white" />
          <mask
            id="mask0_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-7"
            y="-38"
            width="393"
            height="439"
          >
            <path
              d="M30.5073 -37.9648C9.87089 -37.9648 -6.8584 -20.6633 -6.8584 0.679127V361.953C-6.8584 383.295 9.87089 400.596 30.5073 400.596H348.006C368.643 400.596 385.372 383.295 385.372 361.953V0.679127C385.372 -20.6633 368.643 -37.9648 348.006 -37.9648H30.5073Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_5794_68403)">
            <path d="M414.465 -64.3711H-33.4668V241.473H414.465V-64.3711Z" fill="#A5F4F7" />
            <path
              d="M93.1726 37.2188C46.165 37.2188 -62.3633 211.991 -62.3633 211.991H222.955C222.955 211.991 140.181 37.2188 93.1726 37.2188Z"
              fill="#336666"
            />
          </g>
          <mask
            id="mask1_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-7"
            y="36"
            width="230"
            height="185"
          >
            <path
              d="M-6.85645 135.025V220.165H222.964C222.964 220.165 140.19 36.8945 93.1813 36.8945C68.5678 36.8945 27.0808 87.1503 -6.85564 135.025H-6.85645Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_5794_68403)">
            <path
              d="M63.1436 47.1644C69.3554 47.1644 69.3557 52.2679 75.5686 52.2679C81.7815 52.2679 81.7815 47.1644 87.9944 47.1644C94.2065 47.1644 94.2066 52.2679 100.419 52.2679C106.632 52.2679 106.632 47.1644 112.846 47.1644C119.059 47.1644 119.059 52.2679 125.273 52.2679V32.8438H63.1436V47.1644Z"
              fill="white"
            />
          </g>
          <mask
            id="mask2_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-7"
            y="-19"
            width="393"
            height="420"
          >
            <path
              d="M30.5073 -18.9414C9.87089 -18.9414 -6.8584 -2.39028 -6.8584 18.0264V363.63C-6.8584 384.046 9.87089 400.597 30.5073 400.597H348.006C368.643 400.597 385.372 384.046 385.372 363.63V18.0264C385.372 -2.39028 368.643 -18.9414 348.006 -18.9414H30.5073Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_5794_68403)">
            <path
              d="M-72.2129 265.8L-2.01818 119.595C0.172707 115.032 6.85033 115.386 8.53651 120.153L60.0617 265.8H-72.2129Z"
              fill="#FF6B1C"
            />
            <path
              d="M-39.4729 272.62L30.7218 126.416C32.9127 121.853 39.5913 122.206 41.2773 126.974L92.8017 272.62H-39.4729Z"
              fill="#FF6B1C"
            />
            <path
              d="M-12.1116 301.062L58.0831 154.858C60.274 150.294 66.9519 150.648 68.6378 155.415L120.163 301.062H-12.1116Z"
              fill="#FF6B1C"
            />
            <path
              d="M14.168 311.41L84.3627 165.205C86.5536 160.642 93.232 160.995 94.9182 165.762L146.443 311.41H14.168Z"
              fill="#FF6B1C"
            />
            <path
              d="M352.191 80.6992C311.246 80.6992 216.717 220.158 216.717 220.158H465.234C465.234 220.158 393.136 80.6992 352.191 80.6992Z"
              fill="#336666"
            />
          </g>
          <mask
            id="mask3_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="216"
            y="80"
            width="170"
            height="141"
          >
            <path
              d="M216.728 220.17H385.374V100.685C373.479 88.6268 361.956 80.7109 352.202 80.7109C311.257 80.7109 216.728 220.17 216.728 220.17Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_5794_68403)">
            <path
              d="M321.935 91.869C328.147 91.869 328.147 96.5246 334.36 96.5246C340.573 96.5246 340.573 91.869 346.785 91.869C352.998 91.869 352.998 96.5246 359.211 96.5246C365.424 96.5246 365.424 91.869 371.637 91.869C377.85 91.869 377.85 96.5246 384.064 96.5246V78.8047H321.935V91.869Z"
              fill="white"
            />
          </g>
          <path d="M414 191H-33V777H414V191Z" fill="#33CC66" />
          <mask
            id="mask4_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-20"
            y="62"
            width="225"
            height="251"
          >
            <path
              d="M1.74475 62.1484C-10.0461 62.1484 -19.6045 72.0339 -19.6045 84.228V290.645C-19.6045 302.839 -10.0461 312.724 1.74475 312.724H183.151C194.941 312.724 204.5 302.839 204.5 290.645V84.228C204.5 72.0339 194.941 62.1484 183.151 62.1484H1.74475Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_5794_68403)">
            <path
              d="M56.6404 229.104L47.4176 243.373L58.5212 250.385L68.2708 235.301C68.2708 235.301 56.7775 229.191 56.6404 229.104Z"
              fill="#336666"
            />
            <path
              d="M47.4229 243.368C47.4229 243.368 46.237 245.203 45.5869 245.462C44.9369 245.721 43.1162 244.954 39.2329 244.989C35.3492 245.025 33.732 250.438 33.732 250.438L51.2306 261.369L59.0097 249.632L51.7495 245.334C51.7495 245.334 51.9619 243.364 50.4756 242.378C48.9898 241.392 48.8188 241.283 48.8188 241.283C48.8188 241.283 47.8624 242.688 47.4229 243.368Z"
              fill="#FFDA33"
            />
            <path
              d="M49.9316 262.177L33.4347 251.759C32.8293 251.377 32.6529 250.581 33.0407 249.981L33.8034 248.801C34.1911 248.201 34.996 248.025 35.6008 248.407L52.0986 258.825C52.7036 259.207 52.8794 260.003 52.4917 260.603L51.7294 261.782C51.3417 262.382 50.5369 262.559 49.9316 262.177Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46.2278 244.673L45.6848 245.489L48.7482 247.485L49.2918 246.669L46.2278 244.673Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.3826 242.865L46.839 243.68L49.9025 245.676L50.446 244.86L47.3826 242.865Z"
              fill="white"
            />
            <path
              d="M50.9274 245.711C50.6775 246.097 50.1588 246.211 49.7688 245.965C49.3789 245.719 49.2655 245.205 49.5153 244.819C49.7651 244.432 50.2837 244.319 50.6736 244.565C51.0635 244.811 51.1772 245.324 50.9274 245.711Z"
              fill="white"
            />
            <path
              d="M49.7497 247.523C49.4998 247.91 48.9811 248.024 48.5911 247.778C48.2012 247.532 48.0878 247.018 48.3376 246.632C48.5874 246.245 49.1064 246.131 49.4963 246.377C49.8862 246.623 49.9995 247.137 49.7497 247.523Z"
              fill="white"
            />
            <path
              d="M93.0462 237.767L85.7517 254.571L98.7985 260.072L106.51 242.308C106.51 242.308 93.2072 237.835 93.0462 237.767Z"
              fill="#336666"
            />
            <path
              d="M85.757 254.566C85.757 254.566 84.819 256.727 84.1732 257.117C83.5274 257.507 81.4556 257.013 77.3312 257.735C73.2064 258.457 72.4404 264.5 72.4404 264.5L92.9804 273.043L99.1861 259.187L90.7059 255.895C90.7059 255.895 90.5845 253.762 88.8296 252.975C87.0753 252.188 86.8739 252.103 86.8739 252.103C86.8739 252.103 86.1047 253.766 85.757 254.566Z"
              fill="#FFDA33"
            />
            <path
              d="M91.7408 274.127L72.3568 265.954C71.6454 265.654 71.3175 264.838 71.6241 264.131L72.227 262.743C72.5337 262.036 73.3591 261.706 74.0698 262.006L93.4549 270.179C94.1657 270.479 94.4934 271.295 94.1865 272.002L93.5835 273.39C93.2769 274.097 92.4521 274.427 91.7408 274.127Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M84.7156 256.165L88.3264 257.747L87.8921 258.711L84.2813 257.128L84.7156 256.165Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M85.7898 254.407L89.4006 255.989L88.9663 256.953L85.3555 255.371L85.7898 254.407Z"
              fill="white"
            />
            <path
              d="M88.963 258.571C88.7655 259.027 88.234 259.239 87.7759 259.046C87.3177 258.853 87.1062 258.327 87.3038 257.872C87.5014 257.416 88.0332 257.204 88.4914 257.397C88.9495 257.59 89.1606 258.116 88.963 258.571Z"
              fill="white"
            />
            <path
              d="M90.0477 256.857C89.7946 257.284 89.2406 257.428 88.8104 257.179C88.3801 256.93 88.2362 256.381 88.4893 255.954C88.7425 255.528 89.2967 255.383 89.7271 255.632C90.1574 255.881 90.3008 256.43 90.0477 256.857Z"
              fill="white"
            />
            <path
              d="M154.587 182.824C154.587 215.922 127.467 242.754 94.0121 242.754C60.5577 242.754 33.4375 215.922 33.4375 182.824C33.4375 149.726 60.5577 122.895 94.0121 122.895C127.467 122.895 154.587 149.726 154.587 182.824Z"
              fill="#00CCFF"
            />
            <mask
              id="mask5_5794_68403"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="33"
              y="122"
              width="122"
              height="121"
            >
              <path
                d="M33.4287 182.828C33.4287 215.927 60.5493 242.758 94.0038 242.758C127.458 242.758 154.578 215.927 154.578 182.828C154.578 149.73 127.458 122.898 94.0038 122.898C60.5493 122.898 33.4287 149.73 33.4287 182.828Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask5_5794_68403)">
              <path
                d="M79.3524 131.222C79.4129 135.555 82.9424 139.056 87.3214 139.127L103.434 139.391C104.936 139.415 106.402 139.853 107.668 140.654L116.199 146.056C119.551 148.178 123.964 147.579 126.613 144.642L132.909 137.663C136.486 133.698 135.244 127.449 130.416 125.119L124.128 122.085C122.885 121.485 121.508 121.21 120.126 121.286L87.0057 123.106C82.6681 123.345 79.2922 126.925 79.3524 131.222Z"
                fill="#FFDA33"
              />
              <path
                d="M136.587 270.697L125.522 264.779C119.698 261.664 118.101 254.117 122.175 248.952C123.866 246.81 124.658 244.106 124.389 241.4L123.017 227.636C122.708 224.53 123.799 221.448 126 219.212L131.294 213.832C133.387 211.706 134.482 208.807 134.31 205.843L133.783 196.711C133.489 191.625 136.897 187.054 141.896 185.83L157.95 181.9C162.139 180.875 166.54 182.439 169.112 185.868L191.491 215.698C194.096 219.172 194.304 223.865 192.014 227.551L178.429 249.421C177.234 251.344 176.692 253.593 176.882 255.843L177.479 262.931C178.034 269.515 172.423 274.988 165.776 274.347L140.636 271.92C139.218 271.783 137.841 271.367 136.587 270.697Z"
                fill="#FFDA33"
              />
              <path
                d="M28.603 183.07H38.7458C42.7892 183.07 46.0983 186.252 46.2143 190.251L46.7884 210.131C46.8992 213.962 49.9504 217.074 53.8177 217.299C58.7386 217.585 62.0357 222.426 60.4565 227.045L53.802 246.517C53.0461 248.729 53.391 251.164 54.7315 253.085L59.2917 259.619C63.3148 265.383 57.7311 272.935 50.9599 270.887L11.6379 258.995C10.32 258.596 9.13879 257.844 8.22578 256.823L-2.94317 244.332C-4.46758 242.627 -5.11272 240.322 -4.69266 238.084L-1.66885 221.974C-1.31978 220.114 -0.262089 218.456 1.28563 217.344L16.5669 206.361C18.2191 205.174 19.3084 203.369 19.5806 201.367L21.1983 189.476C21.6973 185.808 24.8618 183.07 28.603 183.07Z"
                fill="#FFDA33"
              />
            </g>
            <path
              d="M91.7783 175.529L93.1617 174.727L98.6699 178.684L92.2553 183.082L91.7783 175.529Z"
              fill="#FF6B1C"
            />
            <path
              d="M82.2881 154.332C80.5344 154.008 78.5733 153.955 76.4591 154.174L76.3057 152.718C78.5618 152.485 80.6653 152.543 82.559 152.893L82.2881 154.332Z"
              fill="#336666"
            />
            <path
              d="M78.36 172.287C77.1142 172.287 75.856 172.075 74.6766 171.642C72.0065 170.66 70.1576 168.688 69.4707 166.089L70.9013 165.719C71.4628 167.844 72.9867 169.46 75.1915 170.27C77.7938 171.227 80.9559 170.905 83.0607 169.47L83.9001 170.675C82.3437 171.736 80.3681 172.287 78.36 172.287Z"
              fill="#336666"
            />
            <path
              d="M109.751 172.287C107.743 172.287 105.768 171.737 104.211 170.675L105.051 169.47C107.156 170.905 110.318 171.227 112.919 170.27C115.125 169.46 116.648 167.844 117.21 165.719L118.641 166.089C117.953 168.688 116.105 170.66 113.434 171.642C112.255 172.075 110.997 172.287 109.751 172.287Z"
              fill="#336666"
            />
            <path
              d="M107.018 154.328L106.747 152.889C108.641 152.54 110.745 152.481 113.001 152.714L112.847 154.17C110.734 153.951 108.773 154.005 107.018 154.328Z"
              fill="#336666"
            />
            <path d="M103.136 123.707L123.652 124.38L123.783 125.926H106.762L103.136 123.707Z" fill="#FF6B1C" />
            <path
              d="M82.3347 125.93H106.763C106.763 125.93 106.764 112.004 94.5492 112.948C82.3349 113.892 82.3347 125.93 82.3347 125.93Z"
              fill="#FF6B1C"
            />
            <path
              d="M76.4182 157.127C69.9122 157.127 64.6197 162.364 64.6197 168.8C64.6197 175.237 69.9122 180.474 76.4182 180.474C82.9243 180.474 88.2172 175.237 88.2172 168.8C88.2172 162.364 82.9243 157.127 76.4182 157.127ZM76.4182 181.937C69.0967 181.937 63.1406 176.044 63.1406 168.8C63.1406 161.557 69.0967 155.664 76.4182 155.664C83.7397 155.664 89.6963 161.557 89.6963 168.8C89.6963 176.044 83.7397 181.937 76.4182 181.937Z"
              fill="#336666"
            />
            <path
              d="M111.431 157.127C104.925 157.127 99.6324 162.364 99.6324 168.8C99.6324 175.237 104.925 180.474 111.431 180.474C117.937 180.474 123.23 175.237 123.23 168.8C123.23 162.364 117.937 157.127 111.431 157.127ZM111.431 181.937C104.11 181.937 98.1533 176.044 98.1533 168.8C98.1533 161.557 104.11 155.664 111.431 155.664C118.753 155.664 124.709 161.557 124.709 168.8C124.709 176.044 118.753 181.937 111.431 181.937Z"
              fill="#336666"
            />
            <path
              d="M95.054 229.535C81.1808 221.085 67.5648 217.52 54.2441 220.597V177.61C67.9449 174.785 94.7341 179.677 95.054 190.135V229.535Z"
              fill="white"
            />
            <path
              d="M94.6299 229.535C108.503 221.085 122.119 217.52 135.44 220.597V177.61C121.739 174.785 94.9498 179.677 94.6299 190.135V229.535Z"
              fill="white"
            />
            <path
              d="M44.0791 200.772C44.0791 200.772 44.713 196.692 46.6615 194.238C47.9098 192.665 48.3957 192.238 48.3957 192.238L50.1965 193.204L48.5575 196.78L48.6694 196.83L44.0791 200.772Z"
              fill="#336666"
            />
            <path
              d="M95.0549 230.904C95.0549 230.904 93.7696 230.901 91.8443 230.844C76.9775 223.6 63.0387 220.623 48.6152 223.49V180.503C64.5371 177.677 79.3456 181.625 95.0549 190.132V230.904Z"
              fill="#FF6B1C"
            />
            <path
              d="M145.864 200.772C145.864 200.772 145.23 196.692 143.282 194.238C142.034 192.665 141.548 192.238 141.548 192.238L139.747 193.204L141.386 196.78L141.274 196.83L145.864 200.772Z"
              fill="#336666"
            />
            <path
              d="M95.0547 230.904C95.0547 230.904 96.2612 230.884 98.0632 230.901C113.034 223.558 126.982 220.605 141.495 223.49V180.503C125.573 177.677 110.764 181.625 95.0547 190.132V230.904Z"
              fill="#FF6B1C"
            />
            <path
              d="M35.2406 214.748C37.224 217.705 41.4914 218.021 43.8988 215.391C45.2162 213.951 46.6788 212.348 48.1105 210.767C52.4527 205.972 53.7842 204.213 53.7842 204.213L45.2517 199.672C45.2517 199.672 44.4078 200.021 41.7366 202.713C40.4296 204.03 38.1944 205.967 36.2529 207.602C34.1344 209.386 33.7024 212.455 35.2406 214.748Z"
              fill="#336666"
            />
            <path
              d="M48.6703 196.828C49.328 196.606 53.672 191.539 53.672 191.539L55.2523 192.292L52.1013 197.345L52.6505 197.437L56.4559 192.331L58.2904 193.148L54.3255 198.692L54.6144 198.853L58.7309 193.865L60.0362 194.681C58.8775 196.74 56.1901 200.803 56.1901 200.803L56.4439 200.867L59.4325 196.733L60.8418 197.675C60.8418 197.675 61.2034 197.93 58.2738 202.066C56.2272 204.955 52.1453 205.678 52.1453 205.678L44.0713 200.775L48.6703 196.828Z"
              fill="#336666"
            />
            <path
              d="M154.702 214.748C152.719 217.705 148.452 218.021 146.044 215.391C144.727 213.951 143.264 212.348 141.832 210.767C137.49 205.972 136.158 204.213 136.158 204.213L144.691 199.672C144.691 199.672 145.535 200.021 148.206 202.713C149.513 204.03 151.749 205.967 153.69 207.602C155.809 209.386 156.241 212.455 154.702 214.748Z"
              fill="#336666"
            />
            <path
              d="M141.261 196.828C140.603 196.606 136.26 191.539 136.26 191.539L134.679 192.292L137.83 197.345L137.281 197.437L133.475 192.331L131.641 193.148L135.606 198.692L135.317 198.853L131.201 193.865L129.895 194.681C131.054 196.74 133.741 200.803 133.741 200.803L133.487 200.867L130.499 196.733L129.089 197.675C129.089 197.675 128.728 197.93 131.657 202.066C133.704 204.955 137.786 205.678 137.786 205.678L145.86 200.775L141.261 196.828Z"
              fill="#336666"
            />
            <path
              d="M98.2495 170.146C94.7047 167.955 89.3706 170.099 89.3172 170.121L88.751 168.769C88.9979 168.668 94.8505 166.319 99.0334 168.905L98.2495 170.146Z"
              fill="#336666"
            />
            <path
              d="M63.4148 166.713L54.7284 159.879C53.8719 159.205 52.7268 158.96 51.6644 159.222L50.1321 159.601L49.7734 158.182L51.3061 157.803C52.8119 157.43 54.4353 157.778 55.6491 158.734L64.3351 165.568L63.4148 166.713Z"
              fill="#336666"
            />
            <path
              d="M124.341 166.714L123.421 165.568L132.107 158.734C133.321 157.779 134.945 157.43 136.45 157.803L137.983 158.182L137.624 159.601L136.092 159.222C135.03 158.96 133.884 159.205 133.028 159.879L124.341 166.714Z"
              fill="#336666"
            />
            <path
              d="M126.104 206.881C123.99 211.108 118.813 212.838 114.542 210.747C110.27 208.655 108.521 203.534 110.635 199.307C112.749 195.081 124.821 190.195 124.821 190.195C124.821 190.195 128.219 202.655 126.104 206.881Z"
              fill="#336666"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M121.573 194.684L111.067 215.693L111.948 216.124L122.454 195.115L121.573 194.684Z"
              fill="#FFDA33"
            />
            <path d="M98.0818 189.789H91.7314V230.895H98.0818V189.789Z" fill="#FFDA33" />
            <path
              d="M98.143 189.79C98.143 188.02 96.693 186.586 94.9042 186.586C93.1156 186.586 91.665 188.02 91.665 189.79H98.143Z"
              fill="#336666"
            />
            <path d="M103.131 123.203H84.4639V124.666H103.131V123.203Z" fill="#336666" />
          </g>
          <path
            d="M265.77 332.652H241.397C241.397 327.926 246.854 324.094 253.584 324.094C260.314 324.094 265.77 327.926 265.77 332.652Z"
            fill="#336666"
          />
          <path
            d="M312.698 321.281H288.326C288.326 316.555 293.783 312.723 300.513 312.723C307.242 312.723 312.698 316.555 312.698 321.281Z"
            fill="#336666"
          />
          <path
            d="M376.567 284.441H352.196C352.196 279.715 357.651 275.883 364.381 275.883C371.111 275.883 376.567 279.715 376.567 284.441Z"
            fill="#336666"
          />
          <path
            d="M118.233 17.509L117.55 16.8679C112.419 12.053 106.734 10.1812 100.161 11.1502L99.7812 8.61622C106.799 7.585 113.144 9.52621 118.655 14.3932C123.666 12.0378 130.151 12.0219 138.435 14.3372L137.732 16.8015C129.665 14.5469 123.557 14.6328 119.06 17.0624L118.233 17.509Z"
            fill="#336666"
          />
          <path
            d="M278.942 32.8769L277.203 30.9792C282.46 26.2667 288.826 24.3974 296.143 25.4175C298.99 20.7066 304.375 17.1323 312.565 14.5078L313.362 16.9457C305.387 19.5009 300.355 22.9246 297.978 27.4144L297.541 28.2412L296.611 28.0819C289.648 26.8978 283.864 28.4649 278.942 32.8769Z"
            fill="#336666"
          />
          <mask
            id="mask6_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="85"
            y="181"
            width="199"
            height="216"
          >
            <path
              d="M265.049 181.316C275.516 181.316 284 189.801 284 200.268V377.439C284 387.905 275.516 396.389 265.049 396.389H104.019C93.5526 396.389 85.0677 387.905 85.0677 377.439V200.268C85.0677 189.801 93.5525 181.316 104.019 181.316H265.049Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_5794_68403)">
            <path
              d="M211.876 318.206L225.149 325.566L219.471 335.806L205.439 328.024C205.439 328.024 211.806 318.332 211.876 318.206Z"
              fill="#336666"
            />
            <path
              d="M221.792 323.734C221.792 323.734 223.47 324.73 224.09 324.677C224.709 324.625 225.843 323.28 228.934 321.753C232.025 320.225 235.486 323.917 235.486 323.917L226.023 339.69L215.134 333.398L219.156 327.044C219.156 327.044 218.194 325.549 218.974 324.163C219.755 322.777 219.847 322.622 219.847 322.622C219.847 322.622 221.171 323.365 221.792 323.734Z"
              fill="#FFDA33"
            />
            <path
              d="M227.37 339.821L236.246 324.86C236.571 324.311 236.39 323.602 235.842 323.277L234.763 322.637C234.213 322.311 233.505 322.492 233.179 323.041L224.303 338.001C223.978 338.55 224.158 339.259 224.708 339.585L225.787 340.225C226.335 340.55 227.044 340.369 227.37 339.821Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M223.269 324.296L224.028 324.732L222.404 327.559L221.645 327.123L223.269 324.296Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M221.614 323.315L222.373 323.751L220.749 326.578L219.99 326.142L221.614 323.315Z"
              fill="white"
            />
            <path
              d="M219.959 327.008C220.313 327.218 220.77 327.101 220.98 326.747C221.189 326.393 221.073 325.937 220.719 325.727C220.365 325.518 219.909 325.634 219.699 325.988C219.49 326.341 219.606 326.798 219.959 327.008Z"
              fill="white"
            />
            <path
              d="M221.623 327.996C221.977 328.206 222.433 328.089 222.643 327.736C222.853 327.382 222.736 326.926 222.382 326.716C222.029 326.506 221.572 326.622 221.362 326.976C221.153 327.329 221.269 327.787 221.623 327.996Z"
              fill="white"
            />
            <path
              d="M181.411 321.725L190.532 333.856L181.174 340.891L171.532 328.067C171.532 328.067 181.295 321.811 181.411 321.725Z"
              fill="#336666"
            />
            <path
              d="M190.227 333.518C190.227 333.518 191.329 335.128 191.913 335.342C192.497 335.555 194.092 334.813 197.539 334.73C200.986 334.647 202.569 339.454 202.569 339.454L187.342 349.771L180.117 339.477L186.441 335.41C186.441 335.41 186.199 333.648 187.491 332.72C188.782 331.792 188.931 331.69 188.931 331.69C188.931 331.69 189.819 332.922 190.227 333.518Z"
              fill="#FFDA33"
            />
            <path
              d="M188.508 350.454L202.86 340.626C203.387 340.266 203.521 339.547 203.161 339.02L202.452 337.985C202.091 337.458 201.372 337.324 200.846 337.684L186.493 347.512C185.967 347.873 185.832 348.592 186.193 349.119L186.902 350.154C187.262 350.68 187.981 350.815 188.508 350.454Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M191.331 334.648L191.835 335.363L189.172 337.242L188.667 336.527L191.331 334.648Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M190.244 333.062L190.748 333.777L188.085 335.656L187.58 334.941L190.244 333.062Z"
              fill="white"
            />
            <path
              d="M187.185 335.717C187.418 336.056 187.881 336.143 188.22 335.911C188.559 335.678 188.646 335.215 188.414 334.876C188.181 334.536 187.718 334.45 187.379 334.682C187.04 334.915 186.953 335.378 187.185 335.717Z"
              fill="white"
            />
            <path
              d="M188.279 337.311C188.512 337.651 188.975 337.737 189.314 337.505C189.653 337.272 189.74 336.809 189.508 336.47C189.275 336.131 188.812 336.044 188.472 336.276C188.133 336.508 188.047 336.972 188.279 337.311Z"
              fill="white"
            />
            <path
              d="M234.65 290.062C234.65 290.062 237.358 301.56 238.515 305.766C239.672 309.972 239.939 311.437 239.939 311.437L233.447 311.64C233.447 311.64 233.046 311.177 232.257 308.745C231.467 306.314 227.69 294.073 227.69 294.073L234.65 290.062Z"
              fill="#336666"
            />
            <path
              d="M239.398 310.07C239.398 310.07 241.628 311.729 241.983 314.093C242.491 317.477 242.198 317.524 242.198 317.524L241.067 317.674L240.523 314.273L240.352 314.236C240.352 314.236 240.736 317.504 240.809 319.099L239.78 319.204L238.817 314.948L238.595 314.96L239.299 319.507L237.951 319.454L237.211 315.22L236.853 315.112L237.217 319.118L236.041 319.102C236.041 319.102 234.966 314.731 234.639 314.399L234.397 314.407L234.456 317.049L233.084 317.097C233.084 317.097 232.9 316.566 232.647 315.366C232.209 313.297 233.072 310.631 233.072 310.631L239.398 310.07Z"
              fill="#336666"
            />
            <path
              d="M139.058 290.062C139.058 290.062 136.35 301.56 135.193 305.766C134.036 309.972 133.769 311.437 133.769 311.437L140.261 311.64C140.261 311.64 140.662 311.177 141.452 308.745C142.241 306.314 146.019 294.073 146.019 294.073L139.058 290.062Z"
              fill="#336666"
            />
            <path
              d="M134.31 310.07C134.31 310.07 132.079 311.729 131.724 314.093C131.216 317.477 131.509 317.524 131.509 317.524L132.64 317.674L133.184 314.273L133.355 314.236C133.355 314.236 132.971 317.504 132.898 319.099L133.928 319.204L134.89 314.948L135.113 314.96L134.409 319.507L135.756 319.454L136.497 315.22L136.854 315.112L136.49 319.118L137.666 319.102C137.666 319.102 138.741 314.731 139.069 314.399L139.31 314.407L139.252 317.049L140.624 317.097C140.624 317.097 140.825 316.728 141.061 315.366C141.421 313.282 140.636 310.631 140.636 310.631L134.31 310.07Z"
              fill="#336666"
            />
            <path
              d="M133.596 277.708C133.596 307.405 157.67 331.479 187.366 331.479C217.063 331.479 241.137 307.405 241.137 277.708C241.137 248.011 217.063 223.938 187.366 223.938C157.67 223.938 133.596 248.011 133.596 277.708Z"
              fill="#00CCFF"
            />
            <path
              d="M150.37 279.567C150.37 275.871 153.366 272.875 157.061 272.875C160.757 272.875 163.753 275.871 163.753 279.567C163.753 283.263 160.757 286.258 157.061 286.258C153.366 286.258 150.37 283.263 150.37 279.567Z"
              fill="#FF6B1C"
            />
            <path
              d="M208.654 279.567C208.654 275.871 211.65 272.875 215.346 272.875C219.042 272.875 222.038 275.871 222.038 279.567C222.038 283.263 219.042 286.258 215.346 286.258C211.65 286.258 208.654 283.263 208.654 279.567Z"
              fill="#FF6B1C"
            />
            <path d="M189.338 271.165L188.11 270.445L183.221 273.996L188.915 277.942L189.338 271.165Z" fill="#FF6B1C" />
            <path
              d="M169.59 269.769C169.59 268.132 170.917 266.805 172.554 266.805C174.192 266.805 175.519 268.132 175.519 269.769C175.519 271.407 174.192 272.734 172.554 272.734C170.917 272.734 169.59 271.407 169.59 269.769Z"
              fill="#336666"
            />
            <path
              d="M196.689 269.769C196.689 268.132 198.017 266.805 199.654 266.805C201.291 266.805 202.618 268.132 202.618 269.769C202.618 271.407 201.291 272.734 199.654 272.734C198.017 272.734 196.689 271.407 196.689 269.769Z"
              fill="#336666"
            />
            <path
              d="M197.762 258.214C199.319 257.923 201.059 257.876 202.936 258.072L203.073 256.766C201.07 256.556 199.202 256.61 197.521 256.923L197.762 258.214Z"
              fill="#336666"
            />
            <path
              d="M175.81 258.214L176.051 256.923C174.37 256.61 172.502 256.556 170.5 256.766L170.636 258.072C172.513 257.876 174.254 257.924 175.81 258.214Z"
              fill="#336666"
            />
            <path
              d="M197.719 226.66C197.719 226.66 203.649 233.105 191.109 233.141C182.389 233.166 176.035 226.66 176.035 226.66H197.719Z"
              fill="#FF6B1C"
            />
            <path
              d="M197.721 226.661H176.036C176.036 226.661 176.036 214.167 186.878 215.014C197.721 215.861 197.721 226.661 197.721 226.661Z"
              fill="#FF6B1C"
            />
            <path
              d="M177.165 278.496C178.92 278.308 180.599 278.553 182.11 279.138C185.932 280.617 190.11 280.617 193.932 279.138C195.443 278.553 197.123 278.308 198.878 278.496C203.718 279.014 207.623 282.904 208.158 287.741C208.858 294.078 203.915 299.441 197.72 299.441C196.421 299.441 195.176 299.205 194.027 298.773C190.142 297.313 185.901 297.313 182.016 298.773C180.867 299.205 179.622 299.441 178.322 299.441C172.127 299.441 167.185 294.078 167.885 287.741C168.42 282.903 172.325 279.013 177.165 278.496Z"
              fill="#A5F4F7"
            />
            <path
              d="M195.923 290.395V293.254C195.923 297.613 192.377 301.16 188.018 301.16C183.658 301.16 180.111 297.613 180.111 293.254V290.395H195.923ZM188.018 303.786C193.825 303.786 198.549 299.061 198.549 293.254V287.77H177.485V293.254C177.485 299.061 182.211 303.786 188.018 303.786Z"
              fill="#336666"
            />
            <path
              d="M187.048 294.472H188.993C191.973 294.472 194.389 292.056 194.389 289.076C194.389 286.096 191.973 283.68 188.993 283.68H187.048C184.067 283.68 181.652 286.096 181.652 289.076C181.652 292.056 184.067 294.472 187.048 294.472Z"
              fill="#336666"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.417 225.422L195.967 225.805L195.94 227.119L177.39 226.736L177.417 225.422Z"
              fill="#336666"
            />
            <mask
              id="mask7_5794_68403"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="133"
              y="223"
              width="109"
              height="109"
            >
              <path
                d="M241.132 277.708C241.132 307.405 217.058 331.479 187.361 331.479C157.664 331.479 133.59 307.405 133.59 277.708C133.59 248.011 157.664 223.938 187.361 223.938C217.058 223.938 241.132 248.011 241.132 277.708Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask7_5794_68403)">
              <path
                d="M200.369 231.423C200.315 235.31 197.182 238.451 193.295 238.515L178.993 238.752C177.659 238.774 176.357 239.166 175.234 239.885L167.661 244.732C164.686 246.636 160.768 246.098 158.416 243.463L152.828 237.202C149.653 233.644 150.756 228.037 155.041 225.947L160.622 223.224C161.726 222.686 162.948 222.439 164.175 222.507L193.575 224.141C197.426 224.355 200.423 227.566 200.369 231.423Z"
                fill="#FFDA33"
              />
              <path
                d="M146.957 355.977L156.779 350.667C161.949 347.873 163.367 341.1 159.75 336.467C158.25 334.544 157.546 332.118 157.785 329.691L159.003 317.341C159.277 314.555 158.309 311.789 156.355 309.782L151.656 304.956C149.798 303.048 148.826 300.447 148.978 297.788L149.446 289.594C149.707 285.031 146.681 280.93 142.245 279.832L127.994 276.306C124.275 275.386 120.368 276.789 118.085 279.866L98.2204 306.63C95.9074 309.747 95.7231 313.958 97.7555 317.265L109.815 336.887C110.875 338.612 111.356 340.631 111.188 342.649L110.658 349.009C110.166 354.917 115.146 359.827 121.046 359.252L143.363 357.074C144.622 356.952 145.844 356.578 146.957 355.977Z"
                fill="#FFDA33"
              />
              <path
                d="M240.703 283.602H231.699C228.11 283.602 225.172 286.457 225.07 290.044L224.56 307.881C224.462 311.319 221.753 314.111 218.32 314.313C213.952 314.57 211.025 318.912 212.427 323.057L218.334 340.528C219.005 342.513 218.699 344.697 217.509 346.421L213.461 352.283C209.889 357.456 214.846 364.231 220.857 362.394L255.762 351.723C256.932 351.366 257.981 350.691 258.791 349.775L268.706 338.567C270.059 337.038 270.631 334.97 270.259 332.962L267.574 318.508C267.264 316.838 266.326 315.351 264.952 314.353L251.387 304.499C249.92 303.434 248.953 301.815 248.712 300.018L247.275 289.349C246.832 286.058 244.024 283.602 240.703 283.602Z"
                fill="#FFDA33"
              />
            </g>
          </g>
          <mask
            id="mask8_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="190"
            y="95"
            width="196"
            height="212"
          >
            <path
              d="M209.051 95.5625C198.806 95.5625 190.5 103.868 190.5 114.114V287.545C190.5 297.79 198.806 306.095 209.051 306.095H366.682C376.928 306.095 385.232 297.79 385.232 287.545V114.114C385.232 103.868 376.928 95.5625 366.682 95.5625H209.051Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask8_5794_68403)">
            <path
              d="M279.33 239.48L275.666 259.619L286.942 261.671L290.758 240.697C290.758 240.697 279.469 239.506 279.33 239.48Z"
              fill="#336666"
            />
            <path
              d="M275.658 259.613C275.658 259.613 275.316 261.492 274.879 261.916C274.442 262.34 272.722 262.314 269.608 263.617C266.495 264.92 267 269.848 267 269.848L284.73 272.984L287.073 260.898L279.796 259.79C279.796 259.79 279.309 258.12 277.784 257.806C276.259 257.493 276.085 257.461 276.085 257.461C276.085 257.461 275.785 258.916 275.658 259.613Z"
              fill="#FFDA33"
            />
            <path
              d="M283.966 274.067L267.213 271.019C266.598 270.907 266.191 270.318 266.303 269.704L266.523 268.495C266.634 267.881 267.223 267.473 267.838 267.585L284.591 270.633C285.205 270.745 285.613 271.334 285.501 271.948L285.282 273.156C285.17 273.771 284.581 274.179 283.966 274.067Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M275.133 261.062L274.968 261.903L278.1 262.519L278.265 261.678L275.133 261.062Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M275.472 259.223L275.307 260.063L278.439 260.679L278.604 259.838L275.472 259.223Z"
              fill="white"
            />
            <path
              d="M279.257 260.375C279.185 260.771 278.805 261.033 278.409 260.961C278.013 260.889 277.75 260.51 277.822 260.114C277.895 259.718 278.274 259.456 278.67 259.528C279.066 259.599 279.329 259.979 279.257 260.375Z"
              fill="white"
            />
            <path
              d="M278.928 262.223C278.856 262.618 278.476 262.881 278.08 262.809C277.684 262.737 277.421 262.357 277.493 261.962C277.566 261.566 277.945 261.303 278.341 261.375C278.737 261.447 279 261.827 278.928 262.223Z"
              fill="white"
            />
            <path
              d="M311.541 243.266L314.248 251.83C314.912 253.93 316.178 255.789 317.891 257.175L322.445 260.859L332.475 254.274L327.632 250.242C325.976 248.864 324.752 247.038 324.105 244.983L322.264 239.133C322.264 239.133 311.676 243.225 311.541 243.266Z"
              fill="#336666"
            />
            <path
              d="M322.832 260.98C322.832 260.98 324.413 262.052 324.625 262.622C324.837 263.192 324.119 264.757 324.055 268.131C323.991 271.506 328.703 273.032 328.703 273.032L338.728 258.075L328.615 251.055L324.665 257.265C324.665 257.265 322.94 257.037 322.037 258.305C321.136 259.574 321.036 259.721 321.036 259.721C321.036 259.721 322.246 260.583 322.832 260.98Z"
              fill="#FFDA33"
            />
            <path
              d="M339.406 259.212L329.856 273.31C329.505 273.827 328.802 273.963 328.285 273.612L327.269 272.924C326.751 272.573 326.616 271.87 326.966 271.353L336.516 257.255C336.867 256.738 337.569 256.602 338.087 256.953L339.104 257.641C339.621 257.992 339.756 258.695 339.406 259.212Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M325.773 259.43L323.946 262.047L324.649 262.537L326.475 259.92L325.773 259.43Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M324.22 258.379L322.394 260.996L323.096 261.486L324.922 258.869L324.22 258.379Z"
              fill="white"
            />
            <path
              d="M324.97 257.989C325.303 258.214 325.39 258.668 325.164 259.001C324.939 259.335 324.486 259.421 324.153 259.196C323.819 258.97 323.732 258.517 323.957 258.183C324.183 257.851 324.637 257.763 324.97 257.989Z"
              fill="white"
            />
            <path
              d="M326.533 259.047C326.866 259.273 326.954 259.726 326.728 260.06C326.502 260.393 326.049 260.48 325.716 260.254C325.382 260.028 325.295 259.575 325.521 259.242C325.747 258.909 326.2 258.821 326.533 259.047Z"
              fill="white"
            />
            <path
              d="M255.207 160.3L212.226 176.783C210.111 177.594 207.738 176.537 206.927 174.421L196.582 147.446C195.77 145.331 196.827 142.958 198.943 142.147L241.924 125.664C244.04 124.852 246.412 125.91 247.224 128.025L257.568 155C258.38 157.116 257.322 159.488 255.207 160.3Z"
              fill="#FFDA33"
            />
            <path
              d="M211.643 161.481C223.529 166.273 236.771 157.035 235.147 148.111C222.785 141.436 211.532 151.677 211.643 161.481Z"
              fill="#336666"
            />
            <path
              d="M238.612 135.828C234.441 139.62 233.406 143.765 234.454 148.145C236.468 152.306 241.732 153 248.376 152.222C246.308 148.868 243.658 145.999 239.882 145.901C240.521 142.652 240.204 139.091 238.612 135.828Z"
              fill="#336666"
            />
            <path
              d="M219.139 156.949C219.081 156.282 218.493 155.79 217.827 155.848C217.161 155.907 216.669 156.494 216.727 157.16C216.786 157.826 217.373 158.319 218.039 158.26C218.705 158.202 219.197 157.615 219.139 156.949Z"
              fill="#00CCFF"
            />
            <path
              d="M231.158 177.079L235.639 175.711L240.072 177.786L242.135 183.697L240.968 185.568L236.441 185.38L233.705 182.173L231.158 177.079Z"
              fill="#336666"
            />
            <path
              d="M242.437 184.152C242.437 184.152 242.95 180.563 241.955 178.009C241.319 176.373 241.022 175.891 241.022 175.891L239.279 176.248L239.731 179.668L239.625 179.682L242.437 184.152Z"
              fill="#336666"
            />
            <path
              d="M254.159 202.546C254.159 202.546 243.546 204.739 239.816 196.652C239.058 195.009 237.88 193.306 236.878 191.556C234.07 186.649 233.261 184.897 233.261 184.897L241.346 182.547C241.346 182.547 241.999 183 243.774 185.797C244.814 187.436 247.227 189.954 248.711 191.863C249.761 193.213 254.159 192.854 254.583 192.854L254.159 202.546Z"
              fill="#336666"
            />
            <path
              d="M238.849 187.853L228.868 165.373L235.255 162.883L243.125 186.186L238.849 187.853Z"
              fill="#FFDA33"
            />
            <path
              d="M235.497 184.563C235.744 184.012 240.527 180.669 240.527 180.669L239.996 179.24L235.323 181.552L235.287 181.07L240.06 178.196L239.494 176.541L234.325 179.518L234.207 179.255L238.902 176.102L238.295 174.906C236.4 175.74 232.63 177.732 232.63 177.732L232.594 177.507L236.452 175.26L235.741 173.963C235.741 173.963 235.548 173.63 231.694 175.827C229.002 177.36 230.054 180.691 230.577 182.127C231.992 186.019 234.391 186.552 234.391 186.552L235.497 184.563Z"
              fill="#336666"
            />
            <path
              d="M356.084 193.089C356.084 222.159 332.518 245.724 303.448 245.724C274.378 245.724 250.812 222.159 250.812 193.089C250.812 164.019 274.378 140.453 303.448 140.453C332.518 140.453 356.084 164.019 356.084 193.089Z"
              fill="#00CCFF"
            />
          </g>
          <mask
            id="mask9_5794_68403"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="250"
            y="140"
            width="107"
            height="106"
          >
            <path
              d="M250.808 193.089C250.808 222.159 274.374 245.725 303.443 245.725C332.513 245.725 356.079 222.159 356.079 193.089C356.079 164.019 332.513 140.453 303.443 140.453C274.374 140.453 250.808 164.019 250.808 193.089Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask9_5794_68403)">
            <path
              d="M290.711 147.777C290.764 151.582 293.83 154.657 297.635 154.719L311.636 154.951C312.942 154.973 314.216 155.357 315.316 156.061L322.729 160.805C325.641 162.669 329.476 162.143 331.778 159.563L337.248 153.434C340.356 149.951 339.277 144.463 335.082 142.417L329.619 139.752C328.538 139.225 327.341 138.983 326.141 139.05L297.361 140.649C293.592 140.858 290.659 144.002 290.711 147.777Z"
              fill="#FFDA33"
            />
            <path
              d="M337.988 256.418L328.374 251.221C323.312 248.485 321.925 241.856 325.465 237.32C326.934 235.438 327.623 233.063 327.389 230.687L326.197 218.598C325.928 215.87 326.876 213.163 328.789 211.199L333.389 206.475C335.208 204.606 336.159 202.061 336.01 199.458L335.551 191.437C335.296 186.97 338.258 182.955 342.601 181.88L356.551 178.429C360.191 177.528 364.016 178.903 366.251 181.913L385.696 208.113C387.961 211.164 388.141 215.286 386.151 218.523L374.346 237.732C373.308 239.42 372.837 241.396 373.002 243.372L373.521 249.597C374.003 255.38 369.128 260.187 363.352 259.624L341.507 257.492C340.274 257.372 339.078 257.007 337.988 256.418Z"
              fill="#FFDA33"
            />
            <path
              d="M249.08 186.859H257.894C261.408 186.859 264.283 189.654 264.384 193.166L264.882 210.627C264.979 213.991 267.63 216.725 270.991 216.922C275.267 217.174 278.132 221.425 276.759 225.482L270.977 242.584C270.32 244.526 270.62 246.665 271.784 248.352L275.747 254.091C279.243 259.154 274.391 265.787 268.507 263.988L234.339 253.543C233.193 253.193 232.168 252.533 231.374 251.636L221.669 240.665C220.344 239.167 219.783 237.143 220.148 235.177L222.776 221.029C223.08 219.394 223.998 217.938 225.343 216.962L238.622 207.316C240.058 206.273 241.004 204.687 241.24 202.929L242.647 192.485C243.08 189.263 245.83 186.859 249.08 186.859Z"
              fill="#FFDA33"
            />
          </g>
          <path d="M295.719 181.5L294.447 180.93L290.067 184.906L296.028 188.139L295.719 181.5Z" fill="#FF6B1C" />
          <path
            d="M310.33 179.051C310.213 177.452 308.823 176.251 307.224 176.367C305.626 176.484 304.425 177.874 304.541 179.472C304.657 181.071 306.048 182.272 307.646 182.156C309.245 182.039 310.446 180.649 310.33 179.051Z"
            fill="#336666"
          />
          <path
            d="M283.873 180.976C283.756 179.378 282.366 178.176 280.767 178.293C279.169 178.41 277.968 179.8 278.084 181.398C278.2 182.997 279.591 184.198 281.189 184.082C282.788 183.966 283.989 182.575 283.873 180.976Z"
            fill="#336666"
          />
          <path
            d="M276.943 170.003L276.718 168.738C278.657 168.391 280.484 168.309 282.148 168.496L282.005 169.773C280.465 169.601 278.762 169.678 276.943 170.003Z"
            fill="#336666"
          />
          <path
            d="M303.44 168.218L303.112 166.975C304.73 166.549 306.55 166.362 308.521 166.426L308.481 167.711C306.633 167.653 304.938 167.823 303.44 168.218Z"
            fill="#336666"
          />
          <path d="M311.377 141.164L329.204 141.755L329.318 143.113H314.528L311.377 141.164Z" fill="#FF6B1C" />
          <path
            d="M293.302 143.118H314.529C314.529 143.118 314.529 130.887 303.916 131.716C293.302 132.545 293.302 143.118 293.302 143.118Z"
            fill="#FF6B1C"
          />
          <path
            d="M294.545 192.069C291.79 192.069 289.765 191.096 289.622 191.025L290.191 189.873L289.907 190.449L290.19 189.872C290.41 189.98 295.641 192.468 300.554 188.734L301.332 189.758C298.98 191.545 296.566 192.069 294.545 192.069Z"
            fill="#336666"
          />
          <path
            d="M352.248 214.207C352.248 214.207 352.106 217.829 353.358 220.268C354.159 221.83 354.503 222.279 354.503 222.279L356.2 221.745L355.4 218.389L355.504 218.365L352.248 214.207Z"
            fill="#336666"
          />
          <path
            d="M343.703 196.747C345.796 193.696 350.365 193.909 352.167 197.141C353.975 200.383 356.016 203.999 357.357 206.24C360.259 211.092 361.101 212.829 361.101 212.829L353.063 215.333C353.063 215.333 352.401 214.893 350.572 212.13C349.381 210.329 345.983 205.569 343.761 202.471C342.537 200.766 342.515 198.478 343.703 196.747Z"
            fill="#336666"
          />
          <path
            d="M355.506 218.372C356.031 218.672 358.888 223.76 358.888 223.76L360.362 223.372L358.519 218.495L359.003 218.506L361.395 223.538L363.097 223.137L360.642 217.701L360.915 217.61L363.592 222.591L364.843 222.104C364.199 220.137 362.586 216.189 362.586 216.189L362.813 216.176L364.671 220.236L366.031 219.656C366.031 219.656 366.381 219.495 364.574 215.445C363.311 212.615 359.951 211.316 359.951 211.316L352.243 214.209L355.506 218.372Z"
            fill="#336666"
          />
          <path d="M311.857 140.496H295.636V141.781H311.857V140.496Z" fill="#336666" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5794_68403">
          <rect width="374" height="777" fill="white" />
        </clipPath>
        <clipPath id="clip1_5794_68403">
          <rect width="374" height="777" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
