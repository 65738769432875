import { useEffect, useState } from 'react'

import { getRecommendedAuthors } from '@/services/authorService'

const useRecommendedProfiles = () => {
  const [recommendedProfiles, setRecommendedProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const getRandom = (array) => {
    const userProfiles = array.filter((item) => item.Type === 'user')

    return userProfiles.sort(() => Math.random() - 0.5).slice(0, 3)
  }

  const getProfiles = async () => {
    setLoading(true)
    try {
      const recommendedProfilesData = await getRecommendedAuthors()
      const randomProfiles = getRandom(recommendedProfilesData)
      setRecommendedProfiles(randomProfiles)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProfiles()
  }, [])
  return { loading, recommendedProfiles }
}

export default useRecommendedProfiles
