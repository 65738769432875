import { AxiosResponse } from 'axios'

import { CreateEvent, FindCompanyAnalyticsByUUIDAndAggregateArgs } from '@/ts/types/analytics.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const createEvent = ({
  companyUUID,
  name,
  resourceUUID,
  userUUID,
}: CreateEvent): Promise<AxiosResponse<void>> => {
  return axios.post('/analytics/events', {
    CompanyUUID: companyUUID,
    Name: name,
    ResourceUUID: resourceUUID,
    UserUUID: userUUID,
  })
}

export const findCompanyAnalyticsByUUIDAndAggregate = ({
  companyUUID,
  aggregate,
}: FindCompanyAnalyticsByUUIDAndAggregateArgs): Promise<AxiosResponse<void>> => {
  return axios.post('/analytics/events/query', {
    CompanyUUID: companyUUID,
    Aggregate: aggregate,
  })
}

export const uploadWhitepaperUser = (values) => {
  return axios.post('/users/whitepaper/downloads', values)
}

export const getODS = async (index, accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  const res = await axiosInstance.get(`/ods?Index=${index}`)
  return res.data.Data[0]
}
