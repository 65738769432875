import { useRouter } from 'next/router'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import useRecommendedProfiles from '@/hooks/useRecommendedProfiles'
import { Ods } from '@/ts/types/general.types'

import RecommendedProfileSnippetCard from './RecommendedProfileSnippetCard'

type Props = {
  /**
   * ClassNames
   */
  className?: string
  /**
   * Style inline object
   */
  text?: string
  /**
   * Activate Lorem Ipsum for testing purposes
   */
  image?: string
  /**
   * ClassNames
   */
  objective?: Ods
}

const RecommendedProfileSnippets = ({ className, text, image, objective, ...rest }: Props): JSX.Element => {
  const router = useRouter()
  const { loading, recommendedProfiles } = useRecommendedProfiles()

  return (
    <div className="my-4">
      {!loading && (
        <>
          <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
            Te recomendamos seguir
          </CustomText>

          <div className="grid grid-cols-3 gap-3 mt-4">
            {recommendedProfiles.map((item) => (
              <RecommendedProfileSnippetCard
                key={item.UUID}
                image={item?.Photo?.Thumbnail}
                name={item?.Name}
                profile={item}
                levelColor={item?.LevelColor}
                onClick={() => router.push(`/profile/u/${item.UUID}`)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default RecommendedProfileSnippets
