import React from 'react'

import { SvgIcon } from '../../CustomIcon'
const Actions = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.4969 16.3673C27.7725 23.5404 21.4986 28.9336 14.2359 28.5C6.78508 28.0551 1.0971 21.6448 1.542 14.1939C1.98682 6.74308 8.39714 1.05517 15.848 1.5C17.8163 1.61756 19.6617 2.15139 21.3034 3.01077"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="2.35465"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1836 7.23307C26.2991 5.25423 28.5302 5.25424 29.6457 6.24366C30.7613 7.23308 30.7613 9.21191 29.6457 11.1908C28.8648 12.6749 26.8569 14.159 25.1836 15.1484C23.5103 14.159 21.5023 12.6749 20.7214 11.1908C19.6059 9.21191 19.6059 7.23308 20.7214 6.24366C21.8369 5.25424 24.068 5.25423 25.1836 7.23307Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.97562 2.77443C9.91285 2.53277 9.48071 4.28645 13.0643 5.7947C13.1442 5.82837 14.8486 6.74595 14.7209 4.32712C16.3048 5.03017 18.7005 5.61253 16.3776 7.26234C14.8543 8.34424 15.0396 8.64928 15.7363 10.2987C16.7341 12.6609 12.742 9.98696 9.61747 12.576C8.46319 13.5325 10.3199 15.2907 10.4992 15.4606"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.27344 7.00391C4.37247 8.14647 4.63087 11.726 5.84962 12.8801C6.62525 13.6147 10.2362 15.5843 10.7126 15.7647"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9119 25.7974C14.9137 25.8018 12.1585 25.0483 12.6852 22.8633C13.5007 19.4802 11.1677 19.9861 10.8604 17.8772C10.6002 16.0911 11.8478 14.1336 14.4556 15.0357C15.6794 15.4591 15.6426 15.531 16.9303 15.5952C18.7577 15.6862 19.0631 17.8932 17.293 19.6036C16.1251 20.732 14.0989 25.1821 14.9119 25.7974Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Actions
