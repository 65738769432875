import { SvgIcon } from '../CustomIcon'

const Star = ({ size, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M24.2805 32C23.8596 31.9997 23.4458 31.8921 23.078 31.6875L16.3986 28.025C16.2735 27.9557 16.1329 27.9194 15.9899 27.9194C15.8469 27.9194 15.7062 27.9557 15.5811 28.025L8.90175 31.6875C8.53377 31.8922 8.1197 31.9997 7.69863 32C7.31695 31.9974 6.94075 31.909 6.59782 31.7414C6.25489 31.5738 5.954 31.3314 5.71738 31.0319C5.47466 30.7275 5.29906 30.3753 5.20211 29.9983C5.10517 29.6213 5.08907 29.228 5.15488 28.8444L6.42738 21.0906C6.45231 20.9451 6.44268 20.7957 6.39925 20.6546C6.35583 20.5134 6.27982 20.3845 6.17738 20.2781L0.771752 14.7869C0.422774 14.4289 0.177611 13.9829 0.0624637 13.4965C-0.0526834 13.01 -0.0334869 12.5014 0.118002 12.025C0.424252 11.0425 1.22363 10.3375 2.20363 10.1912L9.67175 9.0625C9.81462 9.03984 9.94972 8.98237 10.0651 8.89518C10.1806 8.80798 10.2728 8.69373 10.3336 8.5625L13.673 1.5C14.1149 0.575625 14.9993 0 15.9899 0C16.9805 0 17.868 0.575625 18.3068 1.5L21.6468 8.55625C21.7074 8.6875 21.7995 8.80179 21.9148 8.88899C22.0301 8.9762 22.1652 9.03365 22.308 9.05625L29.7761 10.1881C30.7561 10.3369 31.5555 11.0394 31.8617 12.0219C32.0132 12.4983 32.0324 13.0069 31.9173 13.4933C31.8021 13.9798 31.557 14.4258 31.208 14.7837L25.8024 20.2794C25.6998 20.3857 25.6238 20.5146 25.5804 20.6558C25.5369 20.7969 25.5273 20.9463 25.5524 21.0919L26.828 28.8475C26.8938 29.2312 26.8777 29.6244 26.7808 30.0014C26.6838 30.3784 26.5082 30.7307 26.2655 31.035C26.0282 31.3343 25.7267 31.5764 25.3832 31.7434C25.0397 31.9105 24.6631 31.9982 24.2811 32H24.2805Z"
        fill="#E3A400"
      />
      <path
        d="M17.5416 1.89494L20.881 8.95181C21.001 9.21027 21.1827 9.43531 21.41 9.60716C21.6373 9.77901 21.9034 9.89242 22.1847 9.93744L29.6522 11.0687C31.0722 11.2843 31.6391 13.1037 30.6122 14.1481L25.2079 19.6431C25.0051 19.8515 24.8542 20.1047 24.7674 20.3822C24.6805 20.6597 24.6601 20.9537 24.7079 21.2406L25.9891 28.9999C26.2316 30.4743 24.7472 31.5993 23.4772 30.9031L16.7947 27.2381C16.5481 27.1019 16.2709 27.0304 15.9891 27.0304C15.7073 27.0304 15.4302 27.1019 15.1835 27.2381L8.50412 30.8999C7.23412 31.5962 5.74974 30.4712 5.99224 28.9968L7.26787 21.2406C7.31561 20.9537 7.29523 20.6597 7.20838 20.3822C7.12152 20.1047 6.97062 19.8515 6.76787 19.6431L1.36412 14.1499C0.337241 13.1056 0.904116 11.2862 2.32412 11.0706L9.79349 9.93744C10.0751 9.89224 10.3412 9.77854 10.5686 9.60635C10.7959 9.43416 10.9775 9.20875 11.0972 8.94994L14.4366 1.89494C15.0716 0.553686 16.9066 0.553686 17.5416 1.89494Z"
        fill="url(#paint0_linear_1_3)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_3"
          x1="15.9882"
          y1="0.888998"
          x2="15.9882"
          y2="31.1144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE748" />
          <stop offset="1" stopColor="#E6B400" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default Star
