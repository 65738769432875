import { SvgIcon } from '../../CustomIcon'

const SNT = ({ size, color, ...rest }: SvgIcon) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0001 31.13C24.4258 31.13 31.2561 24.3559 31.2561 15.9996C31.2561 7.64326 24.4258 0.869141 16.0001 0.869141C7.57448 0.869141 0.744141 7.64326 0.744141 15.9996C0.744141 24.3559 7.57448 31.13 16.0001 31.13Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3267 7.43848L7.8342 11.7429L7.5708 20.5928L13.3043 24.559"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6729 7.43848L24.1656 11.7429L24.429 20.5928L18.6953 24.559"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9996 11.9961C15.9996 11.9961 18.5165 15.8372 18.5165 17.4833C18.5165 18.8725 17.3886 20.0004 15.9996 20.0004C14.6103 20.0004 13.4824 18.8725 13.4824 17.4833C13.4824 15.8372 15.9996 11.9961 15.9996 11.9961Z"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SNT
