/* eslint-disable jsx-a11y/media-has-caption */
import videojs from '@mux/videojs-kit'
import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import { muxEnvKey } from '@/config'

type Props = {
  id: string
  className?: string
  customID: string
  isPostModal?: boolean
  pause: () => void
  unPause: () => void
}

const MuxVideoStorie = ({ id, className, customID, pause, unPause }: Props): JSX.Element => {
  const videoRef = useRef(null)
  const VideoOptions = {
    plugins: {
      mux: {
        timelineHoverPreviews: true,
        data: {
          env_key: muxEnvKey,
          video_id: id,
          video_title: customID ? `${customID}-video-${id}` : `video-preview-${id}`,
          player_name: 'VideoJS',
        },
      },
    },
  }

  useEffect(() => {
    if (videoRef.current) {
      const player = videojs(videoRef.current, VideoOptions)

      player.src({
        type: 'video/mux',
        src: id,
      })

      player.poster(`https://image.mux.com/${id}/thumbnail.png?width=742&height=417`)
    }
  }, [videoRef])

  return (
    <div
      key={customID}
      className={clsx('h-full flex flex-col items-center justify-center absolute inset-0', className)}
    >
      <video
        onLoadStart={pause}
        onLoadedData={unPause}
        ref={videoRef}
        className={`video-js vjs-16-9 m-auto`}
        preload="auto"
        autoPlay
      />
    </div>
  )
}

export default MuxVideoStorie
